import React, { useState } from "react";
import { Modal, Button, Space, Steps, message } from "antd";
import TradingAssetForm from "../../models/TradingAsset";
import TradingAssetChainForm from "../../models/TradingAssetChain";
import { EditOutlined } from "@ant-design/icons";
import ClientInfo from "../../models/ClientInfo";
import { update } from "../../services/apiService";

const { Step } = Steps;

const Edit = ({ assetId, onUpdateSuccess }) => {
  const [visible, setVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(1); // Track current step
  const [formData, setFormData] = useState({}); // Store form data

  const toggleForm = () => {
    setVisible(!visible);
  };

  const handleNextStep = (data) => {
    setFormData((prevData) => ({
      ...prevData,
      ...data,
    }));
    setCurrentStep(2); // Move to next step
  };

  const onPrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1); // Go back to previous step
    }
  };

  const handleFinalSubmit = async (data) => {
    try{
        const response = await update('/api/v1/trading-assets/update', {id: assetId}, data);
        
        if(response.status === 200 && response?.data.data){
            message.success(`Asset successfully updated.`);
            onUpdateSuccess(Math.random() > 0.5);
            toggleForm();
            console.log(response.data.data)
        }
    }catch(error){
        message.error(`Could not update asset information`);
    }
  };

  if (!visible) {
    return (
      <Button
          type="text"
          icon={<EditOutlined />}
          style={{
              padding: 0, // Removes padding for a cleaner look
              display: "flex", // Ensures proper alignment
              alignItems: "center",
              justifyContent: "center",
          }}
          onClick={toggleForm} // Properly placed onClick handler
      />
    );
  }

  return (
    <div className="max-w-4xl mx-auto mt-8">
      <Modal
        title="Edit Asset"
        open={visible}
        onCancel={toggleForm}
        footer={null}
        width={1200}
        destroyOnClose
        style={{ maxHeight: '70vh', overflowY: 'auto' }}
      >
        <TradingAssetForm onNextStep={handleFinalSubmit} assetId={assetId} />
      </Modal>
    </div>
  );
};

export default Edit;
