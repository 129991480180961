import React, { useState } from 'react';
import PaginatedTable2 from '../../../../components/PaginatedTable2';
import { useApiQuery } from '../../../../hooks/useApiRequest';
import { formatDate } from '../../../../utils/formateData';

const Index = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);

    const { data, isLoading } = useApiQuery({
        key: ['login-history'],
        endPoint: `/api/v1/login-history/get-all?page=${currentPage}&limit=${pageSize}`,
        method: 'GET',
    });

    const pagination = {
        totalItems: data?.totalItems || 0,
        totalPages: data?.totalPages || 1,
        currentPage,
        limit: pageSize,
        startIndex: (currentPage - 1) * pageSize + 1,
        endIndex: Math.min(currentPage * pageSize, data?.totalItems || 0),
    };

    const handlePageChange = (page, size) => {
        setCurrentPage(page);
        setPageSize(size);
    };

    const columns = [
        {
            title: 'Device Name',
            dataIndex: `device`,
            key: 'device',
        },
        {
            title: 'Browser Name',
            dataIndex: 'browser',
            key: 'browser',
            render: (text) => text || 'N/A'
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date) => formatDate(date),
        },
        {
            title: 'IP Addresses',
            dataIndex: 'ipAddress',
            key: 'ipAddress',
            render: (text) => text?.toUpperCase() || 'N/A',
        }
    ];

    return (
        <div className="mx-auto p-6 bg-white">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-xl font-semibold">Login History</h1>
            </div>
            <PaginatedTable2
                data={data?.results}
                columns={columns}
                pagination={pagination}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageChange}
                loading={isLoading}
            />
        </div>

    );
};

export default Index;


