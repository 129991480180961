import React, { useState, useEffect } from "react";
import { Modal, Input, message } from "antd";
import { useUpdateNotificationService } from "../services/notificationService";

const UpdateNotificationModal = ({
  visible,
  onCancel,
  onSubmit,
  notificationData,
}) => {
  const [updatedHeading, setUpdatedHeading] = useState("");
  const [updatedMessage, setUpdatedMessage] = useState("");
  const { updateNotification } = useUpdateNotificationService();

  useEffect(() => {
    if (notificationData) {
      setUpdatedHeading(notificationData.heading || "");
      setUpdatedMessage(notificationData.message || "");
    }
  }, [notificationData]);

  const handleUpdateSubmit = async () => {
    try {
      await updateNotification(
        { heading: updatedHeading, message: updatedMessage },
        notificationData._id,
        () => {
          onSubmit();
          onCancel();
        },
        () => {}
      );
    } catch (err) {}
  };

  return (
    <Modal
      title="Update Notification"
      visible={visible}
      onCancel={onCancel}
      onOk={handleUpdateSubmit}
      okText="Save"
    >
      <div className="mb-4">
        <label className="block mb-2">Heading</label>
        <Input
          value={updatedHeading}
          onChange={(e) => setUpdatedHeading(e.target.value)}
        />
      </div>
      <div>
        <label className="block mb-2">Message</label>
        <Input.TextArea
          value={updatedMessage}
          onChange={(e) => setUpdatedMessage(e.target.value)}
          rows={4}
        />
      </div>
    </Modal>
  );
};

export default UpdateNotificationModal;
