import { CameraOutlined } from "@ant-design/icons";
import { Button, Modal, Upload, Form, Input, message } from "antd";
import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { create, updateWithFile } from "../../services/apiService";

const AddImage = ({ assetId, onUpdateSuccess }) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const toggleForm = () => {
    setVisible(!visible);
  };

    const handleSubmit = async (values) => {
        try {
        //   const formData = new FormData();
        //   formData.append("image", values.image.file.originFileObj);
        //   formData.append("title", values.title);
        const { images } = values;
        //console.log(data);

        // Collect files as a list of File objects
        const fileList = images?.fileList?.map((file) => file.originFileObj) || [];

        const response = await updateWithFile(`/api/v1/ncw-assets/upload-image/${assetId}`, {}, fileList);

        if (response.status === 200) {
                message.success("Asset icon successfully!");
                onUpdateSuccess(Math.random());
                toggleForm();
                //onNextStep(values); // Pass form data to the next step
            } else {
                message.error(`Submission failed: ${response.data.message}`);
            }
        } catch (error) {
            console.log(error);
            message.error("Failed to upload image");
        }
    };

    if (!visible) {
        return (
        <Button
            type="text"
            icon={<CameraOutlined />}
            style={{
            padding: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            }}
            onClick={toggleForm}
        />
        );
    }

  return (
    <div className="max-w-4xl mx-auto mt-8">
      <Modal
        title="Upload Image"
        open={visible}
        onCancel={toggleForm}
        footer={null}
        destroyOnClose
        style={{ maxHeight: "70vh", overflowY: "auto" }}
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          {/*<Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: "Please enter a title" }]}
          >
            <Input placeholder="Enter image title" />
          </Form.Item>*/}

          <Form.Item
            name="images"
            label="Image"
            valuePropName="fileList"
            getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}
            rules={[{ required: true, message: "Please upload an image" }]}
          >
            <Upload
              listType="picture"
              maxCount={1}
              beforeUpload={() => false} // Prevents auto-upload
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddImage;
