import { Breadcrumb, Space, Tag, Typography } from 'antd';
import React, { useState } from 'react';
import PaginatedTable from '../../components/PaginatedTable';
import AdminLayout from '../../layouts/AdminLayout';
import ToggleStatus from '../../components/ToggleStatus';
import AddImage from './AddImage';
/*import Create from './Create';
import Delete from './Delete';
import Edit from './Edit';
import View from './View';*/

const { Title } = Typography;

const Index = () => {
  const [refresh, setRefresh] = useState(false);

  const columns = [
    {
      title: 'Fireblocks ID',
      dataIndex: 'id',
      key: 'id',
      render: (text) => <strong>{text || 'N/A'}</strong>, // Fallback to 'N/A' if text is undefined or null
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      //render: (text) => (text ? text.toUpperCase() : 'N/A'), // Check if text exists before calling toUpperCase
    },
    {
        title: 'Symbol',
        dataIndex: 'symbol',
        key: 'symbol',
        //render: (text) => (text ? text.toUpperCase() : 'N/A'), // Check if text exists before calling toUpperCase
    },
    {
        title: "Status",
        dataIndex: "enabled",
        key: "status",
        render: (enabled, record) => (
          <ToggleStatus
            enabled={enabled}
            resourceId={record.id}
            onToggle={setRefresh} // Refresh table data on toggle
            toggleUrl={`/api/v1/ncw-assets/toggle-status/${record._id}`}
          />
        ),
    },
    {/*
        title: 'Status',
        dataIndex: 'enabled',
        key: 'symbol',
        render: (enabled) => (
            <Tag color={enabled  ? 'green' : 'red'}>
              {enabled ? "Enabled" : "Disabled"}
            </Tag>
        ),
    */},
    {
        title: 'Network Protocol',
        dataIndex: 'networkProtocol',
        key: 'networkProtocol',
        //render: (text) => (text ? text.toUpperCase() : 'N/A'), // Check if text exists before calling toUpperCase
    },
    {
        title: 'Decimals',
        dataIndex: 'decimals',
        key: 'decimlas',
        //render: (text) => (text ? text.toUpperCase() : 'N/A'), // Check if text exists before calling toUpperCase
    },
    {/*{
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type) => (
        <Tag color={type === 'Crypto' ? 'blue' : 'green'}>
          {type === 'Crypto' ? 'Crypto' : type === 'Fiat' ? 'Fiat' : 'Unknown'}
        </Tag>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status === 'Enabled' ? 'green' : 'red'}>
          {status || 'Unknown'}
        </Tag>
      ),
    */},
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <AddImage assetId={record._id} onUpdateSuccess={setRefresh} />
        </Space>
      ),
    }
  ];


  const filters = [
    { name: "assetName", label: "Asset Name", type: "text" },
    { name: "asset", label: "Asset", type: "text" },
    { name: "firstname", label: "First Name", type: "text" },
    { name: "lastname", label: "Last Name", type: "text" },
    { name: "identity_number", label: "Identity Number", type: "text" },
    { name: "type", label: "Type", type: "dropdown", options: ["Fiat", "Crypto"] },
    { name: "status", label: "Status", type: "dropdown", options: [true, false] },
  ];

  return (
    <AdminLayout currentKey='asset-sub-2' openKey='3'>
      <div className="mx-auto p-6 bg-white">
        {/* Breadcrumb */}
        <Breadcrumb className="mb-4">
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item>NCW Supported Assets</Breadcrumb.Item>
          <Breadcrumb.Item>Manage Supported Assets</Breadcrumb.Item>
        </Breadcrumb>


        {/*<Create onCreateSuccess={setRefresh} />*/}

        <PaginatedTable
          fetchEndpoint="/api/v1/ncw-assets/index"
          columns={columns}
          //actionColumn={actionColumn}
          isSearchable={true}
          isSelectable={true}
          filters={filters}
          refresh={refresh}
        />
      </div>
    </AdminLayout>
  );
};

export default Index;
