// src/Modals/BrokerCommissions/DeleteModal.jsx

import React, { useState } from 'react';
import { Modal, message } from 'antd';
import axios from 'axios';

const DeleteModal = ({ visible, onCancel, data, onDelete }) => {
    const [loading, setLoading] = useState(false);

    if (!data) {
        return null;
    }

    const handleDelete = async () => {
        setLoading(true);
        try {
            await onDelete(data._id);
            message.success('Broker commission deleted successfully.');
            onCancel();
        } catch (error) {
            message.error('Error deleting broker commission.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title="Delete Broker Commission"
            visible={visible}
            onCancel={onCancel}
            onOk={handleDelete}
            okText="Delete"
            okButtonProps={{ danger: true, loading }}
        >
            <p>Are you sure you want to delete the broker commission for broker "{data.brokerId}"?</p>
        </Modal>
    );
};

export default DeleteModal;
