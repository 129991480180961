import { useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { useApiMutation } from "../hooks/useApiRequest";

//*********** add user Withdrawal**********
export const AddEmailsTemplateService = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, error } = useApiMutation();

  const AddEmailsTemplate = async (values, onSuccess, onError) => {
    try {
      await mutateAsync({
        key: ["add-emails-template"],
        endPoint: `/api/v1/email-templates/create`,
        method: "POST",
        bodyContent: values,
      });
      queryClient.invalidateQueries({ queryKey: ["emails"] });
      queryClient.invalidateQueries({ queryKey: ["add-emails-template"] });
      onSuccess();
      message.success("Successfully added Emails");
    } catch (error) {
      onError();
      message.error(error.message);
    }
  };

  return { AddEmailsTemplate, error };
};

//*********** Update user Withdrawal**********
export const UpdateEmailTemplateService = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, error } = useApiMutation();

  const UpdateEmailTemplate = async (id, values, onSuccess, onError) => {
    try {
      await mutateAsync({
        key: ["emails-templates-update"],
        endPoint: `/api/v1/email-templates/update?id=${id}`,
        method: "PATCH",
        bodyContent: values,
      });
      queryClient.invalidateQueries({ queryKey: ["emails"] });
      queryClient.invalidateQueries({ queryKey: ["emails-templates-update"] });
      onSuccess();
      message.success("Successfully updated");
    } catch (error) {
      onError();
      message.error(error.message);
    }
  };

  return { UpdateEmailTemplate, error };
};

//*********** Delete user Withdrawal **********
export const DeleteEmailsTemplateServices = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, error } = useApiMutation();

  const DeleteEmailsTemplate = async (id, onSuccess, onError) => {
    try {
      await mutateAsync({
        key: ["emais-templates-delete"],
        endPoint: `/api/v1/email-templates/delete?id=${id}`,
        method: "DELETE",
      });
      queryClient.invalidateQueries({ queryKey: ["emails"] });
      queryClient.invalidateQueries({ queryKey: ["emais-templates-delete"] });
      onSuccess();
      message.success("Successfully deleted");
    } catch (error) {
      onError();
      message.error(error.message);
    }
  };

  return { DeleteEmailsTemplate, error };
};
