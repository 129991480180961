import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
//import 'antd/dist/antd.css';
import { Auth0Provider } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const queryClient = new QueryClient();
const baseURL = process.env.REACT_APP_BASE_URL
console.log('Base URL: ', baseURL);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-fu88hpvhk3wk7i2x.us.auth0.com"
      clientId="BdKIK1lRYdsWJyx2d80vtNsOfULtJk18"
      authorizationParams={{
        audience: "https://dev-fu88hpvhk3wk7i2x.us.auth0.com/api/v2/",
        scope: "openid profile email",
        redirect_uri: baseURL,
      }}>
      <QueryClientProvider client={queryClient}>
        <App />
        <ToastContainer />
        {/* {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />} */}
      </QueryClientProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
