import React, { useState } from "react";
import { Modal, Button, Typography, Space, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { Text } = Typography;

const DeleteNotificationModal = ({
  visible,
  onCancel,
  onDelete,
  notificationId,
  notificationHeading,
}) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await onDelete(notificationId);
      setLoading(false);
      onCancel();
    } catch (error) {
      setLoading(false);
      console.error("Failed to delete notification:", error);
      message.error(
        error.response?.data?.message ||
          "Failed to delete notification. Please try again."
      );
    }
  };

  return (
    <Modal
      title={
        <Space>
          <ExclamationCircleOutlined style={{ color: "#faad14" }} />
          <Text style={{ color: "#faad14" }}>Delete Confirmation</Text>
        </Space>
      }
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="delete"
          type="primary"
          danger
          onClick={handleDelete}
          loading={loading}
        >
          Delete
        </Button>,
      ]}
    >
      <Space direction="vertical">
        <Text>
          Are you sure you want to delete the notification{" "}
          <Text strong>{notificationHeading || "this notification"}</Text>? This
          action cannot be undone.
        </Text>
      </Space>
    </Modal>
  );
};

export default DeleteNotificationModal;
