import { useQueryClient } from "@tanstack/react-query";
import { message } from 'antd';
import { useApiMutation } from "../hooks/useApiRequest";

//*********** add user wallet**********
export const useAddWalletService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const addWallet = async (values, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["user-wallet-create"],
                endPoint: `/api/v1/user-wallets/create`,
                method: "POST",
                bodyContent: values,
            });
            queryClient.invalidateQueries({ queryKey: ["user-wallet-create"] });
            queryClient.invalidateQueries({ queryKey: ["user-wallets"] });
            onSuccess();
            message.success('Successfully added');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { addWallet, error };
};


//*********** Update user wallet**********
export const useUpdateUserWalletService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const updateUserWallet = async (values, id, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["user-wallet-update"],
                endPoint: `/api/v1/user-wallets/update`,
                method: "PATCH",
                bodyContent: values,
            });
            queryClient.invalidateQueries({ queryKey: ["user-wallets"] });
            queryClient.invalidateQueries({ queryKey: ["user-wallet-update"] });
            onSuccess();
            message.success('Successfully updated');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { updateUserWallet, error };
};



//*********** Delete user wallet **********
export const useDeleteUserWalletService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const deleteUserWallet = async (id, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["user-wallet-delete", id],
                endPoint: `/api/v1/user-wallets/delete-user-wallet?walletId=${id}`,
                method: "DELETE",
            });
            queryClient.invalidateQueries({ queryKey: ["user-wallets"] });
            queryClient.invalidateQueries({ queryKey: ["user-wallet-delete", id] });
            onSuccess();
            message.success('Successfully deleted');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { deleteUserWallet, error };
};

