import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Typography } from 'antd';
import { useState } from 'react';
import { useDeleteAPKeyService } from '../../../../services/clientKeyService';
const { Text } = Typography;

const Delete = (record) => {

  const { deleteAPIKey } = useDeleteAPKeyService()
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false);

  const handleDelete = () => {
    setLoading(true)
    deleteAPIKey(
      record?.record?._id,
      () => {
        setLoading(false)
        toggleView()
      },
      () => {
        setLoading(false)
        toggleView()
      }
    )
  }

  const toggleView = () => {
    setVisible(!visible);
  };

  if (!visible) {
    return (
      <Button
        type="text"
        icon={<DeleteOutlined />}
        style={{
          padding: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={toggleView}
      />
    );
  }

  return (
    <Modal
      open={visible}
      onCancel={toggleView}
      title={
        <Space>
          <ExclamationCircleOutlined style={{ color: '#faad14' }} />
          <Text style={{ color: '#faad14' }}>Delete Confirmation</Text>
        </Space>
      }
      footer={[
        <Button key="cancel" onClick={toggleView}>
          Cancel
        </Button>,
        <Button
          key="delete"
          type="primary"
          danger
          onClick={handleDelete}
          loading={loading}
        >
          Delete
        </Button>,
      ]}>
      <Space direction="vertical">
        <Text>
          Are you sure you want to delete this user API key  This action cannot be undone.
        </Text>
      </Space>
    </Modal>
  );
};

export default Delete;
