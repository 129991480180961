import React, { useState } from "react";
import { Modal, Form, Input, Select, Button, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useCreateAdminManagerService } from "../../services/adminManagerService.js";
import AdminManagerForm from "../../models/AdminManager.jsx";

const { Option } = Select;

const Create = ({ onCreateSuccess }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const toggleForm = () => {
    setVisible(!visible);
  };

  const { CreateAdminManager } = useCreateAdminManagerService();

  /*const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const role = values.role === "Admin" ? 10 : 30;
        CreateAdminManager(
          { ...values, role },
          () => {
            onCreateSuccess();
            form.resetFields();
            setVisible(false);
          },
          () => {}
        );
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };*/
  const submit = async (values) => {
    try {
      //const values = await form.validateFields();
      console.log(values);
      await CreateAdminManager(values, onCreateSuccess, () => {});
      toggleForm();
      //const role = values.role === "Admin" ? 10 : 30;
      /*await CreateAdminManager(
        { ...values, role },
        () => {
          onCreateSuccess();
          form.resetFields();
          setVisible(false);
        },
        () => {}
      );*/
    } catch (error) {
      console.log("Validate Failed:", error);
    }
  };
  

  return (
    <>
      <div className="flex justify-between items-center mb-6">
        <Button
          className="flex items-center px-4 rounded-lg py-4 bg-[#22c6ab] text-white hover:bg-[#1da089] transition"
          onClick={() => setVisible(true)}
        >
          <PlusOutlined className="mr-2" /> Create Admin/Manager
        </Button>
      </div>
      <Modal
        open={visible}
        title="Create Admin/Manager"
        onCancel={toggleForm}
        footer={null}
        //onOk={handleOk}
        //okText="Create"
        style={{ maxHeight: '70vh', overflowY: 'auto' }}
      >
       <AdminManagerForm onSubmit={submit} />
      </Modal>
    </>
  );
};
export default Create;

// const handleOk = () => {
//   form
//     .validateFields()
//     .then((values) => {
//       CreateAdminManager(
//         values,
//         () => {
//           onCreateSuccess();
//           form.resetFields();
//           setVisible(false);
//         },
//         () => {}
//       );
//     })
//     .catch((info) => {
//       console.log("Validate Failed:", info);
//     });
// };
