import React, { useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import PaginatedTable from "../../components/PaginatedTable";
import { Space } from "antd";
import View from "../Clients/View";
import { formatDate } from "../../utils/formateData";

const AuditLogs = () => {
  const columns = [
    {
      title: "User",
      dataIndex: "userId.email",
      key: "user",
      render: (text, record) => record.userId?.email || "N/A",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => formatDate(text),
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <View record={record} />
    //     </Space>
    //   ),
    // },
  ];

  const filters = [
    {
      name: "userId.email",
      label: "User",
      type: "text",
    },
    {
      name: "action",
      label: "Action",
      type: "text",
    },
    {
      name: "createdAt",
      label: "Date",
      type: "date",
    },
  ];

  return (
    <div className="p-6 bg-white">
      <h1 className="text-xl font-semibold mb-4">Audit Logs</h1>
      <PaginatedTable
        fetchEndpoint="/api/v1/audit/logs"
        columns={columns}
        isSearchable={true}
        isSelectable={true}
        filters={filters}
      />
    </div>
  );
};

export default AuditLogs;
