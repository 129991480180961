import { DownloadOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import React, { useState } from 'react';
import PaginatedTable2 from '../../../components/PaginatedTable2';
import { useApiQuery } from '../../../hooks/useApiRequest';
import AdminLayout from '../../../layouts/AdminLayout';
import { formatDate } from '../../../utils/formateData';

const DepositLogs = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);

    const { data, isLoading } = useApiQuery({
        key: ['wd-register-deposits'],
        endPoint: `/api/v1/wd-register/deposits-logs?page=${currentPage}&limit=${pageSize}`,
        method: 'GET',
    });

    const pagination = {
        totalItems: data?.totalItems || 0,
        totalPages: data?.totalPages || 1,
        currentPage,
        limit: pageSize,
        startIndex: (currentPage - 1) * pageSize + 1,
        endIndex: Math.min(currentPage * pageSize, data?.totalItems || 0),
    };

    const handlePageChange = (page, size) => {
        setCurrentPage(page);
        setPageSize(size);
    };

    const handleExport = () => {
        try {
            if (!data?.results?.length) {
                message.warning('No Deposits logs available to export');
                return;
            }

            const headers = ['Deposits ID', 'Created At', 'Data'];
            const rows = data.results.map((item) => [
                item._id,
                new Date(item.createdAt).toLocaleString(),
                JSON.stringify(item.data, null, 2),
            ]);

            const csvContent = [headers, ...rows]
                .map((row) => row.map((field) => `"${String(field).replace(/"/g, '""')}"`).join(','))
                .join('\n');

            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Deposits_logs_export.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            message.success('Exported Deposits logs successfully');
        } catch (error) {
            message.error('Failed to export Deposits logs');
        }
    };

    const processedData = data?.results?.map((item) => ({
        key: item._id,
        date: formatDate(item.createdAt),
        data: JSON.stringify(item.data || {}),
    })) || [];

    const columns = [
        { title: "Date", dataIndex: "date", key: "date" },
        { title: "Data", dataIndex: "data", key: "data" },
    ];

    return (
        <AdminLayout currentKey={'dw-sub5'} openKey='5'>
            <div className="mx-auto p-6 bg-white">

                <div className="flex justify-between mb-6">
                    <h1 className="text-2xl font-semibold mb-6">Deposits Logs</h1>
                    <div className="mb-6 flex justify-end items-end">
                        <div>
                            <div className="flex justify-between items-center mb-4">
                                <div className="text-xs text-gray-500">
                                    Dashboard &gt; Deposits/Withdrawals &gt; Deposits
                                </div>
                            </div>

                            {/* <div className='flex justify-end'>
                                <Button
                                    type="primary"
                                    icon={<DownloadOutlined />}
                                    onClick={handleExport}
                                    className="flex items-center px-4 py-2 bg-[#57249c] text-white rounded-lg hover:bg-purple-800 transition"
                                >
                                    Export (csv)
                                </Button>
                            </div> */}
                        </div>
                    </div>
                </div>

                <PaginatedTable2
                    data={processedData}
                    columns={columns}
                    pagination={pagination}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageChange}
                />
            </div>
        </AdminLayout>
    );
};

export default DepositLogs;
