// src/pages/Investments/UserSummary.jsx

import React, { useEffect, useState } from 'react';
import { Button, Space, message, Breadcrumb } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import axios from 'axios';
import PaginatedTable from '../../components/PaginatedTable';
import AdminLayout from '../../layouts/AdminLayout';

const UserSummary = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchInvestmentSummaries = async () => {
    setLoading(true);
    try {
      const response = await axios.get('http://localhost:3000/api/v1/investments/investments-summary');
      const transformedData = [];

      response.data.forEach(user => {
        if (user.monthlyBreakdown && user.monthlyBreakdown.length > 0) {
          user.monthlyBreakdown.forEach(month => {
            transformedData.push({
              userId: user.userId,
              username: user.username,
              totalInvested: user.totalInvested,
              totalReturns: user.totalReturns,
              profit: user.profit,
              month: month.month,
              amountInvested: month.amountInvested,
              returns: month.returns,
              profitMonth: month.profit,
              _id: user._id,
            });
          });
        } else {
          transformedData.push({
            userId: user.userId,
            username: user.username,
            totalInvested: user.totalInvested,
            totalReturns: user.totalReturns,
            profit: user.profit,
            month: 'N/A',
            amountInvested: 0,
            returns: 0,
            profitMonth: 0,
            _id: user._id,
          });
        }
      });

      setData(transformedData);
    } catch (error) {
      console.error('Error fetching investment summaries:', error);
      message.error('Error fetching investment summaries.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvestmentSummaries();
  }, []);

  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Total Invested',
      dataIndex: 'totalInvested',
      key: 'totalInvested',
      render: (text) => `$${text}`,
    },
    {
      title: 'Total Returns',
      dataIndex: 'totalReturns',
      key: 'totalReturns',
      render: (text) => `$${text}`,
    },
    {
      title: 'Profit',
      dataIndex: 'profit',
      key: 'profit',
      render: (text) => `$${text}`,
    },
    {
      title: 'Month',
      dataIndex: 'month',
      key: 'month',
    },
    {
      title: 'Amount Invested',
      dataIndex: 'amountInvested',
      key: 'amountInvested',
      render: (text) => `$${text}`,
    },
    {
      title: 'Returns',
      dataIndex: 'returns',
      key: 'returns',
      render: (text) => `$${text}`,
    },
    {
      title: 'Profit (Month)',
      dataIndex: 'profitMonth',
      key: 'profitMonth',
      render: (text) => `$${text}`,
    },
  ];

  const headers = [
    { label: 'User ID', key: 'userId' },
    { label: 'Username', key: 'username' },
    { label: 'Total Invested', key: 'totalInvested' },
    { label: 'Total Returns', key: 'totalReturns' },
    { label: 'Profit', key: 'profit' },
    { label: 'Month', key: 'month' },
    { label: 'Amount Invested', key: 'amountInvested' },
    { label: 'Returns', key: 'returns' },
    { label: 'Profit (Month)', key: 'profitMonth' },
  ];

  return (
    <AdminLayout currentKey='inv-sub3' openKey='20'>
      <div className="mx-auto p-6 bg-white ">
        {/* Breadcrumb */}
        <Breadcrumb className="mb-4">
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item>Investments</Breadcrumb.Item>
          <Breadcrumb.Item>User Summary</Breadcrumb.Item>
        </Breadcrumb>

        {/* Header */}
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-semibold">User Summary</h1>
          <Space>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              style={{ backgroundColor: '#57249c', borderColor: '#57249c' }}
            >
              <CSVLink
                data={data}
                headers={headers}
                filename={"investment-summaries.csv"}
                style={{ color: 'white', textDecoration: 'none' }}
              >
                Export CSV
              </CSVLink>
            </Button>
          </Space>
        </div>

        {/* Table */}
        <PaginatedTable
          columns={columns}
          dataSource={data}
          loading={loading}
          itemsPerPage={10}
          rowKey="_id"
        />
      </div>
    </AdminLayout>
  );
};

export default UserSummary;
