import { useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { useApiMutation, useApiQuery } from "../hooks/useApiRequest";

//* ---------Get all withdrawal---------
export const useGetAllWithdrawal = () => {
    return useApiQuery({
        key: ["user-withdrawal"],
        endPoint: `/api/v1/withdrawals`, // need to change this
        method: "GET",
    });
};
export const useGetAllWithdrawalLogs = () => {
    return useApiQuery({
        key: ["user-withdrawal"],
        endPoint: `/api/v1/withdrawals`, // need to change this
        method: "GET",
    });
};

//*********** add user Withdrawal**********
export const useAddUserWithdrawalService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const addUserWithdrawal = async (values, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ['user-withdrawal-add'],
                endPoint: `/api/v1/withdrawals/create`,
                method: 'POST',
                bodyContent: values
            });
            queryClient.invalidateQueries({ queryKey: ['user-withdrawal'] });
            queryClient.invalidateQueries({ queryKey: ['user-withdrawal-add'] });
            onSuccess();
            message.success('Successfully added');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { addUserWithdrawal, error };
};

//*********** Update user Withdrawal**********
export const useUpdateWithdrawalService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const updateUserWithdrawal = async (values, id, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["user-withdrawal", id],
                endPoint: `/api/v1/withdrawals/${id}`,
                method: "PATCH",
                bodyContent: values,
            });
            queryClient.invalidateQueries({ queryKey: ["user-withdrawal"] });
            queryClient.invalidateQueries({ queryKey: ["user-withdrawal", id] });
            onSuccess();
            message.success("Successfully updated");
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { updateUserWithdrawal, error };
};

//*********** Approved user Withdrawal**********
export const useApprovedWithdrawalService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const approveUserWithdrawal = async (id, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ['user-withdrawal-approved', id],
                endPoint: `/api/v1/withdrawals/${id}`,
                method: 'POST',
                // bodyContent: values
            });
            queryClient.invalidateQueries({ queryKey: ['user-withdrawal'] });
            queryClient.invalidateQueries({ queryKey: ['user-withdrawal-approved', id] });
            onSuccess();
            message.success('Successfully Updated the status');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { approveUserWithdrawal, error };
};

//*********** Update Withdrawal Status **********
export const useUpdateWithdrawalStatusService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const updateUserWithdrawalStatus = async (values, id, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["user-withdrawal", id],
                endPoint: `/api/v1/withdrawals/${id}`,
                method: "PATCH",
                bodyContent: values,
            });
            queryClient.invalidateQueries({ queryKey: ["user-withdrawal"] });
            queryClient.invalidateQueries({ queryKey: ["user-withdrawal", id] });
            onSuccess();
            message.success("Successfully updated");
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { updateUserWithdrawalStatus, error };
};

//*********** reject user withdrawal**********
export const useRejectWithdrawalService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const rejectWithdrawal = async (id, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["user-withdrawals-reject", id],
                endPoint: `/api/v1/withdrawals/${id}/reject`,
                method: "POST",
            });
            queryClient.invalidateQueries({ queryKey: ["user-withdrawal"] });
            queryClient.invalidateQueries({ queryKey: ["user-withdrawals-reject", id] });
            onSuccess();
            message.success('Successfully Rejected');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { rejectWithdrawal, error };
};



//*********** Delete user Withdrawal **********
export const useDeleteUserWithdrawalService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const deleteWithdrawal = async (id, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ['user-withdrawal-delete'],
                endPoint: `/api/v1/withdrawals/${id}`,
                method: 'DELETE',
            });
            queryClient.invalidateQueries({ queryKey: ['user-withdrawal'] });
            queryClient.invalidateQueries({ queryKey: ['user-withdrawal-delete'] });
            onSuccess();
            message.success('Successfully deleted');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { deleteWithdrawal, error };
};
