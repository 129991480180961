import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { getQuery } from "../services/apiService";

const { TextArea } = Input;

const FAQForm = ({
  id = null,
  productId = null,
  onSubmit,
  onPrevious,
  previousData,
  isFinal = true,
  state = "create"
}) => {
  const [form] = Form.useForm();
  const [faqs, setFaqs] = useState([{ key: Date.now() }]); // Initialize with one row

  const handleAddRow = () => {
    setFaqs([...faqs, { key: Date.now() }]);
  };

  const handleRemoveRow = (key) => {
    setFaqs(faqs.filter((faq) => faq.key !== key));
  };

  const onFinish = (values) => {
    const faqsData = values.faqs || [];
    console.log("FAQ Form Data:", { faqs: faqsData, ...previousData });
    onSubmit({ faqs: faqsData, ...previousData });
  };

  useEffect(() => {
    const fetchFaq = async () => {
      console.log(id);
      try{
        const response = await getQuery('/api/v1/faqs/view', { id });

        if(response.status == 200){
          const data = response.data.data;

          // Set the fetched question and answer directly in the form
          form.setFieldsValue({
            faqs: [
              {
                question: data.question || "",
                answer: data.answer || "",
              },
            ],
          });

          // Update FAQs state with one row for rendering purposes
          setFaqs([{ key: Date.now() }]);
        }
      }catch(error){
        console.log(error);
        message.error("Could not fetch faq.");
      }
    }
    console.log("No use effect?")

    if(state == "edit"){
      console.log("fetching?")
      fetchFaq();
    }
  }, [id]);

  const mapResponseDataToFormFields = (data) => ({
    /*name: data?.name || "",
    description: data?.description || "",*/
    /*faqs: data?.faqs?.map((faq) => ({
      question: faq.question || "",
      answer: faq.answer || "",
    })) || [{ question: "", answer: "" }], // Fallback to one empty row*/
    question: data.question,
    answer: data.answer
  });


  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      className="p-4 flex flex-col items-center justify-center"
      initialValues={{ faqs: faqs.map(() => ({})) }}
    >
      <h2 className="text-xl font-bold mb-4 text-center">FAQs</h2>

      {faqs.map((faq, index) => (
        <Row
          gutter={16}
          key={faq.key}
          className="mb-4 w-full max-w-4xl" 
          align="middle"
          justify="center"
        >
          <Col span={8}>
            <Form.Item
              label="Question"
              name={["faqs", index, "question"]}
              rules={[{ required: true, message: "Question is required" }]}
            >
              <TextArea placeholder="Enter Question" rows={3} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Answer"
              name={["faqs", index, "answer"]}
              rules={[{ required: true, message: "Answer is required" }]}
            >
              <TextArea placeholder="Enter Answer" rows={3} />
            </Form.Item>
          </Col>
          <Col span={2} className="flex justify-center items-center">
            {faqs.length > 1 && (
              <Button
                type="danger"
                icon={<DeleteOutlined />}
                onClick={() => handleRemoveRow(faq.key)}
              />
            )}
          </Col>
        </Row>
      ))}

      {state !== "edit" && (
        <Button
          type="dashed"
          onClick={handleAddRow}
          className="mb-4"
          icon={<PlusOutlined />}
        >
          Add FAQ
        </Button>
      )}

      <div className="flex justify-between mt-4 w-full max-w-4xl">
        <Button type="default" onClick={onPrevious}>
          Previous
        </Button>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </div>
    </Form>
  );
};

export default FAQForm;
