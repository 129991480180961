// src/components/SystemSettings.jsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  FaEnvelope,
  FaClock,
  FaMoneyBillWave,
  FaPercentage,
  FaDollarSign,
  FaExclamationTriangle,
  FaCog,
} from 'react-icons/fa';
import UpdateSystemSettings from '../../components/Modals/Settings/UpdateSystemSettings';

const SystemSettings = () => {
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // Modal state
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);

  // Fetch RfQ Settings data from the backend
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get('http://localhost:3000/api/v1/settings/rfq-settings');
        setSettings(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching System Settings:', err);
        setError(true);
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  // Handle Update Button Click
  const handleUpdate = () => {
    setIsUpdateModalVisible(true);
  };

  // Handle successful update
  const handleUpdateSuccess = () => {
    // Refetch settings after successful update
    setLoading(true);
    axios.get('http://localhost:3000/api/v1/settings/rfq-settings')
      .then(response => {
        setSettings(response.data);
        setLoading(false);
      })
      .catch(err => {
        console.error('Error refetching System Settings:', err);
        setError(true);
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="flex items-center text-gray-500 text-lg">
          <FaCog className="animate-spin mr-2 text-xl" />
          Loading System Settings...
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="flex items-center text-red-500 text-lg">
          <FaExclamationTriangle className="mr-2 text-xl" />
          Failed to load System Settings. Please try again later.
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto p-6 bg-gray-100 min-h-screen">
      {/* Header with Update Button */}
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8">
        <h2 className="text-3xl font-bold text-gray-800 mb-4 md:mb-0">System Settings</h2>
        <button
          onClick={handleUpdate}
          className="flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <FaCog className="mr-2" />
          Update
        </button>
      </div>

      {/* RfQ Settings Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {settings.map((setting) => (
          <div
            key={setting._id}
            className="bg-white rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300 border border-gray-200"
          >
            {/* Card Header */}
            <div className="border-b border-gray-200 p-6 flex items-center">
              <FaDollarSign className="text-teal-500 text-2xl mr-4" />
              <h3 className="text-lg font-semibold text-gray-700">
                RfQ Setting ID: {setting._id}
              </h3>
            </div>

            {/* Card Content */}
            <div className="p-6">
              {/* Down Email Notifications */}
              <div className="flex items-start mb-4">
                <FaEnvelope className="text-blue-500 text-lg mr-4 mt-1" />
                <div>
                  <h4 className="text-md font-medium text-gray-800">Down Email Notifications</h4>
                  <ul className="list-disc list-inside text-gray-700 mt-1">
                    {setting.downEmailNotifications.map((email, index) => (
                      <li key={index}>{email}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <hr className="border-gray-200 mb-4" />

              {/* Quote Time */}
              <div className="flex items-center mb-4">
                <FaClock className="text-green-500 text-lg mr-4" />
                <div>
                  <h4 className="text-md font-medium text-gray-800">Quote Time</h4>
                  <p className="text-gray-700">{new Date(setting.quoteTime).toLocaleString()}</p>
                </div>
              </div>
              <hr className="border-gray-200 mb-4" />

              {/* Minimum Quote Trade */}
              <div className="flex items-center mb-4">
                <FaMoneyBillWave className="text-yellow-500 text-lg mr-4" />
                <div>
                  <h4 className="text-md font-medium text-gray-800">Minimum Quote Trade ($)</h4>
                  <p className="text-gray-700">${setting.minQuoteTrade.toLocaleString()}</p>
                </div>
              </div>
              <hr className="border-gray-200 mb-4" />

              {/* Arbitrage Commission */}
              <div className="flex items-center mb-4">
                <FaPercentage className="text-purple-500 text-lg mr-4" />
                <div>
                  <h4 className="text-md font-medium text-gray-800">Arbitrage Commission (%)</h4>
                  <p className="text-gray-700">{setting.arbitrageCommission}%</p>
                </div>
              </div>
              <hr className="border-gray-200 mb-4" />

              {/* Basic Withdrawal Limit */}
              <div className="flex items-center mb-4">
                <FaMoneyBillWave className="text-red-500 text-lg mr-4" />
                <div>
                  <h4 className="text-md font-medium text-gray-800">Basic Withdrawal Limit ($)</h4>
                  <p className="text-gray-700">${setting.basicWithdrawalLimit.toLocaleString()}</p>
                </div>
              </div>
              <hr className="border-gray-200 mb-4" />

              {/* Advanced Withdrawal Limit */}
              <div className="flex items-center mb-4">
                <FaMoneyBillWave className="text-indigo-500 text-lg mr-4" />
                <div>
                  <h4 className="text-md font-medium text-gray-800">Advanced Withdrawal Limit ($)</h4>
                  <p className="text-gray-700">${setting.advancedWithdrawalLimit.toLocaleString()}</p>
                </div>
              </div>
              <hr className="border-gray-200 mb-4" />

              {/* Pro Withdrawal Limit */}
              <div className="flex items-center mb-4">
                <FaDollarSign className="text-teal-500 text-lg mr-4" />
                <div>
                  <h4 className="text-md font-medium text-gray-800">Pro Withdrawal Limit ($)</h4>
                  <p className="text-gray-700">
                    {setting.proWithdrawalLimit === 999999999999.00
                      ? 'Unlimited'
                      : `$${setting.proWithdrawalLimit.toLocaleString()}`}
                  </p>
                </div>
              </div>
              <hr className="border-gray-200 mb-4" />

              {/* Inactive Timeout */}
              <div className="flex items-center">
                <FaExclamationTriangle className="text-pink-500 text-lg mr-4" />
                <div>
                  <h4 className="text-md font-medium text-gray-800">Inactive Timeout (minutes)</h4>
                  <p className="text-gray-700">
                    {setting.inactiveTimeout !== null && setting.inactiveTimeout !== undefined
                      ? `${setting.inactiveTimeout} minutes`
                      : 'Not Set'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Update Modal */}
      <UpdateSystemSettings
        visible={isUpdateModalVisible}
        onCancel={() => setIsUpdateModalVisible(false)}
        settings={settings}
        onUpdateSuccess={handleUpdateSuccess}
      />
    </div>
  );
};

export default SystemSettings;
