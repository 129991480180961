import React, { useEffect, useState } from "react";
import { getQuery } from "../../../../services/apiService";
import { convertDecimal128 } from "../../../../utils/convertNumbers";
import { Button, message } from "antd";
import { EditOutlined } from '@ant-design/icons';
import DetailView from "../../../../components/DetailView";
import Edit from "../../Edit";
import { formatDate } from "../../../../utils/formateData";

const DetailsTab = ({product = {}, reload}) => {
	  
	const investmentDetails = [
		{ label: 'Name', value: product.name || 'N/A' },
		{ label: 'Description', value: product.description || 'N/A' },
		{ label: 'Type', value: product.investmentType || 'N/A' },
		{ label: 'Minimun Amount', value: product.minAmount ? convertDecimal128(product.minAmount) : 'N/A' },
		{ label: 'Maximum Amount', value: product.maxAmount ? convertDecimal128(product.maxAmount) : 'N/A' }, // Allow numeric zero
		{ label: 'ROI (%)', value: product.roi || 'N/A' },
		{ label: 'Asset', value: product?.assetId?.assetName || 'N/A' },
		{ label: 'Duration', value: product.duration || 'N/A' },
		{ label: 'Status', value: product.status || 'N/A' },
		{ label: 'State', value: product.state || 'N/A' },
		{ label: 'Risk', value: product.risk  || 'N/A' },
		{ label: 'Deposit Fee Type', value: product?.depositFee?.feeType  || 'N/A' },
		{ label: 'Deposit Fee Amount', value: product?.depositFee?.feeAmount ? convertDecimal128(product?.depositFee?.feeAmount) : 'N/A' },
		{ label: 'Withdrawal Fee Type', value: product?.withdrawalFee?.feeType  || 'N/A' },
		{ label: 'Withdrawal Fee Amount', value: product?.withdrawalFee?.feeAmount ? convertDecimal128(product?.withdrawalFee?.feeAmount) : 'N/A' },
		{ label: 'Withdrawable', value: product?.withdrawable  || 'N/A' },
		{ label: 'Created At', value: formatDate(product.createdAt || null) },
		{ label: 'Updated At', value: formatDate(product.updatedAt || null) },
	];

	return(
		<>
			<div className="py-4 float-right">
				{/*<Button 
					type="primary"
					onClick={show}
				>
					<EditOutlined /> Edit Details
				</Button>*/}
				<div className="inline-block bg-blue-500 p-2 rounded">
					<Edit productId={product._id} onUpdateSuccess={reload} />
				</div>
			</div>
			<DetailView data={investmentDetails} className="w-full" />
		</>
	)
}

export default DetailsTab;