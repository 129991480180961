import { Button, Form, Input, Modal, message, Select } from "antd";
import React, { useState } from "react";
import { AddNewsService } from "../../services/newsService";

const { TextArea } = Input;
const { Option } = Select;

const Add = ({ visible, onCancel, onSubmit }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { addUserNews } = AddNewsService();

  const handleAdd = async () => {
    try {
      setLoading(true);

      const values = await form.validateFields();

      const payload = {
        name: values.name,
        description: values.description,
        link: values.link,
        category: values.category,
      };

      await addUserNews(
        payload,
        () => {
          onCancel();
          onSubmit();
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    } catch (error) {
      setLoading(false);
      message.error("Failed to add news. Please try again.");
    }
  };

  return (
    <Modal
      visible={visible}
      title="Add News"
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleAdd} loading={loading}>
          Save
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="News Title"
          rules={[{ required: true, message: "Please enter the news title" }]}
        >
          <Input placeholder="Enter news title" />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: "Please enter the description" }]}
        >
          <TextArea rows={4} placeholder="Enter description" />
        </Form.Item>

        <Form.Item
          name="link"
          label="News Link"
          rules={[
            { required: true, message: "Please enter the news link" },
            { type: "url", message: "Please enter a valid URL" },
          ]}
        >
          <Input placeholder="Enter news link" />
        </Form.Item>

        <Form.Item
          name="category"
          label="Category"
          rules={[{ required: true, message: "Please select a category" }]}
        >
          <Select placeholder="Select category">
            <Option value="Technology">Technology</Option>
            <Option value="Sports">Sports</Option>
            <Option value="Politics">Politics</Option>
            <Option value="Entertainment">Entertainment</Option>
            <Option value="Health">Health</Option>
            <Option value="Business">Business</Option>
            <Option value="Science">Science</Option>
            <Option value="Other">Other</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Add;
