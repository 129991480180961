import { EditOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select } from "antd";
import React, { useState } from "react";
import { UpdateNewsService } from "../../services/newsService";

const { TextArea } = Input;
const { Option } = Select;

const Edit = ({ data }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const { updateNews } = UpdateNewsService();

  const handleEdit = async () => {
    try {
      setLoading(true);

      const values = await form.validateFields();

      await updateNews(
        data?._id,
        values,
        () => {
          setLoading(false);
          toggleView();
        },
        () => {
          setLoading(false);
        }
      );
    } catch (error) {
      setLoading(false);
    }
  };

  const toggleView = () => {
    setVisible(!visible);
  };

  if (!visible) {
    return (
      <Button
        type="text"
        icon={<EditOutlined />}
        style={{
          padding: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={toggleView}
      />
    );
  }

  return (
    <Modal
      open={visible}
      title="Edit News"
      onCancel={toggleView}
      footer={[
        <Button key="cancel" onClick={toggleView}>
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={handleEdit}
          loading={loading}
        >
          Save
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          name: data?.name,
          description: data?.description,
          link: data?.link,
          category: data?.category,
        }}
      >
        <Form.Item
          name="name"
          label="News Title"
          rules={[{ required: true, message: "Please enter the news title" }]}
        >
          <Input placeholder="Enter news title" />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: "Please enter the description" }]}
        >
          <TextArea rows={4} placeholder="Enter description" />
        </Form.Item>

        <Form.Item
          name="link"
          label="News Link"
          rules={[
            { required: true, message: "Please enter the news link" },
            { type: "url", message: "Please enter a valid URL" },
          ]}
        >
          <Input placeholder="Enter news link" />
        </Form.Item>

        <Form.Item
          name="category"
          label="Category"
          rules={[{ required: true, message: "Please select a category" }]}
        >
          <Select placeholder="Select category">
            <Option value="Technology">Technology</Option>
            <Option value="Sports">Sports</Option>
            <Option value="Politics">Politics</Option>
            <Option value="Entertainment">Entertainment</Option>
            <Option value="Health">Health</Option>
            <Option value="Business">Business</Option>
            <Option value="Science">Science</Option>
            <Option value="Other">Other</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Edit;
