import { useQueryClient } from "@tanstack/react-query";
import { message } from 'antd';
import { useApiMutation, useApiQuery } from "../hooks/useApiRequest";

//* ---------Get system ---------
export const useGetSystemService = () => {
    return useApiQuery({
        key: ["systems"],
        endPoint: `/api/v1/system-settings/get-all`,
        method: "GET",
    });
};

//*********** Update System**********
export const useUpdateSystemService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const updateSystem = async (values, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["system-update"],
                endPoint: `/api/v1/system-settings/update`,
                method: "PATCH",
                bodyContent: values,
            });
            queryClient.invalidateQueries({ queryKey: ["systems"] });
            queryClient.invalidateQueries({ queryKey: ["systems-update"] });
            onSuccess();
            message.success('Successfully updated');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { updateSystem, error };
};


