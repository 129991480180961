import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import logo from "../assets/icons/logo.png";
import cube from "../assets/icons/rounded_logo.png";
import { items } from "./AdminMenuItems";
import { useAuth } from "../context/AuthContext";

const { Header, Content, Sider } = Layout;

export default function AdminLayout({
  user,
  header,
  children,
  currentKey,
  openKey = "",
}) {
  const [collapsed, setCollapsed] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const  { userProfile } = useAuth()

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth]);

  return (
    <div className="min-h-screen bg-red-300">
      <Layout style={{ minHeight: "100vh" }}>
        <div className="h-full">
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            breakpoint="lg"
            collapsedWidth="80"
            width={250}
            onCollapse={(collapsed, type) => {
              setCollapsed(collapsed);
            }}
            //inlineCollapsed={collapsed}
            style={{
              background: "#FFFFFF",
              overflow: "auto",
              height: "100vh",
              width: "250px",
              position: "fixed",
              left: 0,
              top: 0,
              bottom: 0,
              boxShadow:
                "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
              zIndex: 40,
            }}
          >
            <div className="demo-logo-vertical" />
            <div className="dash-bg flex items-center justify-center mb-5">
              <Link to="/dashboard" className="block text-center">
                <img
                  src={collapsed ? cube : logo}
                  className={collapsed ? "collapsed-logo" : "logo"}
                  alt="Logo"
                />
              </Link>
            </div>
            {!collapsed && (
              <div className="text-center py-4">
                <span>{userProfile?.email || ""}</span>
                <div>
                  <Link className="p-2">
                    <SettingOutlined />
                  </Link>
                  <Link className="p-2">
                    <LogoutOutlined />
                  </Link>
                </div>
              </div>
            )}
            <Menu
              theme="light"
              defaultSelectedKeys={[currentKey]}
              defaultOpenKeys={[openKey]}
              mode="inline"
              items={items}
            />
          </Sider>
        </div>
        <Layout
          style={{
            minHeight: "100vh",
            marginLeft: collapsed ? "80px" : "250px",
          }}
        >
          <Header
            style={{
              padding: 0,
              background: "#FFFFFF",
              boxShadow:
                "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
              height: "64px",
              //marginLeft: '100px'
            }}
          >
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
            <div className="float-right p-1 mr-4 flex">
              <div className="px-2">
                <div>{/*{user.name}*/}</div>
              </div>
              {/*<div>
                                <Avatar src={`${window.location.origin}/math.png`} />
                            </div>*/}
              <div className="dash-bg ">
                <img src={cube} className={"collapsed-logo"} alt="Logo" />
              </div>
            </div>
          </Header>
          <Content
            style={{
              background: "#FFFFFF",
            }}
          >
            <div className="p-4">{children}</div>
          </Content>
        </Layout>
      </Layout>
      <style jsx>{`
        .logo {
          width: 150px;
          height: auto;
          margin: 0 auto;
          transition: width 0.3s;
        }
        .collapsed-logo {
          width: 50px;
          height: auto;
          margin: 0 auto;
          transition: width 0.3s;
        }
      `}</style>
    </div>
  );
}
