import React, { useState } from "react";
import { Image, Typography, Button, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

const { Title } = Typography;

const ImagesTab = ({ imageUrls: initialImageUrls, onDelete }) => {
  const [imageUrls, setImageUrls] = useState(initialImageUrls);

  const handleDelete = (url) => {
    setImageUrls((prev) => prev.filter((imageUrl) => imageUrl !== url));
    if (onDelete) {
      onDelete(url);
    }
  };

  if (!Array.isArray(imageUrls) || imageUrls.length === 0) {
    return (
      <div className="p-4 text-center">
        <Title level={4} className="text-gray-600">
          No Images Available
        </Title>
      </div>
    );
  }

  return (
    <div className="p-4">
      <Title level={3} className="mb-4 text-center text-gray-800">
        Image Gallery
      </Title>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {imageUrls.map((url, index) => (
          <div
            key={index}
            className="border border-gray-300 rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-200 relative"
          >
            <Image
              src={url}
              alt={`Image ${index + 1}`}
              className="object-cover w-full h-full"
              width="100%"
              height={200}
              preview={true}
            />
            <Popconfirm
              title="Are you sure you want to delete this image?"
              onConfirm={() => handleDelete(url)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                className="absolute top-2 right-2"
              />
            </Popconfirm>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImagesTab;
