// ../../Modals/Settings/SettingsAPIKeys/AddAPIKeys.jsx

import React from 'react';
import { Modal, Form, Input, Select, Button, message } from 'antd';
import PropTypes from 'prop-types';
import axios from 'axios';

const { Option } = Select;

const AddAPIKeys = ({ visible, onCancel, onAddSuccess }) => {
  const [form] = Form.useForm();

  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        axios
          .post('http://localhost:3000/api/v1/settings/service-settings', values)
          .then(response => {
            message.success('API Key added successfully!');
            onAddSuccess(response.data);
            form.resetFields();
          })
          .catch(error => {
            message.error(
              error.response?.data?.message || 'Failed to add API Key.'
            );
          });
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      open={visible}
      title="Add API Key"
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Add
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        name="add_api_keys_form"
        initialValues={{
          status: 'Testing',
        }}
      >
        <Form.Item
          name="name"
          label="Service Name"
          rules={[
            {
              required: true,
              message: 'Please input the service name!',
            },
          ]}
        >
          <Input placeholder="Enter Service Name" />
        </Form.Item>

        <Form.Item
          name="status"
          label="Status"
          rules={[{ required: true, message: 'Please select a status!' }]}
        >
          <Select>
            <Option value="Enabled">Enabled</Option>
            <Option value="Disabled">Disabled</Option>
            <Option value="Testing">Live</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="apiKey"
          label="API Key"
          rules={[
            {
              required: true,
              message: 'Please input the API Key!',
            },
          ]}
        >
          <Input placeholder="Enter API Key" />
        </Form.Item>

        <Form.Item
          name="apiSecret"
          label="API Secret"
          rules={[
            {
              required: true,
              message: 'Please input the API Secret!',
            },
          ]}
        >
          <Input placeholder="Enter API Secret" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

AddAPIKeys.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAddSuccess: PropTypes.func.isRequired,
};

export default AddAPIKeys;
