import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useGetAllAssets, useGetAllChains } from '../../../../services/assetService';
import { useUpdateUserWalletService } from '../../../../services/userWalletService';

const { Option } = Select;

const Edit = ({ data }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [assetId, setAssetId] = useState(data?.assetId?._id || null);
    const [chainId, setChainId] = useState(data?.chainId?._id || null);
    const [visible, setVisible] = useState(false);

    const { updateUserWallet } = useUpdateUserWalletService();
    const { data: assets, error: errorAsset, isLoading: loadingAsset } = useGetAllAssets("type=Crypto");
    const { data: chains } = useGetAllChains(assetId);

    useEffect(() => {
        if (data?.assetId?._id) {
            setAssetId(data.assetId._id);
        }
        if (data?.chainId?._id) {
            setChainId(data.chainId._id);
        }
    }, [data]);

    const handleEdit = async () => {
        setLoading(true);

        const values = await form.validateFields();

        const payload = {
            _id: data?._id,
            walletId: values.walletId,
            walletIdLegacy: values.walletIdLegacy,
            walletType: values.walletType,
            assetId: assetId,
            chainId: chainId,
            tag: values.tag,
        };

        updateUserWallet(
            payload,
            data?._id,
            () => {
                setLoading(false);
                toggleView()
            },
            () => setLoading(false)
        );
    };

    const toggleView = () => {
        setVisible(!visible);
    };

    if (!visible) {
        return (
            <Button
                type="text"
                icon={<EditOutlined />}
                style={{
                    padding: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                onClick={toggleView}
            />
        );
    }

    return (
        <Modal
            visible={visible}
            title="Edit Client Wallet"
            onCancel={toggleView}
            footer={[
                <Button key="cancel" onClick={toggleView}>
                    Cancel
                </Button>,
                <Button key="save" type="primary" onClick={handleEdit} loading={loading}>
                    Save
                </Button>,
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    asset: data?.assetId?.assetName,
                    chainId: data?.chainId?.chain,
                    walletId: data?.walletId,
                    walletIdLegacy: data?.walletIdLegacy,
                    walletType: data?.walletType,
                    tag: data?.tag,
                }}>
                <Form.Item
                    name="asset"
                    label="Asset"
                    rules={[{ required: true, message: 'Please select an asset' }]}>
                    <Select
                        showSearch
                        placeholder="Select an asset"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }
                        onChange={(value) => {
                            const selectedAsset = assets?.find(asset => asset.asset === value);
                            setAssetId(selectedAsset?._id);
                        }}
                    >
                        {assets?.map(asset => (
                            <Option key={asset?._id} value={asset?.asset}>
                                {asset?.assetName}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="chainId"
                    label="Chain"
                    rules={[{ required: true, message: 'Please select a chain' }]}
                >
                    <Select
                        showSearch
                        placeholder="Select a chain"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }
                        onChange={(value) => {
                            setChainId(value);
                        }}
                    >
                        {chains?.map(chain => {
                            return (
                                <Option key={chain?._id} value={chain?._id}>
                                    {chain?.chain}
                                </Option>
                            )
                        })}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="walletId"
                    label="Wallet Address"
                    rules={[{ required: true, message: 'Please enter wallet ID' }]}
                >
                    <Input placeholder="Enter wallet address" />
                </Form.Item>

                <Form.Item
                    name="walletIdLegacy"
                    label="Wallet Legacy"
                // rules={[{ required: true, message: 'Please enter wallet legacy' }]}
                >
                    <Input placeholder="Enter wallet legacy" />
                </Form.Item>

                <Form.Item
                    name="tag"
                    label="Tag"
                // rules={[{ required: true, message: 'Please enter tag' }]}
                >
                    <Input placeholder="Enter tag" />
                </Form.Item>

                <Form.Item
                    name="walletType"
                    label="Wallet Type"
                    rules={[{ required: true, message: 'Please select wallet type' }]}
                >
                    <Select
                        showSearch
                        placeholder="Select a wallet type"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }
                    >
                        <Option value="Custodial">Custodial Wallet</Option>
                        <Option value="Non-Custodial">Non-Custodial Wallet</Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Edit;

