import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
const baseURL = process.env.REACT_APP_BASE_URL;

const LogoutButton = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    // Optional: You can specify a redirect URL after logout
    console.log('Loggin out...');
    logout({ returnTo: baseURL /*window.location.origin*/ });
  };

  return (
    <button onClick={handleLogout}>Logout</button>
  );
};

export default LogoutButton;
