import { ExclamationCircleOutlined, SecurityScanOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Typography } from 'antd';
import { useState } from 'react';
import { useVerifyClientService } from '../../../services/clientService';
const { Text } = Typography;

const Verify = ({ userId, visible, onCancel, onSubmit }) => {

  const { verifyClient } = useVerifyClientService()
  const [loading, setLoading] = useState(false)

  const handleApprove = () => {

    const payload = {
      userId,
      status: "Approved"
    }

    setLoading(true)
    verifyClient(
      payload,
      () => {
        onCancel();
        onSubmit();
        setLoading(false)
      },
      () => {
        setLoading(false)
      }
    )
  }

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={
        <Space>
          <SecurityScanOutlined style={{ color: '#57249c' }} />
          <Text style={{ color: '#57249c' }}> Approve User </Text>
        </Space>
      }
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="delete"
          type="primary"
          default
          onClick={handleApprove}
          loading={loading}
        >
          Approved
        </Button>,
      ]}>
      <Space direction="vertical">
        <Text>
          Are you sure you want to approve this user ? This action cannot be undone.
        </Text>
      </Space>
    </Modal>
  );
};

export default Verify;
