import { Button, Form, Input, Modal, Select } from 'antd';
import React, { useState } from 'react';
import Countries from '../../../../data/Countries';
import { useAddUSDBankAccService, useGetAllBankService, useGetBankByIdService } from '../../../../services/USDbankAccService';

const { Option } = Select;

const AddBankAccount = ({ userId, visible, onCancel, onSubmit }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const { addUSDBankAcc } = useAddUSDBankAccService();
    const { data: bankList, isLoading } = useGetAllBankService();
    const { data: bank } = useGetBankByIdService(/* get by id here */);

    const handleAdd = async () => {
        setLoading(true);

        try {
            const values = await form.validateFields();

            const payload = {
                userId: userId,
                accName: values.accName,
                bankId: values.bankId,
                accNumber: values.accNumber,
                branchCode: values.branchCode,
                accType: values.accType,
                swiftCode: values.swiftCode,
                accAddressCountry: values.accAddressCountry,
                accAddressCity: values.accAddressCity,
                accAddressProvince: values.accAddressProvince,
                accPostalCode: values.accPostalCode,
                accAddressStreet: values.accAddressStreet,
            };

            addUSDBankAcc(
                payload,
                () => {
                    onCancel();
                    onSubmit();
                    setLoading(false);
                },
                () => setLoading(false)
            );
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <Modal
            visible={visible}
            title="Add USD Bank Account"
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button
                    key="save"
                    type="primary"
                    onClick={handleAdd}
                    loading={loading}
                >
                    Save
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="accName"
                    label="Account Name"
                    rules={[{ required: true, message: 'Please enter an account name' }]}
                >
                    <Input placeholder="Enter account name" />
                </Form.Item>

                <Form.Item
                    name="bankId"
                    label="Bank Name"
                    rules={[{ required: true, message: 'Please enter a bank' }]}
                >
                    <Input placeholder="Enter Bank name" />
                </Form.Item>


                {/* <Form.Item
                    name="bankId"
                    label="Bank"
                    rules={[{ required: true, message: 'Please select a bank' }]}
                >
                    <Select
                        placeholder="Select a bank"
                        loading={isLoading}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }
                    >
                        {bankList &&
                            bankList.map((bank) => (
                                <Option key={bank._id} value={bank._id}>
                                    {bank.name}
                                </Option>
                            ))}
                    </Select>
                </Form.Item> */}

                <Form.Item
                    name="accNumber"
                    label="Account Number (IBAN)"
                    rules={[{ required: true, message: 'Please enter an account number' }]}
                >
                    <Input placeholder="Enter account number or IBAN" />
                </Form.Item>

                <Form.Item
                    name="branchCode"
                    label="Branch Code"
                    rules={[{ required: true, message: 'Please enter a branch code' }]}
                >
                    <Input placeholder="Enter branch code" />
                </Form.Item>

                <Form.Item
                    name="accType"
                    label="Account Type"
                    rules={[{ required: true, message: 'Please select an account type' }]}
                >
                    <Select placeholder="Select account type">
                        <Option value="Saving">Saving</Option>
                        <Option value="Cheque">Cheque</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="swiftCode"
                    label="SWIFT Code (Routing Number)"
                    rules={[{ required: true, message: 'Please enter a SWIFT code' }]}
                >
                    <Input placeholder="Enter SWIFT code or routing number" />
                </Form.Item>


                <Form.Item
                    name="accAddressCountry"
                    label="Country"
                    rules={[{ required: true, message: 'Please enter the country' }]}
                >
                    <Select
                        placeholder="Select a country"
                        // loading={isLoading}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }
                    >
                        {Countries &&
                            Countries?.map((item) => (
                                <Option key={item.code} value={item.name}>
                                    {item.name}
                                </Option>
                            ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="accAddressCity"
                    label="City"
                    rules={[{ required: true, message: 'Please enter the city' }]}
                >
                    <Input placeholder="Enter city" />
                </Form.Item>

                <Form.Item
                    name="accAddressProvince"
                    label="Province"
                    rules={[{ required: true, message: 'Please enter the province' }]}
                >
                    <Input placeholder="Enter province" />
                </Form.Item>

                <Form.Item
                    name="accPostalCode"
                    label="Postal Code"
                    rules={[{ required: true, message: 'Please enter the postal code' }]}
                >
                    <Input placeholder="Enter postal code" />
                </Form.Item>

                <Form.Item
                    name="accAddressStreet"
                    label="Street Address"
                    rules={[{ required: true, message: 'Please enter the street address' }]}
                >
                    <Input placeholder="Enter street address" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddBankAccount;