import { Space, Table } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetAllUSDBankingAcc } from '../../../../services/USDbankAccService';
import { formatDate } from '../../../../utils/formateData';
import Add from './Add';
import Delete from './Delete';
import Edit from './Edit';

const BankAccountsTab = ({ userID }) => {
	const { data, isLoading, error, } = useGetAllUSDBankingAcc(userID)
	const [isAddModalVisible, setIsAddModalVisible] = useState(false);

	const columns = [
		{ title: "Account Name", dataIndex: "accName", key: "accName" },
		{ title: "Account Number", dataIndex: "accNumber", key: "accNumber" },
		// {
		// 	title: "Bank Name",
		// 	dataIndex: "bankId",
		// 	key: "bankId",
		// 	render: (text, record) => `${record?.bankId?.name}` || "N/A"
		// },
		{ title: "Branch Code", dataIndex: "branchCode", key: "branchCode" },
		{ title: "Account Type", dataIndex: "accType", key: "accType" },
		{ title: "Swift Code", dataIndex: "swiftCode", key: "swiftCode" },
		{ title: "Address Country", dataIndex: "accAddressCountry", key: "accAddressCountry" },
		{ title: "Address City", dataIndex: "accAddressCity", key: "accAddressCity" },
		{ title: "Address Province", dataIndex: "accAddressProvince", key: "accAddressProvince" },
		{ title: "Postal Code", dataIndex: "accPostalCode", key: "accPostalCode" },
		{ title: "Address Street", dataIndex: "accAddressStreet", key: "accAddressStreet" },
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
			render: (text) => formatDate(text),
		},
		{
			title: "Actions",
			key: "actions",
			render: (text, record) => {
				return (
					<Space size="middle">
						<Link>  <Edit data={record} /> </Link>
						<Link>  <Delete record={record} /> </Link>
					</Space>
				)
			},
		}
	];

	return (
		<>
			<div className="flex justify-between items-center mb-6">
				<button
					className="px-4 rounded-lg py-2 bg-[#22c6ab] text-white hover:bg-[#1da089] transition"
					onClick={() => setIsAddModalVisible(true)}
				>
					+ Add USD Bank Account
				</button>
			</div>

			<Table
				dataSource={data}
				columns={columns}
				loading={isLoading}
				pagination={false}
				rowKey={(record) => record.id || record._id || record.key}
				rowClassName={(record, index) =>
					index % 2 === 0 ? "table-row-light" : "table-row-dark"
				}
				scroll={{ x: 'max-content' }}
			/>

			{/* Modals */}
			{isAddModalVisible && (
				<Add
					userId={userID}
					visible={isAddModalVisible}
					onCancel={() => setIsAddModalVisible(false)}
					onSubmit={() => { setIsAddModalVisible(false) }}
				/>
			)}
		</>
	)
}

export default BankAccountsTab;