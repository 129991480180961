// AddWithdrawalModal.js

import { Button, Form, Input, InputNumber, Modal, Select } from 'antd';
import React, { useState } from 'react';
import AdminLayout from '../../../layouts/AdminLayout';
import { useGetAllAssets } from '../../../services/assetService';
import { useGetAllUsers } from '../../../services/userService';
import { useAddUserWithdrawalService } from '../../../services/withdrawalService';

const { Option } = Select;
const { TextArea } = Input;

const Add = ({ visible, onCancel, onSubmit }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const { addUserWithdrawal } = useAddUserWithdrawalService()
    const { data: users, error: errorUser, isLoading: loadingUser } = useGetAllUsers();
    const { data: assets, error: errorAsset, isLoading: loadingAsset } = useGetAllAssets("type=Fiat"); // Crypto Fiat

    const handleAdd = async () => {
        setLoading(true)

        const values = await form.validateFields();

        const payload = {
            userId: values.user,
            asset: values.asset,
            requestedAmount: values.amount,
            txid: values.txid,
            notes: values.notes,
            status: 'Pending',
            bid: "66e720f5bd9b0e59b6b3b608",
            fees: values.fees,
            date: new Date().toISOString(),
        }

        addUserWithdrawal(
            payload,
            () => {
                onCancel();
                onSubmit();
                setLoading(false)
            },
            () => setLoading(false)
        )
    };

    if (errorUser && errorAsset) {
        return (
            <AdminLayout>
                <div className="mx-auto p-6 bg-white">
                    <div className="text-red-500">Error Couldn't find assets or users </div>
                </div>
            </AdminLayout>
        );
    }

    if (loadingUser && loadingAsset) {
        return (
            <AdminLayout>
                <div className="mx-auto p-6 bg-white">
                    <div className="text-blue-500">Loading...</div>
                </div>
            </AdminLayout>
        );
    }

    return (
        <Modal
            visible={visible}
            title="New Withdrawal Request"
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button
                    key="save"
                    type="primary"
                    onClick={handleAdd}
                    loading={loading}
                >
                    Save
                </Button>,
            ]}>
            <Form form={form} layout="vertical">
                <Form.Item
                    name="user"
                    label="User"
                    rules={[{ required: true, message: 'Please select a user' }]}
                >
                    <Select
                        showSearch
                        placeholder="Select a user"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }>
                        {users?.results?.map(user => (
                            <Option key={user?._id} value={user?._id}>
                                {`${user?.email} - ${user?.username}`}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="asset"
                    label="Asset"
                    rules={[{ required: true, message: 'Please select an asset' }]}
                >
                    <Select
                        showSearch
                        placeholder="Select an asset"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }
                    >
                        {assets?.map(asset => {
                            return (
                                <Option key={asset?._id} value={asset?.asset}>
                                    {asset?.assetName}
                                </Option>
                            )
                        })}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="amount"
                    label="Amount"
                    rules={[
                        { required: true, message: 'Please enter the amount' },
                        { type: 'number', min: 0, message: 'Amount must be positive' },
                    ]}
                >
                    <InputNumber
                        style={{ width: '100%' }}
                        step="0.00000001"
                        placeholder="Enter amount"
                    />
                </Form.Item>

                <Form.Item
                    name="fees"
                    label="Fees"
                    rules={[
                        { required: true, message: 'Please enter the fees' },
                        { type: 'number', min: 0, message: 'Fees must be positive' },
                    ]}
                >
                    <InputNumber
                        style={{ width: '100%' }}
                        step="0.00000001"
                        placeholder="Enter fees"
                    />
                </Form.Item>

                <Form.Item
                    name="txid"
                    label="Transaction ID"
                >
                    <Input placeholder="Enter transaction ID" />
                </Form.Item>

                <Form.Item
                    name="notes"
                    label="Notes"
                >
                    <TextArea rows={4} placeholder="Enter notes" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Add;
