import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Select, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useUpdateAdminManagernService } from "../../services/adminManagerService.js";

const { Option } = Select;

const Edit = ({ adminManager, onUpdateSuccess }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const { updateAdminManager } = useUpdateAdminManagernService();

  useEffect(() => {
    if (adminManager) {
      form.setFieldsValue({
        email: adminManager.email,
        role: adminManager.role === 10 ? "Admin" : "Manager",
        status: adminManager.status,
      });
    }
  }, [adminManager, form]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const role = values.role === "Admin" ? 10 : 30;

        updateAdminManager(
          { ...values, role },
          adminManager.id,
          () => {
            onUpdateSuccess();
            form.resetFields();
            setVisible(false);
          },
          () => {}
        );
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      <Button
        type="text"
        icon={<EditOutlined />}
        style={{
          padding: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => setVisible(true)}
      />
      <Modal
        visible={visible}
        title="Edit Admin/Manager"
        onCancel={() => setVisible(false)}
        onOk={handleOk}
        okText="Update"
      >
        <Form
          form={form}
          layout="vertical"
          name="edit_admin_manager_form"
          initialValues={{
            email: adminManager?.email || "",
            role: adminManager?.role === 10 ? "Admin" : "Manager",
            status: adminManager?.status || "Active",
          }}
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Please input the email!" },
              { type: "email", message: "Please enter a valid email!" },
            ]}
          >
            <Input placeholder="Enter email" />
          </Form.Item>

          <Form.Item
            name="role"
            label="Role"
            rules={[{ required: true, message: "Please select a role!" }]}
          >
            <Select placeholder="Select role">
              <Option value="Admin">Admin</Option>
              <Option value="Manager">Manager</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: true, message: "Please select a status!" }]}
          >
            <Select>
              <Option value="Active">Active</Option>
              <Option value="Inactive">Inactive</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

Edit.propTypes = {
  adminManager: PropTypes.object.isRequired,
  onUpdateSuccess: PropTypes.func.isRequired,
};
export default Edit;
