import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Spin, Alert } from 'antd';

const LiquidityPool = () => {
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchAssets = async () => {
    try {
      const response = await axios.get('http://localhost:3000/api/v1/trading-assets/get-assets');
      const assetsData = response.data.data;

      const assetsWithRates = await Promise.all(
        assetsData.map(async (asset) => {
          let buyRate = 'N/A';
          let sellRate = 'N/A';

          try {
            // Fetch buy rate
            if (asset.urlBuy) {
              const buyResponse = await axios.get(asset.urlBuy);
              buyRate = buyResponse.data?.rate || 'N/A';
            }

            // Fetch sell rate
            if (asset.urlSell) {
              const sellResponse = await axios.get(asset.urlSell);
              sellRate = sellResponse.data?.rate || 'N/A';
            }
          } catch (err) {
            console.warn(`Failed to fetch rates for ${asset.asset}: ${err.message}`);
          }

          return {
            ...asset,
            buyRate,
            sellRate,
          };
        })
      );

      setAssets(assetsWithRates);
    } catch (err) {
      setError('Failed to fetch assets or rates.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAssets();
  }, []);

  const columns = [
    {
      title: 'Asset',
      dataIndex: 'assetName',
      key: 'assetName',
      render: (text, record) => (
        <div>
          <img src={`http://localhost:3000/images/coins/${record.image}`} alt={record.asset} width="24" style={{ marginRight: '8px' }} />
          {text}
        </div>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'minAmount',
      key: 'amount',
      render: (amount) => parseFloat(amount.$numberDecimal).toFixed(2),
    },
    {
      title: 'Buy',
      dataIndex: 'buyRate',
      key: 'buy',
      render: (buyRate) => (typeof buyRate === 'number' ? `R${buyRate.toFixed(2)}` : buyRate),
    },
    {
      title: 'Sell',
      dataIndex: 'sellRate',
      key: 'sell',
      render: (sellRate) => (typeof sellRate === 'number' ? `R${sellRate.toFixed(2)}` : sellRate),
    },
  ];

  if (loading) {
    return <Spin tip="Loading Liquidity Pool..." />;
  }

  if (error) {
    return <Alert message={error} type="error" />;
  }

  return (
    <div style={{ padding: '20px' }}>
      <h1>Liquidity Pool</h1>
      <Table
        dataSource={assets}
        columns={columns}
        rowKey="_id"
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
};

export default LiquidityPool;
