// src/pages/Clients/ClientUser.jsx

import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import PaginatedTable from '../../components/PaginatedTable';
import AdminLayout from '../../layouts/AdminLayout';
import { debounce } from 'lodash';
import { message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons'; // Importing required icon

const ClientUser = () => {
    const [allUsers, setAllUsers] = useState([]); // Store all users
    const [filteredUsers, setFilteredUsers] = useState([]); // Store filtered users
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Modal state variables can be added here if needed

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const response = await axios.get('http://localhost:3000/api/v1/clients/all-clients');
            if (response.status === 200 && response.data && Array.isArray(response.data.results)) {
                setAllUsers(response.data.results);
                setFilteredUsers(response.data.results); // Initialize filteredUsers with all data
            } else {
                throw new Error("Data is not in expected format");
            }
        } catch (err) {
            setError('Failed to fetch data: ' + err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleSearch = useCallback(
        debounce((filters) => {
            // Apply front-end filtering based on the filters object
            let filtered = allUsers;

            // Global Search
            if (filters.globalSearch) {
                const searchText = filters.globalSearch.toLowerCase();
                filtered = filtered.filter((user) =>
                    JSON.stringify(user).toLowerCase().includes(searchText)
                );
            }

            // Column-specific Filters
            Object.keys(filters).forEach((key) => {
                if (key !== 'globalSearch') {
                    const value = filters[key].toLowerCase();
                    if (value) {
                        filtered = filtered.filter((user) => {
                            const itemValue = getNestedValue(user, key);
                            return (
                                itemValue &&
                                itemValue.toString().toLowerCase().includes(value)
                            );
                        });
                    }
                }
            });

            setFilteredUsers(filtered);
        }, 300),
        [allUsers]
    );

    // Utility function to get nested values
    const getNestedValue = (obj, path) => {
        return path
            .split('.')
            .reduce((o, p) => (o && o[p] !== undefined && o[p] !== null ? o[p] : ''), obj);
    };

    // Function to convert JSON data to CSV
    const convertToCSV = (data) => {
        const headers = ['Username', 'Email', 'Role', 'Status', 'Created At'];
        const rows = data.map((user) => [
            user.username || '',
            user.email || '',
            user.role || '',
            user.status || '',
            formatDate(user.createdAt) || '',
        ]);
        const csvContent = [headers, ...rows];
        const csvString = csvContent
            .map((row) =>
                row
                    .map((item) => {
                        const escapedItem = ('' + item).replace(/"/g, '""');
                        if (/[",\n]/.test(escapedItem)) {
                            return `"${escapedItem}"`;
                        }
                        return escapedItem;
                    })
                    .join(',')
            )
            .join('\n');
        return csvString;
    };

    const handleExport = () => {
        try {
            if (filteredUsers.length === 0) {
                message.warning('No users available to export');
                return;
            }
            const csvData = convertToCSV(filteredUsers);
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'users_export.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            message.success('Exported users data successfully');
        } catch (error) {
            message.error('Failed to export users');
        }
    };

    // Function to format date as "07 November 2024, 07:30"
    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day} ${month} ${year}, ${hours}:${minutes}`;
    };

    const columns = [
        { title: 'Username', dataIndex: 'username', key: 'username' },
        { title: 'Email', dataIndex: 'email', key: 'email' },
        { title: 'Role', dataIndex: 'role', key: 'role' },
        { title: 'Status', dataIndex: 'status', key: 'status' },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text) => formatDate(text), // Updated render function for date formatting
        },
        // If there are more fields, add them here similarly without leaving placeholders
    ];

    if (error) {
        return (
            <AdminLayout>
                <div className="mx-auto p-6 bg-white">
                    <div className="text-red-500">Error: {error}</div>
                </div>
            </AdminLayout>
        );
    }

    if (loading) {
        return (
            <AdminLayout>
                <div className="mx-auto p-6 bg-white">
                    <div className="text-blue-500">Loading...</div>
                </div>
            </AdminLayout>
        );
    }

    return (
        <AdminLayout currentKey={'sub2'} openKey='4'>
            <div className="mx-auto p-6 bg-white">
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-xl font-semibold">Client Users</h1>
                    <div className="text-xs text-gray-500">
                        Client &gt; Dashboard &gt; Users
                    </div>
                </div>
                <div className="flex justify-between items-center mb-6">
                    {/* Placeholder div for alignment */}
                    <div></div>
                    <button
                        className="flex items-center px-4 py-2 bg-[#57249c] text-white rounded-lg hover:bg-[#432076] transition"
                        onClick={handleExport}
                    >
                        <DownloadOutlined className="mr-2" /> Export (csv)
                    </button>
                </div>
                <PaginatedTable
                    columns={columns}
                    itemsPerPage={15}
                    onEdit={null} // If edit functionality is needed, implement handlers accordingly
                    onDelete={null} // If delete functionality is needed, implement handlers accordingly
                    onView={null} // If view functionality is needed, implement handlers accordingly
                    searchFilters={null} // Not used since filtering is handled internally
                    onSearch={handleSearch}
                    dataSource={filteredUsers}
                    isSearchable={true} // Ensure search is enabled
                />
            </div>
        </AdminLayout>
    );
};

export default ClientUser;
