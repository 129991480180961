import { Button, Form, Input, Modal, Select } from 'antd';
import React, { useState } from 'react';
import AdminLayout from '../../../../layouts/AdminLayout';
import { useGetAllAssets, useGetAllChains } from '../../../../services/assetService';
import { useAddWalletService } from '../../../../services/userWalletService';
const { Option } = Select;

const Add = ({ userId, visible, onCancel, onSubmit }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [assetId, setAssetId] = useState(null);
    const { addWallet } = useAddWalletService()
    const { data: assets, error: errorAsset, isLoading: loadingAsset } = useGetAllAssets("type=Crypto") // type=Crypto
    const { data: chains } = useGetAllChains(assetId);

    const handleAdd = async () => {
        setLoading(true)

        const values = await form.validateFields();

        const payload = {
            walletId: values.walletId,
            walletIdLegacy: values.walletIdLegacy,
            walletType: values.walletType,
            assetId: assetId,
            chainId: values.chainId,
            tag: values.tag,
            userId: userId,
        }

        addWallet(
            payload,
            () => {
                onCancel();
                onSubmit();
                setLoading(false)
            },
            () => setLoading(false)
        )
    };

    if (errorAsset) {
        return (
            <AdminLayout>
                <div className="mx-auto p-6 bg-white">
                    <div className="text-red-500">Error Couldn't find assets or users </div>
                </div>
            </AdminLayout>
        );
    }

    if (loadingAsset) {
        return (
            <AdminLayout>
                <div className="mx-auto p-6 bg-white">
                    <div className="text-blue-500">Loading...</div>
                </div>
            </AdminLayout>
        );
    }

    return (
        <Modal
            visible={visible}
            title="Add Client Wallet"
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button
                    key="save"
                    type="primary"
                    onClick={handleAdd}
                    loading={loading}
                >
                    Save
                </Button>,
            ]}>
            <Form form={form} layout="vertical">
                <Form.Item
                    name="asset"
                    label="Asset"
                    rules={[{ required: true, message: 'Please select an asset' }]}
                >
                    <Select
                        showSearch
                        placeholder="Select an asset"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }

                        onChange={(value) => {
                            const selectedAsset = assets?.find(asset => asset.asset === value);
                            setAssetId(selectedAsset?._id);
                        }}
                    >
                        {assets?.map(asset => {
                            return (
                                <Option key={asset?._id} value={asset?.asset}>
                                    {asset?.assetName}
                                </Option>
                            )
                        })}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="chainId"
                    label="Chain"
                    rules={[{ required: true, message: 'Please select a chain' }]}
                >
                    <Select
                        showSearch
                        placeholder="Select a chain"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }>
                        {chains?.map(chain => {
                            return (
                                <Option key={chain?._id} value={chain?._id}>
                                    {chain?.chain}
                                </Option>
                            )
                        })}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="walletId"
                    label="Wallet Address"
                    rules={[
                        { required: true, message: 'Please enter wallet ID' },
                    ]}
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="Enter user wallet"
                    />
                </Form.Item>

                <Form.Item
                    name="walletIdLegacy"
                    label="Wallet Legacy"
                // rules={[
                //     { required: true, message: 'Please enter wallet legacy' },
                // ]}
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="Enter user wallet legacy"
                    />
                </Form.Item>

                <Form.Item
                    name="tag"
                    label="Tag"
                // rules={[
                //     { required: true, message: 'Please enter tag' },
                // ]}
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="Enter user tag"
                    />
                </Form.Item>

                <Form.Item
                    name="walletType"
                    label="Wallet Type"
                    rules={[{ required: true, message: 'Please select wallet type' }]}
                >
                    <Select
                        showSearch
                        placeholder="Select a wallet type"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }>
                        <Option value="Custodial">  Custodial  Wallet</Option>
                        <Option value="Non-Custodial">  Non Custodial Wallet </Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Add;
