import React, { useState } from "react";
import { Modal, Button, Space, Steps, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import FAQForm from "../../../../models/FAQ";
import { update } from "../../../../services/apiService";

const { Step } = Steps;

const Edit = ({ id, productId, onUpdateSuccess }) => {
  const [visible, setVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(1); // Track current step
  const [formData, setFormData] = useState({}); // Store form data

  const toggleForm = () => {
    setVisible(!visible);
  };

  const handleNextStep = (data) => {
    setFormData((prevData) => ({
      ...prevData,
      ...data,
    }));
    setCurrentStep(2); // Move to next step
  };

  const onPrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1); // Go back to previous step
    }
  };

  const handleFinalSubmit = async (data) => {
    try{
        const response = await update('/api/v1/faqs/update', { id }, data.faqs[0]);
        
        if(response.status === 200 && response?.data.data){
          console.log(response.data.data);
            message.success(`FAQ successfully updated.`);
            onUpdateSuccess(Math.random());
            toggleForm();
            console.log(response.data.data)
        }
    }catch(error){
        message.error(`Could not update asset information`);
    }
  };

  if (!visible) {
    return (
      <Button
        type="text"
        icon={<EditOutlined />}
        style={{
            padding: 0, // Removes padding for a cleaner look
            display: "flex", // Ensures proper alignment
            alignItems: "center",
            justifyContent: "center",
        }}
        onClick={toggleForm} // Properly placed onClick handler
      />
    );
  }

  return (
    <div className="max-w-4xl mx-auto mt-8">
        <Modal
            title="Edit Details"
            open={visible}
            onCancel={toggleForm}
            footer={null}
            width={1200}
            destroyOnClose
            style={{ maxHeight: '80vh', overflowY: 'auto' }}
        >
            <FAQForm
              id={id}
              productId={productId}
              onSubmit={handleFinalSubmit}
              state="edit"
            />
        </Modal>
    </div>
  );
};

export default Edit;
