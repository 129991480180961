import {
  Breadcrumb,
  Space,
  Typography
} from "antd";
import React, { useState } from "react";
import PaginatedTable from "../../components/PaginatedTable";
import AdminLayout from "../../layouts/AdminLayout";
//import View from './View';
//import Create from "./Create";
import { convertDecimal128 } from "../../utils/convertNumbers";
import Aprprove from "./Approve";
/*import Edit from './Edit';
import Delete from './Delete';*/

const { Title } = Typography;

const Index = () => {
	const [refresh, setRefresh] = useState(false);

	const columns = [
		{
			title: "User",
			dataIndex: "userId.email",
			key: "name",
			render: (text, record) => {
			return record.userId.email;
			}
		},
		{
		title: "Name",
		dataIndex: "productId.name",
		key: "name",
		render: (text, record) => {
			return record?.productId?.name || "N/A"
		}
		},
		{
		title: "Amount",
		dataIndex: "investmentAmount",
		key: "minAmount",
		render: (text, record) => (
			record.investmentAmount ? convertDecimal128(record?.investmentAmount): "N/A"
		)
		},
		{
		title: "Expirience",
		dataIndex: "experience",
		key: "experience",
		//render: (text) => (text ? text.toUpperCase() : 'N/A'), // Check if text exists before calling toUpperCase
		},
		{
		title: "Risk Tolerance",
		dataIndex: "riskTolerance",
		key: "riskTolerance",
		},
		{
		title: "Status",
		dataIndex: "status",
		key: "status",
		render: (text, record) => (
			<Space size="middle">
				<Aprprove itemStatus={record.status} id={record._id} />
			</Space>
		),
		},
		{
		title: "Actions",
		key: "actions",
		render: (text, record) => (
			<Space size="middle">
				
			</Space>
		),
		}
	];

  const filters = [
    { name: "assetName", label: "Asset Name", type: "text" },
    { name: "asset", label: "Asset", type: "text" },
    { name: "firstname", label: "First Name", type: "text" },
    { name: "lastname", label: "Last Name", type: "text" },
    { name: "identity_number", label: "Identity Number", type: "text" },
    {
      name: "type",
      label: "Type",
      type: "dropdown",
      options: ["Fiat", "Crypto"],
    },
    {
      name: "status",
      label: "Status",
      type: "dropdown",
      options: [true, false],
    },
  ];

  return (
    <AdminLayout currentKey={'inv-sub2'} openKey='20'>
      <div className="mx-auto p-6 bg-white">
        {/* Breadcrumb */}
        <Breadcrumb className="mb-4">
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item>Investment</Breadcrumb.Item>
          <Breadcrumb.Item>Investment Products</Breadcrumb.Item>
        </Breadcrumb>

        {/*<Create onCreateSuccess={setRefresh} />*/}

        <PaginatedTable
          fetchEndpoint="/api/v1/investment-application/index"
          columns={columns}
          //actionColumn={actionColumn}
          isSearchable={true}
          isSelectable={true}
          filters={filters}
          refresh={refresh}
        />
      </div>
    </AdminLayout>
  );
};

export default Index;
