import { Form, Input, InputNumber, Modal } from "antd";
import React, { useState } from "react";
import { useUpdateSystemService } from "../../../../services/systemSettingService";

const EditSystem = ({ data, visible, onCancel, onSubmit }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const { updateSystem } = useUpdateSystemService();

    const handleUpdate = async () => {
        setLoading(true);
        try {
            const values = await form.validateFields();

            const now = new Date();
            const hours = parseInt(values.quoteTime, 10);
            const formattedQuoteTime = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate(),
                hours,
                0,
                0
            );

            const payload = {
                _id: data?._id,
                downEmailNotifications: values.downEmailNotifications.split(",").map((email) => email.trim()),
                quoteTime: formattedQuoteTime,
                minQuoteTrade: values?.minQuoteTrade,
                arbitrageCommission: values?.arbitrageCommission,
                basicWithdrawalLimit: values?.basicWithdrawalLimit,
                advancedWithdrawalLimit: values?.advancedWithdrawalLimit,
                proWithdrawalLimit: values?.proWithdrawalLimit,
                inactiveTimeout: values?.inactiveTimeout,
            };

            await updateSystem(
                payload,
                () => {
                    onSubmit();
                    setLoading(false);
                },
                () => setLoading(false)
            );
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <Modal
            visible={visible}
            title="Edit System Information"
            onCancel={onCancel}
            onOk={handleUpdate}
            confirmLoading={loading}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    downEmailNotifications: data?.downEmailNotifications.join(", "),
                    quoteTime: data?.quoteTime ? new Date(data?.quoteTime).getHours() : "",
                    minQuoteTrade: data?.minQuoteTrade,
                    arbitrageCommission: data?.arbitrageCommission,
                    basicWithdrawalLimit: data?.basicWithdrawalLimit,
                    advancedWithdrawalLimit: data?.advancedWithdrawalLimit,
                    proWithdrawalLimit: data?.proWithdrawalLimit,
                    inactiveTimeout: data?.inactiveTimeout,
                }}
            >
                {/* Down Email Notifications */}
                <Form.Item
                    name="downEmailNotifications"
                    label="RfQ Down Email Notifications"
                    rules={[{ required: true, message: "Down Email Notifications are required" }]}
                >
                    <Input placeholder="Enter emails separated by commas" />
                </Form.Item>

                {/* Quote Time */}
                <Form.Item
                    name="quoteTime"
                    label="RfQ Quote Time"
                    rules={[{ required: true, message: "Quote Time is required" }]}
                >
                    <InputNumber min={0} placeholder="Enter quote time" />
                </Form.Item>

                {/* Minimum Quote Trade */}
                <Form.Item
                    name="minQuoteTrade"
                    label="RfQ Minimum Quote Trade"
                    rules={[{ required: true, message: "Minimum Quote Trade is required" }]}
                >
                    <InputNumber min={0} placeholder="Enter minimum quote trade" />
                </Form.Item>

                {/* Arbitrage Commission */}
                <Form.Item
                    name="arbitrageCommission"
                    label="Arbitrage Commission (%)"
                    rules={[{ required: true, message: "Arbitrage Commission is required" }]}
                >
                    <InputNumber min={0} placeholder="Enter arbitrage commission" />
                </Form.Item>

                {/* Basic Withdrawal Limit */}
                <Form.Item
                    name="basicWithdrawalLimit"
                    label="Basic Level Withdrawal Limit"
                    rules={[{ required: true, message: "Basic Withdrawal Limit is required" }]}
                >
                    <InputNumber min={0} placeholder="Enter basic withdrawal limit" />
                </Form.Item>

                {/* Advanced Withdrawal Limit */}
                <Form.Item
                    name="advancedWithdrawalLimit"
                    label="Advanced Level Withdrawal Limit"
                    rules={[{ required: true, message: "Advanced Withdrawal Limit is required" }]}
                >
                    <InputNumber min={0} placeholder="Enter advanced withdrawal limit" />
                </Form.Item>

                {/* Pro Withdrawal Limit */}
                <Form.Item
                    name="proWithdrawalLimit"
                    label="Pro Level Withdrawal Limit"
                    rules={[{ required: true, message: "Pro Withdrawal Limit is required" }]}
                >
                    <InputNumber min={0} placeholder="Enter pro withdrawal limit" />
                </Form.Item>

                {/* Inactive Timeout */}
                <Form.Item
                    name="inactiveTimeout"
                    label="Inactive Timeout (in minutes)"
                    rules={[{ required: true, message: "Inactive Timeout is required" }]}
                >
                    <InputNumber min={0} placeholder="Enter inactive timeout in minutes" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditSystem;
