import React from "react";
import { Descriptions } from "antd";

/**
 * Reusable DetailView component for displaying key-value data.
 *
 * @param {Object[]} data - Array of objects representing rows. Each object should have `label` and `value` keys.
 * @param {string} className - Additional Tailwind classes for customizing layout.
 */
const DetailView = ({ data, className = "" }) => {
  return (
    <div className={`w-full ${className}`}>
      <Descriptions bordered column={1}>
        {data.map((item) => (
          <Descriptions.Item key={item.label} label={item.label}>
            {item.value || "N/A"}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </div>
  );
};

export default DetailView;
