import { EyeOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import ProfileInfoTab from '../Tabs/Overview';

const View = ({ userId }) => {
  const [visible, setVisible] = useState(false);

  const toggleView = () => {
    setVisible(!visible);
  };

  if (!visible) {
    return (
      <Button
        type="text"
        icon={<EyeOutlined />}
        style={{
          padding: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={toggleView}
      />
    );
  }

  return (
    <Modal
      title="View User"
      open={visible}
      onCancel={toggleView}
      footer={null}
      width={1200}
      style={{ maxHeight: '80vh', overflowY: 'auto', background: '#FFFFFF' }}
    >
      <ProfileInfoTab userID={userId} hideBtn={false} />
    </Modal>
  );
};

export default View;
