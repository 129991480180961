import React, { useEffect, useState } from "react";
import { Form, Select, Spin } from "antd";
import axios from "axios";
import { getQuery } from "../services/apiService";

const { Option } = Select;

/**
 * Reusable DynamicSelect component for fetching and displaying data in a searchable dropdown.
 *
 * @param {string} name - The name of the form field.
 * @param {string} label - The label of the form field.
 * @param {string} endpoint - The API endpoint to fetch the data.
 * @param {string} placeholder - Placeholder text for the dropdown.
 * @param {string} valueKey - The key in the API response to use as the option value.
 * @param {string} labelKey - The key in the API response to use as the option label.
 * @param {boolean} [allowClear=true] - Whether to allow clearing the selection.
 * @param {Function} [dataTransformer] - Optional function to transform the fetched data.
 * @param {Object} [queryParams={}] - Optional query parameters for the API request.
 * @param {Array} rules - Form validation rules.
 */
const DynamicSelect = ({
  name,
  label,
  endpoint,
  placeholder,
  valueKey,
  labelKey,
  allowClear = true,
  dataTransformer,
  queryParams = {},
  rules = [],
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
        setLoading(true);
        try {
            //const response = await axios.get(endpoint, { params: queryParams });
            const response =  await getQuery(endpoint, { params: queryParams });
            let fetchedData = response.data.data;
            console.log(fetchedData)

            if (dataTransformer) {
            fetchedData = dataTransformer(fetchedData);
            }

            setData(fetchedData);
        } catch (error) {
            console.error("Failed to fetch data:", error);
        } finally {
            setLoading(false);
        }
    };

    fetchData();
  }, []);

  return (
    <Form.Item name={name} label={label} rules={rules}>
      <Select
        showSearch
        placeholder={placeholder}
        allowClear={allowClear}
        loading={loading}
        filterOption={(input, option) =>
          option?.children?.toLowerCase().includes(input.toLowerCase())
        }
      >
        {data.map((item) => (
          <Option key={item[valueKey]} value={item[valueKey]}>
            {item[labelKey]}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default DynamicSelect;
