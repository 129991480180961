import { Space } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useApiQuery } from "../../../hooks/useApiRequest";
import { formatDate } from "../../../utils/formateData";
import PaginatedTable2 from "../../../components/PaginatedTable2";
import View from "./View";
import Add from "./Add";

const Index = () => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { data, isLoading } = useApiQuery({
    key: ["transactions"],
    endPoint: `/api/v1/trading/get-all-trades?page=${currentPage}&limit=${pageSize}`,
    method: "GET",
  });

  const pagination = {
    totalItems: data?.totalItems || 0,
    totalPages: data?.totalPages || 1,
    currentPage,
    limit: pageSize,
    startIndex: (currentPage - 1) * pageSize + 1,
    endIndex: Math.min(currentPage * pageSize, data?.totalItems || 0),
  };

  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const transactionsHistoryColumns = [
    {
      title: "User",
      dataIndex: `user`,
      key: "userId",
      render: (text, record) =>
        `${record?.userId?.username} - ${record?.userId?.email} ` || "N/A",
    },
    {
      title: "Pair",
      dataIndex: "pair",
      key: "pair",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => text.charAt(0).toUpperCase() + text.slice(1),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `R ${parseFloat(amount?.$numberDecimal).toFixed(8)}`,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (total) => `R ${parseFloat(total?.$numberDecimal).toFixed(8)}`,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price) => `R ${parseFloat(price?.$numberDecimal).toFixed(8)}`,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => formatDate(date),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <span
          className={`${
            status === "Completed"
              ? "text-green-700"
              : status === "Pending"
              ? "text-yellow-500"
              : "text-red-500"
          }`}
        >
          {status}
        </span>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <View data={record} />
        </Space>
      ),
    },
  ];

  return (
    <div className="mx-auto p-6 bg-white">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-semibold">Transaction History </h1>
        <div className="text-xs text-gray-500">
          Dashboard &gt; Transaction &gt; History
        </div>
      </div>

      <div className="flex justify-between items-center mb-6">
        <button
          className="px-4 rounded-lg py-2 bg-[#22c6ab] text-white hover:bg-[#1da089] transition"
          onClick={() => setIsAddModalVisible(true)}
        >
          New User Trade
        </button>
      </div>

      <PaginatedTable2
        data={data?.results}
        columns={transactionsHistoryColumns}
        pagination={pagination}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageChange}
        loading={isLoading}
      />

      {isAddModalVisible && (
        <Add
          visible={isAddModalVisible}
          onCancel={() => setIsAddModalVisible(false)}
          onSubmit={() => {
            setIsAddModalVisible(false);
          }}
        />
      )}
    </div>
  );
};

export default Index;
