import React, { useEffect, useState } from "react";
import { getQuery } from "../../../../services/apiService";
import { convertDecimal128 } from "../../../../utils/convertNumbers";
import { Button, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import DetailView from "../../../../components/DetailView";
import { formatDate } from "../../../../utils/formateData";
import Edit from "./Edit";

const TermsConditionsTabs = ({product, reload}) => {
	const [termsAndConditions, setTermAndConditions] = useState(product.termsAndConditions);

	useEffect(() => {
		setTermAndConditions(product.termsAndConditions);
	}, [product]);

	const termsDetails = [
		{ label: 'Content', value: termsAndConditions.content || 'N/A' },
		{ label: 'Effective Date', value: formatDate(termsAndConditions.effectiveDate || null) },
		{ label: 'Visible', value: termsAndConditions.isVisible || 'N/A' },
		{ label: 'Created At', value: formatDate(termsAndConditions?.createdAt || null) },
		{ label: 'Updated At', value: formatDate(termsAndConditions.updatedAt || null) },
		
	];

	return(
		<>
			<div className="text-right py-2">
				<Edit id={termsAndConditions?._id} onUpdateSuccess={reload} />
			</div>
			<DetailView data={termsDetails} className="w-full" />
		</>
	)
}

export default TermsConditionsTabs;