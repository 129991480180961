import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const baseURL = process.env.REACT_APP_BASE_URL;

const UnauthorizedPage = () => {
  const navigate = useNavigate();
  const { logout } = useAuth0();

  const goToHome = () => {
    //navigate("/");
    logout({ returnTo: baseURL /*window.location.origin*/ });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <Button 
            type="primary" 
            className="bg-blue-500 hover:bg-blue-600 text-white"
            onClick={goToHome}
          >
            Back to Home
          </Button>
        }
      />
    </div>
  );
};

export default UnauthorizedPage;
