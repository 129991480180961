import { useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { useApiMutation, useApiQuery } from "../hooks/useApiRequest";
import axios from "axios";

//* ---------Get all clients---------
export const useGetAllClientsService = () => {
  return useApiQuery({
    key: ["clients"],
    endPoint: `/api/v1/get-all-clients-temp`,
    method: "GET",
  });
};

//*********** add payment transfer**********
export const useAddPaymentService = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, error } = useApiMutation();

  const addPayment = async (values, onSuccess, onError) => {
    try {
      await mutateAsync({
        key: ["payment-create"],
        endPoint: `/api/v1/pay/create`,
        method: "POST",
        bodyContent: values,
      });

      queryClient.invalidateQueries({ queryKey: ["payment"] });
      queryClient.invalidateQueries({ queryKey: ["payment-create"] });
      onSuccess();
      message.success("Successfully added");
    } catch (error) {
      console.error("Error during payment:", error);
      onError();
      message.error(error.message || "An error occurred");
    }
  };

  return { addPayment, error };
};

export const useReversePaymentService = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, error } = useApiMutation();

  const reversePayment = async (payload, onSuccess, onError) => {
    console.log("Payload sent to API:", payload);

    try {
      await mutateAsync({
        key: ["payment-reverse"],
        endPoint: `/api/v1/pay/reverse/${payload.transactionId}`,
        method: "POST",
        body: { asset: payload.asset },
      });
      queryClient.invalidateQueries({ queryKey: ["payment"] });
      message.success("Transaction successfully reversed");
      onSuccess();
    } catch (error) {
      message.error(error.message || "Failed to reverse the transaction");
      onError();
    }
  };

  return { reversePayment, error };
};

export const useGenerateQuoteService = () => {
  const { error } = useApiMutation();

  const generateQuote = async (payload, onSuccess, onError) => {
    try {
      const response = await axios.post("/api/v1/pay/quote", payload);
      onSuccess(response.data);
    } catch (err) {
      onError(err);
    }
  };

  return { generateQuote, error };
};
