import {
  BankOutlined,
  DollarOutlined,
  ExportOutlined,
  EyeOutlined,
  KeyOutlined,
  MoneyCollectOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Button, Modal, Tabs } from 'antd';
import React, { useState } from 'react';

import BalanceOveriewTab from './Tabs/BalanceOverviewTab';
import BankAccountsTab from './Tabs/Bank-account-tab';
import ClientKeyTab from './Tabs/Client-key-tab';
import ProfileInfoTab from './Tabs/Overview';
import USDBankAccountsTab from './Tabs/USD-Bank-account-tab';
import WalletsTab from './Tabs/Wallets-tab';

const { TabPane } = Tabs;

const View = ({ /*visible,* onCancel, userData*/ userId }) => {
  const [activeTab, setActiveTab] = useState('1');
  const [visible, setVisible] = useState(false);


  const toggleView = () => {
    setVisible(!visible);
  };

  if (!visible) {
    return (
      <Button
        type="text"
        icon={<EyeOutlined />}
        style={{
          padding: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={toggleView}
      />
    );
  }

  return (
    <Modal
      title="View User"
      open={visible}
      onCancel={toggleView}
      footer={null}
      width={1200}
      style={{ maxHeight: '80vh', overflowY: 'auto', background: '#FFFFFF' }}
    >
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        {/* Profile Information Tab */}
        <TabPane
          tab={
            <span>
              <UserOutlined /> Overview
            </span>
          }
          key="1"
        >
          <ProfileInfoTab userID={userId} hideBtn={true} />
        </TabPane>

        {/* Balance Overview Tab */}
        <TabPane
          tab={
            <span>
              <DollarOutlined /> Balance Overview
            </span>
          }
          key="3"
        >
          {<BalanceOveriewTab activeTab={activeTab} userID={userId} />}
        </TabPane>

        {/* Additional Tabs */}
        <TabPane
          tab={
            <span>
              <BankOutlined /> Bank Accounts
            </span>
          }
          key="4"
        >
          {/* Bank Account(s) content */}
          <BankAccountsTab activeTab={activeTab} userID={userId} />
        </TabPane>
        <TabPane
          tab={
            <span>
              <MoneyCollectOutlined /> USD Bank Accounts
            </span>
          }
          key="5"
        >
          {/* Bank Account(s) content */}
          <USDBankAccountsTab activeTab={activeTab} userID={userId} />
        </TabPane>
        <TabPane
          tab={
            <span>
              <ExportOutlined /> Crypto Addresses
            </span>
          }
          key="6"
        >
          {/* Withdrawal Addresses content */}
          <WalletsTab activeTab={activeTab} userID={userId} />
        </TabPane>
        <TabPane
          tab={
            <span>
              <KeyOutlined /> Client API Keys
            </span>
          }
          key="7"
        >
          {/* Client API Keys content  - there */}
          <ClientKeyTab activeTab={activeTab} userID={userId} />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default View;
