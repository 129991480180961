// ../../Modals/Settings/EditAPIKeys.jsx

import React, { useEffect } from 'react';
import { Modal, Form, Input, Select, Button, message } from 'antd';
import PropTypes from 'prop-types';
import axios from 'axios';

const { Option } = Select;

const EditAPIKeys = ({ visible, onCancel, serviceSetting, onUpdateSuccess }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (serviceSetting && visible) {
      form.setFieldsValue({
        status: serviceSetting.status,
        apiKey: serviceSetting.apiKey,
        apiSecret: serviceSetting.apiSecret,
      });
    }
  }, [serviceSetting, visible, form]);

  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        axios
          .put(
            `/api/v1/settings/service-settings/${serviceSetting._id}`,
            values
          )
          .then(response => {
            message.success('API Keys updated successfully!');
            onUpdateSuccess(response.data);
            form.resetFields();
          })
          .catch(error => {
            message.error(
              error.response?.data?.message || 'Failed to update API Keys.'
            );
          });
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      visible={visible}
      title={`Edit API Keys - ${serviceSetting?.name}`}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Save Changes
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        name="edit_api_keys_form"
        initialValues={{
          status: serviceSetting?.status || 'Testing',
          apiKey: serviceSetting?.apiKey || '',
          apiSecret: serviceSetting?.apiSecret || '',
        }}
      >
        <Form.Item
          name="status"
          label="Status"
          rules={[{ required: true, message: 'Please select a status!' }]}
        >
          <Select>
            <Option value="Enabled">Enabled</Option>
            <Option value="Disabled">Disabled</Option>
            <Option value="Testing">Testing</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="apiKey"
          label="API Key"
          rules={[
            {
              required: true,
              message: 'Please input the API Key!',
            },
          ]}
        >
          <Input placeholder="Enter API Key" />
        </Form.Item>

        <Form.Item
          name="apiSecret"
          label="API Secret"
          rules={[
            {
              required: true,
              message: 'Please input the API Secret!',
            },
          ]}
        >
          <Input placeholder="Enter API Secret" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

EditAPIKeys.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  serviceSetting: PropTypes.object.isRequired,
  onUpdateSuccess: PropTypes.func.isRequired,
};

export default EditAPIKeys;
