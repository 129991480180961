import { useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { useApiMutation, useApiQuery } from "../hooks/useApiRequest";

//* ---------Get all crypto deposits---------
export const useGetAllInvestApplication = () => {
  return useApiQuery({
    key: ["user-invests-appliaction"],
    endPoint: `/api/v1/investment-application/index`,
    method: "GET",
  });
};

//*********** Update user Crypto deposit**********
export const UpdateInvestmentApplicationStatusService = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, error } = useApiMutation();

  const updateInvestmentApplication = async (
    values,
    id,
    onSuccess,
    onError
  ) => {
    try {
      await mutateAsync({
        key: ["investment-application-update-status", id],
        endPoint: `/api/v1/investment-application/update-statusappli/${id}`,
        method: "PATCH",
        bodyContent: values,
      });
      queryClient.invalidateQueries({
        queryKey: ["investment-application-update-status"],
      });
      queryClient.invalidateQueries({
        queryKey: ["user-invests-appliaction", id],
      });
      onSuccess();
      message.success("Successfully updated");
    } catch (error) {
      onError();
      message.error(error.message);
    }
  };

  return { updateInvestmentApplication, error };
};

//*********** Delete user crypto deposit **********
export const DeleteInvestmentApplicationService = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, error } = useApiMutation();

  const deleteInvestApp = async (id, onSuccess, onError) => {
    try {
      await mutateAsync({
        key: ["invest-application-delete"],
        endPoint: `/api/v1/investment-application/${id}`,
        method: "DELETE",
      });
      queryClient.invalidateQueries({
        queryKey: ["invest-application-delete"],
      });
      onSuccess();
      message.success("Successfully deleted investment Application");
    } catch (error) {
      onError();
      message.error(error.message);
    }
  };

  return { deleteInvestApp, error };
};
