import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Switch,
  message,
  Select,
} from "antd";
import {
  useAddPaymentService,
  useGetAllClientsService,
} from "../../services/paymentService";
import DynamicSelect from "../../components/DynamicSelect";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Add = ({ visible, onCancel, onSubmit }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { addPayment } = useAddPaymentService();

  const { data: clientsData, isLoading: clientsLoading } =
    useGetAllClientsService();

  const mappedOptions = (clientsData?.results || []).map((client) => {
    return {
      label: client.email || "No Email",
      value: client._id,
      key: client._id,
    };
  });

  const handleAdd = async () => {
    setLoading(true);

    try {
      const values = await form.validateFields();

      const payload = {
        senderId: values.userId,
        anonymous: values.anonymous || false,
        senderReference: values.senderReference,
        receiverReference: values.receiverReference,
        amount: parseFloat(values.amount),
        serviceFee: parseFloat(values.serviceFee),
        totalAmount: parseFloat(values.totalAmount),
        assetId: values.assetId,
      };

      switch (values.receiverValue) {
        case "payId":
          payload.receiverPayId = values.receiverInfo;
          break;
        case "email":
          payload.receiverEmail = values.receiverInfo;
          break;
        case "phone":
          payload.receiverPhone = values.receiverInfo;
          break;
        default:
          throw new Error("Invalid Receiver Type selected.");
      }

      console.log("Payload:", payload);

      addPayment(
        payload,
        () => {
          onCancel();
          onSubmit();
          setLoading(false);
        },
        () => setLoading(false)
      );
    } catch (error) {
      console.error("Validation error:", error);
      message.error(error.message || "Validation failed");
      setLoading(false);
    }
  };

  const handleValuesChange = (changedValues, allValues) => {
    const { amount, serviceFee } = allValues;

    if (amount && serviceFee) {
      form.setFieldsValue({
        totalAmount: (parseFloat(amount) - parseFloat(serviceFee)).toFixed(2),
      });
    } else {
      form.setFieldsValue({
        totalAmount: "",
      });
    }
  };

  return (
    <Modal
      open={visible}
      title="Create Payment"
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleAdd} loading={loading}>
          Send
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          serviceFee: "",
          totalAmount: "",
        }}
        onValuesChange={handleValuesChange}
      >
        <Form.Item
          name="userId"
          label="Sender Email"
          rules={[{ required: true, message: "Please select a Sender Email" }]}
        >
          <Select
            placeholder="Select Sender Email"
            loading={clientsLoading}
            options={mappedOptions}
            showSearch
            filterOption={(input, option) =>
              (option?.label || "").toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>

        <Form.Item
          name="receiverValue"
          label="Type"
          rules={[{ required: true, message: "Please select a Receiver Type" }]}
        >
          <Select
            placeholder="Select Receiver Type"
            onChange={(value) => {
              form.setFieldsValue({ receiverInfoLabel: value });
              form.setFieldsValue({ receiverInfo: "" });
            }}
          >
            <Select.Option value="payId">PayID</Select.Option>
            <Select.Option value="email">Email</Select.Option>
            <Select.Option value="phone">Phone Number</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.receiverValue !== currentValues.receiverValue
          }
        >
          {() => {
            const type = form.getFieldValue("receiverValue");
            const dynamicLabel =
              type === "payId"
                ? "PayID"
                : type === "email"
                ? "Email"
                : type === "phone"
                ? "Phone Number"
                : "Receiver";

            return (
              <Form.Item
                name="receiverInfo"
                label={dynamicLabel}
                rules={[
                  {
                    required: true,
                    message: `Please enter a valid ${dynamicLabel}`,
                  },
                ]}
              >
                {type === "phone" ? (
                  <PhoneInput
                    country={"us"}
                    onChange={(phone) => {
                      form.setFieldsValue({ receiverInfo: phone });
                    }}
                  />
                ) : (
                  <Input placeholder="Enter PayID, Email, or Phone Number" />
                )}
              </Form.Item>
            );
          }}
        </Form.Item>

        <Form.Item name="anonymous" label="Anonymous" valuePropName="checked">
          <Switch />
        </Form.Item>

        <Form.Item
          name="senderReference"
          label="Sender Reference"
          rules={[{ required: true, message: "Please enter Sender Reference" }]}
        >
          <Input placeholder="Enter Sender Reference" />
        </Form.Item>

        <Form.Item
          name="receiverReference"
          label="Receiver Reference"
          rules={[
            { required: true, message: "Please enter Receiver Reference" },
          ]}
        >
          <Input placeholder="Enter Receiver Reference" />
        </Form.Item>

        <Form.Item
          name="amount"
          label="Amount"
          rules={[{ required: true, message: "Please enter Amount" }]}
        >
          <InputNumber style={{ width: "100%" }} min={0} step={0.01} />
        </Form.Item>

        <DynamicSelect
          name="assetId"
          label="Asset"
          endpoint="/api/v1/trading-assets/select-query"
          placeholder="Choose an asset"
          valueKey="value"
          labelKey="label"
          rules={[{ required: true, message: "Please select an asset!" }]}
        />

        <Form.Item
          name="serviceFee"
          label="Service Fee"
          rules={[{ required: true, message: "Please enter a service fee" }]}
        >
          <Input placeholder="Enter Service Fee" />
        </Form.Item>

        <Form.Item name="totalAmount" label="Total Amount">
          <Input readOnly disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Add;
