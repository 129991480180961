import { useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { useApiMutation, useApiQuery } from "../hooks/useApiRequest";

//*********** add user Withdrawal**********
export const AddNewsService = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, error } = useApiMutation();

  const addUserNews = async (values, onSuccess, onError) => {
    try {
      await mutateAsync({
        key: ["add-news-add"],
        endPoint: `/api/v1/news/create`,
        method: "POST",
        bodyContent: values,
      });
      queryClient.invalidateQueries({ queryKey: ["news"] });
      queryClient.invalidateQueries({ queryKey: ["add-news-add"] });
      onSuccess();
      message.success("Successfully added News");
    } catch (error) {
      onError();
      message.error(error.message);
    }
  };

  return { addUserNews, error };
};

//*********** Delete user Withdrawal **********
export const DeleteNews = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, error } = useApiMutation();

  const deleteNews = async (id, onSuccess, onError) => {
    try {
      await mutateAsync({
        key: ["news-delete"],
        endPoint: `/api/v1/news/delete?id=${id}`,
        method: "DELETE",
      });
      queryClient.invalidateQueries({ queryKey: ["news"] });
      queryClient.invalidateQueries({ queryKey: ["news-delete"] });
      onSuccess();
      message.success("Successfully deleted");
    } catch (error) {
      onError();
      message.error(error.message);
    }
  };

  return { deleteNews, error };
};

//*********** Update user Withdrawal**********
export const UpdateNewsService = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, error } = useApiMutation();

  const updateNews = async (id, values, onSuccess, onError) => {
    try {
      await mutateAsync({
        key: ["news-update"],
        endPoint: `/api/v1/news/update?id=${id}`,
        method: "PATCH",
        bodyContent: values,
      });
      queryClient.invalidateQueries({ queryKey: ["news"] });
      queryClient.invalidateQueries({ queryKey: ["news-update"] });
      onSuccess();
      message.success("Successfully updated");
    } catch (error) {
      onError();
      message.error(error.message);
    }
  };

  return { updateNews, error };
};
