import { Button, Form, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAddAPIKeyService } from '../../../../services/clientKeyService';

const Add = ({ userId, visible, onCancel, onSubmit }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const { addAPIKey } = useAddAPIKeyService()
    const [generatedKey, setGeneratedKey] = useState('');

    useEffect(() => {
        setGeneratedKey(uuidv4());
        form.setFieldsValue({ APIKey: uuidv4() });
    }, [form]);


    const handleAdd = async () => {
        setLoading(true)

        const values = await form.validateFields();

        const payload = {
            userId: userId,
            APIName: values.APIName,
            APIKey: values.APIKey
        }

        addAPIKey(
            payload,
            () => {
                onCancel();
                onSubmit();
                setLoading(false)
            },
            () => setLoading(false)
        )
    };

    return (
        <Modal
            visible={visible}
            title="Add Client API Key"
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button
                    key="save"
                    type="primary"
                    onClick={handleAdd}
                    loading={loading}
                >
                    Save
                </Button>,
            ]}>
            <Form form={form} layout="vertical">
                <Form.Item
                    name="APIName"
                    label="API Name"
                    rules={[{ required: true, message: 'Please enter a api  name' }]}
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="Enter api name"
                    />
                </Form.Item>

                <Form.Item
                    name="APIKey"
                    label="Generate API Key name"
                    rules={[{ required: true, message: 'API key is required' }]}
                >
                    <Input
                        style={{ width: '100%' }}
                        value={generatedKey}
                        disabled
                        placeholder="Generated API key"
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Add;
