import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Modal, Select } from 'antd';
import React, { useState } from 'react';
import AdminLayout from '../../../layouts/AdminLayout';
import { useGetAllAssets, useGetAllChains } from '../../../services/assetService';
import { useUpdateUserCryptoWithdrawalStatusService } from '../../../services/withdrawalCryptoService';
const { TextArea } = Input;
const { Option } = Select;

const Edit = ({ data }) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [assetId, setAssetId] = useState(null);
    const { updateUserCryptoWithdrawal } = useUpdateUserCryptoWithdrawalStatusService();
    const { data: assets, error: errorAsset, isLoading: loadingAsset } = useGetAllAssets("type=Crypto");
    const { data: chains } = useGetAllChains(assetId);
    const changedAsset = assets?.find((asset) => asset?._id === assetId);

    const handleEdit = async () => {
        setLoading(true)

        const values = await form.validateFields();

        const payload = {
            cryptoAsset: changedAsset?.asset ? changedAsset?.asset : data?.cryptoAsset,
            walletAddress: values.walletAddress,
            amount: values.amount,
            txid: values.txid,
            notes: values.notes,
            fee: values.fee,
            ...(values.chain && { chain: values.chain })
        }

        updateUserCryptoWithdrawal(
            payload,
            data?._id,
            () => {
                setLoading(false)
                toggleView()
            },
            () => { setLoading(false) }
        )
    }

    const toggleView = () => {
        setVisible(!visible);
    };

    if (!visible) {
        return (
            <Button
                type="text"
                icon={<EditOutlined />}
                style={{
                    padding: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                onClick={toggleView}
            />
        );
    }

    if (errorAsset) {
        return (
            <AdminLayout>
                <div className="mx-auto p-6 bg-white">
                    <div className="text-red-500">Error Couldn't find assets or users </div>
                </div>
            </AdminLayout>
        );
    }

    if (loadingAsset) {
        return (
            <AdminLayout>
                <div className="mx-auto p-6 bg-white">
                    <div className="text-blue-500">Loading...</div>
                </div>
            </AdminLayout>
        );
    }

    return (
        <Modal
            open={visible}
            title="Edit Withdrawal"
            onCancel={toggleView}
            footer={[
                <Button key="cancel" onClick={toggleView}>
                    Cancel
                </Button>,
                <Button
                    key="save"
                    type="primary"
                    onClick={handleEdit}
                    loading={loading}
                >
                    Save
                </Button>,
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    cryptoAsset: data?.cryptoAsset,
                    notes: data?.notes,
                    walletAddress: data?.walletAddress,
                    user: data?.userId?.email,
                    txid: data?.txid,
                    fee: data?.fee?.$numberDecimal ? parseFloat(data.fee.$numberDecimal) : undefined,
                    amount: data?.amount?.$numberDecimal
                        ? parseFloat(data.amount.$numberDecimal)
                        : undefined,
                }}>

                <Form.Item
                    name="user"
                    label="User"
                    rules={[{ required: true, message: 'Please enter user name' }]}
                >
                    <Input placeholder="Enter User name" disabled />
                </Form.Item>

                <Form.Item
                    name="cryptoAsset"
                    label="Asset"
                    rules={[{ required: true, message: 'Please select an asset' }]}>
                    <Select
                        showSearch
                        placeholder="Select an asset"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }
                        onChange={(value) => {
                            const selectedAsset = assets?.find(asset => asset._id === value);
                            setAssetId(selectedAsset?._id);
                        }}
                    >
                        {assets?.map(asset => {
                            return (
                                <Option key={asset?._id} value={asset?.cryptoAsset}>
                                    {asset?.assetName}
                                </Option>
                            )
                        })}
                    </Select>
                </Form.Item>

                {/* Get all chains */}
                {
                    assetId && (
                        <Form.Item
                            name="chain"
                            label="Chain">
                            <Select
                                showSearch
                                placeholder="Select a chain"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {chains?.map(chain => {
                                    return (
                                        <Option key={chain?._id} value={chain?._id}>
                                            {chain?.chain}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    )
                }

                <Form.Item
                    name="walletAddress"
                    label="Wallet Address"
                    rules={[
                        { required: true, message: 'Please enter the wallet address' },
                    ]}
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="Enter amount"
                    />
                </Form.Item>

                <Form.Item
                    name="amount"
                    label="Amount"
                    rules={[
                        { required: true, message: 'Please enter the amount' },
                        { type: 'number', min: 0, message: 'Amount must be positive' },
                    ]}
                >
                    <InputNumber
                        style={{ width: '100%' }}
                        step="0.00000001"
                        placeholder="Enter amount"
                    />
                </Form.Item>

                <Form.Item
                    name="fee"
                    label="Fees"
                    rules={[
                        { required: true, message: 'Please enter the fees' },
                        { type: 'number', min: 0, message: 'Fees must be positive' },
                    ]}
                >
                    <InputNumber
                        style={{ width: '100%' }}
                        step="0.00000001"
                        placeholder="Enter fees"
                    />
                </Form.Item>

                <Form.Item
                    name="txid"
                    label="Transaction ID"
                >
                    <Input placeholder="Enter transaction ID" />
                </Form.Item>

                <Form.Item
                    name="notes"
                    label="Notes"
                >
                    <TextArea rows={4} placeholder="Enter notes" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Edit;
