import { message } from "antd";
import React, { useEffect, useState } from "react";
import DetailView from "../../../components/DetailView";
import { getQuery } from "../../../services/apiService";
import { convertDecimal128 } from "../../../utils/convertNumbers";

const DetailsTab = ({ assetId }) => {
	const [assetInfo, setAssetInfo] = useState([]);

	useEffect(() => {
		const fetchAsset = async () => {
			try {
				console.log(assetId);
				const response = await getQuery('/api/v1/trading-assets/get', { id: assetId });

				if (response.status == 200) {
					setAssetInfo(response.data.data);
				}
			} catch (error) {
				message.error('Error while fetching client profile.');
			}
		}

		fetchAsset();
	}, [assetId]);


	const assetDetails = [
		{ label: 'Asset Name', value: assetInfo.assetName || 'N/A' },
		{ label: 'Asset', value: assetInfo.asset ? assetInfo.asset.toUpperCase() : 'N/A' },
		{ label: 'Notes', value: assetInfo.notes || 'N/A' },
		{ label: 'Deposit Minimum Amount', value: assetInfo.minAmount ? convertDecimal128(assetInfo.minAmount) : 'N/A' },
		{ label: 'Deposit Confirmations Amount', value: assetInfo.confirmation ?? 'N/A' }, // Allow numeric zero
		{ label: 'Deposit Pay Address', value: assetInfo.payAddress || 'N/A' },
		{ label: 'Deposit Fee', value: assetInfo.fees ? convertDecimal128(assetInfo.fees) : 'N/A' },
		{ label: 'Withdrawal Notes', value: assetInfo.withdrawalNotes || 'N/A' },
		{ label: 'Withdrawal Fees', value: assetInfo.withdrawlFees ? convertDecimal128(assetInfo.withdrawlFees) : 'N/A' },
		{ label: 'Withdrawal Minimum Amount', value: assetInfo.withdrawalMinAmount ? convertDecimal128(assetInfo.withdrawalMinAmount) : 'N/A' },
		{ label: 'Type', value: assetInfo.type || 'Unknown' },
		{ label: 'Tag Number', value: assetInfo.tagNumber || 'N/A' },
		{ label: 'Status', value: assetInfo.status || 'Unknown' },
		{ label: 'Reason', value: assetInfo.reason || 'N/A' },
		{ label: 'Liquidity Buy Status', value: assetInfo.liqBuyStatus < 1 ? 'Off' : 'On' },
		{ label: 'Liquidity Buy Spread', value: assetInfo.liqBuySpread ? convertDecimal128(assetInfo.liqBuySpread) : 'N/A' },
		{ label: 'Liquidity Sell Status', value: assetInfo.liqSellStatus < 1 ? 'Off' : 'On' },
		{ label: 'Liquidity Sell Spread', value: assetInfo.liqSellSpread ? convertDecimal128(assetInfo.liqSellSpread) : 'N/A' },
	];

	return (
		<>
			<DetailView data={assetDetails} className="w-full" />
		</>
	)
}

export default DetailsTab;

