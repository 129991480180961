// src/pages/BrokerCommissions.jsx

import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { message, Space, Button, Breadcrumb } from 'antd';
import { DownloadOutlined } from '@ant-design/icons'; // Only import necessary icons
import PaginatedTable from '../../components/PaginatedTable';
import ViewModal from '../../Modals/BrokerCommissions/ViewModal';
import EditModal from '../../Modals/BrokerCommissions/EditModal';
import DeleteModal from '../../Modals/BrokerCommissions/DeleteModal';
import AdminLayout from '../../layouts/AdminLayout';
import { debounce } from 'lodash';

const BrokerCommissions = () => {
    const [allCommissions, setAllCommissions] = useState([]); // Store all broker commissions
    const [filteredCommissions, setFilteredCommissions] = useState([]); // Store filtered commissions
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Modal state variables
    const [isViewModalVisible, setIsViewModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);

    // Function to fetch broker commissions data
    const fetchBrokerCommissions = async () => {
        setLoading(true);
        try {
            const response = await axios.get('http://localhost:3000/api/v1/brokers/broker-commissions');
            if (response.data.status) {
                const processedData = response.data.data.map((commission) => ({
                    _id: commission._id || '',
                    oldId: commission.oldId || 'N/A',
                    brokerId: commission.brokerId || 'N/A',
                    userId: commission.userId || 'N/A',
                    commission: commission.commission || { $numberDecimal: '0' },
                    asset: commission.asset || 'N/A',
                    type: commission.type || 'N/A',
                    traceId: commission.traceId || 'N/A',
                    date: commission.date || 'N/A',
                    createdAt: commission.createdAt || 'N/A',
                    updatedAt: commission.updatedAt || 'N/A',
                }));
                setAllCommissions(processedData);
                setFilteredCommissions(processedData); // Initialize with all data
            } else {
                message.error('Failed to fetch broker commissions.');
            }
        } catch (error) {
            message.error('Error fetching broker commissions.');
            console.error('Error fetching broker commissions:', error);
            setError('Failed to fetch broker commissions.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBrokerCommissions();
    }, []);

    // Debounced search handler
    const handleSearch = useCallback(
        debounce((filters) => {
            // Apply front-end filtering based on the filters object
            let filtered = allCommissions;

            // Global Search
            if (filters.globalSearch) {
                const searchText = filters.globalSearch.toLowerCase();
                filtered = filtered.filter((commission) =>
                    JSON.stringify(commission).toLowerCase().includes(searchText)
                );
            }

            // Column-specific Filters
            Object.keys(filters).forEach((key) => {
                if (key !== 'globalSearch') {
                    const value = filters[key].toLowerCase();
                    if (value) {
                        filtered = filtered.filter((commission) => {
                            const itemValue = getNestedValue(commission, key);
                            return (
                                itemValue &&
                                itemValue.toString().toLowerCase().includes(value)
                            );
                        });
                    }
                }
            });

            setFilteredCommissions(filtered);
        }, 300),
        [allCommissions]
    );

    // Utility function to get nested values
    const getNestedValue = (obj, path) => {
        return path
            .split('.')
            .reduce((o, p) => (o && o[p] !== undefined && o[p] !== null ? o[p] : ''), obj);
    };

    // Modal handlers
    const handleView = (record) => {
        setSelectedRecord(record);
        setIsViewModalVisible(true);
    };

    const handleEdit = (record) => {
        setSelectedRecord(record);
        setIsEditModalVisible(true);
    };

    const handleDelete = (record) => {
        setSelectedRecord(record);
        setIsDeleteModalVisible(true);
    };

    const handleDeleteCommission = async (commissionId) => {
        try {
            await axios.delete(`http://localhost:3000/api/v1/brokers/delete-broker-commission?id=${commissionId}`);
            message.success('Broker commission deleted successfully.');
            fetchBrokerCommissions();
            setIsDeleteModalVisible(false);
        } catch (error) {
            console.error('Failed to delete broker commission:', error);
            message.error('Failed to delete broker commission.');
        }
    };

    // Function to convert JSON data to CSV
    const convertToCSV = (data) => {
        const headers = [
            'ID',
            'Old ID',
            'Broker ID',
            'User ID',
            'Commission',
            'Asset',
            'Type',
            'Trace ID',
            'Date',
            'Created At',
            'Updated At'
        ];

        const rows = data.map((comm) => [
            comm._id || '',
            comm.oldId || '',
            comm.brokerId || '',
            comm.userId || '',
            comm.commission?.$numberDecimal || '0',
            comm.asset || '',
            comm.type || '',
            comm.traceId || '',
            formatDate(comm.date) || '',
            formatDate(comm.createdAt) || '',
            formatDate(comm.updatedAt) || ''
        ]);

        const csvContent = [headers, ...rows]
            .map((row) =>
                row
                    .map((field) => `"${String(field).replace(/"/g, '""')}"`)
                    .join(',')
            )
            .join('\n');

        return csvContent;
    };

    const handleExport = () => {
        try {
            if (filteredCommissions.length === 0) {
                message.warning('No broker commissions available to export');
                return;
            }

            const csvData = convertToCSV(filteredCommissions);
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'broker_commissions.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            message.success('Exported broker commissions data successfully');
        } catch (error) {
            message.error('Error exporting broker commissions.');
            console.error('Export error:', error);
        }
    };

    // Function to format date as "07 November 2024, 07:30"
    const formatDate = (dateString) => {
        if (!dateString || dateString === 'N/A') return 'N/A';
        const date = new Date(dateString);
        if (isNaN(date)) return 'Invalid Date';
        const day = String(date.getDate()).padStart(2, '0');
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day} ${month} ${year}, ${hours}:${minutes}`;
    };

    const columns = [
        { title: 'ID', dataIndex: '_id', key: '_id' },
        { title: 'Old ID', dataIndex: 'oldId', key: 'oldId' },
        { title: 'Broker ID', dataIndex: 'brokerId', key: 'brokerId' },
        { title: 'User ID', dataIndex: 'userId', key: 'userId' },
        { 
            title: 'Commission', 
            dataIndex: 'commission.$numberDecimal', 
            key: 'commission',
            render: (text) => parseFloat(text).toFixed(8),
        },
        { title: 'Asset', dataIndex: 'asset', key: 'asset' },
        { title: 'Type', dataIndex: 'type', key: 'type' },
        { title: 'Trace ID', dataIndex: 'traceId', key: 'traceId' },
        { 
            title: 'Date', 
            dataIndex: 'date', 
            key: 'date',
            render: (text) => formatDate(text),
        },
        { 
            title: 'Created At', 
            dataIndex: 'createdAt', 
            key: 'createdAt',
            render: (text) => formatDate(text),
        },
        { 
            title: 'Updated At', 
            dataIndex: 'updatedAt', 
            key: 'updatedAt',
            render: (text) => formatDate(text),
        },
        // Removed manual 'Actions' column to prevent ESLint errors and adhere to your preferences
    ];

    if (error) {
        return (
            <AdminLayout>
                <div className="mx-auto p-6 bg-white">
                    <div className="text-red-500">Error: {error}</div>
                </div>
            </AdminLayout>
        );
    }

    return (
        <AdminLayout>
            <div className="mx-auto p-6 bg-white">
                {/* Breadcrumb */}
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item>Broker</Breadcrumb.Item>
                    <Breadcrumb.Item>Commissions</Breadcrumb.Item>
                </Breadcrumb>

                {/* Header */}
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-2xl font-semibold">Broker Commissions</h1>
                    <Space>
                        <Button
                            type="primary"
                            icon={<DownloadOutlined />}
                            onClick={handleExport}
                            style={{ backgroundColor: '#57249c', borderColor: '#57249c' }}
                        >
                            Export CSV
                        </Button>
                    </Space>
                </div>

                {/* Table */}
                <PaginatedTable
                    columns={columns}
                    itemsPerPage={10}
                    onView={handleView}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    searchFilters={null} // Not used since filtering is handled internally
                    onSearch={handleSearch}
                    dataSource={filteredCommissions}
                    isSearchable={true} // Ensure search is enabled
                    loading={loading}
                />

                {/* Modals */}
                {selectedRecord && (
                    <>
                        <ViewModal
                            visible={isViewModalVisible}
                            onCancel={() => setIsViewModalVisible(false)}
                            data={selectedRecord}
                        />
                        <EditModal
                            visible={isEditModalVisible}
                            onCancel={() => setIsEditModalVisible(false)}
                            data={selectedRecord}
                            refreshData={fetchBrokerCommissions}
                        />
                        <DeleteModal
                            visible={isDeleteModalVisible}
                            onCancel={() => setIsDeleteModalVisible(false)}
                            data={selectedRecord}
                            onDelete={handleDeleteCommission}
                        />
                    </>
                )}
            </div>
        </AdminLayout>
    );
};

export default BrokerCommissions;
