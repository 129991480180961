import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Alert, Table, Typography, Tag, Button, Space } from 'antd';

const { Title } = Typography;

const LPAlert = () => {
  const [poolData, setPoolData] = useState([]);
  const [lowFundsAssets, setLowFundsAssets] = useState([]);
  const threshold = 1000; // Set your threshold value here

  const fetchPoolData = async () => {
    try {
      const response = await axios.get('http://localhost:3000/api/v1/lp-alert');
      const pool = response.data.data || []; // Ensure data is fetched properly
      setPoolData(pool);
      
      // Check for assets below the threshold
      const lowFunds = pool.filter(asset => parseFloat(asset.amount.$numberDecimal) < threshold);
      setLowFundsAssets(lowFunds);
    } catch (error) {
      console.error('Failed to fetch pool data', error);
    }
  };

  useEffect(() => {
    fetchPoolData();
    const intervalId = setInterval(fetchPoolData, 5000); // Poll every 5 seconds
    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  const handleView = (record) => {
    console.log('View Details:', record);
    // Add view logic here
  };

  const handleEdit = (record) => {
    console.log('Edit:', record);
    // Add edit logic here
  };

  const handleDelete = (record) => {
    console.log('Delete:', record);
    // Add delete logic here
  };

  const columns = [
    {
      title: 'Asset',
      dataIndex: 'asset',
      render: (text) => <strong>{text.toUpperCase()}</strong>
    },
    {
      title: 'Alert Amount',
      dataIndex: 'amount',
      render: (amount) => (
        <span>
          {parseFloat(amount.$numberDecimal).toFixed(2)}
        </span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'amount',
      render: (amount) => {
        const value = parseFloat(amount.$numberDecimal);
        return (
          <Tag color={value < threshold ? 'red' : 'green'}>
            {value < threshold ? 'Low' : 'Sufficient'}
          </Tag>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: '_id',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => handleView(record)}>View</Button>
          <Button type="link" onClick={() => handleEdit(record)}>Edit</Button>
          <Button type="link" danger onClick={() => handleDelete(record)}>Delete</Button>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: 'auto' }}>
      <Title level={3} style={{ textAlign: 'center', marginBottom: '20px' }}>LP Alert Management</Title>
      
      {lowFundsAssets.length > 0 && (
        <Alert
          message="Low Liquidity Alert"
          description="Some assets have fallen below the threshold amount."
          type="warning"
          showIcon
          style={{ marginBottom: '20px' }}
        />
      )}
      
      <Table
        dataSource={poolData}
        rowKey="_id"
        columns={columns}
        pagination={{ pageSize: 5 }}
        bordered
      />
    </div>
  );
};

export default LPAlert;
