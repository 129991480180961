import { useMutation, useQuery } from '@tanstack/react-query';
import { fetcher } from './fetcher';

export function useApiQuery(props) {
    const { key, endPoint, method = 'GET', bodyContent, queryOptions = {}, dynamicKey } = props;
    const queryKey = dynamicKey ? [key, dynamicKey] : [...key, endPoint];

    return useQuery({
        queryKey,
        queryFn: () => fetcher(endPoint, method, method === 'POST' ? bodyContent : undefined),
        ...queryOptions,
    });
}

export function useApiMutation() {
    return useMutation({
        mutationFn: ({
            endPoint,
            method = 'POST',
            bodyContent
        }) => fetcher(endPoint, method, bodyContent),
    });
}
