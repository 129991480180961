import { useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { useApiMutation, useApiQuery } from "../hooks/useApiRequest";

//* --------- Get audit log by ID ---------
export const GetadminManager = (id) => {
  return useApiQuery({
    key: ["admin-user", id],
    endPoint: `/api/v1/admin-user/view?id=${id}`,
    method: "GET",
  });
};

export const useCreateAdminManagerService = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, error } = useApiMutation();

  const CreateAdminManager = async (values, onSuccess, onError) => {
    try {
      await mutateAsync({
        key: ["admin-user"],
        endPoint: `/api/v1/admin-user/create`,
        method: "POST",
        bodyContent: values,
      });
      queryClient.invalidateQueries({ queryKey: ["admin-manager"] });
      message.success("Admin/Manager created successfully!");
      onSuccess();
    } catch (err) {
      message.error(err.message || "Failed to create admin-manager.");
      onError();
    }
  };

  return { CreateAdminManager, error };
};

//*********** Update Withdrawal Status **********
export const useUpdateAdminManagernService = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, error } = useApiMutation();

  const updateAdminManager = async (values, id, onSuccess, onError) => {
    try {
      if (!id) {
        message.error("ID is missing!");
        return;
      }
      await mutateAsync({
        key: ["admin-user", id],
        endPoint: `/api/v1/admin-user/update?&id=${id}`,
        method: "PATCH",
        bodyContent: values,
      });
      queryClient.invalidateQueries({ queryKey: ["admin-user"] });
      queryClient.invalidateQueries({ queryKey: ["admin-user", id] });
      onSuccess();
      message.success(" Admin/Manager Successfully updated");
    } catch (error) {
      onError();
      message.error(error.message);
    }
  };

  return { updateAdminManager, error };
};

//*********** Delete user Withdrawal **********
export const useDeleteAdminManagerService = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, error } = useApiMutation();

  const DeleteAdminManager = async (id, onSuccess, onError) => {
    try {
      await mutateAsync({
        key: ["admin-user"],
        endPoint: `/api/v1/admin-user/delete?&id=${id}`,
        method: "DELETE",
      });
      queryClient.invalidateQueries({ queryKey: ["admin-user"] });
      onSuccess();
      message.success("Admin/Manager Successfully deleted");
    } catch (error) {
      onError();
      message.error(error.message);
    }
  };

  return { DeleteAdminManager, error };
};
