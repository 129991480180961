// src/pages/Clients/ClientBalanceOverview.jsx

import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import AdminLayout from "../../layouts/AdminLayout";
import PaginatedTable from "../../components/PaginatedTable";
import { debounce } from "lodash";
import { message, Breadcrumb, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons"; // Importing required icon
import EditModal from "../../Modals/ClientsBankOverview/EditModal";
import DeleteModal from "../../Modals/ClientsBankOverview/DeleteModal";
import ViewModal from "../../Modals/ClientsBankOverview/ViewModal";

const ClientBalanceOverview = () => {
  const [allBalances, setAllBalances] = useState([]); // Store all balances
  const [filteredBalances, setFilteredBalances] = useState([]); // Store filtered balances
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Modal state variables
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const fetchBalances = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "http://localhost:3000/api/v1/clients/balance-overview"
      );
      if (
        response.status === 200 &&
        response.data.success &&
        Array.isArray(response.data.data)
      ) {
        const processedData = response.data.data.flatMap((user) => {
          const userInfo = user["User Info"];
          const wallets = userInfo.wallets || [];
          return wallets.map((wallet) => ({
            username: user.username,
            email: user.email,
            fullName: `${userInfo.personal_info.legal_name || ""} ${
              userInfo.personal_info.legal_lastname || ""
            }`.trim(),
            identityNumber: userInfo.personal_info.identity_number || "N/A",
            phoneNumber: userInfo.personal_info.phoneNumber || "N/A",
            userCode: userInfo.ref_info.user_code || "N/A",
            brokerCode: userInfo.broker_info.broker_code || "N/A",
            createdAt: userInfo.createdAt || "N/A",
            asset: wallet.asset || "N/A",
            /*totalBalance: user['Balances'].total || 0,
                        availableBalance: user['Balances'].available || 0,
                        lockedBalance: user['Balances'].locked || 0,*/
            walletId: wallet.walletId || "N/A",
            walletType: wallet.walletType || "N/A",
            tag: wallet.tag || "N/A",
          }));
        });
        setAllBalances(processedData);
        setFilteredBalances(processedData); // Initialize filteredBalances with all data
      } else {
        throw new Error("Data format is incorrect or missing required fields");
      }
    } catch (err) {
      setError("Failed to fetch balances: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBalances();
  }, []);

  const handleSearch = useCallback(
    debounce((filters) => {
      // Apply front-end filtering based on the filters object
      let filtered = allBalances;

      // Global Search
      if (filters.globalSearch) {
        const searchText = filters.globalSearch.toLowerCase();
        filtered = filtered.filter((balance) =>
          JSON.stringify(balance).toLowerCase().includes(searchText)
        );
      }

      // Column-specific Filters
      Object.keys(filters).forEach((key) => {
        if (key !== "globalSearch") {
          const value = filters[key].toLowerCase();
          if (value) {
            filtered = filtered.filter((balance) => {
              const itemValue = getNestedValue(balance, key);
              return (
                itemValue && itemValue.toString().toLowerCase().includes(value)
              );
            });
          }
        }
      });

      setFilteredBalances(filtered);
    }, 300),
    [allBalances]
  );

  // Utility function to get nested values
  const getNestedValue = (obj, path) => {
    return path
      .split(".")
      .reduce(
        (o, p) => (o && o[p] !== undefined && o[p] !== null ? o[p] : ""),
        obj
      );
  };

  // Modal handlers
  const handleEdit = (record) => {
    setSelectedRecord(record);
    setIsEditModalVisible(true);
  };

  const handleDelete = (record) => {
    setSelectedRecord(record);
    setIsDeleteModalVisible(true);
  };

  const handleDeleteUser = async (userId) => {
    try {
      await axios.delete(
        `http://localhost:3000/api/v1/clients/delete/${userId}`
      );
      message.success("User deleted successfully");
      fetchBalances();
      setIsDeleteModalVisible(false);
    } catch (error) {
      message.error(error.response?.data?.message || "Failed to delete user");
    }
  };

  const handleView = (record) => {
    setSelectedRecord(record);
    setIsViewModalVisible(true);
  };

  // Function to convert JSON data to CSV
  const convertToCSV = (data) => {
    const headers = [
      "Username",
      "Email",
      "Full Name",
      "Identity Number",
      "Phone Number",
      "User Code",
      "Broker Code",
      "Created At",
      "Asset",
      "Total Balance",
      "Available Balance",
      "Locked Balance",
      "Wallet ID",
      "Wallet Type",
      "Tag",
    ];
    const rows = data.map((item) => [
      item.username,
      item.email,
      item.fullName,
      item.identityNumber,
      item.phoneNumber,
      item.userCode,
      item.brokerCode,
      formatDate(item.createdAt),
      item.asset,
      item.totalBalance,
      item.availableBalance,
      item.lockedBalance,
      item.walletId,
      item.walletType,
      item.tag,
    ]);
    const csvContent = [headers, ...rows]
      .map((row) =>
        row.map((field) => `"${String(field).replace(/"/g, '""')}"`).join(",")
      )
      .join("\n");
    return csvContent;
  };

  const handleExport = () => {
    try {
      if (filteredBalances.length === 0) {
        message.warning("No balances available to export");
        return;
      }

      const csvData = convertToCSV(filteredBalances);
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "balances_export.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      message.success("Exported balances data successfully");
    } catch (error) {
      message.error("Failed to export balances");
    }
  };

  // Function to format date as "07 November 2024, 07:30"
  const formatDate = (dateString) => {
    if (!dateString || dateString === "N/A") return "N/A";
    const date = new Date(dateString);
    if (isNaN(date)) return "Invalid Date";
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day} ${month} ${year}, ${hours}:${minutes}`;
  };

  const columns = [
    { title: "Username", dataIndex: "username", key: "username" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Full Name", dataIndex: "fullName", key: "fullName" },
    {
      title: "Identity Number",
      dataIndex: "identityNumber",
      key: "identityNumber",
    },
    { title: "Phone Number", dataIndex: "phoneNumber", key: "phoneNumber" },
    { title: "User Code", dataIndex: "userCode", key: "userCode" },
    { title: "Broker Code", dataIndex: "brokerCode", key: "brokerCode" },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => formatDate(text), // Updated render function for date formatting
    },
    { title: "Asset", dataIndex: "asset", key: "asset" },
    { title: "Total Balance", dataIndex: "totalBalance", key: "totalBalance" },
    {
      title: "Available Balance",
      dataIndex: "availableBalance",
      key: "availableBalance",
    },
    {
      title: "Locked Balance",
      dataIndex: "lockedBalance",
      key: "lockedBalance",
    },
    { title: "Wallet ID", dataIndex: "walletId", key: "walletId" },
    { title: "Wallet Type", dataIndex: "walletType", key: "walletType" },
    { title: "Tag", dataIndex: "tag", key: "tag" },
    // Add any additional fields here without leaving placeholders
  ];

  if (error) {
    return (
      <AdminLayout>
        <div className="mx-auto p-6 bg-white">
          <div className="text-red-500">Error: {error}</div>
        </div>
      </AdminLayout>
    );
  }

  if (loading) {
    return (
      <AdminLayout>
        <div className="mx-auto p-6 bg-white">
          <div className="text-blue-500">Loading...</div>
        </div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout currentKey={"sub3"} openKey="4">
      <div className="mx-auto p-6 bg-white">
        <Breadcrumb style={{ marginBottom: "20px" }}>
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item>Clients</Breadcrumb.Item>
          <Breadcrumb.Item>Client Balance Overview</Breadcrumb.Item>
        </Breadcrumb>
        <h1 className="text-2xl font-semibold mb-6">Client Balance Overview</h1>
        <div className="flex justify-between items-center mb-6">
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={handleExport}
            className="flex items-center px-4 py-2 bg-[#57249c] text-white rounded-lg hover:bg-[#432076] transition"
          >
            Export (csv)
          </Button>
        </div>
        <PaginatedTable
          columns={columns}
          itemsPerPage={15}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onView={handleView}
          searchFilters={null} // Not used since filtering is handled internally
          onSearch={handleSearch}
          dataSource={filteredBalances}
          isSearchable={true} // Ensure search is enabled
        />
        <EditModal
          visible={isEditModalVisible}
          onCancel={() => setIsEditModalVisible(false)}
          data={selectedRecord}
          onSubmit={() => {
            fetchBalances();
            setIsEditModalVisible(false);
          }}
        />
        <DeleteModal
          visible={isDeleteModalVisible}
          onCancel={() => setIsDeleteModalVisible(false)}
          clientId={selectedRecord?.user_id}
          userName={selectedRecord?.fullName}
          onDelete={handleDeleteUser}
        />
        <ViewModal
          visible={isViewModalVisible}
          onCancel={() => setIsViewModalVisible(false)}
          data={selectedRecord}
        />
      </div>
    </AdminLayout>
  );
};

export default ClientBalanceOverview;
