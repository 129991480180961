import {
  CalendarOutlined,
  DashboardOutlined,
  FileSyncOutlined,
  LogoutOutlined,
  MailOutlined,
  NotificationOutlined,
  ProfileOutlined,
  SettingOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import {
  faCoins,
  faCreditCard,
  faMoneyBill,
  faPoll,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import LogoutButton from "../components/LogoutButton";

export const items = [
  {
    label: (
      <Link to="/admin/dashboard">
        <span className="font-light"> Dashboard</span>
      </Link>
    ),
    key: "2",
    icon: <DashboardOutlined />,
  },
  // {
  //   label: (
  //     <Link to="/admin/clients">
  //       <span className="font-light"> Clients </span>
  //     </Link>
  //   ),
  //   key: "4",
  //   icon: <UsergroupAddOutlined />,
  // },

  {
    label: "Clients",
    key: "4",
    icon: <UsergroupAddOutlined icon={faCoins} />,
    children: [
      {
        key: "client-sub1",
        label: (
          <Link to="/admin/clients">
            <span className="font-light"> Clients </span>
          </Link>
        ),
      },
      {
        key: "client-sub2",
        label: (
          <Link to="/admin/verif-clients">
            <span className="font-light"> Veriff Clients </span>
          </Link>
        ),
      },
    ],
  },

  {
    label: "Deposits/Withdrawals",
    key: "5",
    icon: <FontAwesomeIcon icon={faMoneyBill} />,
    children: [
      {
        key: "dw-sub1",
        label: (
          <Link to="/admin/deposits">
            <span className="font-light"> ZAR Deposits</span>
          </Link>
        ),
      },
      {
        key: "dw-sub2",
        label: (
          <Link to="/admin/crypto-deposits">
            <span className="font-light"> Crypto Deposits </span>
          </Link>
        ),
      },
      {
        key: "dw-sub3",
        label: (
          <Link to="/admin/withdrawals">
            <span className="font-light">ZAR Withdrawals </span>
          </Link>
        ),
      },
      {
        key: "dw-sub4",
        label: (
          <Link to="/admin/crypto-withdrawals">
            <span className="font-light"> Crypto Withdrawals </span>
          </Link>
        ),
      },
      {
        key: "dw-sub5",
        label: (
          <Link to="/admin/deposit-logs">
            <span className="font-light"> Deposits Logs </span>
          </Link>
        ),
      },
      {
        key: "dw-sub6",
        label: (
          <Link to="/admin/withdrawal-logs">
            <span className="font-light"> Withdrawal Logs </span>
          </Link>
        ),
      },
    ],
  },

  {
    label: "OTC",
    key: "6",
    icon: <FontAwesomeIcon icon={faPoll} />,
    children: [
      {
        key: "otc-sub1",
        label: (
          <Link to="/admin/liquidity-pool">
            <span className="font-light"> Liquidity Pool </span>
          </Link>
        ),
      },
      {
        key: "otc-sub2",
        label: (
          <Link to="/admin/update-pool">
            <span className="font-light"> Adjust LP </span>
          </Link>
        ),
      },
      {
        key: "otc-sub3",
        label: (
          <Link to="/admin/lp-history">
            <span className="font-light"> LP History </span>
          </Link>
        ),
      },
      {
        key: "otc-sub4",
        label: (
          <Link to="/admin/lp-alert">
            <span className="font-light"> LP Alert </span>
          </Link>
        ),
      },
      {
        key: "otc-sub5",
        label: (
          <Link to="/admin/transaction-history">
            <span className="font-light"> Transaction History </span>
          </Link>
        ),
      },
      {
        key: "otc-sub6",
        label: (
          <Link to="/admin/trading-fees">
            <span className="font-light"> Trading Fees </span>
          </Link>
        ),
      },
    ],
  },
  {
    label: "Assets",
    key: "3",
    icon: <FontAwesomeIcon icon={faCreditCard} />,
    children: [
      {
        key: "asset-sub-1",
        label: (
          <Link to="/admin/trading-assets">
            <span className="font-light"> Trading Assets </span>
          </Link>
        ),
      },
      {
        key: "asset-sub-2",
        label: (
          <Link to="/admin/ncw-supported-assets">
            <span className="font-light"> NCW Supported Assets </span>
          </Link>
        ),
      },
    ],
  },
  {
    label: "Investments",
    key: "20",
    icon: <FontAwesomeIcon icon={faCoins} />,
    children: [
      {
        key: "inv-sub1",
        label: (
          <Link to="/admin/manage-investments">
            <span className="font-light"> Manage Investments </span>
          </Link>
        ),
      },
      {
        key: "inv-sub2",
        label: (
          <Link to="/admin/subscription-approval">
            <span className="font-light"> Subscription Approval </span>
          </Link>
        ),
      },
      {
        key: "inv-sub3",
        label: (
          <Link to="/admin/user-summary">
            <span className="font-light"> User Summary </span>
          </Link>
        ),
      },
    ],
  },
  {
    label: (
      <Link to="/admin/all-news">
        <span className="font-light"> Manage News </span>
      </Link>
    ),
    key: "10",
    icon: <CalendarOutlined />,
  },

  {
    label: (
      <Link to="/admin/emails">
        <span className="font-light"> Emails </span>
      </Link>
    ),
    key: "12",
    icon: <MailOutlined />,
  },
  {
    label: (
      <Link to="/admin/PushNotification">
        <span className="font-light"> Notifications </span>
      </Link>
    ),
    key: "14",
    icon: <NotificationOutlined />,
  },

  {
    label: (
      <Link to="/admin/PaymentTransaction">
        <span className="font-light">80Eight Pay </span>
      </Link>
    ),
    key: "18",
    icon: <SwapOutlined />,
  },

  {
    label: (
      <Link to="/admin/policies">
        <span className="font-light"> Policies </span>
      </Link>
    ),
    key: "16",
    icon: <ProfileOutlined />,
  },
  {
    label: (
      <Link to="/admin/admin-users">
        <span className="font-light"> Admin Managers </span>
      </Link>
    ),
    key: "15",
    icon: <UserAddOutlined />,
  },
  {
    label: (
      <Link to="/admin/audit-logs">
        <span className="font-light"> Audit Logs </span>
      </Link>
    ),
    key: "17",
    icon: <FileSyncOutlined />,
  },
  {
    label: (
      <Link to="/admin/settings">
        <span className="font-light"> Settings </span>
      </Link>
    ),
    key: "13",
    icon: <SettingOutlined />,
  },
  {
    label: <LogoutButton />,
    key: "19",
    icon: <LogoutOutlined />,
  },
];
