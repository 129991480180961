import React from 'react';
import { Form, Input, Select, Row, Col } from 'antd';

const { Option } = Select;

const ValueUnitInput = ({
  label,
  name,
  units = ['days', 'months', 'years'],
  required = false,
  rules = [],
  placeholderValue = 'Enter value',
  placeholderUnit = 'Select unit',
  disabled = false,
}) => {
  const validationRules = required
    ? [{ required: true, message: `${label} is required` }, ...rules]
    : rules;

  return (
    <Form.Item
      label={label}
      name={name}
      rules={validationRules}
      required={required}
    >
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue, setFieldsValue }) => {
          const rawValue = getFieldValue(name);
          const currentValue = typeof rawValue === 'string' ? rawValue : '';
          const [value, unit] = currentValue.split(' ') || ['', ''];

          const handleValueChange = (e) => {
            const newValue = e.target.value;
            setFieldsValue({
              [name]: `${newValue} ${unit || ''}`.trim(),
            });
          };

          const handleUnitChange = (newUnit) => {
            setFieldsValue({
              [name]: `${value || ''} ${newUnit}`.trim(),
            });
          };

          return (
            <Row gutter={8} align="middle">
              <Col span={16}>
                <Input
                  type="number"
                  placeholder={placeholderValue}
                  disabled={disabled}
                  value={value || ''}
                  onChange={handleValueChange}
                />
              </Col>
              <Col span={8}>
                <Select
                  placeholder={placeholderUnit}
                  disabled={disabled}
                  value={unit || undefined}
                  onChange={handleUnitChange}
                >
                  {units.map((unitOption) => (
                    <Option key={unitOption} value={unitOption}>
                      {unitOption.charAt(0).toUpperCase() + unitOption.slice(1)}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          );
        }}
      </Form.Item>
    </Form.Item>
  );
};

export default ValueUnitInput;
