import React, { useState } from "react";
import { Button, Modal, Form, Input } from "antd";
import { useCreateNotificationService } from "../../src/services/notificationService";

const CreateNotification = ({ visible, onCancel, onSubmit }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { createNotification } = useCreateNotificationService();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      await createNotification(
        { heading: values.heading, message: values.message },
        () => {
          form.resetFields();
          onSubmit();
          onCancel();
        },
        () => {}
      );
    } catch (error) {
      console.error("Failed to create notification:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Create New Notification"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          loading={loading}
        >
          Create Notification
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Heading"
          name="heading"
          rules={[{ required: true, message: "Please input the heading" }]}
        >
          <Input placeholder="Enter notification heading" />
        </Form.Item>

        <Form.Item
          label="Message"
          name="message"
          rules={[{ required: true, message: "Please input the message" }]}
        >
          <Input.TextArea placeholder="Enter notification message" rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateNotification;
