import React, { useState } from "react";
import { Row, Col, Breadcrumb, Space, message, Input, Button, Tag } from "antd";

import PaginatedTable from "../../components/PaginatedTable";
import View from "./View";
import Delete from "./Delete";
import Edit from "./Edit";
import Create from "./Create";
import { render } from "@testing-library/react";

const { Search } = Input;

const AdminManagers = () => {
  const [refresh, setRefresh] = useState(false);

  const columns = [
    {
      title: "Email",
      dataIndex: ["userId", "email"],
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: "Name",
      dataIndex: "legalFirstName",
      key: "firstLegalName"
    },
    {
      title: "Surname",
      dataIndex: "legalLastName",
      key: "legalLastName"
    },
    {
      title: "Role",
      dataIndex: "role",
      key: ["userId", "role"],
      render: (text, record) => {
        return record.userId.role === 10 ? 'Admin' : 'Manager';
      }
      /*filters: [
        { text: "Admin", value: "Admin" },
        { text: "Manager", value: "Manager" },
      ],
      onFilter: (value, record) => record.role === value,
      sorter: (a, b) => a.role.localeCompare(b.role),*/
    },
    {
      title: "Status",
      dataIndex: "status",
      key: ["userId", "status"],
      filters: [
        { text: "Active", value: "Active" },
        { text: "Inactive", value: "Inactive" },
      ],
      onFilter: (value, record) => record.status === value,
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (text, record) => (
        <Tag color={record.userId.status === 'enabled' ? 'green' : 'red'}>
          {record.userId.status || 'Unknown'} 
        </Tag>
      ),

      /*render: (text, record) => {
        let color;
        switch (status) {
          case "Active":
            color = "green";
            break;
          case "Inactive":
            color = "volcano";
            break;
          default:
            color = "geekblue";
        }
        return <span style={{ color }}>{status}</span>;
      },*/
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      width: 220,
      render: (_, record) => (
        <Space size="middle">
          <View userId={record.userId._id} />
          <Edit
            adminManager={record}
            onUpdateSuccess={() => setRefresh(!refresh)}
          />
          <Delete
            adminManager={record}
            onDeleteSuccess={() => setRefresh(!refresh)}
          />
        </Space>
      ),
    },
  ];

  const filters = [
    { name: "email", label: "Email", type: "text" },
    {
      name: "role",
      label: "Role",
      type: "dropdown",
      options: ["Admin", "Manager"],
    },
    {
      name: "status",
      label: "Status",
      type: "dropdown",
      options: ["Active", "Inactive"],
    },
  ];

  return (
    <div className="mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold text-right">Admin/Managers</h3>
        <Breadcrumb>
          <Breadcrumb.Item>Admins</Breadcrumb.Item>
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <Create onCreateSuccess={() => setRefresh(!refresh)} />

      <PaginatedTable
        fetchEndpoint="/api/v1/admin-user/index"
        columns={columns}
        filters={filters}
        isSearchable={true}
        isSelectable={true}
        refresh={refresh}
      />
    </div>
  );
};

export default AdminManagers;
