import { useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useApiMutation, useApiQuery } from '../hooks/useApiRequest';

//* ---------Get all policies---------
export const useGetAllPolicies = (filter) => {
    return useApiQuery({
        key: ['policies',],
        endPoint: `/api/v1/policies/get-all`,
        method: 'GET',
    });
};

export const useGetPoliciesById = (id) => {
    return useApiQuery({
        key: ['policies-by-id'],
        endPoint: `/api/v1/policies/get-one?policyId=${id}`,
        method: 'GET',
    });
};

//*********** add new policies**********
export const useAddPoliciesService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const addPolicies = async (values, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["add-policies"],
                endPoint: `/api/v1/policies/create`,
                method: "POST",
                bodyContent: values,
            });
            queryClient.invalidateQueries({ queryKey: ["policies"] });
            queryClient.invalidateQueries({ queryKey: ["add-policies"] });
            onSuccess();
            message.success('Successfully added');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { addPolicies, error };
};


//*********** Update policies **********
export const useUpdatePoliciesService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const updatePolicies = async (values, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["update-policies"],
                endPoint: `/api/v1/policies/update`,
                method: "PATCH",
                bodyContent: values,
            });
            queryClient.invalidateQueries({ queryKey: ["policies"] });
            queryClient.invalidateQueries({ queryKey: ["update-policies"] });
            onSuccess();
            message.success('Successfully updated');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { updatePolicies, error };
};


//*********** Delete policies **********
export const useDeletePoliciesService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const deletePolicies = async (id, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["delete-policies"],
                endPoint: `/api/v1/policies/delete?id=${id}`,
                method: "DELETE",
            });
            queryClient.invalidateQueries({ queryKey: ["policies"] });
            queryClient.invalidateQueries({ queryKey: ["delete-policies"] });
            onSuccess();
            message.success('Successfully deleted');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { deletePolicies, error };
};

