import React, { useState } from "react";
import { Modal, Form, Input, Select, Spin, Button, DatePicker } from "antd";
import Countries from "../../../../data/Countries";
import { useGetClientByIdService, useUpdateClientService } from "../../../../services/clientService";
import moment from "moment";
const { Option } = Select;


const Edit = ({ userId, visible, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { data, isLoading, error } = useGetClientByIdService(userId)
  const { updateClient } = useUpdateClientService()

  const handleUpdateClient = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();

      const payload = {
        legal_name: values?.legal_name,
        legal_lastname: values?.legal_lastname,
        email: values?.email,
        status: values?.status,
        username: values?.username,
        phoneNumber: values?.phoneNumber,
        identity_number: values?.identity_number,
        title: values?.title,
        country: values?.country,
        city: values?.city,
        postal_code: values?.postal_code,
        province: values?.province,
        street: values?.street,
        suburb: values?.suburb,
        address2: values?.address2,
        default_currency: values?.default_currency,
        ref_code: values?.ref_code,
        user_code: values?.user_code,
        funds_source: values?.funds_source,
        accountType: values?.accountType,
        sars_no: values?.sars_no,
        dob: values?.dob
      };

      await updateClient(
        userId,
        payload,
        () => {
          onCancel();
          setLoading(false);
        },
        () => setLoading(false)
      );
    } catch (error) {
      setLoading(false);
    }
  };

  if (isLoading) return (
    <Modal
      visible={visible}
      title="Edit Client"
      onCancel={onCancel}
      confirmLoading={loading}
      width={900}
    >
      <div className="flex flex-col items-center justify-center h-80 bg-gray-100">
        <Spin size={"large"} className="mb-4" />
        <p className="text-lg text-gray-700"> Loading Client Details</p>
      </div>
    </Modal>
  )

  if (error) return (
    <Modal
      visible={visible}
      title="Edit Client"
      onCancel={onCancel}
      confirmLoading={loading}
      width={900}
    >
      <div className="flex flex-col items-center justify-center h-80 bg-gray-100">
        <p className="text-lg text-red-700"> {error?.message || "Something went wrong to the server"} </p>
      </div>
    </Modal>
  )

  return (
    <Modal
      visible={visible}
      title="Edit Client"
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={handleUpdateClient}
          loading={loading}
        >
          Save
        </Button>,
      ]}
      confirmLoading={loading}
      width={900}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          legal_name: data?.personal_info?.legal_name,
          legal_lastname: data?.personal_info?.legal_lastname,
          email: data?.user_id?.email,
          status: data?.user_id?.status,
          username: data?.user_id?.username,
          phoneNumber: data?.personal_info?.phoneNumber,
          identity_number: data?.personal_info?.identity_number,
          // dob: data?.personal_info?.dob,
          dob: data?.personal_info?.dob ? moment(data.personal_info.dob, "YYYY-MM-DD") : null,
          title: data?.personal_info?.title,
          country: data?.address?.country,
          city: data?.address?.city,
          postal_code: data?.address?.postal_code,
          province: data?.address?.province,
          street: data?.address?.street,
          suburb: data?.address?.suburb,
          address2: data?.address?.address2,
          default_currency: data?.profile_settings?.default_currency,
          ref_code: data?.ref_info?.ref_code,
          user_code: data?.ref_info?.user_code,
          funds_source: data?.funds_source,
          accountType: data?.accountType,
          sars_no: data?.company_info?.sars_no,
        }}>
        <div className="grid grid-cols-2 gap-4 items-center mb-4">
          <Form.Item
            label="Legal First Name"
            name="legal_name"
            rules={[{ required: true, message: "Please enter the first name" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Legal Last Name"
            name="legal_lastname"
            rules={[{ required: true, message: "Please enter the last name" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[{ type: "email", message: "Please enter a valid email" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="User Name"
            name="username"
            rules={[{ required: true, message: "User name is required" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Date of Birth"
            name="dob"
            rules={[{ required: true, message: "Date of birth is required" }]}
          >
            <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            label="Status"
            name="status"
            rules={[{ required: true, message: "Please select the status" }]}
          >
            <Select>
              <Option value="enabled">Enabled</Option>
              <Option value="disabled">Disabled</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Contact Number"
            name="phoneNumber"
            rules={[{ required: true, message: "Please enter the phone number" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Identity Number"
            name="identity_number"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Account Type"
            name="accountType"
            rules={[{ required: true, message: "Please select the Account Type" }]}
          >
            <Select>
              <Option value="personal">Personal</Option>
              <Option value="company">Company</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Referral Code"
            name="ref_code"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Default Currency"
            name="default_currency"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="User Code"
            name="user_code"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Source of Funds"
            name="funds_source"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="SARS Number"
            name="sars_no"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Country"
            name="country"
            rules={[{ required: true, message: "Please enter the country" }]}
          >
            <Select
              placeholder="Select a country"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {Countries?.map((item) => (
                <Option key={item.code} value={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="City"
            name="city"
          >
            <Input />
          </Form.Item>


          <Form.Item
            label="Province"
            name="province"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Street Name & Address"
            name="street"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Postal Code"
            name="postal_code"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Suburb"
            name="suburb"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Address Line 2"
            name="address2"
          >
            <Input />
          </Form.Item>


        </div>
      </Form>
    </Modal>
  );
};

export default Edit;






// import React, { useState } from "react";
// import { Modal, Button, Steps, message } from "antd";
// import { EditOutlined } from "@ant-design/icons";
// import ClientInfo from "../../../../models/ClientInfo";
// import { updatePatch } from "../../../../services/apiService";

// const Edit = ({ userId, visible, onCancel, onSubmit }) => {
//   const [loading, setLoading] = useState(false);
//   const [currentStep, setCurrentStep] = useState(1);
//   const [formData, setFormData] = useState({});

//   const handleFinalSubmit = async (data) => {
//     setLoading(true)
//     try {
//       const response = await updatePatch('/api/v1/clients', { clientId: userId }, data);

//       if (response.status === 200 && response?.data.data) {
//         message.success(`Client successfully updated.`);
//         // onUpdateSuccess(Math.random() > 0.5);
//         // toggleForm();
//         setLoading(false)
//         console.log(response.data.data)
//       }
//     } catch (error) {
//       setLoading(false)
//       message.error(`Could not update client information`);
//     }
//   };


//   return (
//     <div className="max-w-4xl mx-auto mt-8">
//       <Modal
//         visible={visible}
//         title="Edit Client"
//         onCancel={onCancel}
//         open={visible}
//         confirmLoading={loading}
//       >
//         {/* <ClientInfo onNextStep={handleFinalSubmit} userId={userId} visible={visible} loading={loading} /> */}
//       </Modal>
//     </div>
//   );
// };

// export default Edit;
