import { useQueryClient } from "@tanstack/react-query";
import { message } from 'antd';
import { useApiMutation, useApiQuery } from "../hooks/useApiRequest";

//* ---------Get client details by id ---------
export const useGetClientByIdService = (userId) => {
    return useApiQuery({
        key: ["get-client-by-id"],
        endPoint: `/api/v1/clients/view?clientId=${userId}`,
        method: "GET",
    });
};

// ********** ADD THE USER TO MONDAY.COM **********
export const useAddToMondayService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const addUserToMonday = async (userId, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["monday"],
                endPoint: `/api/v1/leads/monday-upload?userId=${userId}`,
                method: "POST",
                bodyContent: {},
            });
            queryClient.invalidateQueries({ queryKey: ["monday"] });
            onSuccess();
            message.success('Successfully added to monday.com');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { addUserToMonday, error };
};

// Export all clients details to excel
export const useExportClientService = () => {
    const exportClient = async (onSuccess, onError) => {
        try {
            const response = await fetch(`http://localhost:3000/api/v1/clients/export-clients`, {
                // const response = await fetch(`${baseURL}/api/v1/clients/export-clients`, {
                method: "POST",
                headers: {
                    "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                },
            });

            if (!response.ok) {
                throw new Error("Failed to export clients");
            }

            // Handle the blob for file download
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            // Create a download link
            const link = document.createElement("a");
            link.href = url;
            link.download = "clients.xlsx"; // Name of the downloaded file
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

            onSuccess();
            message.success("Successfully exported clients.");
        } catch (error) {
            onError();
            message.error(`Export failed: ${error.message}`);
        }
    };

    return { exportClient };
};


export const useVerifyClientService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const verifyClient = async (values, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["verify-client-approved"],
                endPoint: `/api/v1/user/update-kyc`,
                method: "POST",
                bodyContent: values,
            });
            queryClient.invalidateQueries({ queryKey: ["verif-clients"] });
            queryClient.invalidateQueries({ queryKey: ["verify-client-approved"] });
            onSuccess();
            message.success('Successfully Verified');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { verifyClient, error };
};

export const useUpdateClientService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const updateClient = async (clientId, values, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["update-client"],
                endPoint: `/api/v1/clients?clientId=${clientId}`,
                method: "PATCH",
                bodyContent: values,
            });
            queryClient.invalidateQueries({ queryKey: ["get-client-by-id"] });
            queryClient.invalidateQueries({ queryKey: ["update-client"] });
            onSuccess();
            message.success('Successfully Updated');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { updateClient, error };
};