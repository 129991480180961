import React, { useEffect, useState } from "react";
import { Table, Typography, Spin, Button, Space } from "antd";
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import axios from "axios";
import Edit from "./Edit";
import Create from "./Create";
import Delete from "./Delete";

const { Title } = Typography;

const FAQSTab = ({product = {}, reload}) => {
  const [faqs, setFaqs] = useState(product.faqs);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setFaqs(product.faqs);
    
  }, [product]);

  const columns = [
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
      render: (text) => <span className="font-semibold">{text}</span>,
    },
    {
      title: "Answer",
      dataIndex: "answer",
      key: "answer",
      render: (text) => <span className="text-gray-700">{text}</span>,
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: "Updated",
      dataIndex: "updated",
      key: "updated",
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
            <Edit id={record._id} onUpdateSuccess={reload} />
            <Delete id={record._id} onDeleteSuccess={reload} />
        </Space>
    ),
    }
  ];

  return (
    <div className="p-4">
      <div className="text-right py-2">
       {/*<Edit productId={product._id} />*/}
       <Create productId={product._id} onCreateSuccess={reload} />
      </div>
      <Table
        className="shadow-lg rounded-lg overflow-hidden"
        dataSource={faqs.map((faq) => ({ ...faq, key: faq._id }))}
        columns={columns}
        pagination={{ pageSize: 20 }}
      />
    </div>
  );
};

export default FAQSTab;
