import { Tabs } from "antd";
import React from "react";
import TwoFASetting from "./Tabs/2FA";
import APIKeysSettings from "./Tabs/ApiKey";
import ContactSettings from "./Tabs/Contacts";
import LoginHistory from "./Tabs/LoginHistory";
import SystemSettings from "./Tabs/Systems";

const { TabPane } = Tabs;

const Settings = () => {

    return (
        <div>
            <Tabs defaultActiveKey="1">
                <TabPane tab="System" key="1" >
                    <SystemSettings />
                </TabPane>

                <TabPane tab="Contact" key="2">
                    <ContactSettings />
                </TabPane>

                <TabPane tab="API Keys" key="3">
                    <APIKeysSettings />
                </TabPane>

                <TabPane tab="Login History" key="4">
                    <LoginHistory />
                </TabPane>

                <TabPane tab="2FA" key="5">
                    <TwoFASetting />
                </TabPane>
            </Tabs>
        </div>
    );
};

export default Settings;

