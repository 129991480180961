import { Button, Form, Input, Modal, message, Switch } from "antd";
import React, { useState } from "react";
import { AddEmailsTemplateService } from "../../services/emailsService";

const { TextArea } = Input;

const Add = ({ visible, onCancel, onSubmit }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { AddEmailsTemplate } = AddEmailsTemplateService();
  const [isActive, setIsActive] = useState(true);

  const handleAdd = async () => {
    try {
      setLoading(true);

      const values = await form.validateFields();

      const payload = {
        templateName: values.templateName,
        templateId: values.templateId,
        isActive,
      };

      await AddEmailsTemplate(
        payload,
        () => {
          onCancel();
          onSubmit();
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    } catch (error) {
      setLoading(false);
      message.error("An error occurred. Please try again.");
    }
  };

  return (
    <Modal
      visible={visible}
      title="Add Email Template"
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleAdd} loading={loading}>
          Save
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="templateName"
          label="Template Name"
          rules={[
            { required: true, message: "Please enter the template name" },
          ]}
        >
          <Input placeholder="Enter template name" />
        </Form.Item>

        <Form.Item
          name="templateId"
          label="Template ID"
          rules={[{ required: true, message: "Please enter the template ID" }]}
        >
          <Input placeholder="Enter template ID" />
        </Form.Item>

        {/* Add switch for active/inactive status */}
        <Form.Item label="Status">
          <Switch
            checked={isActive}
            onChange={(checked) => setIsActive(checked)}
            checkedChildren="Active"
            unCheckedChildren="Inactive"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Add;

// import { Button, Form, Input, Modal, message } from "antd";
// import React, { useState } from "react";
// import { AddEmailsTemplateService } from "../../services/emailsService";

// const { TextArea } = Input;

// const Add = ({ visible, onCancel, onSubmit }) => {
//   const [form] = Form.useForm();
//   const [loading, setLoading] = useState(false);
//   const { AddEmailsTemplate } = AddEmailsTemplateService();

//   const handleAdd = async () => {
//     try {
//       setLoading(true);

//       const values = await form.validateFields();

//       const payload = {
//         templateName: values.templateName,
//         templateId: values.templateId,
//       };

//       await AddEmailsTemplate(
//         payload,
//         () => {
//           onCancel();
//           onSubmit();
//           setLoading(false);
//         },
//         () => {
//           setLoading(false);
//         }
//       );
//     } catch (error) {
//       setLoading(false);
//       message.error("Failed to add email template. Please try again.");
//     }
//   };

//   return (
//     <Modal
//       visible={visible}
//       title="Add Email Template"
//       onCancel={onCancel}
//       footer={[
//         <Button key="cancel" onClick={onCancel}>
//           Cancel
//         </Button>,
//         <Button key="save" type="primary" onClick={handleAdd} loading={loading}>
//           Save
//         </Button>,
//       ]}
//     >
//       <Form form={form} layout="vertical">
//         <Form.Item
//           name="templateName"
//           label="Template Name"
//           rules={[
//             { required: true, message: "Please enter the template name" },
//           ]}
//         >
//           <Input placeholder="Enter template name" />
//         </Form.Item>

//         <Form.Item
//           name="templateId"
//           label="Template ID"
//           rules={[{ required: true, message: "Please enter the template ID" }]}
//         >
//           <Input placeholder="Enter template ID" />
//         </Form.Item>
//       </Form>
//     </Modal>
//   );
// };

// export default Add;
