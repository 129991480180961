// src/pages/Clients/ClientUSDAccounts.jsx

import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import AdminLayout from '../../layouts/AdminLayout';
import PaginatedTable from '../../components/PaginatedTable';
import { debounce } from 'lodash';
import { message, Breadcrumb, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons'; // Importing required icon
import AddBankModal from '../../Modals/ClientBankAccounts/AddBankModal'; // Assuming similar modals
import EditModal from '../../Modals/ClientBankAccounts/EditModal';
import DeleteModal from '../../Modals/ClientBankAccounts/DeleteModal';
import ViewModal from '../../Modals/ClientBankAccounts/ViewModal';

const ClientUSDAccounts = () => {
    const [allUsdAccounts, setAllUsdAccounts] = useState([]); // Store all USD accounts
    const [filteredUsdAccounts, setFilteredUsdAccounts] = useState([]); // Store filtered USD accounts
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Modal state variables
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [isViewModalVisible, setIsViewModalVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);

    const fetchUsdAccounts = async () => {
        setLoading(true);
        console.log("Fetching USD bank accounts data...");
        try {
            const response = await axios.get('http://localhost:3000/api/v1/clients/usd-bank-accounts');
            console.log("Response received:", response.data);

            if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
                const processedData = response.data.data.map((account) => ({
                    user: account.user || 'N/A',
                    accountName: account.accountName || 'N/A',
                    bank: account.bank || 'N/A',
                    accountNumber: account.accountNumber || account.ibanNumber || 'N/A',
                    circleId: account.circleId || 'N/A',
                    circleTrackingReference: account.circleTrackingReference || 'N/A',
                    circleStatus: account.circleStatus || 'N/A',
                    signUpDate: account.signUpDate || 'N/A',
                    date: account.date || 'N/A',
                }));
                setAllUsdAccounts(processedData);
                setFilteredUsdAccounts(processedData); // Initialize with all data
                console.log("Data set in state:", processedData);
            } else {
                throw new Error("Data is not in expected format");
            }
        } catch (err) {
            setError('Failed to fetch data: ' + err.message);
            console.error("Error fetching data:", err);
        } finally {
            setLoading(false);
            console.log("Loading completed.");
        }
    };

    useEffect(() => {
        fetchUsdAccounts();
    }, []);

    const handleSearch = useCallback(
        debounce((filters) => {
            // Apply front-end filtering based on the filters object
            let filtered = allUsdAccounts;

            // Global Search
            if (filters.globalSearch) {
                const searchText = filters.globalSearch.toLowerCase();
                filtered = filtered.filter((account) =>
                    JSON.stringify(account).toLowerCase().includes(searchText)
                );
            }

            // Column-specific Filters
            Object.keys(filters).forEach((key) => {
                if (key !== 'globalSearch') {
                    const value = filters[key].toLowerCase();
                    if (value) {
                        filtered = filtered.filter((account) => {
                            const itemValue = getNestedValue(account, key);
                            return (
                                itemValue &&
                                itemValue.toString().toLowerCase().includes(value)
                            );
                        });
                    }
                }
            });

            setFilteredUsdAccounts(filtered);
        }, 300),
        [allUsdAccounts]
    );

    // Utility function to get nested values
    const getNestedValue = (obj, path) => {
        return path
            .split('.')
            .reduce((o, p) => (o && o[p] !== undefined && o[p] !== null ? o[p] : ''), obj);
    };

    // Modal handlers
    const handleAction = (record) => {
        // Implement action logic here
        console.log('Action clicked for:', record);
    };

    const handleAddBank = () => {
        setIsAddModalVisible(true);
    };

    const handleEdit = (record) => {
        setSelectedRecord(record);
        setIsEditModalVisible(true);
    };

    const handleDelete = (record) => {
        setSelectedRecord(record);
        setIsDeleteModalVisible(true);
    };

    const handleDeleteUser = async (userId) => {
        try {
            await axios.delete(`http://localhost:3000/api/v1/clients/delete/${userId}`);
            message.success('User deleted successfully');
            fetchUsdAccounts();
            setIsDeleteModalVisible(false);
        } catch (error) {
            message.error(error.response?.data?.message || 'Failed to delete user');
        }
    };

    const handleView = (record) => {
        setSelectedRecord(record);
        setIsViewModalVisible(true);
    };

    // Function to convert JSON data to CSV
    const convertToCSV = (data) => {
        const headers = [
            'User',
            'Account Name',
            'Bank',
            'Account Number / IBAN Number',
            'Circle ID',
            'Circle Tracking Reference',
            'Circle Status',
            'Sign Up Date',
            'Date',
        ];

        const rows = data.map((item) => [
            item.user || '',
            item.accountName || '',
            item.bank || '',
            item.accountNumber || '',
            item.circleId || '',
            item.circleTrackingReference || '',
            item.circleStatus || '',
            formatDate(item.signUpDate) || '',
            formatDate(item.date) || '',
        ]);

        const csvContent = [headers, ...rows]
            .map((row) =>
                row
                    .map((field) => `"${String(field).replace(/"/g, '""')}"`)
                    .join(',')
            )
            .join('\n');

        return csvContent;
    };

    const handleExport = () => {
        try {
            if (filteredUsdAccounts.length === 0) {
                message.warning('No USD bank accounts available to export');
                return;
            }

            const csvData = convertToCSV(filteredUsdAccounts);
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'usd_bank_accounts_export.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            message.success('Exported USD bank accounts data successfully');
        } catch (error) {
            message.error('Failed to export USD bank accounts');
        }
    };

    // Function to format date as "07 November 2024, 07:30"
    const formatDate = (dateString) => {
        if (!dateString || dateString === 'N/A') return 'N/A';
        const date = new Date(dateString);
        if (isNaN(date)) return 'Invalid Date';
        const day = String(date.getDate()).padStart(2, '0');
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day} ${month} ${year}, ${hours}:${minutes}`;
    };

    const columns = [
        { title: 'User', dataIndex: 'user', key: 'user' },
        { 
            title: 'Account Name', 
            dataIndex: 'accountName', 
            key: 'accountName', 
            render: (text) => text || 'N/A' 
        },
        { 
            title: 'Bank', 
            dataIndex: 'bank', 
            key: 'bank', 
            render: (text) => text || 'N/A' 
        },
        { 
            title: 'Account Number / IBAN Number', 
            key: 'accountNumber', 
            render: (_, record) => record.accountNumber || record.ibanNumber || 'N/A' 
        },
        { 
            title: 'Circle ID', 
            dataIndex: 'circleId', 
            key: 'circleId', 
            render: (text) => text || 'N/A' 
        },
        { 
            title: 'Circle Tracking Reference', 
            dataIndex: 'circleTrackingReference', 
            key: 'circleTrackingReference', 
            render: (text) => text || 'N/A' 
        },
        { 
            title: 'Circle Status', 
            dataIndex: 'circleStatus', 
            key: 'circleStatus', 
            render: (text) => text || 'N/A' 
        },
        { 
            title: 'Sign Up Date', 
            dataIndex: 'signUpDate', 
            key: 'signUpDate',
            render: (text) => formatDate(text),
        },
        { 
            title: 'Date', 
            dataIndex: 'date', 
            key: 'date',
            render: (text) => formatDate(text),
        },
        // { 
        //     title: 'Actions', 
        //     key: 'actions', 
        //     render: (_, record) => (
        //         <div>
        //             <button 
        //                 onClick={() => handleAction(record)} 
        //                 className="text-blue-500 hover:underline mr-2"
        //             >
        //                 Action
        //             </button>
        //             {/* Add more action buttons if needed */}
        //         </div>
        //     ) 
        // },
    ];

    if (error) {
        return (
            <AdminLayout>
                <div className="mx-auto p-6 bg-white">
                    <div className="text-red-500">Error: {error}</div>
                </div>
            </AdminLayout>
        );
    }

    if (loading) {
        return (
            <AdminLayout>
                <div className="mx-auto p-6 bg-white">
                    <div className="text-blue-500">Loading...</div>
                </div>
            </AdminLayout>
        );
    }

    return (
        <AdminLayout currentKey={'sub5'} openKey='4'>
            <div className="mx-auto p-6 bg-white">
                <Breadcrumb style={{ marginBottom: '20px' }}>
                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item>Clients</Breadcrumb.Item>
                    <Breadcrumb.Item>USD Bank Accounts</Breadcrumb.Item>
                </Breadcrumb>
                <h1 className="text-2xl font-semibold mb-6">Client USD Bank Accounts</h1>
                <div className="flex justify-between items-center mb-6">
                    <button
                        className="flex items-center px-4 py-2 bg-[#22c6ab] text-white rounded-lg hover:bg-[#1da089] transition"
                        onClick={handleAddBank}
                    >
                        + Add Bank to User
                    </button>
                    <Button
                        type="primary"
                        icon={<DownloadOutlined />}
                        onClick={handleExport}
                        className="flex items-center px-4 py-2 bg-[#57249c] text-white rounded-lg hover:bg-[#432076] transition"
                    >
                        Export (csv)
                    </Button>
                </div>
                <PaginatedTable
                    columns={columns}
                    itemsPerPage={15}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    onView={handleView}
                    searchFilters={null} // Not used since filtering is handled internally
                    onSearch={handleSearch}
                    dataSource={filteredUsdAccounts}
                    isSearchable={true} // Ensure search is enabled
                    loading={loading}
                />
                {/* Modals */}
                {isAddModalVisible && (
                    <AddBankModal
                        visible={isAddModalVisible}
                        onCancel={() => setIsAddModalVisible(false)}
                        onSubmit={() => {
                            fetchUsdAccounts();
                            setIsAddModalVisible(false);
                        }}
                    />
                )}
                {isEditModalVisible && (
                    <EditModal
                        visible={isEditModalVisible}
                        onCancel={() => setIsEditModalVisible(false)}
                        data={selectedRecord}
                        onSubmit={() => {
                            fetchUsdAccounts();
                            setIsEditModalVisible(false);
                        }}
                    />
                )}
                {isDeleteModalVisible && (
                    <DeleteModal
                        visible={isDeleteModalVisible}
                        onCancel={() => setIsDeleteModalVisible(false)}
                        clientId={selectedRecord?.user_id}
                        userName={selectedRecord?.user}
                        onDelete={handleDeleteUser}
                    />
                )}
                {isViewModalVisible && (
                    <ViewModal
                        visible={isViewModalVisible}
                        onCancel={() => setIsViewModalVisible(false)}
                        data={selectedRecord}
                    />
                )}
            </div>
        </AdminLayout>
    );
};

export default ClientUSDAccounts;
