import { Space, Table, Tag } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetAllSysAPIKeyService } from '../../../../services/apiKeyService';
import { formatDate } from '../../../../utils/formateData';
import Add from './Add';
// import Delete from './Delete';
import Edit from './Edit';

const APIKeysSettings = () => {
  const { data, isLoading } = useGetAllSysAPIKeyService()
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  const columns = [
    {
      title: "API Name",
      dataIndex: "apiName",
      key: "apiName"
    },
    {
      title: "Status",
      dataIndex: "env",
      key: "env",
      render: (status) => {
        let color;
        switch (status) {
          case 'dev':
            color = 'geekblue';
            break;
          case 'test':
            color = 'blue';
            break;
          case 'live':
            color = 'green';
            break;
          default:
            color = 'gray';
        }

        return (
          <Tag color={color}>
            {status.toUpperCase()}
          </Tag>
        );
      }

    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => formatDate(text),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => {
        return (
          <Space size="middle">
            <Link>  <Edit data={record} /> </Link>
            {/* <Link>  <Delete record={record} /> </Link> */}
          </Space>
        )
      },
    }
  ];

  return (
    <>
      <div className="flex justify-end items-center mb-6">
        <button
          className="px-4 rounded-lg py-2 bg-[#22c6ab] text-white hover:bg-[#1da089] transition"
          onClick={() => setIsAddModalVisible(true)}
        >
          + Add New API Key
        </button>
      </div>

      <Table
        dataSource={data}
        columns={columns}
        loading={isLoading}
        pagination={false}
        rowKey={(record) => record.id || record._id || record.key}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        scroll={{ x: 'max-content' }}
      />

      {/* Modals */}
      {isAddModalVisible && (
        <Add
          visible={isAddModalVisible}
          onCancel={() => setIsAddModalVisible(false)}
          onSubmit={() => { setIsAddModalVisible(false) }}
        />
      )}
    </>
  )
}

export default APIKeysSettings;