import React, { useState } from "react";
import { Modal, Button, Space, Steps, message } from "antd";
import TradingAssetForm from "../../models/TradingAsset";
import TradingAssetChainForm from "../../models/TradingAssetChain";
import { PlusOutlined } from "@ant-design/icons";
import { create, createWithFile } from "../../services/apiService";
import InvestmentProductForm from "../../models/InvestmentProduct";
import InvestmentTermsAndConditionsForm from "../../models/InvestmentTerms";
import FAQForm from "../../models/FAQ";

const { Step } = Steps;

const Create = ({onCreateSuccess}) => {
  const [visible, setVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(1); // Track current step
  const [formData, setFormData] = useState({}); // Store form data

  const toggleForm = () => {
    setVisible(!visible);
  };

  const handleNextStep = (data) => {
    setFormData((prevData) => ({
      ...prevData,
      ...data,
    }));
    setCurrentStep(currentStep + 1); // Move to next step
  };

  const onPrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1); // Go back to previous step
    }
  };

  const handleFinalSubmit = async (values) => {
    
    const { images, ...data } = values;
    console.log(data);

    // Collect files as a list of File objects
    const fileList = images?.fileList?.map((file) => file.originFileObj) || [];

    try {
      
      const response = await createWithFile('/api/v1/investment-product/create', data, fileList);

      if (response.status === 201) {
        message.success("Investment product created successfully!");
        onCreateSuccess(Math.random() > 0.5);
        toggleForm();
        //onNextStep(values); // Pass form data to the next step
      } else {
        message.error(`Submission failed: ${response.data.message}`);
      }
    } catch (error) {
      message.error("An error occurred during submission.");
    }
    
  };

  const stepsData = [
    { title: "Step 1: Product Details" },
    { title: "Step 2: Terms and Conditions" },
    { title: "Step 3: QA" },
  ];

  if (!visible) {
    return (
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold">Investments</h1>
        <Space>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={toggleForm}
            style={{ backgroundColor: '#22c6ab', borderColor: '#22c6ab' }}
          >
            Create Investment
          </Button>
        </Space>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto mt-8">
      <Modal
        title="Create Investment Product"
        open={visible}
        onCancel={toggleForm}
        footer={null}
        width={1200}
        destroyOnClose
        style={{ maxHeight: '70vh', overflowY: 'auto' }}
      >
        {/* Steps component for showing progress */}
        <Steps current={currentStep - 1} size="small" className="mb-6">
          {stepsData.map((step, index) => (
            <Step key={index} title={step.title} />
          ))}
        </Steps>

        {/* Render forms based on the current step */}
        {currentStep === 1 && (
        
          <InvestmentProductForm
            onNextStep={handleNextStep}
            previousData={formData}
          />
        )}
        
        {currentStep === 2 && (
          <InvestmentTermsAndConditionsForm
            onNextStep={handleNextStep}
            onPrevious={onPrevious}
            initialData={formData}
            isFinal={false}
          />
        )}
        {currentStep === 3 && (
          <FAQForm
            onSubmit={handleFinalSubmit}
            onPrevious={onPrevious}
            previousData={formData}
          />
        )}
      </Modal>
    </div>
  );
};

export default Create;
