import { useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useApiMutation, useApiQuery } from '../hooks/useApiRequest';

//* ---------Get all assets---------
export const useGetAllAssets = (filter) => {
    return useApiQuery({
        key: ['assets',],
        endPoint: `/api/v1/trading-assets/get-assets?${filter}`,
        method: 'GET',
    });
};


export const useGetAllChains = (assetId) => {
    return useApiQuery({
        key: ["chains"],
        endPoint: `/api/v1/trading-assets/get-chains?assetId=${assetId}`,
        method: "GET",
    });
};

export const useGetAssetPairs = (assetId) => {
    return useApiQuery({
        key: ["asset-pairs"],
        endPoint: `/api/v1/trading-assets/get-pairs?assetId=${assetId}`,
        method: "GET",
    });
};


//*********** add new asset chain**********
export const useAddAssetChainService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const addAssetChain = async (values, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["create-asset-chain"],
                endPoint: `/api/v1/trading-assets/create-chains`,
                method: "POST",
                bodyContent: values,
            });
            queryClient.invalidateQueries({ queryKey: ["chains"] });
            queryClient.invalidateQueries({ queryKey: ["create-asset-chain"] });
            onSuccess();
            message.success('Successfully added');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { addAssetChain, error };
};


//*********** Update asset chain**********
export const useUpdateAssetChainService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const updateAssetChain = async (values, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["update-asset-chain"],
                endPoint: `/api/v1/trading-assets/update-chains`,
                method: "PATCH",
                bodyContent: values,
            });
            queryClient.invalidateQueries({ queryKey: ["chains"] });
            queryClient.invalidateQueries({ queryKey: ["update-asset-chain"] });
            onSuccess();
            message.success('Successfully updated');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { updateAssetChain, error };
};


//*********** Update asset pairs**********
export const useUpdateAssetPairsService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const updateAssetPairs = async (values, onSuccess, onError) => {
        console.log(" values : ", values);
        try {
            await mutateAsync({
                key: ["update-asset-pairs"],
                endPoint: `/api/v1/trading-assets/update-pairs`,
                method: "PATCH",
                bodyContent: values,
            });
            queryClient.invalidateQueries({ queryKey: ["asset-pairs"] });
            queryClient.invalidateQueries({ queryKey: ["update-asset-pairs"] });
            onSuccess();
            message.success('Successfully updated');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { updateAssetPairs, error };
};



//*********** Delete asset chain **********
export const useDeleteAssetChainService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const deleteAssetChain = async (id, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["delete-asset-chain", id],
                endPoint: `/api/v1/trading-assets/delete-chains?chainId=${id}`,
                method: "DELETE",
            });
            queryClient.invalidateQueries({ queryKey: ["chains"] });
            queryClient.invalidateQueries({ queryKey: ["delete-asset-chain", id] });
            onSuccess();
            message.success('Successfully deleted');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { deleteAssetChain, error };
};

