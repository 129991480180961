import { Space } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AdminLayout from '../../../layouts/AdminLayout';
import { useApproveCryptoDepositService } from '../../../services/depositCryptoService';
import { formatDate } from '../../../utils/formateData';
import Add from './Add';
import Delete from './Delete';
import { useApiQuery } from '../../../hooks/useApiRequest';
import PaginatedTable2 from '../../../components/PaginatedTable2';

const Index = () => {
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const { approveCryptoDeposit } = useApproveCryptoDepositService();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [loadingStates, setLoadingStates] = useState({});

    const { data, isLoading } = useApiQuery({
        key: ['crypto-user-deposits'],
        endPoint: `/api/v1/crypto-transactions/deposits?page=${currentPage}&limit=${pageSize}`,
        method: 'GET',
    });

    const pagination = {
        totalItems: data?.totalItems || 0,
        totalPages: data?.totalPages || 1,
        currentPage,
        limit: pageSize,
        startIndex: (currentPage - 1) * pageSize + 1,
        endIndex: Math.min(currentPage * pageSize, data?.totalItems || 0),
    };

    const handlePageChange = (page, size) => {
        setCurrentPage(page);
        setPageSize(size);
    };

    const cryptoDepositColumns = [
        {
            title: 'User',
            dataIndex: `user`,
            key: 'userId',
            render: (text, record) => `${record?.userId?.username} - ${record?.userId?.email} ` || "N/A"
        },
        {
            title: 'Reference',
            dataIndex: 'txid',
            key: 'txid',
            render: (text) => text || 'N/A'
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date) => formatDate(date),
        },
        {
            title: 'Asset',
            dataIndex: 'cryptoAsset',
            key: 'cryptoAsset',
            render: (text) => text?.toUpperCase() || 'N/A',
            // render: (text) => text || 'N/A',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount) => `R ${parseFloat(amount?.$numberDecimal).toFixed(8)}`,
        },
        {
            title: 'Fees',
            dataIndex: 'fee',
            key: 'fee',
            render: (fees) => `${parseFloat(fees?.$numberDecimal).toFixed(8)}`,

        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes',
            render: (text) => text || 'N/A'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status, record) => {

                const isApproveLoading = loadingStates[record._id]?.approve;

                if (status === 'Pending') {
                    return (
                        <button
                            className="px-3 py-1 text-sm font-semibold text-white bg-yellow-500 rounded hover:bg-yellow-600"
                            onClick={() => handleApprove(record)}
                            disabled={isApproveLoading}>
                            {isApproveLoading ? "Loading..." : "Mark Completed"}
                        </button>
                    );
                }
                return <span className="text-sm font-semibold text-green-600">{status}</span>;
            },
        },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Link>
                            <Delete record={record} />
                        </Link>
                    </Space>
                )
            },
        }
    ];

    const setLoadingForRecord = (recordId, type, value) => {
        setLoadingStates((prev) => ({
            ...prev,
            [recordId]: {
                ...prev[recordId],
                [type]: value,
            },
        }));
    };

    const handleApprove = async (record) => {
        setLoadingForRecord(record._id, 'approve', true);
        approveCryptoDeposit(
            record?._id,
            () => setLoadingForRecord(record._id, 'approve', false),
            () => setLoadingForRecord(record._id, 'approve', false)
        )
    }

    return (
        <AdminLayout currentKey={'dw-sub2'} openKey='5'>
            <div className="mx-auto p-6 bg-white">
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-xl font-semibold">Crypto Deposits </h1>
                    <div className="text-xs text-gray-500">
                        Dashboard &gt; Crypto &gt; Deposits
                    </div>
                </div>

                <div className="flex justify-between items-center mb-6">
                    <button
                        className="px-4 rounded-lg py-2 bg-[#22c6ab] text-white hover:bg-[#1da089] transition"
                        onClick={() => setIsAddModalVisible(true)}
                    >
                        + Add Crypto Deposit
                    </button>
                </div>

                <PaginatedTable2
                    data={data?.results}
                    columns={cryptoDepositColumns}
                    pagination={pagination}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageChange}
                    loading={isLoading}
                />

                {/* Modals */}
                {isAddModalVisible && (
                    <Add
                        visible={isAddModalVisible}
                        onCancel={() => setIsAddModalVisible(false)}
                        onSubmit={() => { setIsAddModalVisible(false) }}
                    />
                )}
            </div>
        </AdminLayout>
    );
};

export default Index;