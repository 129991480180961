// Modals/ClientBankAccounts/DeleteModal.jsx

import React, { useState } from 'react';
import { Modal, Button, Typography, Space, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;

const DeleteModal = ({ open, onCancel, onDelete, clientId, userName }) => {
    const [loading, setLoading] = useState(false);

    const handleDelete = async () => {
        setLoading(true);
        try {
            await onDelete(clientId);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            message.error(error.response?.data?.message || 'Failed to delete user. Please try again.');
        }
    };

    return (
        <Modal
            title={
                <Space>
                    <ExclamationCircleOutlined style={{ color: '#faad14' }} />
                    <Text style={{ color: '#faad14' }}>Delete Confirmation</Text>
                </Space>
            }
            open={open}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button
                    key="delete"
                    type="primary"
                    danger
                    onClick={handleDelete}
                    loading={loading}
                >
                    Delete
                </Button>,
            ]}
        >
            <Space direction="vertical">
                <Text>
                    Are you sure you want to delete <strong>{userName}</strong>? This action cannot be undone.
                </Text>
            </Space>
        </Modal>
    );
};

export default DeleteModal;
