// src/components/SystemContacts.jsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaCog, FaExclamationTriangle, FaSpinner, FaPhone, FaEnvelope, FaMapMarkerAlt, FaWhatsapp, FaFacebook, FaTwitter, FaLinkedin, FaInstagram, FaYoutube } from 'react-icons/fa';
import UpdateSystemContacts from '../../components/Modals/Settings/UpdateSystemContacts';

const SystemContacts = () => {
  const [contact, setContact] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // Modal state
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);

  // Fetch System Contact data
  useEffect(() => {
    const fetchContact = async () => {
      try {
        const response = await axios.get('http://localhost:3000/api/v1/settings/system-contacts');
        // Assuming there's only one contact entry
        setContact(response.data[0]);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching System Contacts:', err);
        setError(true);
        setLoading(false);
      }
    };

    fetchContact();
  }, []);

  // Handle Update Button Click
  const handleUpdate = () => {
    setIsUpdateModalVisible(true);
  };

  // Handle successful update
  const handleUpdateSuccess = (updatedContact) => {
    setContact(updatedContact);
    setIsUpdateModalVisible(false);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full bg-gray-100">
        <div className="flex items-center text-gray-500 text-lg">
          <FaSpinner className="animate-spin mr-2 text-xl" />
          Loading System Contacts...
        </div>
      </div>
    );
  }

  if (error || !contact) {
    return (
      <div className="flex justify-center items-center h-full bg-gray-100">
        <div className="flex items-center text-red-500 text-lg">
          <FaExclamationTriangle className="mr-2 text-xl" />
          Failed to load System Contacts. Please try again later.
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto p-6 bg-gradient-to-r from-blue-50 to-purple-50 min-h-screen">
      {/* Header with Update Button */}
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8">
        <h2 className="text-4xl font-bold text-gray-800 mb-4 md:mb-0">System Contacts</h2>
        <button
          onClick={handleUpdate}
          className="flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <FaCog className="mr-2" />
          Update
        </button>
      </div>

      {/* Contact Information Card */}
      <div className="bg-white rounded-xl shadow-lg p-8">
        <h3 className="text-2xl font-semibold text-gray-700 mb-6">Contact Details</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Telephone */}
          <div className="flex items-center">
            <FaPhone className="text-blue-500 text-2xl mr-4" />
            <div>
              <h4 className="text-lg font-medium text-gray-800">Telephone</h4>
              <p className="text-gray-600">{contact.tel}</p>
            </div>
          </div>
          {/* Email */}
          <div className="flex items-center">
            <FaEnvelope className="text-green-500 text-2xl mr-4" />
            <div>
              <h4 className="text-lg font-medium text-gray-800">Email</h4>
              <p className="text-gray-600">{contact.email}</p>
            </div>
          </div>
          {/* Address */}
          <div className="flex items-start">
            <FaMapMarkerAlt className="text-red-500 text-2xl mr-4 mt-1" />
            <div>
              <h4 className="text-lg font-medium text-gray-800">Address</h4>
              <p className="text-gray-600">
                {contact.addressLine1}
                {contact.addressLine2 && `, ${contact.addressLine2}`}<br />
                {contact.city}, {contact.state}, {contact.postalCode}, {contact.country}
              </p>
            </div>
          </div>
          {/* WhatsApp */}
          <div className="flex items-center">
            <FaWhatsapp className="text-teal-500 text-2xl mr-4" />
            <div>
              <h4 className="text-lg font-medium text-gray-800">WhatsApp</h4>
              <p className="text-gray-600">{contact.whatsappButtonNumber}</p>
            </div>
          </div>
          {/* Map */}
          <div className="col-span-1 md:col-span-2">
            <h4 className="text-lg font-medium text-gray-800 mb-2">Map</h4>
            {contact.map ? (
              <iframe
                src={contact.map}
                className="w-full h-64 rounded-lg"
                allowFullScreen=""
                loading="lazy"
                title="Google Maps"
              ></iframe>
            ) : (
              <p className="text-gray-600">N/A</p>
            )}
          </div>
          {/* Social Media Links */}
          <div className="col-span-1 md:col-span-2">
            <h4 className="text-lg font-medium text-gray-800 mb-2">Social Media</h4>
            <div className="flex space-x-4">
              {contact.facebookLink && (
                <a href={contact.facebookLink} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800 text-3xl">
                  <FaFacebook />
                </a>
              )}
              {contact.twitterLink && (
                <a href={contact.twitterLink} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-600 text-3xl">
                  <FaTwitter />
                </a>
              )}
              {contact.linkedinLink && (
                <a href={contact.linkedinLink} target="_blank" rel="noopener noreferrer" className="text-blue-700 hover:text-blue-900 text-3xl">
                  <FaLinkedin />
                </a>
              )}
              {contact.instagramLink && (
                <a href={contact.instagramLink} target="_blank" rel="noopener noreferrer" className="text-pink-500 hover:text-pink-700 text-3xl">
                  <FaInstagram />
                </a>
              )}
              {contact.youtubeLink && (
                <a href={contact.youtubeLink} target="_blank" rel="noopener noreferrer" className="text-red-600 hover:text-red-800 text-3xl">
                  <FaYoutube />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Update Modal */}
      <UpdateSystemContacts
        visible={isUpdateModalVisible}
        onCancel={() => setIsUpdateModalVisible(false)}
        contact={contact}
        onUpdateSuccess={handleUpdateSuccess}
      />
    </div>
  );
};

export default SystemContacts;
