import { useQueryClient } from "@tanstack/react-query";
import { message } from 'antd';
import { useApiMutation, useApiQuery } from "../hooks/useApiRequest";

//* ---------Get all API Key ---------
export const useGetAllSysAPIKeyService = () => {
    return useApiQuery({
        key: ["system-api-keys"],
        endPoint: `/api/v1/api-keys/get-all`,
        method: "GET",
    });
};

//*********** add API Key **********
export const useAddApiKeyService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const addApiKey = async (values, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["api-key-create"],
                endPoint: `/api/v1/api-keys/create`,
                method: "POST",
                bodyContent: values,
            });
            queryClient.invalidateQueries({ queryKey: ["system-api-keys"] });
            queryClient.invalidateQueries({ queryKey: ["api-key-create"] });
            onSuccess();
            message.success('Successfully added');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { addApiKey, error };
};

//*********** Update API Key**********
export const useUpdateAPIKeyService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const updateAPIKey = async (values, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["api-key-update"],
                endPoint: `/api/v1/api-keys/update`,
                method: "PATCH",
                bodyContent: values,
            });
            queryClient.invalidateQueries({ queryKey: ["system-api-keys"] });
            queryClient.invalidateQueries({ queryKey: ["api-key-update"] });
            onSuccess();
            message.success('Successfully updated');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { updateAPIKey, error };
};

//*********** Delete API Key **********
export const useDeleteAPIKeyService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const deleteAPIKey = async (id, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["api-key-delete"],
                endPoint: `/api/v1/api-keys/delete?apiKeyId=${id}`,
                method: "DELETE",
            });
            queryClient.invalidateQueries({ queryKey: ["system-api-keys"] });
            queryClient.invalidateQueries({ queryKey: ["api-key-delete"] });
            onSuccess();
            message.success('Successfully deleted');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { deleteAPIKey, error };
};

