// ../../Modals/Settings/SettingsAPIKeys/ViewAPIKeys.jsx

import React from 'react';
import { Modal, Descriptions } from 'antd';
import PropTypes from 'prop-types';

const ViewAPIKeys = ({ visible, onCancel, serviceSetting }) => {
  return (
    <Modal
      visible={visible}
      title="View API Keys"
      onCancel={onCancel}
      footer={null}
    >
      {serviceSetting ? (
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Service Name">
            {serviceSetting.name}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            {serviceSetting.status}
          </Descriptions.Item>
          <Descriptions.Item label="API Key">
            {serviceSetting.apiKey || 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="API Secret">
            {serviceSetting.apiSecret || 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Created At">
            {new Date(serviceSetting.createdAt).toLocaleString()}
          </Descriptions.Item>
          <Descriptions.Item label="Updated At">
            {new Date(serviceSetting.updatedAt).toLocaleString()}
          </Descriptions.Item>
        </Descriptions>
      ) : (
        <p>No service setting selected.</p>
      )}
    </Modal>
  );
};

ViewAPIKeys.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  serviceSetting: PropTypes.object,
};

export default ViewAPIKeys;
