import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  message,
  Space,
  Button,
  Breadcrumb,
  Modal,
  Carousel,
  Tooltip,
  Typography,
  Tag,
} from "antd";
import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import PaginatedTable from "../../components/PaginatedTable";
import AdminLayout from "../../layouts/AdminLayout";
import View from './View';
import Create from "./Create";
/*import Edit from './Edit';
import Delete from './Delete';*/

const { Title } = Typography;

const Index = () => {
  const [refresh, setRefresh] = useState(false);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <strong>{text || "N/A"}</strong>, // Fallback to 'N/A' if text is undefined or null
    },
    {
      title: "Min",
      dataIndex: "minAmount",
      key: "minAmount",
      //render: (text) => (text ? text.toUpperCase() : 'N/A'), // Check if text exists before calling toUpperCase
    },
    {
      title: "Max",
      dataIndex: "maxAmount",
      key: "minAmount",
      //render: (text) => (text ? text.toUpperCase() : 'N/A'), // Check if text exists before calling toUpperCase
    },
    {
      title: "ROI (%)",
      dataIndex: "roi",
      key: "roi",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "active" ? "green" : "red"}>
          {status || "Unknown"}
        </Tag>
      ),
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Investment Type",
      dataIndex: "investmentType",
      key: "investmentType",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "investmentType",
    },
    {
      title: "Risk Level",
      dataIndex: "risk",
      key: "risk",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
          <Space size="middle">
              <View productId={record._id} />
              {/*<Edit assetId={record._id} onUpdateSuccess={setRefresh} />
              <Delete assetId={record._id} onDeleteSuccess={setRefresh} />*/}
          </Space>
      ),
    }
  ];

  const filters = [
    { name: "assetName", label: "Asset Name", type: "text" },
    { name: "asset", label: "Asset", type: "text" },
    { name: "firstname", label: "First Name", type: "text" },
    { name: "lastname", label: "Last Name", type: "text" },
    { name: "identity_number", label: "Identity Number", type: "text" },
    {
      name: "type",
      label: "Type",
      type: "dropdown",
      options: ["Fiat", "Crypto"],
    },
    {
      name: "status",
      label: "Status",
      type: "dropdown",
      options: [true, false],
    },
  ];

  return (
    <AdminLayout currentKey={'inv-sub1'} openKey='20'>
      <div className="mx-auto p-6 bg-white">
            {/* Breadcrumb */}
        <Breadcrumb className="mb-4">
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item>Investment</Breadcrumb.Item>
          <Breadcrumb.Item>Investment Products</Breadcrumb.Item>
        </Breadcrumb>

        <Create onCreateSuccess={setRefresh} />

        <PaginatedTable
          fetchEndpoint="/api/v1/investment-product/index"
          columns={columns}
          //actionColumn={actionColumn}
          isSearchable={true}
          isSelectable={true}
          filters={filters}
          refresh={refresh}
        />
      </div>
    </AdminLayout>
  );
};

export default Index;
