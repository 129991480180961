import React, { useState } from "react";
import { Switch, message } from "antd";
import { updatePatch } from "../services/apiService";

const ToggleStatus = ({ enabled, resourceId, onToggle, toggleUrl }) => {
  const [loading, setLoading] = useState(false);

  const handleToggle = async (checked) => {
    setLoading(true);
    try {
      // Simulating an API call
      // Replace with actual API call logic
      /*const response = await fetch(`/api/v1/ncw-assets/toggle-status/${resourceId}`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ enabled: checked }),
      });*/
      const response = await updatePatch(toggleUrl);

      if (response.status === 200) {
        message.success(`Status updated successfully`);
        onToggle(Math.random()); // Notify parent component to refresh data
      } else {
        throw new Error("Failed to update status");
      }
    } catch (error) {
      message.error(error.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Switch
      checked={enabled}
      onChange={handleToggle}
      loading={loading}
      checkedChildren="Enabled"
      unCheckedChildren="Disabled"
    />
  );
};

export default ToggleStatus;
