import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Switch } from 'antd';
import React, { useState } from 'react';
import { useUpdateAssetPairsService } from '../../../../services/assetService';
import { convertDecimal128 } from '../../../../utils/convertNumbers';

const Edit = ({ data, assetId }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const { updateAssetPairs } = useUpdateAssetPairsService();

    const handleEdit = async () => {
        setLoading(true);

        const values = await form.validateFields();

        const payload = {
            assetId: assetId,
            pairId: data?._id,
            buyFee: convertDecimal128(values.buyFee),
            sellFee: convertDecimal128(values.sellFee),
            enabled: values.enabled,
        };

        updateAssetPairs(
            payload,
            () => {
                setLoading(false);
                toggleView()
            },
            () => setLoading(false)
        );
    };

    const toggleView = () => {
        setVisible(!visible);
    };

    if (!visible) {
        return (
            <Button
                type="text"
                icon={<EditOutlined />}
                style={{
                    padding: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                onClick={toggleView}
            />
        );
    }

    return (
        <Modal
            visible={visible}
            title="Edit Asset Pair"
            onCancel={toggleView}
            footer={[
                <Button key="cancel" onClick={toggleView}>
                    Cancel
                </Button>,
                <Button
                    key="save"
                    type="primary"
                    onClick={handleEdit}
                    loading={loading}
                >
                    Save
                </Button>,
            ]}>
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    buyFee: data?.buyFee ? parseFloat(data.buyFee.$numberDecimal) : "",
                    sellFee: data?.sellFee ? parseFloat(data.sellFee.$numberDecimal) : "",
                    enabled: data?.enabled,
                }}>

                <Form.Item
                    name="sellFee"
                    label="Sell Fee "
                    rules={[{ required: true, message: 'Please enter Sell Fee' }]}
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="Enter Sell Fee"
                    />
                </Form.Item>

                <Form.Item
                    name="buyFee"
                    label="Buy Fee"
                    rules={[{ required: true, message: 'Please enter Buy Fee' }]}
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="Enter buy fee"
                    />
                </Form.Item>

                <Form.Item
                    label="Status"
                    name="enabled"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>

            </Form>
        </Modal>

    );
};

export default Edit;

