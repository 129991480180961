import React from "react";
import AdminLayout from "../../layouts/AdminLayout";

const ComingSoon = () => {
  return (
    <AdminLayout currentKey='2' openKey='2'>
      <div className="flex flex-col md:flex-row min-h-screen">
        {/* Left side - Background image and text */}
        <div
          className="w-full md:w-1/2 flex items-center justify-center text-white relative"
          style={{
            backgroundImage: "url('/background_purple.jpg')", // Replace with your image path
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h1 className="text-6xl font-bold text-center">Coming Soon</h1>
        </div>

        {/* Right side - Logo and text */}
        <div className="w-full md:w-1/2 flex flex-col justify-center bg-white">
          <div className="flex flex-col items-center">
            <img
              src="/full_favicon.png" // Replace with your 88 logo path
              alt="88 Logo"
              className="w-20 h-20 mb-6"
              style={{
                borderRadius: 99,
              }}
            />
            <h2 className="text-lg uppercase tracking-widest text-gray-500">
              Stay Tuned
            </h2>
            <h1 className="text-4xl font-bold text-gray-800 mt-2 text-center">
              New And Exciting Dashboard Coming Soon
            </h1>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default ComingSoon;