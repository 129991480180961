import { BlockOutlined, CameraFilled, EyeOutlined, InfoCircleOutlined, QuestionOutlined } from '@ant-design/icons';
import { Button, message, Modal, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { getQuery } from '../../services/apiService';
import DetailsTab from './Tabs/Details/DetailsTab';
import TermsConditionsTabs from './Tabs/TermsConditions/TermsConditionsTab';
import FAQSTab from './Tabs/Faqs/FaqsTab';
import ImagesTab from './Tabs/Images/ImagesTab';

const { TabPane } = Tabs;

const View = ({ productId }) => {
  console.log(productId);
  const [visible, setVisible] = useState(false);
  const [product, setProduct] = useState({});
  const [reload, setReload] = useState(0);

  const toggleView = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    const getInvestment = async () => {
      try{
        const response = await getQuery('/api/v1/investment-product/view', { productId });
        console.log('Reload... ', reload);

        if(response.status == 200 && response.data.data){
          console.log('Product" ', response.data.data);
          setProduct(response.data.data);
        }
      }catch(error){
        message.error('Could not fetch investment product.');
      }
    }

    if(visible){
      getInvestment();
    }
  }, [visible, reload]);

  if (!visible) {
    return (
      <Button
        type="text"
        icon={<EyeOutlined />}
        style={{
          padding: 0, // Removes padding for a cleaner look
          display: "flex", // Ensures proper alignment
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={toggleView} // Properly placed onClick handler
      />
    );
  }

  return (
    <Modal
      title="View Investment"
      open={visible}
      onCancel={toggleView}
      footer={null}
      width={1200}
      style={{ maxHeight: '80vh', overflowY: 'auto', backgroundColor: '#FFFFFF' }}
    >
      <Tabs defaultActiveKey="1" type="card">
        {/* Tab 1: Asset Details */}
        <TabPane
          tab={
            <span>
              <InfoCircleOutlined style={{ marginRight: 8 }} />
              Investment Product Details
            </span>
          }
          key="1"
        >
          {<DetailsTab product={product} reload={setReload} />}
        </TabPane>

        {/* Tab 2: Tab for currency pair */}
        <TabPane tab={
            <span>
              <CameraFilled style={{marginRight: 8}} />
              Images
            </span>
          } 
          key="2"
        >
          <div>
            { <ImagesTab imageUrls={product.imageUrls} /> }
          </div>
        </TabPane>

        {/* Tab 3: Tab for asset chains */}

        <TabPane
          tab={
            <span>
              <BlockOutlined style={{ marginRight: 8 }} />
              Terms & Conditions
            </span>
          }
          key="3">
          <div>
            {<TermsConditionsTabs product={product} reload={setReload} />}
          </div>
        </TabPane>
        <TabPane
          tab={
            <span>
              <QuestionOutlined style={{ marginRight: 8 }} />
              FAQs
            </span>
          }
          key="4">
          <div>
            { <FAQSTab product={product} reload={setReload} /> }
          </div>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default View;
