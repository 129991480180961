import React, { useState } from "react";
import { Modal, Button, message } from "antd";
import { StopOutlined } from "@ant-design/icons";
import { deleteResource } from "../../services/apiService";

const Delete = ({ userId, onDeleteSuccess, status }) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const toggleModal = () => {
        setVisible(!visible);
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            //const response = await deleteUser(`/api/v1/clients/${userId}`);
            const response = await deleteResource('/api/v1/clients/delete', { clientId: userId });
            if (response.status === 200) {
                message.success(`Client ${status === "enabled" ? "Disable" : "Enable"} successfully.`);
                onDeleteSuccess(Math.random() > 0.5);
                toggleModal();
            } else {
                throw new Error("Failed to delete client.");
            }
        } catch (error) {
            message.error(error.message || "Error deleting client.");
        } finally {
            setLoading(false);
        }
    };

    if (!visible) {
        return (
            <Button
                type="text"
                icon={<StopOutlined />}
                style={{
                    padding: 0, // Removes padding for a cleaner look
                    display: "flex", // Ensures proper alignment
                    alignItems: "center",
                    justifyContent: "center",
                }}
                onClick={toggleModal} // Properly placed onClick handler
            />
        );
    }

    return (
        <>
            <Button type="danger" onClick={toggleModal}>
                {status === "enabled" ? "Disable Client" : "Enable this client"}
            </Button>

            <Modal
                title={status === "enabled" ? "Disable Client" : "Enable this client"}
                open={visible}
                onCancel={toggleModal}
                footer={null}
                destroyOnClose
                style={{ maxWidth: "600px" }}
            >
                <div>
                    <p>Are you sure you want to  {status === "enabled" ? "Disable" : "Enable"}  this client?</p>
                    <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                        <Button onClick={toggleModal}>Cancel</Button>
                        <button
                            className={`px-4 rounded-lg py-0 text-white transition ${status === "enabled"
                                ? "bg-red-500 hover:bg-red-600"
                                : "bg-[#57249c] hover:bg-[#974cf8]"
                                }`}
                            onClick={handleDelete}
                        >
                            {status === "enabled" ? "Disable" : "Enable"}
                        </button>

                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Delete;
