// ../../Pages/Settings/ServiceSettings.jsx

import React, { useState, useEffect } from 'react';
import { Table, Button, Space, message, Input, Row, Col } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import EditAPIKeys from '../../components/Modals/Settings/EditAPIKeys';
import ViewAPIKeys from '../../components/Modals/Settings/ViewAPIKeys';
import AddAPIKeys from '../../components/Modals/Settings/AddAPIKeys';

const { Search } = Input;

const SettingsAPIKeys = () => {
  const [serviceSettings, setServiceSettings] = useState([]);
  const [filteredSettings, setFilteredSettings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const fetchServiceSettings = () => {
    setLoading(true);
    axios
      .get('http://localhost:3000/api/v1/settings/service-settings')
      .then(response => {
        setServiceSettings(response.data);
        setFilteredSettings(response.data);
        setLoading(false);
      })
      .catch(() => {
        message.error('Failed to fetch service settings.');
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchServiceSettings();
  }, []);

  const handleView = (record) => {
    setSelectedService(record);
    setViewModalVisible(true);
  };

  const handleEdit = (record) => {
    setSelectedService(record);
    setEditModalVisible(true);
  };

  const handleAdd = () => {
    setAddModalVisible(true);
  };

  const handleSearch = (value) => {
    const filtered = serviceSettings.filter(service =>
      service.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredSettings(filtered);
  };

  const columns = [
    {
      title: 'Service Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: text => <strong>{text}</strong>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'Enabled', value: 'Enabled' },
        { text: 'Disabled', value: 'Disabled' },
        { text: 'Testing', value: 'Testing' },
      ],
      onFilter: (value, record) => record.status === value,
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: status => {
        let color;
        switch (status) {
          case 'Enabled':
            color = 'green';
            break;
          case 'Disabled':
            color = 'volcano';
            break;
          case 'Testing':
            color = 'geekblue';
            break;
          default:
            color = 'default';
        }
        return <span style={{ color }}>{status}</span>;
      },
    },
    {
      title: 'API Key',
      dataIndex: 'apiKey',
      key: 'apiKey',
      render: text => text ? '********' : 'N/A',
    },
    {
      title: 'API Secret',
      dataIndex: 'apiSecret',
      key: 'apiSecret',
      render: text => text ? '********' : 'N/A',
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => handleView(record)}>View</Button>
          <Button type="primary" onClick={() => handleEdit(record)}>
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="p-6">
      <Row justify="space-between" align="middle" className="mb-4">
        <Col>
          <h2 className="text-2xl font-semibold">Service Settings</h2>
        </Col>
        <Col>
          <Space>
            <Search
              placeholder="Search Service"
              onSearch={handleSearch}
              enterButton={<SearchOutlined />}
              allowClear
            />
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleAdd}
            >
              Add Service
            </Button>
          </Space>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={filteredSettings}
        rowKey="_id"
        loading={loading}
        scroll={{ x: 800 }}
        pagination={{ pageSize: 5 }}
      />

      {/* View Modal */}
      <ViewAPIKeys
        visible={viewModalVisible}
        onCancel={() => setViewModalVisible(false)}
        serviceSetting={selectedService}
      />

      {/* Edit Modal */}
      <EditAPIKeys
        visible={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        serviceSetting={selectedService}
        onUpdateSuccess={(updatedService) => {
          message.success('Service updated successfully.');
          fetchServiceSettings();
          setEditModalVisible(false);
        }}
      />

      {/* Add Modal */}
      <AddAPIKeys
        visible={addModalVisible}
        onCancel={() => setAddModalVisible(false)}
        onAddSuccess={(newService) => {
          message.success('Service added successfully.');
          fetchServiceSettings();
          setAddModalVisible(false);
        }}
      />
    </div>
  );
};

export default SettingsAPIKeys;
