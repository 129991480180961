import React, { useState, useEffect } from "react";
import { Space } from "antd";
import PaginatedTable2 from "../../../components/PaginatedTable2";
import { useApiQuery } from "../../../hooks/useApiRequest";
import AdminLayout from "../../../layouts/AdminLayout";

const TradingFees = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const { data, isLoading, refetch } = useApiQuery({
    key: ["tradingfee"],
    endPoint: `/api/v1/trading/trading-fees?page=${currentPage}&limit=${pageSize}`,
    method: "GET",
  });

  useEffect(() => {
    // If the current page is invalid, reset to page 1
    if (data?.totalPages && currentPage > data.totalPages) {
      setCurrentPage(1);
    }
  }, [data?.totalPages]);

  const pagination = {
    totalItems: data?.totalItems || 0,
    totalPages: data?.totalPages || 1,
    currentPage,
    limit: pageSize,
    startIndex: (currentPage - 1) * pageSize + 1,
    endIndex: Math.min(currentPage * pageSize, data?.totalItems || 0),
  };

  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
    refetch();
  };

  const tradingFeesColumns = [
    {
      title: "Asset From",
      dataIndex: "asset",
      key: "asset",
      render: (text) => text?.toUpperCase() || "N/A",
    },
    {
      title: "Asset To",
      dataIndex: "assetTo",
      key: "assetTo",
      render: (text) => text?.toUpperCase() || "N/A",
    },
    {
      title: "Fees (%)",
      dataIndex: "fee",
      key: "fee",
      render: (fee) => `${parseFloat(fee?.$numberDecimal).toFixed(8)}%`,
    },
  ];

  return (
    <div className="mx-auto p-6 bg-white">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-semibold">Trading Fees</h1>
        <div className="text-xs text-gray-500">Dashboard &gt; Trading Fees</div>
      </div>

      <PaginatedTable2
        data={data?.results || []}
        columns={tradingFeesColumns}
        pagination={pagination}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageChange}
        loading={isLoading}
      />
    </div>
  );
};

export default TradingFees;
