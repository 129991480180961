import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom"; // Added Navigate for redirection
import { AuthProvider } from "./context/AuthContext";
import "./index.css";
import ClientIndex from "./pages/Clients/Index";
import Notification from "./pages/PushNotification/pushNotification";
import PrivateRoute from "./routes/PrivateRoute";
import PaymentTransaction from "./pages/Payment/index";
import AdminLayout from "./layouts/AdminLayout";
import ArbitrageBatches from "./pages/Arbitrage/ArbitrageBatches";
import TradingAssetIndex from "./pages/Assets/Index";
import NcwSupportedAssets from "./pages/NcwSupportedAssets/Index";
import BrokerApplications from "./pages/Brokers/BrokerApplications";
import BrokerCommissions from "./pages/Brokers/BrokerCommissions";
import BrokerOverview from "./pages/Brokers/BrokerOverview";
import BrokerTrades from "./pages/Brokers/BrokerTrades";
import ClientBalanceOverview from "./pages/Clients/ClientBalanceOverview";
import ClientBankAccount from "./pages/Clients/ClientBankAccounts";
import ClientUSDAccounts from "./pages/Clients/ClientUSDAccounts";
import ClientUser from "./pages/Clients/ClientUser";
import ClientWithdrawallAddresses from "./pages/Clients/ClientWithdrawallAddresses";
import ClientApiKeys from "./pages/Clients/Tabs/Client-key-tab";
import ComingSoon from "./pages/Dashboard/CominSoon";
import CryptoDeposits from "./pages/Deposits_Withdrawals/Crypto-deposits";
import CryptoWithdrawals from "./pages/Deposits_Withdrawals/Crypto-withdrawals";
import DepositLogs from "./pages/Deposits_Withdrawals/deposit-logs";
import Deposits from "./pages/Deposits_Withdrawals/Deposits";
import WithdrawalLogs from "./pages/Deposits_Withdrawals/withdrawal-logs";
import Withdrawals from "./pages/Deposits_Withdrawals/withdrawals";
import TwoFactorAuth from "./pages/Settings/Tabs/2FA";
//import AdminManagers from "./pages/Extras/AdminManagers";
import AdminManagers from "./pages/AdminManager/index";
import AuditLogs from "./pages/Extras/AuditLogs";
import News from "./pages/Extras/News";
// import Policies from "./pages/Extras/Policies";
import SettingsAPIKeys from "./pages/Extras/SettingsAPIKeys";
import SettingsContact from "./pages/Extras/SettingsContact";
import SettingsSystem from "./pages/Extras/SettingsSystem";
import PoliciesPage from "./pages/Policies";
//import ManageInvestments from "./pages/Investments/ManageInvestments";
import InvestmentApplications from "./pages/InvestmentApplications/Index";
import InvestmentProducts from "./pages/InvestmentProducts/Index";
import SubscriptionApproval from "./pages/Investments/SubscriptionApproval";
import UserSummary from "./pages/Investments/UserSummary";
// import Login from "./pages/Login/Login";
import AdjustLP from "./pages/OTC/AdjustLP";
import LiquidityPool from "./pages/OTC/LiquidityPool";
import LPAlert from "./pages/OTC/LPAlert";
import LPHistory from "./pages/OTC/LPHistory";
import TradingFees from "./pages/OTC/tradingFees/TradingFees";
import TransactionHistory from "./pages/OTC/transactionHistory/index";
import EditPolicy from "./pages/Policies/EditPolicy";
import ViewPolicy from "./pages/Policies/ViewPolicy";
import SettingPage from "./pages/Settings/Settings";
import UnauthorizedPage from "./pages/Unauthorized";
import EmailsTemplate from "./pages/Emails/Emails";
import VerifClients from "./pages/Clients/VerifClients";

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Redirect root path ("/") to "/dashboard" */}
          {/*<Route path="/" element={<Navigate to="/admin/dashboard" />} />*/}

          <Route
            path="/"
            element={
              <PrivateRoute>
                <Navigate to="/admin/dashboard" />
              </PrivateRoute>
            }
          />

          {/* Protected route for dashboard */}
          <Route
            path="/admin/dashboard"
            element={
              <PrivateRoute>
                {/* <Dashboard / > */}
                <ComingSoon />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/clients"
            element={
              <PrivateRoute>
                <ClientIndex />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/verif-clients"
            element={
              <PrivateRoute>
                <VerifClients />
              </PrivateRoute>
            }
          />

          <Route
            path="/admin/PushNotification"
            element={
              <PrivateRoute>
                <Notification />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/PaymentTransaction"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <PaymentTransaction />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/clientuser"
            element={
              <PrivateRoute>
                <ClientUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/clientbalanceoverview"
            element={
              <PrivateRoute>
                <ClientBalanceOverview />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/clientbankaccounts"
            element={
              <PrivateRoute>
                <ClientBankAccount />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/client-usd-bankaccounts"
            element={
              <PrivateRoute>
                <ClientUSDAccounts />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/client-withdrawall-addresses"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <ClientWithdrawallAddresses />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/client-api-keys"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <ClientApiKeys />
                </AdminLayout>
              </PrivateRoute>
            }
          />

          {/* Deposits Section */}

          <Route
            path="/admin/deposits"
            element={
              <PrivateRoute>
                <Deposits />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/withdrawals"
            element={
              <PrivateRoute>
                <Withdrawals />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/crypto-deposits"
            element={
              <PrivateRoute>
                <CryptoDeposits />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/crypto-withdrawals"
            element={
              <PrivateRoute>
                <CryptoWithdrawals />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/deposit-logs"
            element={
              <PrivateRoute>
                <DepositLogs />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/withdrawal-logs"
            element={
              <PrivateRoute>
                <AdminLayout currentKey={"dw-sub6"} openKey="5">
                  <WithdrawalLogs />
                </AdminLayout>
              </PrivateRoute>
            }
          />

          {/* Arbitrage */}
          <Route
            path="/admin/arbitrage-batches"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <ArbitrageBatches />
                </AdminLayout>
              </PrivateRoute>
            }
          />

          {/* Liquidity Pools */}
          <Route
            path="/admin/liquidity-pool"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <LiquidityPool />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/update-pool"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <AdjustLP />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/lp-history"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <LPHistory />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/lp-alert"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <LPAlert />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/trading-fees"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <TradingFees />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/transaction-history"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <TransactionHistory />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          {/* Brokers Section */}
          <Route
            path="/admin/broker-applications"
            element={
              <PrivateRoute>
                <BrokerApplications />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/broker-overview"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <BrokerOverview />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/broker-trades"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <BrokerTrades />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/broker-commissions"
            element={
              <PrivateRoute>
                <BrokerCommissions />
              </PrivateRoute>
            }
          />

          {/* Investments Section */}
          <Route
            path="/admin/manage-investments"
            element={
              <PrivateRoute>
                <InvestmentProducts />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/subscription-approval"
            element={
              <PrivateRoute>
                <InvestmentApplications />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/user-summary"
            element={
              <PrivateRoute>
                <UserSummary />
              </PrivateRoute>
            }
          />

          {/* Assets Section */}

          <Route
            path="/admin/trading-assets"
            element={
              <PrivateRoute>
                <TradingAssetIndex />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/ncw-supported-assets"
            element={
              <PrivateRoute>
                <NcwSupportedAssets />
              </PrivateRoute>
            }
          />
          {/* Investments Section */}
          {/*<Route
            path="/admin/manage-investments"
            element={
              <PrivateRoute>
                <ManageInvestments />
              </PrivateRoute>
            }
          />*/}
          <Route
            path="/admin/subscription-approval"
            element={
              <PrivateRoute>
                <SubscriptionApproval />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/user-summary"
            element={
              <PrivateRoute>
                <UserSummary />
              </PrivateRoute>
            }
          />

          {/* News Section */}

          <Route
            path="/admin/all-news"
            element={
              <PrivateRoute>
                <News />
              </PrivateRoute>
            }
          />

          {/* Settings Section */}

          <Route
            path="/admin/settings-system"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <SettingsSystem />
                </AdminLayout>
              </PrivateRoute>
            }
          />

          <Route
            path="/admin/settings-contact"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <SettingsContact />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/settings-api-keys"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <SettingsAPIKeys />
                </AdminLayout>
              </PrivateRoute>
            }
          />

          {/* Emails */}

          <Route
            path="/admin/emails"
            element={
              <PrivateRoute>
                <AdminLayout currentKey={"12"} openKey="12">
                  <EmailsTemplate />
                </AdminLayout>
              </PrivateRoute>
            }
          />

          {/* Emails */}

          {/* <Route
            path="/admin/emails"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Login />
                </AdminLayout>
              </PrivateRoute>
            }
          /> */}

          {/* Audit Logs */}
          <Route
            path="/admin/audit-logs"
            element={
              <PrivateRoute>
                <AdminLayout currentKey={"17"} openKey="17">
                  <AuditLogs />
                </AdminLayout>
              </PrivateRoute>
            }
          />

          {/* Admin/Managers Section */}

          {/* <Route
            path="/admin/admin-managers"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <AdminManagers />
                </AdminLayout>
              </PrivateRoute>
            }
          /> */}

          <Route
            path="/admin/admin-users"
            element={
              <PrivateRoute>
                <AdminLayout currentKey={"15"} openKey="15">
                  <AdminManagers />
                </AdminLayout>
              </PrivateRoute>
            }
          />

          {/* Policies Section */}

          <Route
            path="/admin/policies"
            element={
              <PrivateRoute>
                <AdminLayout currentKey={"16"} openKey="16">
                  <PoliciesPage />
                </AdminLayout>
              </PrivateRoute>
            }
          />

          {/* Two Factor Authentication */}

          <Route
            path="/admin/2fa"
            element={
              <PrivateRoute>
                <AdminLayout currentKey={"18"} openKey="18">
                  <TwoFactorAuth />
                </AdminLayout>
              </PrivateRoute>
            }
          />

          <Route
            path="/admin/settings"
            element={
              <PrivateRoute>
                <AdminLayout currentKey={"13"} openKey="13">
                  <SettingPage />
                </AdminLayout>
              </PrivateRoute>
            }
          />

          <Route path="/unauthorized" element={<UnauthorizedPage />} />

          <Route
            path="/admin/policies/view/:id"
            element={
              <PrivateRoute>
                <AdminLayout currentKey={"16"} openKey="16">
                  <ViewPolicy />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/policies/edit/:id"
            element={
              <PrivateRoute>
                <AdminLayout currentKey={"16"} openKey="16">
                  <EditPolicy />
                </AdminLayout>
              </PrivateRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;
