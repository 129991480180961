import React, { useState } from "react";
import { Modal, Button, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useDeleteAdminManagerService } from "../../services/adminManagerService.js";

const Delete = ({ adminManager, onDeleteSuccess }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const { DeleteAdminManager } = useDeleteAdminManagerService();

  const handleDelete = () => {
    setLoading(true);
    DeleteAdminManager(
      adminManager.id,
      () => {
        onDeleteSuccess();
        setVisible(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  return (
    <>
      <Button
        type="text"
        icon={<DeleteOutlined />}
        style={{ padding: 0 }}
        onClick={() => setVisible(true)}
      />
      <Modal
        visible={visible}
        title="Delete Admin/Manager"
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            type="primary"
            danger
            onClick={handleDelete}
            loading={loading}
          >
            Delete
          </Button>,
        ]}
      >
        <p>
          Are you sure you want to delete <strong>{adminManager?.email}</strong>
          ?
        </p>
      </Modal>
    </>
  );
};

Delete.propTypes = {
  adminManager: PropTypes.object.isRequired,
  onDeleteSuccess: PropTypes.func.isRequired,
};

export default Delete;
