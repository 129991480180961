import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Select, Input, Button, Card, Typography, Alert } from 'antd';

const { Option } = Select;
const { Title, Text } = Typography;
const baseUrl = process.env.BASE_URL || 'http://localhost:3000'; // Update with your actual base URL if different

const AdjustLP = () => {
  const [assets, setAssets] = useState([]);
  const [status, setStatus] = useState(null);
  const [amount, setAmount] = useState('');
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const response = await axios.get('http://localhost:3000/api/v1/trading-assets/get-assets');
        setAssets(response.data.data);
      } catch (err) {
        setError('Failed to load assets');
      }
    };
    fetchAssets();
  }, []);

  const handleSubmit = async () => {
    if (!amount || !selectedAsset || !status) {
      setError('Please fill in all fields.');
      return;
    }
    setError(null);
    try {
      await axios.post('http://localhost:3000/api/v1/trading-assets/update-pool', {
        asset: selectedAsset,
        status,
        amount: parseFloat(amount),
      });
      setSuccess('Liquidity Pool updated successfully!');
      setAmount('');
      setSelectedAsset(null);
      setStatus(null);
    } catch (err) {
      setError('Failed to update Liquidity Pool');
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
      <Card style={{ width: '100%', maxWidth: '600px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
        <Title level={3} style={{ textAlign: 'center', marginBottom: '20px' }}>Update Liquidity Pool</Title>
        
        {error && <Alert message={error} type="error" showIcon style={{ marginBottom: '15px' }} />}
        {success && <Alert message={success} type="success" showIcon style={{ marginBottom: '15px' }} />}
        
        <Form layout="vertical" onFinish={handleSubmit}>
          <Form.Item label="Asset" style={{ marginBottom: '20px' }}>
            <Select
              placeholder="Select Asset"
              onChange={value => setSelectedAsset(value)}
              value={selectedAsset}
              style={{ width: '100%' }}
              size="large"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children[1].toLowerCase().includes(input.toLowerCase())
              }
            >
              {assets.map(asset => (
                <Option key={asset._id} value={asset.asset}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={`${baseUrl}/images/coins/${asset.image}`}
                      alt={asset.assetName}
                      style={{ width: '30px', height: '30px', marginRight: '10px', objectFit: 'cover', borderRadius: '50%' }}
                    />
                    {asset.assetName}
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          
          <Form.Item label="Status" style={{ marginBottom: '20px' }}>
            <Select
              placeholder="Select Action"
              onChange={value => setStatus(value)}
              value={status}
              style={{ width: '100%' }}
              size="large"
            >
              <Option value="add">Add to Pool</Option>
              <Option value="remove">Remove from Pool</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Amount to Add/Remove" style={{ marginBottom: '20px' }} required>
            <Input
              type="number"
              placeholder="Enter amount"
              value={amount}
              onChange={e => setAmount(e.target.value)}
              style={{ width: '100%' }}
              size="large"
            />
            {!amount && <Text type="danger">Amount cannot be blank.</Text>}
          </Form.Item>

          <Form.Item style={{ textAlign: 'center', marginTop: '25px' }}>
            <Button type="primary" htmlType="submit" style={{ width: '100%', height: '45px', fontSize: '16px', borderRadius: '5px' }}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default AdjustLP;
