import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select } from 'antd';
import React, { useState } from 'react';
import { useUpdateUserBankAccService } from '../../../../services/bankAccService';
import { useGetAllBankService } from '../../../../services/USDbankAccService';

const { Option } = Select;

const Edit = ({ data }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const { updateUserBankAcc } = useUpdateUserBankAccService();
    const { data: bankList, isLoading } = useGetAllBankService();

    const handleEdit = async () => {
        setLoading(true);

        const values = await form.validateFields();

        const payload = {
            _id: data?._id,
            customName: values.customName,
            AccHolderName: values.AccHolderName,
            bankName: values.bankName,
            accNumber: values.accNumber,
            branchCode: values.branchCode,
            accType: values.accType,
            bankId: values.bankId,
        };

        updateUserBankAcc(
            payload,
            () => {
                setLoading(false);
                toggleView();
            },
            () => setLoading(false)
        );
    };

    const handleBankChange = (value) => {
        const selectedBank = bankList?.find((bank) => bank._id === value);

        if (selectedBank) {
            form.setFieldsValue({
                bankName: selectedBank.name,
                branchCode: selectedBank.branchCode,
                bankId: selectedBank._id,
            });
        }
    };

    const toggleView = () => {
        setVisible(!visible);
    };

    if (!visible) {
        return (
            <Button
                type="text"
                icon={<EditOutlined />}
                style={{
                    padding: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                onClick={toggleView}
            />
        );
    }

    return (
        <Modal
            visible={visible}
            title="Edit Bank Account"
            onCancel={toggleView}
            footer={[
                <Button key="cancel" onClick={toggleView}>
                    Cancel
                </Button>,
                <Button key="save" type="primary" onClick={handleEdit} loading={loading}>
                    Save
                </Button>,
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    customName: data?.customName,
                    AccHolderName: data?.AccHolderName,
                    bankName: data?.bankName,
                    accNumber: data?.accNumber,
                    branchCode: data?.branchCode,
                    accType: data?.accType,
                    bankId: data?.bankId,
                    bankSelect: data?.bankId,
                }}
            >
                <Form.Item
                    name="customName"
                    label="Custom Name"
                    rules={[{ required: true, message: 'Please enter a custom name' }]}
                >
                    <Input placeholder="Enter custom name" />
                </Form.Item>

                <Form.Item
                    name="AccHolderName"
                    label="Account Holder Name"
                    rules={[{ required: true, message: 'Please enter account holder name' }]}
                >
                    <Input placeholder="Enter account holder name" />
                </Form.Item>

                <Form.Item
                    name="bankSelect"
                    label="Select Bank"
                // rules={[{ required: true, message: 'Please select a bank' }]}
                >
                    <Select
                        placeholder="Choose a bank"
                        onChange={handleBankChange}
                        loading={isLoading}
                    >
                        {bankList?.map((bank) => (
                            <Option key={bank._id} value={bank._id}>
                                {bank.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="bankName"
                    label="Bank Name"
                    rules={[{ required: true, message: 'Please enter bank name' }]}
                >
                    <Input placeholder="Bank name" disabled />
                </Form.Item>

                <Form.Item
                    name="branchCode"
                    label="Branch Code"
                    rules={[{ required: true, message: 'Please enter branch code' }]}
                >
                    <Input placeholder="Branch code" disabled />
                </Form.Item>

                <Form.Item
                    name="accNumber"
                    label="Account Number"
                    rules={[
                        { required: true, message: 'Please enter account number' },
                        { pattern: /^\d+$/, message: 'Account number must be numeric' },
                    ]}
                >
                    <Input placeholder="Enter account number" />
                </Form.Item>

                <Form.Item
                    name="accType"
                    label="Account Type"
                    rules={[{ required: true, message: 'Please select an account type' }]}
                >
                    <Select placeholder="Select account type">
                        <Option value="Saving">Saving</Option>
                        <Option value="Cheque">Cheque</Option>
                    </Select>
                </Form.Item>

                {/* Hidden field for bankId */}
                <Form.Item name="bankId" hidden>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Edit;



// import { EditOutlined } from '@ant-design/icons';
// import { Button, Form, Input, Modal, Select } from 'antd';
// import React, { useState } from 'react';
// import { useUpdateUserBankAccService } from '../../../../services/bankAccService';

// const { Option } = Select;

// const Edit = ({ data }) => {
//     const [form] = Form.useForm();
//     const [loading, setLoading] = useState(false);
//     const [visible, setVisible] = useState(false);

//     const { updateUserBankAcc } = useUpdateUserBankAccService();

//     const handleEdit = async () => {
//         setLoading(true);

//         const values = await form.validateFields();

//         const payload = {
//             _id: data?._id,
//             customName: values.customName,
//             AccHolderName: values.AccHolderName,
//             bankName: values.bankName,
//             accNumber: values.accNumber,
//             branchCode: values.branchCode,
//             accType: values.accType
//         };

//         updateUserBankAcc(
//             payload,
//             () => {
//                 setLoading(false);
//                 toggleView()
//             },
//             () => setLoading(false)
//         );
//     };

//     const toggleView = () => {
//         setVisible(!visible);
//     };

//     if (!visible) {
//         return (
//             <Button
//                 type="text"
//                 icon={<EditOutlined />}
//                 style={{
//                     padding: 0,
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                 }}
//                 onClick={toggleView}
//             />
//         );
//     }

//     return (
//         <Modal
//             visible={visible}
//             title="Edit Bank Account"
//             onCancel={toggleView}
//             footer={[
//                 <Button key="cancel" onClick={toggleView}>
//                     Cancel
//                 </Button>,
//                 <Button
//                     key="save"
//                     type="primary"
//                     onClick={handleEdit}
//                     loading={loading}
//                 >
//                     Save
//                 </Button>,
//             ]}>
//             <Form
//                 form={form}
//                 layout="vertical"
//                 initialValues={{
//                     customName: data?.customName,
//                     AccHolderName: data?.AccHolderName,
//                     bankName: data?.bankName,
//                     accNumber: data?.accNumber,
//                     branchCode: data?.branchCode,
//                     accType: data?.accType,
//                 }}>
//                 <Form.Item
//                     name="customName"
//                     label="Custom Name"
//                     rules={[{ required: true, message: 'Please enter a custom name' }]}
//                 >
//                     <Input
//                         style={{ width: '100%' }}
//                         placeholder="Enter custom name"
//                     />
//                 </Form.Item>

//                 <Form.Item
//                     name="AccHolderName"
//                     label="Account Holder Name"
//                     rules={[{ required: true, message: 'Please enter account holder name' }]}
//                 >
//                     <Input
//                         style={{ width: '100%' }}
//                         placeholder="Enter account holder name"
//                     />
//                 </Form.Item>

//                 <Form.Item
//                     name="bankName"
//                     label="Bank Name"
//                     rules={[{ required: true, message: 'Please enter bank name' }]}
//                 >
//                     <Input
//                         style={{ width: '100%' }}
//                         placeholder="Enter bank name"
//                     />
//                 </Form.Item>

//                 <Form.Item
//                     name="accNumber"
//                     label="Account Number"
//                     rules={[
//                         { required: true, message: 'Please enter account number' },
//                         { pattern: /^\d+$/, message: 'Account number must be numeric' },
//                     ]}>
//                     <Input
//                         style={{ width: '100%' }}
//                         placeholder="Enter account number"
//                     />
//                 </Form.Item>

//                 <Form.Item
//                     name="branchCode"
//                     label="Branch Code"
//                     rules={[{ required: true, message: 'Please enter branch code' }]}
//                 >
//                     <Input
//                         style={{ width: '100%' }}
//                         placeholder="Enter branch code"
//                     />
//                 </Form.Item>

//                 <Form.Item
//                     name="accType"
//                     label="Account Type"
//                     rules={[{ required: true, message: 'Please select an account type' }]}
//                 >
//                     <Select
//                         showSearch
//                         placeholder="Select account type"
//                         optionFilterProp="children"
//                         filterOption={(input, option) =>
//                             option.children.toLowerCase().includes(input.toLowerCase())
//                         }>
//                         <Option value="Saving">Saving</Option>
//                         <Option value="Cheque">Cheque</Option>
//                     </Select>
//                 </Form.Item>
//             </Form>
//         </Modal>

//     );
// };

// export default Edit;

