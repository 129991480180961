import React, { useState } from "react";
import { Modal, Button, Space, Steps, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
//import FAQForm from "../../models/FAQ";
import { create } from "../../../../services/apiService";
import FAQForm from "../../../../models/FAQ";

const { Step } = Steps;

const Create = ({productId, onCreateSuccess}) => {
  const [visible, setVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(1); // Track current step
  const [formData, setFormData] = useState({}); // Store form data

  const toggleForm = () => {
    setVisible(!visible);
  };

  const handleNextStep = (data) => {
    setFormData((prevData) => ({
      ...prevData,
      ...data,
    }));
    setCurrentStep(currentStep + 1); // Move to next step
  };

  const onPrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1); // Go back to previous step
    }
  };

  const handleFinalSubmit = async (values) => {
    
    values['productId'] = productId;
    console.log(values);

    try {
      
      const response = await create('/api/v1/investment-product/add-faqs', values);

      if (response.status === 201) {
        message.success("FAQs added succesfully!");
        onCreateSuccess(Math.random());
        toggleForm();
        //onNextStep(values); // Pass form data to the next step
      } else {
        message.error(`Could not create faqs : ${response.data.message}`);
      }
    } catch (error) {
		console.log(error);
      message.error("An error occurred during submission.");
    }
    
  };


  if (!visible) {
    return (
      <div className="flex justify-end items-right mb-6">
        <Space>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={toggleForm}
            style={{ backgroundColor: '#22c6ab', borderColor: '#22c6ab' }}
          >
            Add FAQ
          </Button>
        </Space>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto mt-8">
      <Modal
        title="Create FAQs"
        open={visible}
        onCancel={toggleForm}
        footer={null}
        width={1200}
        destroyOnClose
        style={{ maxHeight: '70vh', overflowY: 'auto' }}
      >
        
        
        <FAQForm
          onSubmit={handleFinalSubmit}
          onPrevious={onPrevious}
          previousData={formData}
        />
      </Modal>
    </div>
  );
};

export default Create;
