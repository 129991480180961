import { Space, Table } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetAllAPIKey } from '../../../../services/clientKeyService';
import { formatDate } from '../../../../utils/formateData';
import Add from './Add';
import Delete from './Delete';

const ClientKeyTab = ({ userID }) => {
  const { data, isLoading, error, } = useGetAllAPIKey(userID)
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  const columns = [
    { title: "API Name", dataIndex: "APIName", key: "APIName" },
    { title: "API Key", dataIndex: "APIKey", key: "APIKey" },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => formatDate(text),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => {
        return (
          <Space size="middle">
            <Link>  <Delete record={record} /> </Link>
          </Space>
        )
      },
    }
  ];

  return (
    <>
      <div className="flex justify-between items-center mb-6">
        <button
          className="px-4 rounded-lg py-2 bg-[#22c6ab] text-white hover:bg-[#1da089] transition"
          onClick={() => setIsAddModalVisible(true)}
        >
          + Add Client Key
        </button>
      </div>

      <Table
        dataSource={data}
        columns={columns}
        loading={isLoading}
        pagination={false}
        rowKey={(record) => record.id || record._id || record.key}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        scroll={{ x: 'max-content' }}
      />

      {/* Modals */}
      {isAddModalVisible && (
        <Add
          userId={userID}
          visible={isAddModalVisible}
          onCancel={() => setIsAddModalVisible(false)}
          onSubmit={() => { setIsAddModalVisible(false) }}
        />
      )}
    </>
  )
}

export default ClientKeyTab;