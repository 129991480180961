// src/pages/Clients/ClientBankAccounts.jsx

import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import AdminLayout from '../../layouts/AdminLayout';
import PaginatedTable from '../../components/PaginatedTable';
import { debounce } from 'lodash';
import { message, Breadcrumb, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons'; // Importing required icon
import AddBankModal from '../../Modals/ClientBankAccounts/AddBankModal';
import EditModal from '../../Modals/ClientBankAccounts/EditModal';
import DeleteModal from '../../Modals/ClientBankAccounts/DeleteModal';
import ViewModal from '../../Modals/ClientBankAccounts/ViewModal';

const ClientBankAccounts = () => {
    const [allBankAccounts, setAllBankAccounts] = useState([]); // Store all bank accounts
    const [filteredBankAccounts, setFilteredBankAccounts] = useState([]); // Store filtered bank accounts
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Modal state variables
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [isViewModalVisible, setIsViewModalVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);

    const fetchBankAccounts = async () => {
        setLoading(true);
        try {
            const response = await axios.get('http://localhost:3000/api/v1/clients/getbanks');
            if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
                const processedData = [];
                response.data.data.forEach((account) => {
                    const userInfo = account['User Info'] || {};
                    const wallets = userInfo.wallets || [{}];

                    wallets.forEach((wallet) => {
                        processedData.push({
                            username: account.username || 'N/A',
                            email: account.email || 'N/A',
                            fullName: `${userInfo.personal_info?.legal_name || ''} ${userInfo.personal_info?.legal_lastname || ''}`.trim(),
                            idNumber: account.personal_info?.identity_number || 'N/A',
                            cellphoneNumber: userInfo.personal_info?.phoneNumber || 'N/A',
                            broker: userInfo.broker_info?.broker_code || 'N/A',
                            signUpDate: userInfo.createdAt || 'N/A',
                            recipientName: account.recipientName || 'N/A',
                            bank: account.bank || 'N/A',
                            accountNumber: account.accountNumber || 'N/A',
                            accountType: account.accountType || 'N/A',
                            date: account.date || 'N/A',
                            walletId: wallet.walletId || 'N/A',
                            walletType: wallet.walletType || 'N/A',
                            tag: wallet.tag || 'N/A',
                        });
                    });
                });
                setAllBankAccounts(processedData);
                setFilteredBankAccounts(processedData); // Initialize filteredBankAccounts with all data
            } else {
                throw new Error("Data is not in expected format");
            }
        } catch (err) {
            setError('Failed to fetch data: ' + err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBankAccounts();
    }, []);

    const handleSearch = useCallback(
        debounce((filters) => {
            // Apply front-end filtering based on the filters object
            let filtered = allBankAccounts;

            // Global Search
            if (filters.globalSearch) {
                const searchText = filters.globalSearch.toLowerCase();
                filtered = filtered.filter((account) =>
                    JSON.stringify(account).toLowerCase().includes(searchText)
                );
            }

            // Column-specific Filters
            Object.keys(filters).forEach((key) => {
                if (key !== 'globalSearch') {
                    const value = filters[key].toLowerCase();
                    if (value) {
                        filtered = filtered.filter((account) => {
                            const itemValue = getNestedValue(account, key);
                            return (
                                itemValue &&
                                itemValue.toString().toLowerCase().includes(value)
                            );
                        });
                    }
                }
            });

            setFilteredBankAccounts(filtered);
        }, 300),
        [allBankAccounts]
    );

    // Utility function to get nested values
    const getNestedValue = (obj, path) => {
        return path
            .split('.')
            .reduce((o, p) => (o && o[p] !== undefined && o[p] !== null ? o[p] : ''), obj);
    };

    // Modal handlers
    const handleAddBank = () => {
        setIsAddModalVisible(true);
    };

    const handleEdit = (record) => {
        setSelectedRecord(record);
        setIsEditModalVisible(true);
    };

    const handleDelete = (record) => {
        setSelectedRecord(record);
        setIsDeleteModalVisible(true);
    };

    const handleDeleteUser = async (userId) => {
        try {
            await axios.delete(`http://localhost:3000/api/v1/clients/delete/${userId}`);
            message.success('User deleted successfully');
            fetchBankAccounts();
            setIsDeleteModalVisible(false);
        } catch (error) {
            message.error(error.response?.data?.message || 'Failed to delete user');
        }
    };

    const handleView = (record) => {
        setSelectedRecord(record);
        setIsViewModalVisible(true);
    };

    // Function to convert JSON data to CSV
    const convertToCSV = (data) => {
        const headers = [
            'Username',
            'Email',
            'Full Name',
            'ID Number',
            'Cellphone Number',
            'Broker Code',
            'Sign Up Date',
            'Recipient Name',
            'Bank',
            'Account Number',
            'Account Type',
            'Date',
            'Wallet ID',
            'Wallet Type',
            'Tag',
        ];

        const rows = data.map((item) => [
            item.username || '',
            item.email || '',
            item.fullName || '',
            item.idNumber || '',
            item.cellphoneNumber || '',
            item.broker || '',
            formatDate(item.signUpDate) || '',
            item.recipientName || '',
            item.bank || '',
            item.accountNumber || '',
            item.accountType || '',
            formatDate(item.date) || '',
            item.walletId || '',
            item.walletType || '',
            item.tag || '',
        ]);

        const csvContent = [headers, ...rows]
            .map((row) =>
                row
                    .map((field) => `"${String(field).replace(/"/g, '""')}"`)
                    .join(',')
            )
            .join('\n');

        return csvContent;
    };

    const handleExport = () => {
        try {
            if (filteredBankAccounts.length === 0) {
                message.warning('No bank accounts available to export');
                return;
            }

            const csvData = convertToCSV(filteredBankAccounts);
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'bank_accounts_export.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            message.success('Exported bank accounts data successfully');
        } catch (error) {
            message.error('Failed to export bank accounts');
        }
    };

    // Function to format date as "07 November 2024, 07:30"
    const formatDate = (dateString) => {
        if (!dateString || dateString === 'N/A') return 'N/A';
        const date = new Date(dateString);
        if (isNaN(date)) return 'Invalid Date';
        const day = String(date.getDate()).padStart(2, '0');
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day} ${month} ${year}, ${hours}:${minutes}`;
    };

    const columns = [
        { title: 'Username', dataIndex: 'username', key: 'username' },
        { title: 'Email', dataIndex: 'email', key: 'email' },
        { title: 'Full Name', dataIndex: 'fullName', key: 'fullName' },
        { title: 'ID Number', dataIndex: 'idNumber', key: 'idNumber' },
        { title: 'Cellphone Number', dataIndex: 'cellphoneNumber', key: 'cellphoneNumber' },
        { title: 'Broker Code', dataIndex: 'broker', key: 'broker' },
        { 
            title: 'Sign Up Date',
            dataIndex: 'signUpDate',
            key: 'signUpDate',
            render: (text) => formatDate(text),
        },
        { title: 'Recipient Name', dataIndex: 'recipientName', key: 'recipientName' },
        { title: 'Bank', dataIndex: 'bank', key: 'bank' },
        { title: 'Account Number', dataIndex: 'accountNumber', key: 'accountNumber' },
        { title: 'Account Type', dataIndex: 'accountType', key: 'accountType' },
        { 
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text) => formatDate(text),
        },
        { title: 'Wallet ID', dataIndex: 'walletId', key: 'walletId' },
        { title: 'Wallet Type', dataIndex: 'walletType', key: 'walletType' },
        { title: 'Tag', dataIndex: 'tag', key: 'tag' },
    ];

    if (error) {
        return (
            <AdminLayout>
                <div className="mx-auto p-6 bg-white">
                    <div className="text-red-500">Error: {error}</div>
                </div>
            </AdminLayout>
        );
    }

    return (
        <AdminLayout currentKey={'sub5'} openKey='4'>
            <div className="mx-auto p-6 bg-white shadow rounded-lg">
                <Breadcrumb style={{ marginBottom: '10px' }}>
                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item>Clients</Breadcrumb.Item>
                    <Breadcrumb.Item>Client Bank Accounts</Breadcrumb.Item>
                </Breadcrumb>
                <h1 className="text-2xl font-semibold mb-6">Client Bank Accounts</h1>
                <div className="flex justify-between items-center mb-6">
                    <button
                        className="flex items-center px-4 rounded-lg py-2 bg-[#22c6ab] text-white hover:bg-[#1da089] transition"
                        onClick={handleAddBank}
                    >
                        + Add Bank to User
                    </button>
                    <Button
                        type="primary"
                        icon={<DownloadOutlined />}
                        onClick={handleExport}
                        className="flex items-center px-4 py-2 bg-[#57249c] text-white rounded-lg hover:bg-[#432076] transition"
                    >
                        Export (csv)
                    </Button>
                </div>
                <PaginatedTable
                    columns={columns}
                    itemsPerPage={15}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    onView={handleView}
                    searchFilters={null} // Not used since filtering is handled internally
                    onSearch={handleSearch}
                    dataSource={filteredBankAccounts}
                    isSearchable={true} // Ensure search is enabled
                    loading={loading}
                />
                {/* Modals */}
                {isAddModalVisible && (
                    <AddBankModal
                        visible={isAddModalVisible}
                        onCancel={() => setIsAddModalVisible(false)}
                        onSubmit={() => {
                            fetchBankAccounts();
                            setIsAddModalVisible(false);
                        }}
                    />
                )}

                {isEditModalVisible && (
                    <EditModal
                        visible={isEditModalVisible}
                        onCancel={() => setIsEditModalVisible(false)}
                        data={selectedRecord}
                        onSubmit={() => {
                            fetchBankAccounts();
                            setIsEditModalVisible(false);
                        }}
                    />
                )}
                {isDeleteModalVisible && (
                    <DeleteModal
                        visible={isDeleteModalVisible}
                        onCancel={() => setIsDeleteModalVisible(false)}
                        clientId={selectedRecord?.userInfo?.user_id}
                        userName={`${selectedRecord?.userInfo?.personal_info?.legal_name || ''} ${selectedRecord?.userInfo?.personal_info?.legal_lastname || ''}`}
                        onDelete={handleDeleteUser}
                    />
                )}
                {isViewModalVisible && (
                    <ViewModal
                        visible={isViewModalVisible}
                        onCancel={() => setIsViewModalVisible(false)}
                        data={selectedRecord}
                    />
                )}
            </div>
        </AdminLayout>
    );
};

export default ClientBankAccounts;
