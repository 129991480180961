import { EditOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, message, Switch } from "antd";
import React, { useState } from "react";
import { UpdateEmailTemplateService } from "../../services/emailsService";

const Edit = ({ data }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const { UpdateEmailTemplate } = UpdateEmailTemplateService();
  const [isActive, setIsActive] = useState(data?.isActive || false);

  const handleEdit = async () => {
    try {
      setLoading(true);

      const values = await form.validateFields();

      const payload = {
        templateName: values.templateName,
        templateId: values.templateId,
        isActive,
      };

      await UpdateEmailTemplate(
        data?._id,
        payload,
        () => {
          setLoading(false);
          toggleView();
        },
        () => {
          setLoading(false);
        }
      );
    } catch (error) {
      setLoading(false);
    }
  };

  const toggleView = () => {
    setVisible(!visible);
  };

  return (
    <>
      <Button
        type="text"
        icon={<EditOutlined />}
        style={{
          padding: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={toggleView}
      />
      <Modal
        open={visible}
        title="Edit Email Template"
        onCancel={toggleView}
        footer={[
          <Button key="cancel" onClick={toggleView}>
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={handleEdit}
            loading={loading}
          >
            Save
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            templateName: data?.templateName,
            templateId: data?.templateId,
          }}
        >
          <Form.Item
            name="templateName"
            label="Template Name"
            rules={[
              { required: true, message: "Please enter the template name" },
            ]}
          >
            <Input placeholder="Enter template name" />
          </Form.Item>

          <Form.Item
            name="templateId"
            label="Template ID"
            rules={[
              { required: true, message: "Please enter the template ID" },
            ]}
          >
            <Input placeholder="Enter template ID" />
          </Form.Item>

          {/* Switch for active/inactive status */}
          <Form.Item label="Status">
            <Switch
              checked={isActive}
              onChange={(checked) => setIsActive(checked)}
              checkedChildren="Active"
              unCheckedChildren="Inactive"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Edit;

// import { EditOutlined } from "@ant-design/icons";
// import { Button, Form, Input, Modal, message } from "antd";
// import React, { useState } from "react";
// import { UpdateEmailTemplateService } from "../../services/emailsService";

// const Edit = ({ data }) => {
//   const [form] = Form.useForm();
//   const [loading, setLoading] = useState(false);
//   const [visible, setVisible] = useState(false);
//   const { UpdateEmailTemplate } = UpdateEmailTemplateService();

//   const handleEdit = async () => {
//     try {
//       setLoading(true);

//       const values = await form.validateFields();

//       const payload = {
//         templateName: values.templateName,
//         templateId: values.templateId,
//       };

//       await UpdateEmailTemplate(
//         data?._id,
//         payload,
//         () => {
//           setLoading(false);
//           toggleView();
//         },
//         () => {
//           setLoading(false);
//         }
//       );
//     } catch (error) {
//       setLoading(false);
//     }
//   };

//   const toggleView = () => {
//     setVisible(!visible);
//   };

//   return (
//     <>
//       <Button
//         type="text"
//         icon={<EditOutlined />}
//         style={{
//           padding: 0,
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//         }}
//         onClick={toggleView}
//       />
//       <Modal
//         open={visible}
//         title="Edit Email Template"
//         onCancel={toggleView}
//         footer={[
//           <Button key="cancel" onClick={toggleView}>
//             Cancel
//           </Button>,
//           <Button
//             key="save"
//             type="primary"
//             onClick={handleEdit}
//             loading={loading}
//           >
//             Save
//           </Button>,
//         ]}
//       >
//         <Form
//           form={form}
//           layout="vertical"
//           initialValues={{
//             templateName: data?.templateName,
//             templateId: data?.templateId,
//           }}
//         >
//           <Form.Item
//             name="templateName"
//             label="Template Name"
//             rules={[
//               { required: true, message: "Please enter the template name" },
//             ]}
//           >
//             <Input placeholder="Enter template name" />
//           </Form.Item>

//           <Form.Item
//             name="templateId"
//             label="Template ID"
//             rules={[
//               { required: true, message: "Please enter the template ID" },
//             ]}
//           >
//             <Input placeholder="Enter template ID" />
//           </Form.Item>
//         </Form>
//       </Modal>
//     </>
//   );
// };

// export default Edit;
