import { DownloadOutlined } from '@ant-design/icons';
import { Button, message, Space } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PaginatedTable2 from '../../../components/PaginatedTable2';
import { useApiQuery } from '../../../hooks/useApiRequest';
import AdminLayout from '../../../layouts/AdminLayout';
import { useApprovedDepositService } from '../../../services/depositService';
import { formatDate } from '../../../utils/formateData';
import Add from './Add';
import Delete from './Delete';

const Index = () => {
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const { approveUserDeposit } = useApprovedDepositService();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [loadingStates, setLoadingStates] = useState({});

    const { data, isLoading } = useApiQuery({
        key: ['user-deposits'],
        endPoint: `/api/v1/user-deposits?page=${currentPage}&limit=${pageSize}`,
        method: 'GET',
    });

    const depositColumns = [
        {
            title: 'User',
            dataIndex: `user`,
            key: 'userId',
            render: (text, record) => `${record?.userId?.username} - ${record?.userId?.email} ` || "N/A"
        },
        {
            title: 'Reference',
            dataIndex: 'txid',
            key: 'txid',
            render: (text) => text || 'N/A'
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (date) => formatDate(date),
        },
        {
            title: 'Asset',
            dataIndex: 'asset',
            key: 'asset',
            render: (text) => text?.toUpperCase() || 'N/A',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount) => `R ${parseFloat(amount?.$numberDecimal).toFixed(8)}`,
        },
        {
            title: 'Fees',
            dataIndex: 'fees',
            key: 'fees',
            render: (fees) => `${parseFloat(fees?.$numberDecimal).toFixed(8)}`,

        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes',
            render: (text) => text || 'N/A'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status, record) => {
                const isApproveLoading = loadingStates[record._id]?.approve;
                if (status === 'Pending') {
                    return (
                        <button
                            className="px-3 py-1 text-sm font-semibold text-white bg-yellow-500 rounded hover:bg-yellow-600"
                            onClick={() => handleApprove(record)}
                            disabled={isApproveLoading}
                        >
                            {isApproveLoading ? "Loading..." : " Mark Completed"}
                        </button>
                    );
                }
                return <span className="text-sm font-semibold text-green-600">{status}</span>;
            },
        },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Link>
                            <Delete record={record} />
                        </Link>
                    </Space>
                )
            },
        }
    ];

    const pagination = {
        totalItems: data?.totalItems || 0,
        totalPages: data?.totalPages || 1,
        currentPage,
        limit: pageSize,
        startIndex: (currentPage - 1) * pageSize + 1,
        endIndex: Math.min(currentPage * pageSize, data?.totalItems || 0),
    };

    const setLoadingForRecord = (recordId, type, value) => {
        setLoadingStates((prev) => ({
            ...prev,
            [recordId]: {
                ...prev[recordId],
                [type]: value,
            },
        }));
    };

    const handleApprove = async (record) => {
        setLoadingForRecord(record._id, 'approve', true);
        approveUserDeposit(
            record?._id,
            () => setLoadingForRecord(record._id, 'approve', false),
            () => setLoadingForRecord(record._id, 'approve', false)
        )
    }

    const handlePageChange = (page, size) => {
        setCurrentPage(page);
        setPageSize(size);
    };

    const handleExport = () => {
        try {
            if (!data?.results?.length) {
                message.warning('No User Deposits available to export');
                return;
            }

            const headers = ['User Deposits', 'Created At', 'Data'];
            const rows = data?.results.map((item) => [
                item._id,
                new Date(item.createdAt).toLocaleString(),
                JSON.stringify(item.data, null, 2),
            ]);

            const csvContent = [headers, ...rows]
                .map((row) => row.map((field) => `"${String(field).replace(/"/g, '""')}"`).join(','))
                .join('\n');

            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'user_deposits_export.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            message.success('Exported User Deposits successfully');
        } catch (error) {
            message.error('Failed to export User Deposits');
        }
    };

    return (
        <AdminLayout currentKey={'dw-sub1'} openKey='5'>
            <div className="mx-auto p-6 bg-white">

                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-xl font-semibold">Client Deposits</h1>
                    <div className="text-xs text-gray-500">
                        Dashboard &gt; Deposits/Withdrawals &gt; Deposits
                    </div>
                </div>

                <div className="flex justify-between items-center mb-6">
                    <button
                        className="px-4 rounded-lg py-2 bg-[#22c6ab] text-white hover:bg-[#1da089] transition"
                        onClick={() => setIsAddModalVisible(true)}
                    >
                        + Add Deposit
                    </button>
                    {/* <Button
                        type="primary"
                        icon={<DownloadOutlined />}
                        onClick={handleExport}
                        className="flex items-center px-4 py-2 bg-[#57249c] text-white rounded-lg hover:bg-[#432076] transition"
                    >
                        Export (csv)
                    </Button> */}
                </div>

                <PaginatedTable2
                    data={data?.results}
                    columns={depositColumns}
                    pagination={pagination}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageChange}
                    loading={isLoading}
                />

                {/* Modals */}
                {isAddModalVisible && (
                    <Add
                        visible={isAddModalVisible}
                        onCancel={() => setIsAddModalVisible(false)}
                        onSubmit={() => { setIsAddModalVisible(false) }}
                    />
                )}
            </div>
        </AdminLayout>
    );
};

export default Index;




// import { DownloadOutlined } from '@ant-design/icons'; // Importing required icon
// import { Button, message, Space } from 'antd';
// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import PaginatedTable from '../../../components/PaginatedTable';
// import AdminLayout from '../../../layouts/AdminLayout';
// import { useGetAllDeposits, useUpdateUserDepositService } from '../../../services/depositService';
// import { formatDate } from '../../../utils/formateData';
// import Add from './Add';
// import Delete from './Delete';

// const Index = () => {
//     const [isAddModalVisible, setIsAddModalVisible] = useState(false);
//     const { updateUserDeposit } = useUpdateUserDepositService();
//     const { data } = useGetAllDeposits()

//     const depositColumns = [
//         {
//             title: 'User',
//             dataIndex: `user`,
//             key: 'userId',
//             render: (text, record) => `${record?.userId?.username} - ${record?.userId?.email} ` || "N/A"
//         },
//         {
//             title: 'Reference',
//             dataIndex: 'txid',
//             key: 'txid',
//             render: (text) => text || 'N/A'
//         },
//         {
//             title: 'Date',
//             dataIndex: 'date',
//             key: 'date',
//             render: (date) => formatDate(date),
//         },
//         {
//             title: 'Asset',
//             dataIndex: 'asset',
//             key: 'asset',
//             render: (text) => text?.toUpperCase() || 'N/A',
//         },
//         {
//             title: 'Amount',
//             dataIndex: 'amount',
//             key: 'amount',
//             render: (amount) => `R ${parseFloat(amount?.$numberDecimal).toFixed(8)}`,
//         },
//         {
//             title: 'Fees',
//             dataIndex: 'fees',
//             key: 'fees',
//             render: (fees) => `${parseFloat(fees?.$numberDecimal).toFixed(8)}`,

//         },
//         {
//             title: 'Notes',
//             dataIndex: 'notes',
//             key: 'notes',
//             render: (text) => text || 'N/A'
//         },
//         {
//             title: 'Status',
//             dataIndex: 'status',
//             key: 'status',
//             render: (status, record) => {
//                 if (status === 'Pending') {
//                     return (
//                         <button
//                             className="px-3 py-1 text-sm font-semibold text-white bg-yellow-500 rounded hover:bg-yellow-600"
//                             onClick={() => handleEditStatus(record)}
//                         >
//                             Mark Completed
//                         </button>
//                     );
//                 }
//                 return <span className="text-sm font-semibold text-green-600">{status}</span>;
//             },
//         },
//         {
//             title: "Actions",
//             key: "actions",
//             render: (text, record) => {
//                 return (
//                     <Space size="middle">
//                         <Link>
//                             <Delete record={record} />
//                         </Link>
//                     </Space>
//                 )
//             },
//         }
//     ];

//     const handleEditStatus = async (record) => {
//         const payload = {
//             status: "Approved",
//         }

//         updateUserDeposit(
//             payload,
//             record?._id,
//             () => console.log("successfully updated"),
//             () => console.log(" Failed to update")
//         )
//     }

//     const handleExport = () => {
//         try {
//             if (!data?.results?.length) {
//                 message.warning('No Deposits available to export');
//                 return;
//             }

//             const headers = ['Deposits ID', 'Created At', 'Data'];
//             const rows = data.results.map((item) => [
//                 item._id,
//                 new Date(item.createdAt).toLocaleString(),
//                 JSON.stringify(item.data, null, 2),
//             ]);

//             const csvContent = [headers, ...rows]
//                 .map((row) => row.map((field) => `"${String(field).replace(/"/g, '""')}"`).join(','))
//                 .join('\n');

//             const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
//             const url = URL.createObjectURL(blob);
//             const link = document.createElement('a');
//             link.href = url;
//             link.setAttribute('download', 'deposits_export.csv');
//             document.body.appendChild(link);
//             link.click();
//             document.body.removeChild(link);

//             message.success('Exported Deposits successfully');
//         } catch (error) {
//             message.error('Failed to export deposits');
//         }
//     };

//     const filters = [
//         { name: "record.userId.username", label: "User", type: "text" },
//         { name: "txid", label: "Reference", type: "text" },
//         { name: "date", label: "Date", type: "text" },
//         { name: "asset", label: "Assets", type: "text" },
//         { name: "amount", label: "Amount", type: "text" },
//         { name: "fees", label: "Fess", type: "text" },
//         { name: "status", label: "Status", type: "text" },
//         { name: "notes", label: "Notes", type: "text" },
//     ];

//     return (
//         <AdminLayout currentKey={'dw-sub1'} openKey='5'>
//             <div className="mx-auto p-6 bg-white">

//                 <div className="flex justify-between items-center mb-4">
//                     <h1 className="text-xl font-semibold">Client Deposits</h1>
//                     <div className="text-xs text-gray-500">
//                         Dashboard &gt; Deposits/Withdrawals &gt; Deposits
//                     </div>
//                 </div>

//                 <div className="flex justify-between items-center mb-6">
//                     <button
//                         className="px-4 rounded-lg py-2 bg-[#22c6ab] text-white hover:bg-[#1da089] transition"
//                         onClick={() => setIsAddModalVisible(true)}
//                     >
//                         + Add Deposit
//                     </button>
//                     <Button
//                         type="primary"
//                         icon={<DownloadOutlined />}
//                         onClick={handleExport}
//                         className="flex items-center px-4 py-2 bg-[#57249c] text-white rounded-lg hover:bg-[#432076] transition"
//                     >
//                         Export (csv)
//                     </Button>
//                 </div>

//                 <PaginatedTable
//                     fetchEndpoint="/api/v1/user-deposits"
//                     columns={depositColumns}
//                     isSearchable={true}
//                     isSelectable={true}
//                     filters={filters}
//                 />

//                 {/* Modals */}
//                 {isAddModalVisible && (
//                     <Add
//                         visible={isAddModalVisible}
//                         onCancel={() => setIsAddModalVisible(false)}
//                         onSubmit={() => { setIsAddModalVisible(false) }}
//                     />
//                 )}
//             </div>
//         </AdminLayout>
//     );
// };

// export default Index;