// AddBankModal.js

import React, { useState } from 'react';
import { Modal, Button, Form, Input, Select, message } from 'antd';
import axios from 'axios';

const { Option } = Select;

const AddBankModal = ({ visible, onCancel, onSubmit }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const handleOk = () => {
        form.validateFields()
            .then(async (values) => {
                setLoading(true);
                try {
                    const response = await axios.post('http://localhost:3000/api/v1/clients/add-bank-account', values);
                    if (response.status === 201) {
                        message.success('Bank account added successfully!');
                        onSubmit && onSubmit();
                        form.resetFields();
                    } else {
                        throw new Error('Failed to add bank account');
                    }
                } catch (error) {
                    message.error('Failed to add bank account');
                } finally {
                    setLoading(false);
                }
            })
            .catch((info) => {
                console.log('Validation Failed:', info);
            });
    };

    return (
        <Modal
            visible={visible}
            title="Add Bank to User"
            onCancel={onCancel}
            onOk={handleOk}
            confirmLoading={loading}
            okText="Add Bank Account"
            cancelText="Cancel"
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="userId"
                    label="User ID"
                    rules={[{ required: true, message: 'Please enter the User ID' }]}
                >
                    <Input placeholder="Enter User ID" />
                </Form.Item>
                <Form.Item
                    name="recipientName"
                    label="Recipient Name"
                    rules={[{ required: true, message: 'Please enter the Recipient Name' }]}
                >
                    <Input placeholder="Enter Recipient Name" />
                </Form.Item>
                <Form.Item
                    name="bank"
                    label="Bank"
                    rules={[{ required: true, message: 'Please select the Bank' }]}
                >
                    <Select placeholder="Select Bank">
                        <Option value="Bank A">Bank A</Option>
                        <Option value="Bank B">Bank B</Option>
                        {/* Add more bank options as needed */}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="accountNumber"
                    label="Account Number"
                    rules={[{ required: true, message: 'Please enter the Account Number' }]}
                >
                    <Input placeholder="Enter Account Number" />
                </Form.Item>
                <Form.Item
                    name="accountType"
                    label="Account Type"
                    rules={[{ required: true, message: 'Please select the Account Type' }]}
                >
                    <Select placeholder="Select Account Type">
                        <Option value="Savings">Savings</Option>
                        <Option value="Cheque">Cheque</Option>
                        {/* Add more account types as needed */}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddBankModal;
