import { axiosInstance } from "../services/apiService";

export const fetcher = async (url, method = "GET", requestData) => {
    try {
        const config = {
            method,
            url,
            ...(requestData && { data: requestData }),
        };

        const response = await axiosInstance(config);

        if (response.data && response.data.status) {
            return response.data.data;
        }
    } catch (error) {

        if (error?.response?.data?.message) {
            throw new Error(error.response.data.message);

        } else if (error?.response?.data?.error) {
            throw new Error(error.response.data.error);

        } else if (error) {
            throw new Error(error);

        } else {
            throw new Error("An unknown error occurred");
        }
    }
};
