import { EditOutlined } from "@ant-design/icons";
import { Button, Form, Input, InputNumber, Modal, Select } from "antd";
import React, { useState } from "react";
import AdminLayout from "../../layouts/AdminLayout";
import { useGetAllInvestApplication } from "../../services/investApplicationService";
import { UpdateInvestmentApplicationStatusService } from "../../services/investApplicationService";

const { Option } = Select;
const { TextArea } = Input;

const Edit = ({ data }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const { updateInvestmentApplication } =
    UpdateInvestmentApplicationStatusService();
  const {
    data: assets,
    error: errorAsset,
    isLoading: loadingAsset,
  } = useGetAllInvestApplication("type=Fiat");
  const {
    data: users,
    error: errorUser,
    isLoading: loadingUser,
  } = useGetAllInvestApplication();

  const handleEdit = async () => {
    setLoading(true);

    const values = await form.validateFields();

    const payload = {
      // userId: values.user,
      asset: values.asset,
      amount: values.amount,
      txid: values.txid,
      notes: values.notes,
      fees: values.fees,
    };

    updateInvestmentApplication(
      payload,
      data?._id,
      () => {
        setLoading(false);
        toggleView();
      },
      () => {
        setLoading(false);
      }
    );
  };

  const toggleView = () => {
    setVisible(!visible);
  };

  if (!visible) {
    return (
      <Button
        type="text"
        icon={<EditOutlined />}
        style={{
          padding: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={toggleView}
      />
    );
  }

  if (errorUser && errorAsset) {
    return (
      <AdminLayout>
        <div className="mx-auto p-6 bg-white">
          <div className="text-red-500">
            Error Couldn't find assets or users{" "}
          </div>
        </div>
      </AdminLayout>
    );
  }

  if (loadingUser && loadingAsset) {
    return (
      <AdminLayout>
        <div className="mx-auto p-6 bg-white">
          <div className="text-blue-500">Loading...</div>
        </div>
      </AdminLayout>
    );
  }

  return (
    <Modal
      open={visible}
      title="Edit Withdrawal"
      onCancel={toggleView}
      footer={[
        <Button key="cancel" onClick={toggleView}>
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={handleEdit}
          loading={loading}
        >
          Save
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          asset: data?.asset,
          notes: data?.notes,
          user: data?.userId?.email,
          txid: data?.txid,
          fees: data?.fees?.$numberDecimal
            ? parseFloat(data.fees.$numberDecimal)
            : undefined,
          amount: data?.amount?.$numberDecimal
            ? parseFloat(data.amount.$numberDecimal)
            : undefined,
        }}
      >
        <Form.Item
          name="user"
          label="User"
          rules={[{ required: true, message: "Please enter user name" }]}
        >
          <Input placeholder="Enter User name" disabled />
        </Form.Item>

        <Form.Item
          name="asset"
          label="Asset"
          rules={[{ required: true, message: "Please select an asset" }]}
        >
          <Select
            showSearch
            placeholder="Select an asset"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {assets?.map((asset) => {
              return (
                <Option key={asset?._id} value={asset?.asset}>
                  {asset?.assetName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name="amount"
          label="Amount"
          rules={[
            { required: true, message: "Please enter the amount" },
            { type: "number", min: 0, message: "Amount must be positive" },
          ]}
        >
          <InputNumber
            style={{ width: "100%" }}
            step="0.00000001"
            placeholder="Enter amount"
          />
        </Form.Item>

        <Form.Item
          name="fees"
          label="Fees"
          rules={[
            { required: true, message: "Please enter the fees" },
            { type: "number", min: 0, message: "Fees must be positive" },
          ]}
        >
          <InputNumber
            style={{ width: "100%" }}
            step="0.00000001"
            placeholder="Enter fees"
          />
        </Form.Item>

        <Form.Item name="txid" label="Transaction ID">
          <Input placeholder="Enter transaction ID" />
        </Form.Item>

        <Form.Item name="notes" label="Notes">
          <TextArea rows={4} placeholder="Enter notes" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Edit;
