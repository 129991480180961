// Modals/ClientBankAccounts/ViewModal.jsx

import React from 'react';
import { Modal, Descriptions } from 'antd';

const ViewModal = ({ visible, onCancel, data }) => {
    if (!data) {
        return null;
    }

    const {
        username = 'N/A',
        email = 'N/A',
        idNumber = 'N/A',
        cellphoneNumber = 'N/A',
        broker = 'N/A',
        signUpDate,
        recipientName = 'N/A',
        bank = 'N/A',
        accountNumber = 'N/A',
        accountType = 'N/A',
        date,
        wallet = {},
    } = data;

    const signUpDateFormatted = signUpDate ? new Date(signUpDate).toLocaleDateString() : 'N/A';
    const dateFormatted = date ? new Date(date).toLocaleDateString() : 'N/A';

    return (
        <Modal
            title="View Bank Account"
            visible={visible}
            onCancel={onCancel}
            footer={null}
        >
            <Descriptions bordered column={1}>
                <Descriptions.Item label="Username">{username}</Descriptions.Item>
                <Descriptions.Item label="Email">{email}</Descriptions.Item>
                <Descriptions.Item label="ID Number">{idNumber}</Descriptions.Item>
                <Descriptions.Item label="Cellphone Number">{cellphoneNumber}</Descriptions.Item>
                <Descriptions.Item label="Broker Code">{broker}</Descriptions.Item>
                <Descriptions.Item label="Sign Up Date">{signUpDateFormatted}</Descriptions.Item>
                <Descriptions.Item label="Recipient Name">{recipientName}</Descriptions.Item>
                <Descriptions.Item label="Bank">{bank}</Descriptions.Item>
                <Descriptions.Item label="Account Number">{accountNumber}</Descriptions.Item>
                <Descriptions.Item label="Account Type">{accountType}</Descriptions.Item>
                <Descriptions.Item label="Date">{dateFormatted}</Descriptions.Item>
                <Descriptions.Item label="Wallet ID">{wallet.walletId || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Wallet Type">{wallet.walletType || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Tag">{wallet.tag || 'N/A'}</Descriptions.Item>
            </Descriptions>
        </Modal>
    );
};

export default ViewModal;
