import BigNumber from 'bignumber.js';

export const convertDecimal128 = (value) => {
  if (value && value.$numberDecimal) {
    const bigNumberValue = new BigNumber(value.$numberDecimal);
    return bigNumberValue.toFixed();  // This ensures no scientific notation, returning a string
  }
  return value;
};

