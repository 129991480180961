import { Pagination, Table } from "antd";
import React from "react";

const PaginatedTable2 = ({
    data = [],
    columns,
    pagination,
    onPageChange,
    onPageSizeChange,
    loading
}) => {
    return (
        <div>
            <div className="flex justify-between items-end mt-4 w-full py-2">
                <div>
                    Showing {pagination.startIndex} to {pagination.endIndex} of {pagination.totalItems} items
                </div>
                <Pagination
                    current={pagination.currentPage}
                    pageSize={pagination.limit}
                    total={pagination.totalItems}
                    onChange={onPageChange}
                    showSizeChanger
                    onShowSizeChange={onPageSizeChange}
                />
            </div>

            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={(record) => record.id || record._id || record.key}
                rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                loading={loading}
                scroll={{ x: 'max-content' }}
            />

            <div className="flex justify-between items-end mt-4 w-full py-2">
                <div>
                    Showing {pagination.startIndex} to {pagination.endIndex} of {pagination.totalItems} items
                </div>
                <Pagination
                    current={pagination.currentPage}
                    pageSize={pagination.limit}
                    total={pagination.totalItems}
                    onChange={onPageChange}
                    showSizeChanger
                    onShowSizeChange={onPageSizeChange}
                />
            </div>
        </div>
    );
};

export default PaginatedTable2;
