import React, { useEffect } from "react";
import { Form, Input, DatePicker, Switch, Button, Select, message } from "antd";
import { getQuery } from "../services/apiService";
import moment from "moment/moment";

const { TextArea } = Input;
const { Option } = Select;

const InvestmentTermsAndConditionsForm = ({
  id = null,
  onNextStep,
  onPrevious,
  initialData = null,
  productId,
  isFinal = true,
  state="create"
}) => {
  const [form] = Form.useForm();

  // Set form fields with initial data or fetch related data if `productId` is provided
  useEffect(() => {
    if (initialData) {
      form.setFieldsValue(initialData);
      return;
    }

    const fetchTermsAndConditions = async () => {
      try {
        const response = await getQuery("/api/v1/terms-and-conditions/view", { id });
        if (response?.status === 200) {
          console.log('fetching values')
          const data = response.data.data;
          console.log(data);
          form.setFieldsValue({
            content: data.content,
            effectiveDate: moment(data.effectiveDate),
            isVisible: data.isVisible
          })
        }
      } catch (error) {
        message.error("Failed to fetch product data");
      }
    };

    if(state == "edit"){
      console.log("fetching?")
      fetchTermsAndConditions();
    }
  }, [id]);

  const onFinish = (values) => {
    console.log(values);
    onNextStep(values);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      className="p-4"
    >
      <h2 className="text-xl font-bold mb-4">
        Investment Terms & Conditions Form
      </h2>

      {/* Product ID */}
    {/*<Form.Item
        label="Investment Product"
        name="productId"
        rules={[{ required: true, message: "Product is required" }]}
        initialValue={initialData?.name} // Set initial value from previous data
    >
      <Input 
        value={initialData?.name}  // Show selected product
        readOnly  // Make the input field read-only
        placeholder="Investment product" 
      />
    </Form.Item>*/}

      {/* Content */}
      <Form.Item
        label="Content"
        name="content"
        rules={[{ required: true, message: "Content is required" }]}
      >
        <TextArea rows={6} placeholder="Enter terms and conditions content" />
      </Form.Item>

      {/* Effective Date */}
      <Form.Item
        label="Effective Date"
        name="effectiveDate"
        rules={[{ required: true, message: "Effective Date is required" }]}
      >
        <DatePicker style={{ width: "100%" }} />
      </Form.Item>

      {/* Is Visible */}
      <Form.Item
        label="Is Visible"
        name="isVisible"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      {/* Submit Button */}
      
      <div className={`flex justify-${isFinal ? 'center' : 'between'} mt-4`}>
        {isFinal ? (
          // Save Button (if isFinal is true)
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        ) : (
          // Previous and Next Buttons (if isFinal is false)
          <>
            {/* Previous Button */}
            <Button type="default" onClick={onPrevious}>
              Previous
            </Button>

            {/* Next Button */}
            <Button type="primary" htmlType="submit">
              Next
            </Button>
          </>
        )}
      </div>

    </Form>
  );
};

export default InvestmentTermsAndConditionsForm;
