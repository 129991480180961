// src/Modals/BrokerApplications/EditModal.jsx

import React, { useState } from 'react';
import { Modal, Form, Input, Select, message } from 'antd';
import axios from 'axios';

const { Option } = Select;

const EditModal = ({ visible, onCancel, data, refreshData }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        setLoading(true);
        try {
            const response = await axios.patch(`http://localhost:3000/api/v1/brokers/update-broker-application?id=${data._id}`, values);
            if (response.data.status) {
                message.success('Broker application updated successfully.');
                refreshData();
                onCancel();
            } else {
                message.error('Failed to update broker application.');
            }
        } catch (error) {
            message.error('Error updating broker application.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title="Edit Broker Application"
            visible={visible}
            onCancel={onCancel}
            onOk={() => form.submit()}
            confirmLoading={loading}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    name: data.name,
                    compRegNumber: data.compRegNumber,
                    country: data.country,
                    placeOfBusines: data.placeOfBusines,
                    numberOfEmployees: data.numberOfEmployees,
                    plannedUsage: data.plannedUsage,
                    tradingTurnover: data.tradingTurnover,
                    companyRep: data.companyRep,
                    jobTitle: data.jobTitle,
                    companyEmail: data.companyEmail,
                    companyNumber: data.companyNumber,
                    status: data.status,
                }}
                onFinish={onFinish}
            >
                <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please enter the name' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="compRegNumber" label="Company Reg Number">
                    <Input />
                </Form.Item>
                <Form.Item name="country" label="Country">
                    <Input />
                </Form.Item>
                <Form.Item name="placeOfBusines" label="Place of Business">
                    <Input />
                </Form.Item>
                <Form.Item name="numberOfEmployees" label="Number of Employees">
                    <Input />
                </Form.Item>
                <Form.Item name="plannedUsage" label="Planned Usage">
                    <Input />
                </Form.Item>
                <Form.Item name="tradingTurnover" label="Trading Turnover">
                    <Input />
                </Form.Item>
                <Form.Item name="companyRep" label="Company Representative" valuePropName="checked">
                    <Select>
                        <Option value={true}>Yes</Option>
                        <Option value={false}>No</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="jobTitle" label="Job Title">
                    <Input />
                </Form.Item>
                <Form.Item name="companyEmail" label="Company Email">
                    <Input />
                </Form.Item>
                <Form.Item name="companyNumber" label="Company Number">
                    <Input />
                </Form.Item>
                <Form.Item name="status" label="Status">
                    <Select>
                        <Option value={0}>Pending</Option>
                        <Option value={1}>Approved</Option>
                        <Option value={2}>Rejected</Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditModal;
