// src/routes/PrivateRoute.jsx

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getLoggedInUser } from '../services/apiService';
import { useAuth } from '../context/AuthContext';
import LoadingComponent from '../components/LoadingComponent';
//import APIService from "../services/apiService";

/**
 * PrivateRoute forces the authorization before the route can be accessed
 * @returns {HTMLElement} - A route element or redirects
 */
const PrivateRoute = ({ children, roles }) => {
    const { userProfile } = useAuth();

    if (!userProfile) {
        return <LoadingComponent />
    }

    // Check if the route is restricted by role
    if (userProfile && userProfile?.role > 20) {
        // Role not authorized, redirect to the home page
        return <Navigate to="/unauthorized" />;
    }

    // Authorized, render the children components
    console.log("User is authorized. Rendering children.");
    return children;//<Outlet />;
};

export default PrivateRoute;
