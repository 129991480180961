import { EyeOutlined } from "@ant-design/icons";
import { Button, Descriptions, Modal } from "antd";
import React, { useState } from "react";

const ViewTransactionModal = ({ data }) => {
  const [visible, setVisible] = useState(false);

  const toggleView = () => setVisible(!visible);

  if (!visible) {
    return (
      <Button
        type="text"
        icon={<EyeOutlined />}
        style={{
          padding: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={toggleView}
      />
    );
  }

  return (
    <Modal
      visible={visible}
      title="Transaction Details"
      onCancel={toggleView}
      footer={null}
    >
      {data && (
        <Descriptions bordered column={1}>
          <Descriptions.Item label="User">
            {data?.userId?.username || data?.userId?.email || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Pair">
            {data?.pair || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Type">
            {data?.type || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Amount">
            R {parseFloat(data?.amount?.$numberDecimal).toFixed(8) || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Total">
            R {parseFloat(data?.total?.$numberDecimal).toFixed(8) || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Price">
            R {parseFloat(data?.price?.$numberDecimal).toFixed(8) || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Date">
            {new Date(data?.date).toLocaleString() || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            {data?.status || "N/A"}
          </Descriptions.Item>
        </Descriptions>
      )}
    </Modal>
  );
};

export default ViewTransactionModal;
