import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import DetailView from "../../../../components/DetailView"; // Reusable DetailView component
import LoadingComponent from "../../../../components/LoadingComponent";
import { useGetContactService } from "../../../../services/contactService";
import Edit from "./Edit";

const Contacts = () => {

  const { data, isLoading } = useGetContactService();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  if (isLoading) return <LoadingComponent />;

  const contactDetails = [
    { label: "Telephone", value: data[0]?.tel },
    { label: "Email", value: <a href={`mailto:${data[0]?.email}`}>{data[0]?.email}</a> },
    { label: "Address Line 1", value: data[0]?.addressLine1 },
    { label: "Address Line 2", value: data[0]?.addressLine2 || "N/A" },
    { label: "WhatsApp Button Number", value: data[0]?.whatsappButtonNumber },
    { label: "Map", value: data[0]?.map ? <a href={data[0]?.map} target="_blank" rel="noreferrer">View Map</a> : "N/A" },
    { label: "Facebook", value: data[0]?.socialLinks?.facebook ? <a href={data[0]?.socialLinks?.facebook} target="_blank" rel="noreferrer">Facebook Link</a> : "N/A" },
    { label: "Twitter", value: data[0]?.socialLinks?.twitter ? <a href={data[0]?.socialLinks?.twitter} target="_blank" rel="noreferrer">Twitter Link</a> : "N/A" },
    { label: "LinkedIn", value: data[0]?.socialLinks?.linkedin ? <a href={data[0]?.socialLinks?.linkedin} target="_blank" rel="noreferrer">LinkedIn Link</a> : "N/A" },
    { label: "Instagram", value: data[0]?.socialLinks?.instagram ? <a href={data[0]?.socialLinks?.instagram} target="_blank" rel="noreferrer">Instagram Link</a> : "N/A" },
    { label: "YouTube", value: data[0]?.socialLinks?.youtube ? <a href={data[0]?.socialLinks?.youtube} target="_blank" rel="noreferrer">YouTube Link</a> : "N/A" },
  ];

  return (
    <div className="container mx-auto p-4">

      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Contact Information</h2>
        <Button className="px-4 rounded-lg py-2 bg-[#22c6ab] text-white hover:bg-[#1da089] transition" type="primary" icon={<EditOutlined />} onClick={() => setIsEditModalVisible(true)} >
          Edit
        </Button>
      </div>

      {/* Display Contact Information */}
      <DetailView data={contactDetails} className="w-full" />

      {isEditModalVisible && (
        <Edit
          data={data[0]}
          visible={isEditModalVisible}
          onCancel={() => setIsEditModalVisible(false)}
          onSubmit={() => { setIsEditModalVisible(false) }}
        />
      )}

    </div>
  );
};

export default Contacts;

