import React from "react";
import {
  Modal,
  Form,
  InputNumber,
  Select,
  Button,
  Input,
  Switch,
  Row,
  Col,
} from "antd";

const { Option } = Select;

const AddTradeModal = ({ visible, onCancel, onSubmit }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      visible={visible}
      title="Create New Trade"
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Back
        </Button>,
        <Button key="save" type="primary" onClick={""}>
          Get Quote
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        {/* User Dropdown Field */}
        <Form.Item
          name="user"
          label="User"
          rules={[{ required: true, message: "Please select a user" }]}
        >
          <Select placeholder="Select user" showSearch>
            <Option value="user1">User 1</Option>
            <Option value="user2">User 2</Option>
            <Option value="user3">User 3</Option>
          </Select>
        </Form.Item>

        {/* Buy Field */}
        <Form.Item
          name="buy"
          label="Buy"
          rules={[{ required: true, message: "Please select an asset to buy" }]}
        >
          <Select placeholder="Select asset">
            <Option value="bitcoin">Bitcoin</Option>
            <Option value="ethereum">Ethereum</Option>
          </Select>
        </Form.Item>

        {/* With Field */}
        <Form.Item
          name="with"
          label="With"
          rules={[{ required: true, message: "Please select a currency" }]}
        >
          <Select placeholder="Select currency">
            <Option value="zar">South African Rand</Option>
            <Option value="usd">US Dollar</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="rate"
          label="Rate"
          rules={[{ required: true, message: "Please enter the rate" }]}
        >
          <Input placeholder="Enter rate" />
        </Form.Item>

        <Form.Item
          name="enableTrade"
          label="Enable VALR Trade"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>

        <div style={{ marginBottom: "16px" }}>
          <strong>ZAR balance:</strong> <span>0.00</span>
        </div>

        <div style={{ marginBottom: "16px" }}>
          <strong>Rate Indication:</strong> <span>1,791,637.57 BTC</span>
        </div>

        <Form.Item label="Enter an amount" style={{ marginBottom: "0" }}>
          <Row gutter={8}>
            <Col span={16}>
              <Form.Item
                name="amount"
                rules={[{ required: true, message: "Please enter an amount" }]}
                noStyle
              >
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder="Enter amount"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="currency"
                rules={[
                  { required: true, message: "Please select a currency" },
                ]}
                noStyle
              >
                <Select placeholder="Select">
                  <Option value="zar">ZAR</Option>
                  <Option value="btc">BTC</Option>
                  <Option value="usd">USD</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddTradeModal;
