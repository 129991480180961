import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select } from 'antd';
import React, { useState } from 'react';
import Countries from '../../../../data/Countries';
import { useUpdateUserUSDBankAccService } from '../../../../services/USDbankAccService';

const { Option } = Select;

const Edit = ({ data }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const { updateUserUSDBankAcc } = useUpdateUserUSDBankAccService();



    const handleEdit = async () => {
        setLoading(true);

        try {
            const values = await form.validateFields();

            const payload = {
                _id: data?._id,
                accName: values.accName,
                bankId: values.bankId,
                accNumber: values.accNumber,
                branchCode: values.branchCode,
                accType: values.accType,
                swiftCode: values.swiftCode,
                accAddressCountry: values.accAddressCountry,
                accAddressCity: values.accAddressCity,
                accAddressProvince: values.accAddressProvince,
                accPostalCode: values.accPostalCode,
                accAddressStreet: values.accAddressStreet,
            };

            // Only include bankId if it has been modified
            // if (selectedBankId !== data?.bankId) {
            //     payload.bankId = selectedBankId;
            // }

            updateUserUSDBankAcc(
                payload,
                () => {
                    setLoading(false);
                    toggleView();
                },
                () => setLoading(false)
            );
        } catch (error) {
            setLoading(false);
        }
    };

    const toggleView = () => {
        setVisible(!visible);
    };

    if (!visible) {
        return (
            <Button
                type="text"
                icon={<EditOutlined />}
                style={{
                    padding: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                onClick={toggleView}
            />
        );
    }

    return (
        <Modal
            visible={visible}
            title="Edit USD Bank Account"
            onCancel={toggleView}
            footer={[
                <Button key="cancel" onClick={toggleView}>
                    Cancel
                </Button>,
                <Button key="save" type="primary" onClick={handleEdit} loading={loading}>
                    Save
                </Button>,
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    accName: data?.accName,
                    // bankId: selectedBank?.name,
                    bankId: data?.bankId,
                    accNumber: data?.accNumber,
                    branchCode: data?.branchCode,
                    accType: data?.accType,
                    swiftCode: data?.swiftCode,
                    accAddressCountry: data?.accAddressCountry,
                    accAddressCity: data?.accAddressCity,
                    accAddressProvince: data?.accAddressProvince,
                    accPostalCode: data?.accPostalCode,
                    accAddressStreet: data?.accAddressStreet,
                }}
            >
                <Form.Item
                    name="accName"
                    label="Account Name"
                    rules={[{ required: true, message: 'Please enter an account name' }]}
                >
                    <Input placeholder="Enter account name" />
                </Form.Item>

                <Form.Item
                    name="bankId"
                    label="Bank Name"
                    rules={[{ required: true, message: 'Please enter a bank' }]}
                >
                    <Input placeholder="Enter Bank name" />
                </Form.Item>

                {/* <Form.Item name="bankId" label="Bank">
                    <Select
                        placeholder={selectedBank?.name || 'Select a bank'}
                        loading={isLoading}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }
                        onChange={(value) => setSelectedBankId(value)}
                    >
                        {bankList?.map((bank) => (
                            <Option key={bank._id} value={bank._id}>
                                {bank.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item> */}

                <Form.Item
                    name="accNumber"
                    label="Account Number (IBAN)"
                    rules={[{ required: true, message: 'Please enter an account number' }]}
                >
                    <Input placeholder="Enter account number or IBAN" />
                </Form.Item>

                <Form.Item
                    name="branchCode"
                    label="Branch Code"
                    rules={[{ required: true, message: 'Please enter a branch code' }]}
                >
                    <Input placeholder="Enter branch code" />
                </Form.Item>

                <Form.Item
                    name="accType"
                    label="Account Type"
                    rules={[{ required: true, message: 'Please select an account type' }]}
                >
                    <Select placeholder="Select account type">
                        <Option value="Saving">Saving</Option>
                        <Option value="Cheque">Cheque</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="swiftCode"
                    label="SWIFT Code (Routing Number)"
                    rules={[{ required: true, message: 'Please enter a SWIFT code' }]}
                >
                    <Input placeholder="Enter SWIFT code or routing number" />
                </Form.Item>

                {/* <Form.Item
                    name="accAddressCountry"
                    label="Country"
                    rules={[{ required: true, message: 'Please enter the country' }]}
                >
                    <Input placeholder="Enter country" />
                </Form.Item> */}

                <Form.Item
                    name="accAddressCountry"
                    label="Country"
                    rules={[{ required: true, message: 'Please enter the country' }]}
                >
                    <Select
                        placeholder="Select a country"
                        // loading={isLoading}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }
                    >
                        {Countries &&
                            Countries?.map((item) => (
                                <Option key={item.code} value={item.name}>
                                    {item.name}
                                </Option>
                            ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="accAddressCity"
                    label="City"
                    rules={[{ required: true, message: 'Please enter the city' }]}
                >
                    <Input placeholder="Enter city" />
                </Form.Item>

                <Form.Item
                    name="accAddressProvince"
                    label="Province"
                    rules={[{ required: true, message: 'Please enter the province' }]}
                >
                    <Input placeholder="Enter province" />
                </Form.Item>

                <Form.Item
                    name="accPostalCode"
                    label="Postal Code"
                    rules={[{ required: true, message: 'Please enter the postal code' }]}
                >
                    <Input placeholder="Enter postal code" />
                </Form.Item>

                <Form.Item
                    name="accAddressStreet"
                    label="Street Address"
                    rules={[{ required: true, message: 'Please enter the street address' }]}
                >
                    <Input placeholder="Enter street address" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Edit;
