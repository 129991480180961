import { Space } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PaginatedTable2 from '../../../components/PaginatedTable2';
import { useApiQuery } from '../../../hooks/useApiRequest';
import AdminLayout from '../../../layouts/AdminLayout';
import { useApprovedCryptoWithdrawalService, useRejectCryptoWithdrawalService } from '../../../services/withdrawalCryptoService';
import { formatDate } from '../../../utils/formateData';
import Add from './Add';
import Delete from './Delete';
import Edit from './Edit';
import View from './View';

const Index = () => {
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const { rejectCryptoWithdrawal } = useRejectCryptoWithdrawalService();
    const { approveCryptoWithdrawal } = useApprovedCryptoWithdrawalService();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [loadingStates, setLoadingStates] = useState({});

    const { data, isLoading } = useApiQuery({
        key: ['crypto-user-withdrawals'],
        endPoint: `/api/v1/crypto-transactions/withdrawals?page=${currentPage}&limit=${pageSize}`,
        method: 'GET',
    });

    const pagination = {
        totalItems: data?.totalItems || 0,
        totalPages: data?.totalPages || 1,
        currentPage,
        limit: pageSize,
        startIndex: (currentPage - 1) * pageSize + 1,
        endIndex: Math.min(currentPage * pageSize, data?.totalItems || 0),
    };

    const handlePageChange = (page, size) => {
        setCurrentPage(page);
        setPageSize(size);
    };

    const withdrawalColumns = [
        {
            title: 'User',
            dataIndex: `user`,
            key: 'userId',
            render: (text, record) => `${record?.userId?.username} - ${record?.userId?.email} ` || "N/A"
        },
        {
            title: 'Reference',
            dataIndex: 'txid',
            key: 'txid',
            render: (text) => text || 'N/A'
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date) => formatDate(date),
        },
        {
            title: 'Asset',
            dataIndex: 'cryptoAsset',
            key: 'cryptoAsset',
            render: (text) => text?.toUpperCase() || 'N/A',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount) => `R ${parseFloat(amount?.$numberDecimal).toFixed(8)}`,
        },
        {
            title: 'Fees',
            dataIndex: 'fee',
            key: 'fee',
            render: (fees) => `${parseFloat(fees?.$numberDecimal).toFixed(8)}`,

        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes',
            render: (text) => text || 'N/A'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status, record) => {

                const isApproveLoading = loadingStates[record._id]?.approve;
                const isRejectLoading = loadingStates[record._id]?.reject;

                if (status === 'Pending') {
                    return (
                        <div className="flex justify-between items-center gap-4 mb-6">
                            <button
                                className="px-3 py-1 text-sm font-semibold text-white bg-green-500 rounded hover:bg-yellow-700"
                                onClick={() => handleApprove(record)}
                                disabled={isApproveLoading}
                            >
                                {isApproveLoading ? "Loading..." : "Approve"}
                            </button>
                            <button
                                className="px-3 py-1 text-sm font-semibold text-white bg-yellow-500 rounded hover:bg-yellow-700"
                                onClick={() => handleEditStatus(record)}
                                disabled={isRejectLoading}
                            >
                                {isRejectLoading ? "Loading..." : "Reject"}
                            </button>
                        </div>
                    );
                }

                const statusColors = {
                    'Confirming': 'text-gray-500',
                    'Pending Screening': 'text-orange-500',
                    'Completed': 'text-green-600',
                    'Confirmed': 'text-green-600',
                };

                const statusColorClass = statusColors[status] || 'text-red-500';

                return (
                    <span className={`text-sm font-semibold ${statusColorClass}`}>
                        {status}
                    </span>
                );
            },
        },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Link> <View data={record} /> </Link>
                        <Link>  <Edit data={record} /> </Link>
                        <Link>  <Delete record={record} /> </Link>
                    </Space>
                )
            },
        }
    ];

    const setLoadingForRecord = (recordId, type, value) => {
        setLoadingStates((prev) => ({
            ...prev,
            [recordId]: {
                ...prev[recordId],
                [type]: value,
            },
        }));
    };

    const handleApprove = async (record) => {
        setLoadingForRecord(record._id, 'approve', true);
        approveCryptoWithdrawal(
            record?._id,
            () => setLoadingForRecord(record._id, 'approve', false),
            () => setLoadingForRecord(record._id, 'approve', false)
        )
    }

    const handleEditStatus = async (record) => {
        setLoadingForRecord(record._id, 'reject', true);
        rejectCryptoWithdrawal(
            record?._id,
            () => setLoadingForRecord(record._id, 'reject', false),
            () => setLoadingForRecord(record._id, 'reject', false)
        );
    };

    return (
        <AdminLayout currentKey={'dw-sub4'} openKey='5'>
            <div className="mx-auto p-6 bg-white">
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-xl font-semibold"> Client Crypto Withdrawal Requests </h1>
                    <div className="text-xs text-gray-500">
                        Dashboard &gt; Deposits/Withdrawals &gt; Deposits
                    </div>
                </div>

                <div className="flex justify-between items-center mb-6">
                    <button
                        className="px-4 rounded-lg py-2 bg-[#22c6ab] text-white hover:bg-[#1da089] transition"
                        onClick={() => setIsAddModalVisible(true)}
                    >
                        + Add Crypto Withdrawal Request
                    </button>
                </div>

                <PaginatedTable2
                    data={data?.results}
                    columns={withdrawalColumns}
                    pagination={pagination}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageChange}
                    loading={isLoading}
                />

                {isAddModalVisible && (
                    <Add
                        visible={isAddModalVisible}
                        onCancel={() => setIsAddModalVisible(false)}
                        onSubmit={() => { setIsAddModalVisible(false) }}
                    />
                )}
            </div>
        </AdminLayout>
    );
};

export default Index;
