import { message, Space } from "antd";
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import PaginatedTable2 from '../../../../components/PaginatedTable2';
import { useApiQuery } from '../../../../hooks/useApiRequest';
import Add from './Add';
import Delete from './Delete';
import Edit from './Edit';

const WalletsTab = ({ userID }) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);

    const { data, isLoading } = useApiQuery({
        key: ['user-wallets'],
        endPoint: `/api/v1/user-wallets/get?page=${currentPage}&limit=${pageSize}&userID=${userID}`,
        method: 'GET',
    });

    const pagination = {
        totalItems: data?.totalItems || 0,
        totalPages: data?.totalPages || 1,
        currentPage,
        limit: pageSize,
        startIndex: (currentPage - 1) * pageSize + 1,
        endIndex: Math.min(currentPage * pageSize, data?.totalItems || 0),
    };

    const handlePageChange = (page, size) => {
        setCurrentPage(page);
        setPageSize(size);
    };

    const handleExport = () => {
        try {
            if (!data?.results?.length) {
                message.warning('No User Wallet available to export');
                return;
            }

            const headers = ['User Wallet', 'Created At', 'Data'];
            const rows = data?.results.map((item) => [
                item._id,
                new Date(item.createdAt).toLocaleString(),
                JSON.stringify(item.data, null, 2),
            ]);

            const csvContent = [headers, ...rows]
                .map((row) => row.map((field) => `"${String(field).replace(/"/g, '""')}"`).join(','))
                .join('\n');

            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'user_wallets_export.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            message.success('Exported User Wallets successfully');
        } catch (error) {
            message.error('Failed to export User Wallets');
        }
    };

    const columns = [
        { title: "Wallet Id", dataIndex: "walletId", key: "walletId" },
        { title: "WalletId Legacy", dataIndex: "walletIdLegacy", key: "walletIdLegacy" },
        { title: "Wallet Type", dataIndex: "walletType", key: "walletType" },
        {
            title: "Asset Name",
            dataIndex: "assetId",
            key: "assetId",
            render: (text, record) => `${record?.assetId?.assetName}` || "N/A"
        },
        {
            title: "Chain",
            dataIndex: "chainId",
            key: "chainId",
            render: (text, record) => `${record?.chainId?.chain}` || "N/A"
        },
        { title: "Tag", dataIndex: "tag", key: "tag" },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Link>  <Edit data={record} /> </Link>
                        <Link>  <Delete record={record} /> </Link>
                    </Space>
                )
            },
        }
    ];

    return (
        <div className="mx-auto p-6 bg-white">

            <div className="flex justify-between items-center mb-6">
                <button
                    className="px-4 rounded-lg py-2 bg-[#22c6ab] text-white hover:bg-[#1da089] transition"
                    onClick={() => setIsAddModalVisible(true)}
                >
                    + Add Client Wallet
                </button>
                {/* <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={handleExport}
                    className="flex items-center px-4 py-2 bg-[#57249c] text-white rounded-lg hover:bg-[#432076] transition"
                >
                    Export (csv)
                </Button> */}
            </div>

            <PaginatedTable2
                data={data?.results}
                columns={columns}
                pagination={pagination}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageChange}
                loading={isLoading}
            />

            {/* Modals */}
            {isAddModalVisible && (
                <Add
                    userId={userID}
                    visible={isAddModalVisible}
                    onCancel={() => setIsAddModalVisible(false)}
                    onSubmit={() => { setIsAddModalVisible(false) }}
                />
            )}
        </div>
    )

}
export default WalletsTab;


// const WalletsTab = ({ activeTab, userID }) => {
//     const [wallets, setWallets] = useState([]);
//     console.log(userID);

//     useEffect(() => {
//         const fetchBalances = async () => {
//             try {
//                 const response = await getQuery("/api/v1/user-wallets/get", { userID });
//                 if (response.status === 200 && response.data.status && response.data.data) {
//                     setWallets(response.data.data);
//                     console.log(response.data);
//                     return;
//                 }
//             } catch (error) {
//                 message.error("Balance data for this user not found.");
//             }
//         };

//         fetchBalances();
//     }, [userID]);

//     const columns = [
//         {
//             title: "Asset",
//             dataIndex: ["assetId", "assetName"],
//         },
//         {
//             title: "Chain",
//             dataIndex: ["chainId", "chain"],
//             key: "chainId",
//         },
//         {
//             title: "Wallet Address",
//             dataIndex: "walletId",
//             key: "walletId",
//             render: (text) => (
//                 <div style={{ wordWrap: "break-word", wordBreak: "break-word", whiteSpace: "normal" }}>
//                     {text}
//                 </div>
//             ),
//         },
//         {
//             title: "Legacy Wallet Address",
//             dataIndex: "walletIdLegacy",
//             key: "walletIdLegacy",
//             render: (text) => (
//                 <div style={{ wordWrap: "break-word", wordBreak: "break-word", whiteSpace: "normal" }}>
//                     {text}
//                 </div>
//             ),
//         },
//     ];

//     return <Table dataSource={wallets} columns={columns} />;
// };

// export default WalletsTab;
