// Modals/ClientsBankOverview/ViewModal.jsx

import React from 'react';
import { Modal, Descriptions } from 'antd';

const ViewModal = ({ visible, onCancel, data }) => {
    return (
        <Modal
            title="View User"
            visible={visible}
            onCancel={onCancel}
            footer={null}
        >
            <Descriptions bordered column={1}>
                <Descriptions.Item label="Username">{data?.username || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Email">{data?.email || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Full Name">
                    {`${data?.userInfo?.personal_info?.legal_name || ''} ${data?.userInfo?.personal_info?.legal_lastname || ''}`}
                </Descriptions.Item>
                <Descriptions.Item label="Identity Number">
                    {data?.userInfo?.personal_info?.identity_number || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Phone Number">
                    {data?.userInfo?.personal_info?.phoneNumber || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="User Code">
                    {data?.userInfo?.ref_info?.user_code || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Broker Code">
                    {data?.userInfo?.broker_info?.broker_code || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Created At">
                    {data?.userInfo?.createdAt ? new Date(data.userInfo?.createdAt).toLocaleDateString() : 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Asset">{data?.asset || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Wallet ID">{data?.wallet?.walletId || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Wallet Type">{data?.wallet?.walletType || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Tag">{data?.wallet?.tag || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Total Balance">{data?.totalBalance ?? '0'}</Descriptions.Item>
                <Descriptions.Item label="Available Balance">{data?.availableBalance ?? '0'}</Descriptions.Item>
                <Descriptions.Item label="Locked Balance">{data?.lockedBalance ?? '0'}</Descriptions.Item>
            </Descriptions>
        </Modal>
    );
};

export default ViewModal;
