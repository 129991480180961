// Modals/ClientBankAccounts/EditModal.jsx

import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import axios from 'axios';

const EditModal = ({ open, onCancel, data, onSubmit }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open && data) {
            form.setFieldsValue({
                username: data.username,
                email: data.email,
                idNumber: data.idNumber,
                cellphoneNumber: data.cellphoneNumber,
                broker: data.broker,
                signUpDate: data.signUpDate ? new Date(data.signUpDate).toLocaleDateString() : '',
                recipientName: data.recipientName,
                bank: data.bank,
                accountNumber: data.accountNumber,
                accountType: data.accountType,
                date: data.date ? new Date(data.date).toLocaleDateString() : '',
            });
        }
    }, [open, data, form]);

    const handleFinish = async (values) => {
        setLoading(true);
        try {
            // Update the bank account data
            await axios.patch(`http://localhost:3000/api/v1/clients/updatebank/${data.userInfo?.user_id}`, values);
            message.success('Bank account updated successfully');
            onSubmit();
        } catch (error) {
            message.error(error.response?.data?.message || 'Failed to update bank account');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title="Edit Bank Account"
            open={open}
            onCancel={onCancel}
            footer={null}
        >
            <Form
                form={form}
                onFinish={handleFinish}
                layout="vertical"
            >
                <Form.Item
                    label="Username"
                    name="username"
                    rules={[{ required: true, message: 'Please input the username!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input the email!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="ID Number"
                    name="idNumber"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Cellphone Number"
                    name="cellphoneNumber"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Broker Code"
                    name="broker"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Sign Up Date"
                    name="signUpDate"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Recipient Name"
                    name="recipientName"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Bank"
                    name="bank"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Account Number"
                    name="accountNumber"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Account Type"
                    name="accountType"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Date"
                    name="date"
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Update
                    </Button>
                    <Button onClick={onCancel} style={{ marginLeft: '8px' }}>
                        Cancel
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditModal;
