import { DownloadOutlined } from "@ant-design/icons";
import { Button, Space, message } from "antd";
import React, { useState } from "react";
import PaginatedTable2 from "../../components/PaginatedTable2";
import AdminLayout from "../../layouts/AdminLayout";
import { formatDate } from "../../utils/formateData";
import Add from "./Add";
import Delete from "./Delete";
import Edit from "./Edit";
import { useApiQuery } from "../../hooks/useApiRequest";

const News = () => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const { data, isLoading } = useApiQuery({
    key: ["news"],
    endPoint: `/api/v1/news/get-all?page=${currentPage}&limit=${pageSize}`,
    method: "GET",
  });

  const pagination = {
    totalItems: data?.totalItems || 0,
    totalPages: data?.totalPages || 1,
    currentPage,
    limit: pageSize,
    startIndex: (currentPage - 1) * pageSize + 1,
    endIndex: Math.min(currentPage * pageSize, data?.totalItems || 0),
  };

  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const handleExport = () => {
    try {
      if (!data?.results?.length) {
        message.warning("No news available to export");
        return;
      }

      const headers = ["Name", "Description", "Link", "Category"];
      const rows = data?.results.map((item) => [
        item.name || "N/A",
        item.description || "N/A",
        item.link || "N/A",
        item.category || "N/A",
      ]);

      const csvContent = [headers, ...rows]
        .map((row) =>
          row.map((field) => `"${String(field).replace(/"/g, '""')}"`).join(",")
        )
        .join("\n");

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "news_export.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      message.success("Exported news successfully");
    } catch (error) {
      message.error("Failed to export news");
    }
  };

  const newsColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => text || "N/A",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => text || "N/A",
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      render: (text) => text || "N/A",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text) => text || "N/A",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => formatDate(date),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <Edit data={record} />
          <Delete record={record} />
        </Space>
      ),
    },
  ];

  return (
    <AdminLayout currentKey={"10"} openKey="10">
      {/* <AdminLayout currentKey={"dw-sub3"} openKey="5"> */}

      <div className="mx-auto p-6 bg-white">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-xl font-semibold">News</h1>
          <div className="text-xs text-gray-500">Dashboard &gt;News</div>
        </div>

        <div className="flex justify-between items-center mb-6">
          <button
            className="px-4 rounded-lg py-2 bg-[#22c6ab] text-white hover:bg-[#1da089] transition"
            onClick={() => setIsAddModalVisible(true)}
          >
            + Add News
          </button>
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={handleExport}
            className="flex items-center px-4 py-2 bg-[#57249c] text-white rounded-lg hover:bg-[#432076] transition"
          >
            Export (CSV)
          </Button>
        </div>

        <PaginatedTable2
          data={data?.results}
          columns={newsColumns}
          pagination={pagination}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageChange}
          loading={isLoading}
        />

        {isAddModalVisible && (
          <Add
            visible={isAddModalVisible}
            onCancel={() => setIsAddModalVisible(false)}
            onSubmit={() => setIsAddModalVisible(false)}
          />
        )}
      </div>
    </AdminLayout>
  );
};

export default News;
