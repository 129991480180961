import { Form, Input } from "antd";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link, useParams } from "react-router-dom";
import LoadingComponent from "../../components/LoadingComponent";
import { useGetPoliciesById, useUpdatePoliciesService } from "../../services/policieService";

const EditPolicy = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const { data, isLoading } = useGetPoliciesById(id);
  const { updatePolicies } = useUpdatePoliciesService();
  const [loading, setLoading] = useState(false)

  const handleEdit = async () => {
    setLoading(true)

    const values = await form.validateFields();

    const payload = {
      _id: id,
      name: values.name,
      text: values.text,
      rawText: values.rawText,
    }

    updatePolicies(
      payload,
      () => {
        setLoading(false)
      },
      () => { setLoading(false) }
    )
  }

  if (isLoading) return <LoadingComponent />

  return (
    <div className="p-6 bg-white">

      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-semibold">Edit Policy</h1>
        <div className="text-xs text-gray-500">
          Dashboard &gt; Edit Policy
        </div>
      </div>

      <Link to="/admin/policies" className="flex justify-between items-center mb-6">
        <button
          className="px-4 rounded-lg py-2 bg-[#22c6ab] text-white hover:bg-[#1da089] transition"
        >
          Back
        </button>
      </Link>

      <Form
        form={form}
        layout="vertical"
        // onFinish={onFinish}
        initialValues={{
          name: data.name,
          rawText: data.rawText,
          text: data.text
        }}
      >

        <Form.Item
          label="Policy Name"
          name="name"
          rules={[{ required: true, message: "Policy Name is required" }]}
        >
          <Input placeholder="Enter policy name" />
        </Form.Item>

        <Form.Item
          label="Raw Text"
          name="rawText"
          rules={[{ required: true, message: "Raw text is required" }]}
        >
          <Input.TextArea rows={4} placeholder="Enter raw text" />
        </Form.Item>

        <Form.Item
          label="HTML Content"
          name="text"
          rules={[{ required: true, message: "HTML content is required" }]}
        >
          <div style={{ border: "1px solid #d9d9d9", borderRadius: "4px", padding: "4px" }}>
            <ReactQuill
              theme="snow"
              placeholder="Enter HTML content"
              defaultValue={data.text}
              onChange={(value) => form.setFieldsValue({ text: value })}
              style={{
                height: "600px",
                overflow: "hidden",
                paddingBottom: "40px", // Padding around the content
                // margin: "8px 0", // Margin around the ReactQuill editor
              }}
            />
          </div>
        </Form.Item>

        <div className="flex justify-end items-end ">
          <button
            className="px-4 rounded-lg py-2 bg-[#22c6ab] text-white hover:bg-[#1da089] transition"
            onClick={handleEdit}>
            {loading ? "Loading..." : "Save Changes"}
          </button>
        </div>

      </Form>
    </div>
  );
};

export default EditPolicy;
