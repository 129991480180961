// src/Modals/BrokerCommissions/EditModal.jsx

import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Select, message } from 'antd';
import axios from 'axios';

const { Option } = Select;

const EditModal = ({ visible, onCancel, data, refreshData }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                name: data.name || '',
                oldId: data.oldId || '',
                brokerId: data.brokerId || '',
                userId: data.userId || '',
                commission: data.commission.$numberDecimal || '',
                asset: data.asset || '',
                type: data.type || '',
                traceId: data.traceId || '',
                date: data.date ? new Date(data.date).toISOString().slice(0, 16) : '',
            });
        }
    }, [data, form]);

    const onFinish = async (values) => {
        setLoading(true);
        try {
            const updatedData = {
                ...values,
                commission: parseFloat(values.commission),
                date: new Date(values.date),
            };
            const response = await axios.patch(
                `http://localhost:3000/api/v1/brokers/update-broker-commission?id=${data._id}`,
                updatedData
            );
            if (response.data.status) {
                message.success('Broker commission updated successfully.');
                refreshData();
                onCancel();
            } else {
                message.error('Failed to update broker commission.');
            }
        } catch (error) {
            message.error('Error updating broker commission.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title="Edit Broker Commission"
            visible={visible}
            onCancel={onCancel}
            onOk={() => form.submit()}
            confirmLoading={loading}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, message: 'Please enter the name' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="oldId"
                    label="Old ID"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="brokerId"
                    label="Broker ID"
                    rules={[{ required: true, message: 'Please enter the broker ID' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="userId"
                    label="User ID"
                    rules={[{ required: true, message: 'Please enter the user ID' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="commission"
                    label="Commission"
                    rules={[{ required: true, message: 'Please enter the commission' }]}
                >
                    <Input type="number" step="0.01" />
                </Form.Item>
                <Form.Item
                    name="asset"
                    label="Asset"
                    rules={[{ required: true, message: 'Please enter the asset' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="type"
                    label="Type"
                    rules={[{ required: true, message: 'Please select the type' }]}
                >
                    <Select>
                        <Option value="Trade">Trade</Option>
                        <Option value="Deposit">Deposit</Option>
                        <Option value="Withdrawal">Withdrawal</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="traceId"
                    label="Trace ID"
                    rules={[{ required: true, message: 'Please enter the trace ID' }]}
                >
                    <Input type="number" />
                </Form.Item>
                <Form.Item
                    name="date"
                    label="Date"
                    rules={[{ required: true, message: 'Please select the date' }]}
                >
                    <Input type="datetime-local" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditModal;
