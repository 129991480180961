import { Button, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { useAddAssetChainService } from '../../../../services/assetService';

const Add = ({ assetId, visible, onCancel, onSubmit }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const { addAssetChain } = useAddAssetChainService()

    const handleAdd = async () => {
        setLoading(true)

        const values = await form.validateFields();

        const payload = {
            assetId: assetId,
            chain: values.chain,
            fireblocksTest: values.fireblocksTest,
            fireblocksLive: values.fireblocksLive
        }

        addAssetChain(
            payload,
            () => {
                onCancel();
                onSubmit();
                setLoading(false)
            },
            () => setLoading(false)
        )
    };

    return (
        <Modal
            visible={visible}
            title="Add Chain"
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button
                    key="save"
                    type="primary"
                    onClick={handleAdd}
                    loading={loading}
                >
                    Save
                </Button>,
            ]}>
            <Form form={form} layout="vertical">

                <Form.Item
                    name="chain"
                    label="Chain Name"
                    rules={[{ required: true, message: 'Please enter a chain name' }]}
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="Enter chain name"
                    />
                </Form.Item>

                <Form.Item
                    name="fireblocksTest"
                    label="Fireblock Test "
                    rules={[{ required: true, message: 'Please enter Fireblock test' }]}
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="Enter fireblock test "
                    />
                </Form.Item>

                <Form.Item
                    name="fireblocksLive"
                    label="Fireblocks Live"
                    rules={[{ required: true, message: 'Please enter fireblock live' }]}
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="Enter fireblock live"
                    />
                </Form.Item>

            </Form>
        </Modal>
    );
};

export default Add;
