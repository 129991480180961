import React, { useState } from "react";
import { Modal, Button, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { deleteResource } from "../../../../services/apiService";

const Delete = ({ id, onDeleteSuccess }) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const toggleModal = () => {
        setVisible(!visible);
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
			console.log('Deleting...');
			const response = await deleteResource('/api/v1/faqs/delete', { id });
            if (response.status === 200) {
                message.success("FAQ deleted successfully.");
                onDeleteSuccess(Math.random()); // Callback to refresh parent component or handle success
                toggleModal();
            } else {
                throw new Error("Failed to delete faq.");
            }
        } catch (error) {
            message.error(error.message || "Error deleting faq.");
        } finally {
            setLoading(false);
        }
    };

	if (!visible) {
		return (
		  <Button
			  type="text"
			  icon={<DeleteOutlined />}
			  style={{
				  padding: 0, // Removes padding for a cleaner look
				  display: "flex", // Ensures proper alignment
				  alignItems: "center",
				  justifyContent: "center",
			  }}
			  onClick={toggleModal} // Properly placed onClick handler
		  />
		);
	}

    return (
        <>
            <Button type="danger" onClick={toggleModal}>
                Delete FAQ
            </Button>

            <Modal
                title="Delete Asset"
                open={visible}
                onCancel={toggleModal}
                footer={null}
                destroyOnClose
                style={{ maxWidth: "600px" }}
            >
                <div>
                    <p>Are you sure you want to delete this faq?</p>
                    <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                        <Button onClick={toggleModal}>Cancel</Button>
                        <Button type="primary" danger loading={loading} onClick={handleDelete}>
                            Delete
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Delete;
