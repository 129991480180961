import { Button, Form, Modal, Input, Typography, Alert } from 'antd';
import React, { useEffect, useState } from 'react';
import { useGetSecret, useVerifyCodeService } from '../../../../services/userService';
import LoadingComponent from '../../../../components/LoadingComponent';
import QRCode from 'qrcode';
const { Text } = Typography;

const ScanQrcode = ({ visible, onCancel, onSubmit }) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const { verifyCode } = useVerifyCodeService();
    const { data, isLoading, error } = useGetSecret();
    const [qrCodeImage, setQrCodeImage] = useState('');

    useEffect(() => {
        if (data?.otpauth_url) {
            QRCode.toDataURL(data.otpauth_url)
                .then((url) => setQrCodeImage(url))
                .catch((err) => console.error('Failed to generate QR Code:', err));
        }
    }, [data, visible]);

    const handleVerify = async () => {
        setLoading(true);

        try {
            const values = await form.validateFields();

            const payload = {
                token: values.code,
                secret: data?.base32,
            };

            verifyCode(
                payload,
                () => {
                    onSubmit();
                    setLoading(false);
                },
                () => setLoading(false)
            );
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <Modal
            visible={visible}
            title={<h2 style={{ textAlign: "center" }}> Setup Authenticator App </h2>}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button
                    key="enable"
                    type="primary"
                    onClick={handleVerify}
                    loading={loading}
                >
                    Enable 2FA
                </Button>,
            ]}
        >
            {isLoading ? (
                <LoadingComponent />
            ) : error ? (
                <Alert
                    message="Error"
                    description="Failed to load QR Code. Please try again later."
                    type="error"
                    showIcon
                />
            ) : (
                <>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: 'center', marginBottom: 16 }}>
                        {qrCodeImage ? (
                            <img src={qrCodeImage} alt="QR Code" style={{ width: 200 }} />
                        ) : (
                            <Alert message="QR Code not available" type="warning" />
                        )}
                    </div>

                    <div style={{ textAlign: "center" }}>
                        <Text strong>
                            Account Name: 80Eight
                            <br />
                            Your Key: <span style={{ fontFamily: 'monospace' }}>{data?.base32}</span>
                        </Text>
                    </div>

                    <p style={{ marginTop: 20, marginBottom: 20, textAlign: "center" }}>
                        Install an authenticator app (e.g., Google Authenticator) on your mobile device.
                        Scan the QR code or use the key above to link your account.
                    </p>

                    <Form form={form} layout="vertical">
                        <Form.Item
                            label="Enter the verification code from your authenticator app:"
                            name="code"
                            rules={[
                                { required: true, message: 'Please enter the 6-digit code' },
                                { pattern: /^\d{6}$/, message: 'Code must be 6 digits' },
                            ]}
                        >
                            <Input placeholder="2FA Code" maxLength={6} />
                        </Form.Item>
                    </Form>
                </>
            )}

        </Modal>
    );
};

export default ScanQrcode;
