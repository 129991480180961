import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Select } from "antd";
import { getQuery } from "../services/apiService";
import Countries from "../data/Countries";
const { Option } = Select;

const ClientInfo = ({ onNextStep, userId, visible, loading }) => {
    const [form] = Form.useForm(); // Initialize Ant Design form

    useEffect(() => {
        const fetchClientData = async () => {
            try {
                const response = await getQuery("/api/v1/clients/view", { clientId: userId });
                if (response?.data) {
                    const formValues = mapApiDataToFormFields(response.data.data);
                    form.setFieldsValue(formValues); // Dynamically update the form with fetched data
                }
            } catch (error) {
                message.error("Failed to fetch client data");
            }
        };

        if (visible) {
            fetchClientData();
        }
    }, [userId, form]); // Add `form` dependency to avoid stale closures

    // Maps API data to the form's field structure
    const mapApiDataToFormFields = (apiData) => ({
        "personal_info.legal_name": apiData?.personal_info?.legal_name || "",
        "personal_info.legal_lastname": apiData?.personal_info?.legal_lastname || "",
        "personal_info.phoneNumber": apiData?.personal_info?.phoneNumber || "",
        "address.country": apiData?.address?.country || "",
        // Add other mappings as necessary
    });

    const onFinish = (values) => {
        console.log("Step 1 data:", values);
        onNextStep(values); // Pass data to the next step
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className="p-4"
        >
            <div className="grid grid-cols-2 gap-4 items-center mb-4">
                <Form.Item
                    label="Legal First Name"
                    name="personal_info.legal_name"
                    rules={[{ required: true, message: "Please enter the first name" }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Legal Last Name"
                    name="personal_info.legal_lastname"
                    rules={[{ required: true, message: "Please enter the last name" }]}
                >
                    <Input />
                </Form.Item>
            </div>

            <div className="grid grid-cols-2 gap-4 items-center mb-4">
                <Form.Item
                    label="Contact Number"
                    name="personal_info.phoneNumber"
                    rules={[{ required: true, message: "Please enter the phone number" }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="address.country"
                    label="Country"
                    rules={[{ required: true, message: 'Please enter the country' }]}
                >
                    <Select
                        placeholder="Select a country"
                        // loading={isLoading}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }>
                        {Countries &&
                            Countries?.map((item) => (
                                <Option key={item.code} value={item.name}>
                                    {item.name}
                                </Option>
                            ))}
                    </Select>
                </Form.Item>
            </div>

            <div className="text-center">
                <Button type="primary" htmlType="submit" className="mt-4" disabled={loading}>
                    {loading ? "Loading..." : "Save"}
                </Button>

            </div>
        </Form>
    );
};

export default ClientInfo;
