import { useQueryClient } from "@tanstack/react-query";
import { message } from 'antd';
import { useApiMutation } from "../hooks/useApiRequest";


//*********** add user Crypto Withdrawal**********
export const useAddUserCryptoWithdrawalService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const addUserCryptoWithdrawal = async (values, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["user-crypto-withdrawals-add"],
                endPoint: `/api/v1/crypto-transactions/create`,
                method: "POST",
                bodyContent: values,
            });
            queryClient.invalidateQueries({ queryKey: ["crypto-user-withdrawals"] });
            queryClient.invalidateQueries({ queryKey: ["user-crypto-withdrawals-add"] });
            onSuccess();
            message.success('Successfully added');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { addUserCryptoWithdrawal, error };
};

//*********** Update user Crypto withdrawal**********
export const useUpdateUserCryptoWithdrawalStatusService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const updateUserCryptoWithdrawal = async (values, id, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["user-crypto-withdrawals-update-status", id],
                endPoint: `/api/v1/crypto-transactions/${id}`,
                method: "PATCH",
                bodyContent: values,
            });
            queryClient.invalidateQueries({ queryKey: ["crypto-user-withdrawals"] });
            queryClient.invalidateQueries({ queryKey: ["user-crypto-withdrawals-update-status", id] });
            onSuccess();
            message.success('Successfully updated');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { updateUserCryptoWithdrawal, error };
};

//*********** Delete user crypto withdrawal **********
export const useDeleteUserCryptoWithdrawalService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const deleteWithdrawalCrypto = async (id, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["user-crypto-withdrawals-delete"],
                endPoint: `/api/v1/crypto-transactions/${id}`,
                method: "DELETE",
            });
            queryClient.invalidateQueries({ queryKey: ['crypto-user-withdrawals'] });
            queryClient.invalidateQueries({ queryKey: ["user-crypto-withdrawals-delete"] });
            onSuccess();
            message.success('Successfully deleted');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { deleteWithdrawalCrypto, error };
};


//*********** Approved user Crypto withdrawal **********
export const useApprovedCryptoWithdrawalService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const approveCryptoWithdrawal = async (id, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ['user-crypto-withdrawal-approve', id],
                endPoint: `/api/v1/crypto-transactions/approve?transactionId=${id}`,
                method: 'POST',
            });
            queryClient.invalidateQueries({ queryKey: ['crypto-user-withdrawals'] });
            queryClient.invalidateQueries({ queryKey: ['user-crypto-withdrawal-approve', id] });
            onSuccess();
            message.success('Successfully approved');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { approveCryptoWithdrawal, error };
};


//*********** reject user Crypto withdrawal**********
export const useRejectCryptoWithdrawalService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const rejectCryptoWithdrawal = async (id, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["user-crypto-withdrawals-update-status", id],
                endPoint: `/api/v1/crypto-transactions/${id}/reject`,
                method: "POST",
            });
            queryClient.invalidateQueries({ queryKey: ["crypto-user-withdrawals"] });
            queryClient.invalidateQueries({ queryKey: ["user-crypto-withdrawals-update-status", id] });
            onSuccess();
            message.success('Successfully Rejected');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { rejectCryptoWithdrawal, error };
};








