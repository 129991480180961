// src/Modals/BrokerApplications/ViewModal.jsx

import React from 'react';
import { Modal, Descriptions } from 'antd';
import PropTypes from 'prop-types';

const ViewModal = ({ visible, onCancel, data }) => {
    if (!data) {
        return null;
    }

    const {
        name = 'N/A',
        compRegNumber = 'N/A',
        country = 'N/A',
        placeOfBusines = 'N/A',
        numberOfEmployees = 'N/A',
        plannedUsage = 'N/A',
        tradingTurnover = 'N/A',
        companyRep = false,
        jobTitle = 'N/A',
        companyEmail = 'N/A',
        companyNumber = 'N/A',
        created = 'N/A',
        status = 'N/A',
    } = data;

    const statusText = () => {
        switch (status) {
            case 0:
                return 'Pending';
            case 1:
                return 'Approved';
            case 2:
                return 'Rejected';
            default:
                return 'Unknown';
        }
    };

    const formatDate = (dateString) => {
        if (!dateString || dateString === 'N/A') return 'N/A';
        const date = new Date(dateString);
        if (isNaN(date)) return 'Invalid Date';
        const day = String(date.getDate()).padStart(2, '0');
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day} ${month} ${year}, ${hours}:${minutes}`;
    };

    return (
        <Modal
            title="View Broker Application"
            visible={visible}
            onCancel={onCancel}
            footer={null}
        >
            <Descriptions bordered column={1}>
                <Descriptions.Item label="Name">{name}</Descriptions.Item>
                <Descriptions.Item label="Company Reg Number">{compRegNumber}</Descriptions.Item>
                <Descriptions.Item label="Country">{country}</Descriptions.Item>
                <Descriptions.Item label="Place of Business">{placeOfBusines}</Descriptions.Item>
                <Descriptions.Item label="Number of Employees">{numberOfEmployees}</Descriptions.Item>
                <Descriptions.Item label="Planned Usage">{plannedUsage}</Descriptions.Item>
                <Descriptions.Item label="Trading Turnover">{tradingTurnover}</Descriptions.Item>
                <Descriptions.Item label="Company Representative">{companyRep ? 'Yes' : 'No'}</Descriptions.Item>
                <Descriptions.Item label="Job Title">{jobTitle}</Descriptions.Item>
                <Descriptions.Item label="Company Email">{companyEmail}</Descriptions.Item>
                <Descriptions.Item label="Company Number">{companyNumber}</Descriptions.Item>
                <Descriptions.Item label="Created">{formatDate(created)}</Descriptions.Item>
                <Descriptions.Item label="Status">{statusText()}</Descriptions.Item>
            </Descriptions>
        </Modal>
    );
};

// Optional: Add PropTypes for better type checking during development
ViewModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    data: PropTypes.shape({
        name: PropTypes.string,
        compRegNumber: PropTypes.string,
        country: PropTypes.string,
        placeOfBusines: PropTypes.string,
        numberOfEmployees: PropTypes.string,
        plannedUsage: PropTypes.string,
        tradingTurnover: PropTypes.string,
        companyRep: PropTypes.bool,
        jobTitle: PropTypes.string,
        companyEmail: PropTypes.string,
        companyNumber: PropTypes.string,
        created: PropTypes.string,
        status: PropTypes.number,
    }),
};

export default ViewModal;
