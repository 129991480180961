import React, { useEffect, useState } from "react";
import { message, Table, Tag } from 'antd';
import { getQuery } from "../../../services/apiService";
import { convertDecimal128 } from "../../../utils/convertNumbers";

const BalanceOveriewTab = ({ activeTab, userID }) => {
    const [balances, setBlances] = useState([]);
    console.log(userID);

    useEffect(() => {
        const fetchBalances  = async () => {
            try{
                const response = await getQuery("/api/v1/user-balances/get-balances", { userID });
                if(response.status === 200 && response.data.status && response.data.data){
                    setBlances(response.data.data);
                    return
                }

                message.error('Balance data for this user not found.');
            }catch(error){
                message.error('Balance data for this user not found.');
            }
        }

        fetchBalances();
    }, [userID]);

    const columns = [
		{
			title: 'Asset',
			dataIndex: 'asset',
			key: 'asset',
			render: (text) => text.toUpperCase(),
		},
		{
			title: 'Total',
			dataIndex: 'total',
			key: 'total',
			render: (text) => convertDecimal128(text),
		},
		{
			title: 'Available',
			dataIndex: 'available',
			key: 'available',
			render: (text) => (
                <Tag color={'green'}>{convertDecimal128(text)}</Tag>
            ),
		},
		{
			title: 'Locked',
			dataIndex: 'locked',
			key: 'locked',
			render: (text) => (
				<Tag color={'red'}>
					{convertDecimal128(text)}
				</Tag>
			),
		},
	];
	return(
		<>
			<Table dataSource={balances} columns={columns} />
		</>
	)
}

export default BalanceOveriewTab;