import { DownloadOutlined } from "@ant-design/icons";
import { Button, Space, message, Switch, Tag } from "antd";
import React, { useState } from "react";
import PaginatedTable2 from "../../components/PaginatedTable2";
import { useApiQuery } from "../../hooks/useApiRequest";
import Add from "./Add";
import Delete from "./Delete";
import Edit from "./Edit";

const Emails = () => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const { data, isLoading } = useApiQuery({
    key: ["emails"],
    endPoint: `/api/v1/email-templates/get-all?page=${currentPage}&limit=${pageSize}`,
    method: "GET",
  });
  const pagination = {
    totalItems: data?.totalItems || 0,
    totalPages: data?.totalPages || 1,
    currentPage,
    limit: pageSize,
    startIndex: (currentPage - 1) * pageSize + 1,
    endIndex: Math.min(currentPage * pageSize, data?.totalItems || 0),
  };

  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const handleExport = () => {
    try {
      if (!data?.results?.length) {
        message.warning("No email templates available to export");
        return;
      }

      const headers = ["Template ID", "Template Name", "Active Status"];
      const rows = data.results.map((item) => [
        item.templateId || "N/A",
        item.templateName || "N/A",
        item.isActive ? "Active" : "Inactive",
      ]);

      const csvContent = [headers, ...rows]
        .map((row) =>
          row.map((field) => `"${String(field).replace(/"/g, '""')}"`).join(",")
        )
        .join("\n");

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "email_templates.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      message.success("Exported email templates successfully");
    } catch (error) {
      message.error("Failed to export email templates");
    }
  };

  const emailsColumns = [
    {
      title: "Template Name",
      dataIndex: "templateName",
      key: "templateName",
      render: (text) => text || "N/A",
    },
    {
      title: "Template ID",
      dataIndex: "templateId",
      key: "templateId",
      render: (text) => text || "N/A",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (status) => {
        // Comment: Added Tag import and usage
        let color;
        switch (status ? "Active" : "Inactive") {
          case "Inactive":
            color = "blue";
            break;
          case "Active":
            color = "green";
            break;
          default:
            color = "gray";
        }

        return <Tag color={color}>{status ? "ACTIVE" : "INACTIVE"}</Tag>;
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <Edit data={record} />
          <Delete record={record} />
        </Space>
      ),
    },
  ];

  return (
    <div className="mx-auto p-6 bg-white">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-semibold">Emails Templates</h1>
        <div className="text-xs text-gray-500">Dashboard &gt;Email</div>
      </div>
      <div className="flex justify-between mb-6">
        <button
          className="px-4 py-2 bg-[#22c6ab] text-white rounded hover:bg-[#1da089] transition"
          onClick={() => setIsAddModalVisible(true)}
        >
          + Add Template
        </button>
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={handleExport}
          className="bg-[#57249c] text-white px-4 py-2 rounded hover:bg-[#432076] transition"
        >
          Export (CSV)
        </Button>
      </div>
      <PaginatedTable2
        data={data?.results}
        columns={emailsColumns}
        pagination={pagination}
        onPageChange={handlePageChange}
        loading={isLoading}
      />
      {isAddModalVisible && (
        <Add
          visible={isAddModalVisible}
          onCancel={() => setIsAddModalVisible(false)}
          onSubmit={() => setIsAddModalVisible(false)}
        />
      )}
    </div>
  );
};

export default Emails;

// import { DownloadOutlined } from "@ant-design/icons";
// import { Button, Space, message, Switch } from "antd";
// import React, { useState } from "react";
// import PaginatedTable2 from "../../components/PaginatedTable2";
// import { useApiQuery } from "../../hooks/useApiRequest";
// import Add from "./Add";
// import Delete from "./Delete";
// import Edit from "./Edit";

// const Emails = () => {
//   const [isAddModalVisible, setIsAddModalVisible] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSize, setPageSize] = useState(20);

//   const { data, isLoading } = useApiQuery({
//     key: ["emails"],
//     endPoint: `/api/v1/email-templates/get-all?page=${currentPage}&limit=${pageSize}`,
//     method: "GET",
//   });

//   const pagination = {
//     totalItems: data?.totalItems || 0,
//     totalPages: data?.totalPages || 1,
//     currentPage,
//     limit: pageSize,
//     startIndex: (currentPage - 1) * pageSize + 1,
//     endIndex: Math.min(currentPage * pageSize, 0),
//   };

//   const handlePageChange = (page, size) => {
//     setCurrentPage(page);
//     setPageSize(size);
//   };

//   const handleExport = () => {
//     try {
//       if (!data?.results?.length) {
//         message.warning("No email templates available to export");
//         return;
//       }

//       const headers = ["Template ID", "Template Name", "Active Status"];
//       const rows = data.results.map((item) => [
//         item.templateId || "N/A",
//         item.templateName || "N/A",
//         item.isActive ? "Active" : "Inactive",
//       ]);

//       const csvContent = [headers, ...rows]
//         .map((row) =>
//           row.map((field) => `"${String(field).replace(/"/g, '""')}"`).join(",")
//         )
//         .join("\n");

//       const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
//       const url = URL.createObjectURL(blob);
//       const link = document.createElement("a");
//       link.href = url;
//       link.setAttribute("download", "email_templates.csv");
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);

//       message.success("Exported email templates successfully");
//     } catch (error) {
//       message.error("Failed to export email templates");
//     }
//   };

//   const toggleActiveStatus = (record) => {
//     message.info(`Toggling active status for ${record.templateName}`);
//   };

//   const emailsColumns = [
//     {
//       title: "Template Name",
//       dataIndex: "templateName",
//       key: "templateName",
//       render: (text) => text || "N/A",
//     },
//     {
//       title: "Template ID",
//       dataIndex: "templateId",
//       key: "templateId",
//       render: (text) => text || "N/A",
//     },

//     {
//       title: "Active",
//       dataIndex: "isActive",
//       key: "isActive",
//       render: (isActive, record) => (
//         <Switch
//           checked={isActive}
//           onChange={() => toggleActiveStatus(record)}
//         />
//       ),
//     },
//     {
//       title: "Actions",
//       key: "actions",
//       render: (_, record) => (
//         <Space size="middle">
//           <Edit data={record} />
//           <Delete record={record} />
//         </Space>
//       ),
//     },
//   ];

//   return (
//     <div className="mx-auto p-6 bg-white">
//       <h1 className="text-xl font-semibold mb-4">Email Templates</h1>
//       <div className="flex justify-between mb-6">
//         <button
//           className="px-4 py-2 bg-[#22c6ab] text-white rounded hover:bg-[#1da089] transition"
//           onClick={() => setIsAddModalVisible(true)}
//         >
//           + Add Template
//         </button>
//         <Button
//           type="primary"
//           icon={<DownloadOutlined />}
//           onClick={handleExport}
//           className="bg-[#57249c] text-white px-4 py-2 rounded hover:bg-[#432076] transition"
//         >
//           Export (CSV)
//         </Button>
//       </div>
//       <PaginatedTable2
//         data={data?.results}
//         columns={emailsColumns}
//         pagination={pagination}
//         onPageChange={handlePageChange}
//         loading={isLoading}
//       />
//       {isAddModalVisible && (
//         <Add
//           visible={isAddModalVisible}
//           onCancel={() => setIsAddModalVisible(false)}
//           onSubmit={() => setIsAddModalVisible(false)}
//         />
//       )}
//     </div>
//   );
// };

// export default Emails;
