// src/pages/BrokerApplications.jsx

import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { message, Breadcrumb, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons'; // Importing required icon
import AdminLayout from '../../layouts/AdminLayout';
import PaginatedTable from '../../components/PaginatedTable';
import ViewModal from '../../Modals/BrokerApplications/ViewModal';
import EditModal from '../../Modals/BrokerApplications/EditModal';
import DeleteModal from '../../Modals/BrokerApplications/DeleteModal';
import { debounce } from 'lodash';

const BrokerApplications = () => {
    const [allApplications, setAllApplications] = useState([]); // Store all broker applications
    const [filteredApplications, setFilteredApplications] = useState([]); // Store filtered applications
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Modal state variables
    const [isViewModalVisible, setIsViewModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);

    // Function to fetch broker applications data
    const fetchBrokerApplications = async () => {
        setLoading(true);
        try {
            const response = await axios.get('http://localhost:3000/api/v1/brokers/get-broker-applications');
            if (response.data.status) {
                const processedData = response.data.data.map((application) => ({
                    name: application.name || 'N/A',
                    compRegNumber: application.compRegNumber || 'N/A',
                    country: application.country || 'N/A',
                    placeOfBusines: application.placeOfBusines || 'N/A',
                    numberOfEmployees: application.numberOfEmployees || 'N/A',
                    plannedUsage: application.plannedUsage || 'N/A',
                    tradingTurnover: application.tradingTurnover || 'N/A',
                    companyRep: application.companyRep || false,
                    jobTitle: application.jobTitle || 'N/A',
                    companyEmail: application.companyEmail || 'N/A',
                    companyNumber: application.companyNumber || 'N/A',
                    created: application.created || 'N/A',
                    status: application.status || 'Unknown',
                    _id: application._id || '', // Assuming _id is the unique identifier
                }));
                setAllApplications(processedData);
                setFilteredApplications(processedData); // Initialize with all data
            } else {
                message.error('Failed to fetch broker applications.');
            }
        } catch (error) {
            message.error('Error fetching broker applications.');
            console.error('Error fetching broker applications:', error);
            setError('Failed to fetch broker applications.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBrokerApplications();
    }, []);

    // Debounced search handler
    const handleSearch = useCallback(
        debounce((filters) => {
            // Apply front-end filtering based on the filters object
            let filtered = allApplications;

            // Global Search
            if (filters.globalSearch) {
                const searchText = filters.globalSearch.toLowerCase();
                filtered = filtered.filter((application) =>
                    JSON.stringify(application).toLowerCase().includes(searchText)
                );
            }

            // Column-specific Filters
            Object.keys(filters).forEach((key) => {
                if (key !== 'globalSearch') {
                    const value = filters[key].toLowerCase();
                    if (value) {
                        filtered = filtered.filter((application) => {
                            const itemValue = getNestedValue(application, key);
                            return (
                                itemValue &&
                                itemValue.toString().toLowerCase().includes(value)
                            );
                        });
                    }
                }
            });

            setFilteredApplications(filtered);
        }, 300),
        [allApplications]
    );

    // Utility function to get nested values
    const getNestedValue = (obj, path) => {
        return path
            .split('.')
            .reduce((o, p) => (o && o[p] !== undefined && o[p] !== null ? o[p] : ''), obj);
    };

    // Modal handlers
    const handleView = (record) => {
        setSelectedRecord(record);
        setIsViewModalVisible(true);
    };

    const handleEdit = (record) => {
        setSelectedRecord(record);
        setIsEditModalVisible(true);
    };

    const handleDelete = (record) => {
        setSelectedRecord(record);
        setIsDeleteModalVisible(true);
    };

    const handleDeleteApplication = async (applicationId) => {
        try {
            await axios.delete(`http://localhost:3000/api/v1/brokers/delete-broker-application?id=${applicationId}`);
            message.success('Broker application deleted successfully.');
            fetchBrokerApplications();
            setIsDeleteModalVisible(false);
        } catch (error) {
            console.error('Failed to delete broker application:', error);
            message.error('Failed to delete broker application.');
        }
    };

    // Function to convert JSON data to CSV
    const convertToCSV = (data) => {
        const headers = [
            'Name',
            'Company Reg Number',
            'Country',
            'Place of Business',
            'Number of Employees',
            'Planned Usage',
            'Trading Turnover',
            'Company Rep',
            'Job Title',
            'Company Email',
            'Company Number',
            'Created',
            'Status',
        ];

        const rows = data.map((item) => [
            item.name || '',
            item.compRegNumber || '',
            item.country || '',
            item.placeOfBusines || '',
            item.numberOfEmployees || '',
            item.plannedUsage || '',
            item.tradingTurnover || '',
            item.companyRep ? 'Yes' : 'No',
            item.jobTitle || '',
            item.companyEmail || '',
            item.companyNumber || '',
            formatDate(item.created) || '',
            item.status || '',
        ]);

        const csvContent = [headers, ...rows]
            .map((row) =>
                row
                    .map((field) => `"${String(field).replace(/"/g, '""')}"`)
                    .join(',')
            )
            .join('\n');

        return csvContent;
    };

    const handleExport = () => {
        try {
            if (filteredApplications.length === 0) {
                message.warning('No broker applications available to export');
                return;
            }

            const csvData = convertToCSV(filteredApplications);
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'broker_applications_export.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            message.success('Exported broker applications data successfully');
        } catch (error) {
            message.error('Failed to export broker applications');
            console.error('Export error:', error);
        }
    };

    // Function to format date as "07 November 2024, 07:30"
    const formatDate = (dateString) => {
        if (!dateString || dateString === 'N/A') return 'N/A';
        const date = new Date(dateString);
        if (isNaN(date)) return 'Invalid Date';
        const day = String(date.getDate()).padStart(2, '0');
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day} ${month} ${year}, ${hours}:${minutes}`;
    };

    const columns = [
        { title: 'Name', dataIndex: 'name', key: 'name' },
        { title: 'Company Reg Number', dataIndex: 'compRegNumber', key: 'compRegNumber' },
        { title: 'Country', dataIndex: 'country', key: 'country' },
        { title: 'Place of Business', dataIndex: 'placeOfBusines', key: 'placeOfBusines' },
        { title: 'Number of Employees', dataIndex: 'numberOfEmployees', key: 'numberOfEmployees' },
        { title: 'Planned Usage', dataIndex: 'plannedUsage', key: 'plannedUsage' },
        { title: 'Trading Turnover', dataIndex: 'tradingTurnover', key: 'tradingTurnover' },
        {
            title: 'Company Rep',
            dataIndex: 'companyRep',
            key: 'companyRep',
            render: (text) => (text ? 'Yes' : 'No'),
        },
        { title: 'Job Title', dataIndex: 'jobTitle', key: 'jobTitle' },
        { title: 'Company Email', dataIndex: 'companyEmail', key: 'companyEmail' },
        { title: 'Company Number', dataIndex: 'companyNumber', key: 'companyNumber' },
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            render: (text) => formatDate(text),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => {
                switch (text) {
                    case 0:
                        return 'Pending';
                    case 1:
                        return 'Approved';
                    case 2:
                        return 'Rejected';
                    default:
                        return 'Unknown';
                }
            },
        },
        // Removed manual 'Actions' column to prevent ESLint errors and adhere to your preferences
    ];

    if (error) {
        return (
            <AdminLayout>
                <div className="mx-auto p-6 bg-white">
                    <div className="text-red-500">Error: {error}</div>
                </div>
            </AdminLayout>
        );
    }

    return (
        <AdminLayout>
            <div className="mx-auto p-6 bg-white">
                <Breadcrumb style={{ marginBottom: '20px' }}>
                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item>Broker Applications</Breadcrumb.Item>
                </Breadcrumb>
                <h1 className="text-2xl font-semibold mb-6">Broker Applications</h1>
                <div className="flex justify-between items-center mb-6">
                    <Button
                        type="primary"
                        icon={<DownloadOutlined />}
                        onClick={handleExport}
                        className="flex items-center px-4 py-2 bg-[#57249c] text-white rounded-lg hover:bg-[#432076] transition"
                    >
                        Export (csv)
                    </Button>
                </div>
                <PaginatedTable
                    columns={columns}
                    itemsPerPage={10}
                    onView={handleView}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    searchFilters={null} // Not used since filtering is handled internally
                    onSearch={handleSearch}
                    dataSource={filteredApplications}
                    isSearchable={true} // Ensure search is enabled
                    loading={loading}
                />
                {/* Modals */}
                {selectedRecord && (
                    <>
                        <ViewModal
                            visible={isViewModalVisible}
                            onCancel={() => setIsViewModalVisible(false)}
                            data={selectedRecord}
                        />
                        <EditModal
                            visible={isEditModalVisible}
                            onCancel={() => setIsEditModalVisible(false)}
                            data={selectedRecord}
                            refreshData={fetchBrokerApplications}
                        />
                        <DeleteModal
                            visible={isDeleteModalVisible}
                            onCancel={() => setIsDeleteModalVisible(false)}
                            applicationId={selectedRecord?._id}
                            onDelete={handleDeleteApplication}
                        />
                    </>
                )}
            </div>
        </AdminLayout>
    );
};

export default BrokerApplications;
