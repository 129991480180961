import { useQueryClient } from "@tanstack/react-query";
import { message } from 'antd';
import { useApiMutation, useApiQuery } from "../hooks/useApiRequest";

//* ---------Get all deposits---------
export const useGetAllDeposits = () => {
  return useApiQuery({
    key: ["user-deposits"],
    endPoint: `/api/v1/user-deposits`,
    method: "GET",
  });
};

//*********** add user Deposit**********
export const useAddUserDepositService = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, error } = useApiMutation();

  const addUserDeposit = async (values, onSuccess, onError) => {
    try {
      await mutateAsync({
        key: ["user-deposits-add"],
        endPoint: `/api/v1/user-deposits/create`,
        method: "POST",
        bodyContent: values,
      });
      queryClient.invalidateQueries({ queryKey: ["user-deposits"] });
      queryClient.invalidateQueries({ queryKey: ["user-deposits-add"] });
      onSuccess();
      message.success('Successfully added');
    } catch (error) {
      onError();
      message.error(error.message);
    }
  };

  return { addUserDeposit, error };
};


//*********** Update user deposit**********
export const useUpdateUserDepositService = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, error } = useApiMutation();

  const updateUserDeposit = async (values, id, onSuccess, onError) => {
    try {
      await mutateAsync({
        key: ["user-deposits-update", id],
        endPoint: `/api/v1/user-deposits/${id}`,
        method: "PATCH",
        bodyContent: values,
      });
      queryClient.invalidateQueries({ queryKey: ["user-deposits"] });
      queryClient.invalidateQueries({ queryKey: ["user-deposits-update", id] });
      onSuccess();
      message.success('Successfully updated');
    } catch (error) {
      onError();
      message.error(error.message);
    }
  };

  return { updateUserDeposit, error };
};

//*********** Delete user deposit **********
export const useDeleteUserDepositService = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, error } = useApiMutation();

  const deleteDeposit = async (id, onSuccess, onError) => {
    try {
      await mutateAsync({
        key: ["user-deposits-delete"],
        endPoint: `/api/v1/user-deposits/${id}`,
        method: "DELETE",
      });
      queryClient.invalidateQueries({ queryKey: ["user-deposits"] });
      queryClient.invalidateQueries({ queryKey: ["user-deposits-delete"] });
      onSuccess();
      message.success('Successfully deleted');
    } catch (error) {
      onError();
      message.error(error.message);
    }
  };

  return { deleteDeposit, error };
};



//*********** Approved user Deposit**********
export const useApprovedDepositService = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, error } = useApiMutation();

  const approveUserDeposit = async (id, onSuccess, onError) => {
    try {
      await mutateAsync({
        key: ['user-deposits-approved', id],
        endPoint: `/api/v1/user-deposits/${id}`,
        method: 'POST',
        // bodyContent: values
      });
      queryClient.invalidateQueries({ queryKey: ['user-deposits'] });
      queryClient.invalidateQueries({ queryKey: ['user-deposit-approved', id] });
      onSuccess();
      message.success('Successfully approved');
    } catch (error) {
      onError();
      message.error(error.message);
    }
  };

  return { approveUserDeposit, error };
};










