import { DownloadOutlined } from "@ant-design/icons";
import { Button, message, Tag } from "antd";
import React, { useState } from "react";
import { formatDate } from "../../utils/formateData";
import { useReversePaymentService } from "../../services/paymentService";
import PaginatedTable2 from "../../components/PaginatedTable2";
import { useApiQuery } from "../../hooks/useApiRequest";
import Add from "./Add";

const Index = () => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const { reversePayment } = useReversePaymentService();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const { data, isLoading } = useApiQuery({
    key: ["payment"],
    endPoint: `/api/v1/pay/get-all?page=${currentPage}&limit=${pageSize}`,
    method: "GET",
  });

  const pagination = {
    totalItems: data?.totalItems || 0,
    totalPages: data?.totalPages || 1,
    currentPage,
    limit: pageSize,
    startIndex: (currentPage - 1) * pageSize + 1,
    endIndex: Math.min(currentPage * pageSize, data?.totalItems || 0),
  };

  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const handleExport = () => {
    try {
      if (!data?.results?.length) {
        message.warning("No Transaction Payment available to export");
        return;
      }

      const headers = [
        "Sender ID",
        "Receiver Pay ID",
        "Receiver Email",
        "Receiver Phone",
        "Amount",
        "Currency",
        "Status",
        "Transaction Date",
      ];

      const rows = data?.results.map((item) => [
        item.senderId,
        item.receiverPayId,
        item.receiverEmail,
        item.receiverPhone,
        item.amount,
        item.currency,
        item.status,
        formatDate(item.transactionDate),
      ]);

      const csvContent = [headers, ...rows]
        .map((row) =>
          row.map((field) => `"${String(field).replace(/"/g, '""')}"`).join(",")
        )
        .join("\n");

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Transaction_payment_export.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      message.success("Exported Transaction Payment successfully");
    } catch (error) {
      message.error("Failed to export Transaction Payment");
    }
  };
  const handleReverse = async (transactionId, asset) => {
    if (!transactionId) {
      message.error("Transaction ID is missing.");
      return;
    }
    console.log("Reversing transaction:", transactionId);

    const payload = {
      transactionId,
      asset: asset?.toLowerCase(),
    };

    reversePayment(
      payload,
      () => {},
      () => {
        message.error("Failed to reverse transaction.");
      }
    );
  };

  const Paymentcolumns = [
    {
      title: "Sender",
      key: "sender",
      render: (_, record) => {
        const sender = record.senderId;
        if (!sender) return "N/A";
        return <>{sender.email || "No Email"}</>;
      },
    },
    {
      title: "Pay ID",
      dataIndex: "receiverPayId",
      key: "receiverPayId",
      render: (text) => text || "N/A",
    },
    {
      title: "Email",
      dataIndex: "receiverEmail",
      key: "receiverEmail",
      render: (text) => text || "N/A",
    },
    {
      title: "Phone",
      dataIndex: "receiverPhone",
      key: "receiverPhone",
      render: (text) => text || "N/A",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => {
        const value = amount?.$numberDecimal || "0";
        return `R ${parseFloat(value).toFixed(2)}`;
      },
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      render: (text) => text?.toUpperCase() || "N/A",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color;
        switch (status.toLowerCase()) {
          case "pending":
            color = "orange";
            break;
          case "completed":
            color = "green";
            break;
          case "failed":
            color = "red";
            break;
          case "reversed":
            color = "purple";
            break;
          default:
            color = "gray";
        }

        return (
          <Tag color={color} className="font-semibold">
            {status.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: "Transaction Date",
      dataIndex: "transactionDate",
      key: "transactionDate",
      render: (date) => formatDate(date),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => {
        const transactionDate = new Date(record.transactionDate);
        const currentDate = new Date();
        const hoursDifference =
          (currentDate - transactionDate) / (1000 * 60 * 60);

        if (hoursDifference <= 24) {
          return (
            <Button
              type="primary"
              onClick={() => handleReverse(record._id, record.currency)}
            >
              Reverse
            </Button>
          );
        }
        return null;
      },
    },
  ];

  return (
    <div className="mx-auto p-6 bg-white">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-semibold">80Eight-Pay</h1>
        <div className="text-xs text-gray-500">Dashboard &gt; 80Eight Pay</div>
      </div>

      <div className="flex justify-between items-center mb-6">
        <button
          className="px-4 rounded-lg py-2 bg-[#22c6ab] text-white hover:bg-[#1da089] transition"
          onClick={() => setIsAddModalVisible(true)}
        >
          + Add 80Eight Payment
        </button>
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={handleExport}
          className="flex items-center px-4 py-2 bg-[#57249c] text-white rounded-lg hover:bg-[#432076] transition"
        >
          Export (csv)
        </Button>
      </div>

      <PaginatedTable2
        data={data?.results}
        columns={Paymentcolumns}
        pagination={pagination}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageChange}
        loading={isLoading}
      />

      {isAddModalVisible && (
        <Add
          visible={isAddModalVisible}
          onCancel={() => setIsAddModalVisible(false)}
          onSubmit={() => {
            setIsAddModalVisible(false);
          }}
          userList={data?.results || []}
        />
      )}
    </div>
  );
};

export default Index;
