import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { Link, useParams } from 'react-router-dom';
import LoadingComponent from '../../components/LoadingComponent';
import { useGetPoliciesById } from '../../services/policieService';

function View() {
  const { id } = useParams();
  const { data, isLoading } = useGetPoliciesById(id);

  if (isLoading) return <LoadingComponent />

  return (
    <div className="p-6 bg-white">

      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-semibold">View Policies</h1>
        <div className="text-xs text-gray-500">
          Dashboard &gt; Policies
        </div>
      </div>

      <Link to="/admin/policies" className="flex justify-between items-center mb-6">
        <button
          className="px-4 rounded-lg py-2 bg-[#22c6ab] text-white hover:bg-[#1da089] transition"
        >
          Back
        </button>
      </Link>


      <h1 className="text-xl font-semibold mb-4">{data?.name}</h1>
      <h4 className="text-xl font-semibold mb-4">{data?.rawText}</h4>
      <ReactQuill
        value={data?.text}
        readOnly={true}
        theme="bubble"
      />
    </div>
  );
}

export default View;
