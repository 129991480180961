import { PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Space, Steps, message } from "antd";
import React, { useState } from "react";
import TradingAssetForm from "../../models/TradingAsset";
import TradingAssetChainForm from "../../models/TradingAssetChain";
import { create } from "../../services/apiService";

const { Step } = Steps;

const Create = ({ onCreateSuccess }) => {
  const [visible, setVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(1); // Track current step
  const [formData, setFormData] = useState({}); // Store form data

  const toggleForm = () => {
    setVisible(!visible);
  };

  const handleNextStep = (data) => {
    setFormData((prevData) => ({
      ...prevData,
      ...data,
    }));
    setCurrentStep(2); // Move to next step
  };

  const onPrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1); // Go back to previous step
    }
  };

  const handleFinalSubmit = async (data) => {
    try {
      const finalData = { ...formData, ...data };
      console.log('Final submitted data:', finalData);
      const response = await create('/api/v1/trading-assets/create', finalData);

      if (response.status == 201 && response.data.data) {
        message.success('Trading asset created successfully');
        onCreateSuccess(Math.random() > 0.5);
        toggleForm();
      }
    } catch (error) {
      message.error('Could not create new asset')
    }
  };

  const stepsData = [
    { title: "Step 1: Trading Asset Details" },
    { title: "Step 2: Trading Asset Chain" },
  ];

  if (!visible) {
    return (
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold">Trading Assets</h1>
        <Space>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={toggleForm}
            style={{ backgroundColor: '#22c6ab', borderColor: '#22c6ab' }}
          >
            Create Asset
          </Button>
        </Space>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto mt-8">
      <Modal
        title="Create Trading Asset"
        open={visible}
        onCancel={toggleForm}
        footer={null}
        width={1200}
        destroyOnClose
        style={{ maxHeight: '70vh', overflowY: 'auto' }}
      >
        {/* Steps component for showing progress */}
        <Steps current={currentStep - 1} size="small" className="mb-6">
          {stepsData.map((step, index) => (
            <Step key={index} title={step.title} />
          ))}
        </Steps>

        {/* Render forms based on the current step */}
        {currentStep === 1 && (
          <TradingAssetForm
            onNextStep={handleNextStep}
            previousData={formData}
          />
        )}
        {currentStep === 2 && (
          <TradingAssetChainForm
            onSubmit={handleFinalSubmit}
            onPrevious={onPrevious}
            previousData={formData}
          />
        )}
      </Modal>
    </div>
  );
};

export default Create;
