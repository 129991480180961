// UpdateSystemSettings.jsx

import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, InputNumber, Tabs, Button, message } from 'antd';
import {
  MailOutlined,
  ClockCircleOutlined,
  DollarOutlined,
  PercentageOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import axios from 'axios';

const { TabPane } = Tabs;
const { TextArea } = Input;

const UpdateSystemSettings = ({ visible, onCancel, settings, onUpdateSuccess }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  // Update form data when settings prop changes
  useEffect(() => {
    if (settings && visible) {
      const initialValues = {};
      settings.forEach((setting, index) => {
        initialValues[`downEmailNotifications_${index}`] = setting.downEmailNotifications.join(', ');
        initialValues[`quoteTime_${index}`] = setting.quoteTime ? new Date(setting.quoteTime) : null;
        initialValues[`minQuoteTrade_${index}`] = setting.minQuoteTrade;
        initialValues[`arbitrageCommission_${index}`] = setting.arbitrageCommission;
        initialValues[`basicWithdrawalLimit_${index}`] = setting.basicWithdrawalLimit;
        initialValues[`advancedWithdrawalLimit_${index}`] = setting.advancedWithdrawalLimit;
        initialValues[`proWithdrawalLimit_${index}`] =
          setting.proWithdrawalLimit === 999999999999 ? '' : setting.proWithdrawalLimit;
        initialValues[`inactiveTimeout_${index}`] = setting.inactiveTimeout;
      });
      form.setFieldsValue(initialValues);
    }
  }, [settings, visible, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);
      const updatedSettings = settings.map((setting, index) => {
        return {
          ...setting,
          downEmailNotifications: values[`downEmailNotifications_${index}`]
            ? values[`downEmailNotifications_${index}`]
                .split(',')
                .map((email) => email.trim())
                .filter((email) => /^\S+@\S+\.\S+$/.test(email))
            : [],
          quoteTime: values[`quoteTime_${index}`] ? values[`quoteTime_${index}`].toISOString() : null,
          minQuoteTrade: values[`minQuoteTrade_${index}`],
          arbitrageCommission: values[`arbitrageCommission_${index}`],
          basicWithdrawalLimit: values[`basicWithdrawalLimit_${index}`],
          advancedWithdrawalLimit: values[`advancedWithdrawalLimit_${index}`],
          proWithdrawalLimit:
            values[`proWithdrawalLimit_${index}`] === '' ? 999999999999 : values[`proWithdrawalLimit_${index}`],
          inactiveTimeout: values[`inactiveTimeout_${index}`],
        };
      });
      const updatePromises = updatedSettings.map((setting) =>
        axios.put(`http://localhost:3000/api/v1/settings/rfq-settings/${setting._id}`, setting)
      );
      await Promise.all(updatePromises);
      setLoading(false);
      message.success('Settings updated successfully');
      onUpdateSuccess();
      onCancel();
    } catch (err) {
      console.error('Error updating settings:', err);
      message.error('Failed to update settings. Please try again.');
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      title={<span style={{ color: '#1890ff' }}>Update System Settings</span>}
      onCancel={onCancel}
      onOk={handleSubmit}
      confirmLoading={loading}
      width={800}
      bodyStyle={{ maxHeight: '70vh', overflowY: 'auto' }}
    >
      <Form form={form} layout="vertical">
        <Tabs defaultActiveKey="0">
          {settings.map((setting, index) => (
            <TabPane
              tab={
                <span>
                  <SettingOutlined style={{ color: '#595959', marginRight: 4 }} />
                  Setting {index + 1}
                </span>
              }
              key={index.toString()}
            >
              <Form.Item
                label={
                  <span>
                    <MailOutlined style={{ color: '#595959', marginRight: 4 }} /> Down Email Notifications
                  </span>
                }
                name={`downEmailNotifications_${index}`}
                tooltip="Enter emails separated by commas"
              >
                <TextArea placeholder="Enter emails separated by commas" />
              </Form.Item>

              <Form.Item
                label={
                  <span>
                    <ClockCircleOutlined style={{ color: '#595959', marginRight: 4 }} /> Quote Time
                  </span>
                }
                name={`quoteTime_${index}`}
              >
                <Input type="datetime-local" />
              </Form.Item>

              <Form.Item
                label={
                  <span>
                    <DollarOutlined style={{ color: '#595959', marginRight: 4 }} /> Minimum Quote Trade ($)
                  </span>
                }
                name={`minQuoteTrade_${index}`}
              >
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>

              <Form.Item
                label={
                  <span>
                    <PercentageOutlined style={{ color: '#595959', marginRight: 4 }} /> Arbitrage Commission (%)
                  </span>
                }
                name={`arbitrageCommission_${index}`}
              >
                <InputNumber min={0} max={100} step={0.01} style={{ width: '100%' }} />
              </Form.Item>

              <Form.Item
                label={
                  <span>
                    <DollarOutlined style={{ color: '#595959', marginRight: 4 }} /> Basic Withdrawal Limit ($)
                  </span>
                }
                name={`basicWithdrawalLimit_${index}`}
              >
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>

              <Form.Item
                label={
                  <span>
                    <DollarOutlined style={{ color: '#595959', marginRight: 4 }} /> Advanced Withdrawal Limit ($)
                  </span>
                }
                name={`advancedWithdrawalLimit_${index}`}
              >
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>

              <Form.Item
                label={
                  <span>
                    <DollarOutlined style={{ color: '#595959', marginRight: 4 }} /> Pro Withdrawal Limit ($)
                  </span>
                }
                name={`proWithdrawalLimit_${index}`}
                tooltip="Enter '999999999999' for Unlimited"
              >
                <InputNumber
                  min={0}
                  max={999999999999}
                  style={{ width: '100%' }}
                  placeholder="Enter '999999999999' for Unlimited"
                />
              </Form.Item>

              <Form.Item
                label={
                  <span>
                    <ClockCircleOutlined style={{ color: '#595959', marginRight: 4 }} /> Inactive Timeout (minutes)
                  </span>
                }
                name={`inactiveTimeout_${index}`}
              >
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </TabPane>
          ))}
        </Tabs>
      </Form>
    </Modal>
  );
};

export default UpdateSystemSettings;
