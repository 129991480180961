import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select } from 'antd';
import React, { useState } from 'react';
import { useUpdateAPIKeyService } from '../../../../services/apiKeyService'; // Replace with your service
const { Option } = Select;

const EditApiKey = ({ data }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const { updateAPIKey } = useUpdateAPIKeyService();

    const handleEdit = async () => {
        setLoading(true);
        try {
            const values = await form.validateFields();

            const payload = {
                _id: data?._id,
                apiName: values.apiName,
                env: values.env,
                testUrl: values.testUrl,
                liveUrl: values.liveUrl,
                testKey: values.testKey,
                testSecret: values.testSecret,
                liveKey: values.liveKey,
                liveSecret: values.liveSecret,
                // authKey: values.authKey
            };

            updateAPIKey(
                payload,
                () => {
                    toggleView();
                    setLoading(false);
                },
                () => setLoading(false)
            );
        } catch (error) {
            setLoading(false);
        }
    };

    const toggleView = () => {
        setVisible(!visible);
    };

    if (!visible) {
        return (
            <Button
                type="text"
                icon={<EditOutlined />}
                style={{
                    padding: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                onClick={toggleView}
            />
        );
    }

    return (
        <Modal
            visible={visible}
            title="Edit API Key"
            onCancel={toggleView}
            footer={[
                <Button key="cancel" onClick={toggleView}>
                    Cancel
                </Button>,
                <Button
                    key="save"
                    type="primary"
                    onClick={handleEdit}
                    loading={loading}
                >
                    Save
                </Button>,
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    apiName: data?.apiName,
                    env: data?.env,
                    testUrl: data?.testUrl,
                    liveUrl: data?.liveUrl,
                    testKey: data?.testKey,
                    testSecret: data?.testSecret,
                    liveKey: data?.liveKey,
                    liveSecret: data?.liveSecret,
                    // authKey: data?.authKey,
                }}
            >
                <Form.Item
                    name="apiName"
                    label="API Name"
                    rules={[{ required: true, message: 'Please enter API name' }]}
                >
                    <Input placeholder="Enter API name" />
                </Form.Item>

                <Form.Item
                    name="env"
                    label="Environment"
                    rules={[{ required: true, message: 'Please select an environment' }]}
                >
                    <Select placeholder="Select environment">
                        <Option value="dev">dev</Option>
                        <Option value="test">test</Option>
                        <Option value="live">live</Option>
                    </Select>
                </Form.Item>

                <Form.Item name="testUrl" label="Test URL">
                    <Input placeholder="Enter test URL" />
                </Form.Item>

                <Form.Item name="liveUrl" label="Live URL">
                    <Input placeholder="Enter live URL" />
                </Form.Item>

                <Form.Item name="testKey" label="Test Key">
                    <Input placeholder="Enter test key" />
                </Form.Item>

                <Form.Item name="testSecret" label="Test Secret">
                    <Input placeholder="Enter test secret" />
                </Form.Item>

                <Form.Item name="liveKey" label="Live Key">
                    <Input placeholder="Enter live key" />
                </Form.Item>

                <Form.Item name="liveSecret" label="Live Secret">
                    <Input placeholder="Enter live secret" />
                </Form.Item>

                {/* <Form.Item name="authKey" label="Auth Key">
                    <Input placeholder="Enter auth key" />
                </Form.Item> */}
            </Form>
        </Modal>
    );
};

export default EditApiKey;
