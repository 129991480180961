import { DownloadOutlined } from "@ant-design/icons"; // Importing required icon
import { Button, message, Space } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import PaginatedTable2 from "../../../components/PaginatedTable2";
import { useApiQuery } from "../../../hooks/useApiRequest";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  useApprovedWithdrawalService,
  useRejectWithdrawalService,
} from "../../../services/withdrawalService";
import { formatDate } from "../../../utils/formateData";
import Add from "./Add";
import Delete from "./Delete";
import Edit from "./Edit";
import View from "./View";

const Index = () => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const { approveUserWithdrawal } = useApprovedWithdrawalService();
  const { rejectWithdrawal } = useRejectWithdrawalService();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [loadingStates, setLoadingStates] = useState({});

  const { data, isLoading } = useApiQuery({
    key: ["user-withdrawal"],
    endPoint: `/api/v1/withdrawals?page=${currentPage}&limit=${pageSize}`,
    method: "GET",
  });

  const pagination = {
    totalItems: data?.totalItems || 0,
    totalPages: data?.totalPages || 1,
    currentPage,
    limit: pageSize,
    startIndex: (currentPage - 1) * pageSize + 1,
    endIndex: Math.min(currentPage * pageSize, data?.totalItems || 0),
  };

  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const withdrawalColumns = [
    {
      title: "User",
      dataIndex: `user`,
      key: "userId",
      render: (text, record) =>
        `${record?.userId?.username} - ${record?.userId?.email} ` || "N/A",
    },
    {
      title: "Reference",
      dataIndex: "txid",
      key: "txid",
      render: (text) => text || "N/A",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => formatDate(date),
    },
    {
      title: "Asset",
      dataIndex: "asset",
      key: "asset",
      render: (text) => text?.toUpperCase() || "N/A",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `R ${parseFloat(amount?.$numberDecimal).toFixed(8)}`,
    },
    {
      title: "Fees",
      dataIndex: "fees",
      key: "fees",
      render: (fees) => `${parseFloat(fees?.$numberDecimal).toFixed(8)}`,
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      render: (text) => text || "N/A",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => {
        const isApproveLoading = loadingStates[record._id]?.approve;
        const isRejectLoading = loadingStates[record._id]?.reject;

        if (status === "Pending") {
          return (
            <div className="flex justify-between items-center gap-4 mb-6">
              <button
                className="px-3 py-1 text-sm font-semibold text-white bg-green-500 rounded hover:bg-yellow-700"
                onClick={() => handleApprove(record)}
                disabled={isApproveLoading}
              >
                {isApproveLoading ? "Loading..." : "Approve"}
              </button>
              <button
                className="px-3 py-1 text-sm font-semibold text-white bg-yellow-500 rounded hover:bg-yellow-700"
                onClick={() => handleEditStatus(record)}
                disabled={isRejectLoading}
              >
                {isRejectLoading ? "Loading..." : "Reject"}
              </button>
            </div>
          );
        }

        const statusColors = {
          None: "text-gray-500",
          Saved: "text-blue-500",
          Queued: "text-orange-500",
          Approved: "text-green-600",
        };

        const statusColorClass = statusColors[status] || "text-red-500";

        return (
          <span className={`text-sm font-semibold ${statusColorClass}`}>
            {status}
          </span>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => {
        return (
          <Space size="middle">
            <Link>
              {" "}
              <View data={record} />{" "}
            </Link>
            <Link>
              {" "}
              <Edit data={record} />{" "}
            </Link>
            <Link>
              {" "}
              <Delete record={record} />{" "}
            </Link>
          </Space>
        );
      },
    },
  ];

  const setLoadingForRecord = (recordId, type, value) => {
    setLoadingStates((prev) => ({
      ...prev,
      [recordId]: {
        ...prev[recordId],
        [type]: value,
      },
    }));
  };

  const handleApprove = async (record) => {
    setLoadingForRecord(record._id, "approve", true);
    approveUserWithdrawal(
      record?._id,
      () => setLoadingForRecord(record._id, "approve", false),
      () => setLoadingForRecord(record._id, "approve", false)
    );
  };

  const handleEditStatus = async (record) => {
    setLoadingForRecord(record._id, "reject", true);
    rejectWithdrawal(
      record?._id,
      () => setLoadingForRecord(record._id, "reject", false),
      () => setLoadingForRecord(record._id, "reject", false)
    );
  };

  return (
    <AdminLayout currentKey={"dw-sub3"} openKey="5">
      <div className="mx-auto p-6 bg-white">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-xl font-semibold">Client Withdrawal</h1>
          <div className="text-xs text-gray-500">
            Dashboard &gt; Deposits/Withdrawals &gt; Deposits
          </div>
        </div>

        <div className="flex justify-between items-center mb-6">
          <button
            className="px-4 rounded-lg py-2 bg-[#22c6ab] text-white hover:bg-[#1da089] transition"
            onClick={() => setIsAddModalVisible(true)}
          >
            + Add Withdrawal
          </button>
          {/* <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={handleExport}
            className="flex items-center px-4 py-2 bg-[#57249c] text-white rounded-lg hover:bg-[#432076] transition"
          >
            Export (csv)
          </Button> */}
        </div>

        <PaginatedTable2
          data={data?.results}
          columns={withdrawalColumns}
          pagination={pagination}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageChange}
          loading={isLoading}
        />

        {isAddModalVisible && (
          <Add
            visible={isAddModalVisible}
            onCancel={() => setIsAddModalVisible(false)}
            onSubmit={() => {
              setIsAddModalVisible(false);
            }}
          />
        )}
      </div>
    </AdminLayout>
  );
};

export default Index;

// import { DownloadOutlined } from '@ant-design/icons'; // Importing required icon
// import { Button, message, Space } from 'antd';
// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import PaginatedTable from '../../../components/PaginatedTable';
// import AdminLayout from '../../../layouts/AdminLayout';
// import { useApprovedWithdrawalService, useGetAllWithdrawal } from '../../../services/withdrawalService';
// import { formatDate } from '../../../utils/formateData';
// import Add from './Add';
// import Delete from './Delete';
// import Edit from './Edit';
// import View from './View';

// const Index = () => {
//     const [isAddModalVisible, setIsAddModalVisible] = useState(false);
//     const { data } = useGetAllWithdrawal();
//     const { approveUserWithdrawal } = useApprovedWithdrawalService();

//     const withdrawalColumns = [
//         {
//             title: 'User',
//             dataIndex: `user`,
//             key: 'userId',
//             render: (text, record) => `${record?.userId?.username} - ${record?.userId?.email} ` || "N/A"
//         },
//         {
//             title: 'Reference',
//             dataIndex: 'txid',
//             key: 'txid',
//             render: (text) => text || 'N/A'
//         },
//         {
//             title: 'Date',
//             dataIndex: 'date',
//             key: 'date',
//             render: (date) => formatDate(date),
//         },
//         {
//             title: 'Asset',
//             dataIndex: 'asset',
//             key: 'asset',
//             render: (text) => text?.toUpperCase() || 'N/A',
//         },
//         {
//             title: 'Amount',
//             dataIndex: 'amount',
//             key: 'amount',
//             render: (amount) => `R ${parseFloat(amount?.$numberDecimal).toFixed(8)}`,
//         },
//         {
//             title: 'Fees',
//             dataIndex: 'fees',
//             key: 'fees',
//             render: (fees) => `${parseFloat(fees?.$numberDecimal).toFixed(8)}`,

//         },
//         {
//             title: 'Notes',
//             dataIndex: 'notes',
//             key: 'notes',
//             render: (text) => text || 'N/A'
//         },
//         {
//             title: 'Status',
//             dataIndex: 'status',
//             key: 'status',
//             render: (status, record) => {
//                 console.log(" status : ", status)

//                 if (status === 'Pending') {
//                     return (
//                         <button
//                             className="px-3 py-1 text-sm font-semibold text-white bg-yellow-500 rounded hover:bg-yellow-600"
//                             onClick={() => handleEditStatus(record)}
//                         >
//                             Mark Completed
//                         </button>
//                     );
//                 }

//                 const statusColors = {
//                     'None': 'text-gray-500',
//                     'Saved': 'text-blue-500',
//                     'Queued': 'text-orange-500',
//                     'Approved': 'text-green-600',
//                 };

//                 const statusColorClass = statusColors[status] || 'text-red-500';

//                 return (
//                     <span className={`text-sm font-semibold ${statusColorClass}`}>
//                         {status}
//                     </span>
//                 );
//             },
//         },
//         {
//             title: "Actions",
//             key: "actions",
//             render: (text, record) => {
//                 return (
//                     <Space size="middle">
//                         <Link> <View data={record} /> </Link>
//                         <Link>  <Edit data={record} /> </Link>
//                         <Link>  <Delete record={record} /> </Link>
//                     </Space>
//                 )
//             },
//         }
//     ];

//     const handleEditStatus = async (record) => {
//         approveUserWithdrawal(
//             record?._id,
//             () => console.log(" successfully approve"),
//             () => console.log(" failed  to update the status")
//         )
//     }

//     const convertToCSV = (data) => {
//         const headers = [
//             'User ID',
//             'Date',
//             'Asset',
//             'Amount',
//             'Fees',
//             'Total',
//             'Notes',
//             'Status',
//             'Transaction ID',
//             'User Banking ID',
//             'User Address ID',
//             'Trading Asset Chain ID',
//             'Fireblocks ID',
//         ];

//         const rows = data?.map((item) => [
//             item.userId || '',
//             formatDate(item?.date) || '',
//             item.asset || '',
//             item.amount?.$numberDecimal || '',
//             item.fees?.$numberDecimal || '',
//             (
//                 parseFloat(item.amount?.$numberDecimal || 0) +
//                 parseFloat(item.fees?.$numberDecimal || 0)
//             ).toFixed(8),
//             item.notes || '',
//             item.status || '',
//             item.transactionId || '',
//             item.userBankingId || '',
//             item.userAddressId || '',
//             item.tradingAssetChainId || '',
//             item.fireblocksId || '',
//         ]);

//         const csvContent = [headers, ...rows].map((row) =>
//             row?.map((field) => `"${String(field).replace(/"/g, '""')}"`)
//                 .join(',')
//         )
//             .join('\n');

//         return csvContent;
//     };

//     const handleExport = () => {
//         try {
//             if (data?.length === 0) {
//                 message.warning('No withdrawals available to export');
//                 return;
//             }

//             const csvData = convertToCSV(data);
//             const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
//             const url = URL.createObjectURL(blob);

//             const link = document.createElement('a');
//             link.href = url;
//             link.setAttribute('download', 'withdrawals_export.csv');
//             document.body.appendChild(link);
//             link.click();
//             document.body.removeChild(link);

//             message.success('Exported withdrawals data successfully');
//         } catch (error) {
//             message.error('Failed to export withdrawals');
//         }
//     };

//     const filters = [
//         { name: "record.userId.username", label: "User", type: "text" },
//         { name: "txid", label: "Reference", type: "text" },
//         { name: "date", label: "Date", type: "text" },
//         { name: "asset", label: "Assets", type: "text" },
//         { name: "amount", label: "Amount", type: "text" },
//         { name: "fees", label: "Fess", type: "text" },
//         { name: "status", label: "Status", type: "text" },
//         { name: "notes", label: "Notes", type: "text" },
//     ];

//     return (
//         <AdminLayout currentKey={'dw-sub3'} openKey='5'>
//             <div className="mx-auto p-6 bg-white">
//                 <div className="flex justify-between items-center mb-4">
//                     <h1 className="text-xl font-semibold">Client Withdrawal</h1>
//                     <div className="text-xs text-gray-500">
//                         Dashboard &gt; Deposits/Withdrawals &gt; Deposits
//                     </div>
//                 </div>

//                 <div className="flex justify-between items-center mb-6">
//                     <button
//                         className="px-4 rounded-lg py-2 bg-[#22c6ab] text-white hover:bg-[#1da089] transition"
//                         onClick={() => setIsAddModalVisible(true)}
//                     >
//                         + Add Withdrawal
//                     </button>
//                     <Button
//                         type="primary"
//                         icon={<DownloadOutlined />}
//                         onClick={handleExport}
//                         className="flex items-center px-4 py-2 bg-[#57249c] text-white rounded-lg hover:bg-[#432076] transition"
//                     >
//                         Export (csv)
//                     </Button>
//                 </div>

//                 <PaginatedTable
//                     fetchEndpoint="/api/v1/withdrawals"
//                     columns={withdrawalColumns}
//                     isSearchable={true}
//                     isSelectable={true}
//                     filters={filters}
//                 />

//                 {isAddModalVisible && (
//                     <Add
//                         visible={isAddModalVisible}
//                         onCancel={() => setIsAddModalVisible(false)}
//                         onSubmit={() => { setIsAddModalVisible(false) }}
//                     />
//                 )}
//             </div>
//         </AdminLayout>
//     );
// };

// export default Index;
