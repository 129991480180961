import { useQueryClient } from "@tanstack/react-query";
import { message } from 'antd';
import { useApiMutation, useApiQuery } from "../hooks/useApiRequest";


//* ---------Get all user banks ---------
export const useGetAllBankService = () => {
    return useApiQuery({
        key: ["banks"],
        endPoint: `/api/v1/banks/get`,
        method: "GET",
    });
};

//* ---------Get bank by id ---------
export const useGetBankByIdService = (id) => {
    return useApiQuery({
        key: ["get-bank", id],
        endPoint: `/api/v1/banks/get-bank/${id}`,
        method: "GET",
    });
};


//* ---------Get all user usd banking account---------
export const useGetAllUSDBankingAcc = (userID) => {
    return useApiQuery({
        key: ["user-usd-banking-acc"],
        endPoint: `/api/v1/user-banking-usd/get-banking?userID=${userID}`,
        method: "GET",
    });
};


//*********** add user usd bank acc**********
export const useAddUSDBankAccService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const addUSDBankAcc = async (values, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["user-usd-banking-acc-create"],
                endPoint: `/api/v1/user-banking-usd/create`,
                method: "POST",
                bodyContent: values,
            });
            queryClient.invalidateQueries({ queryKey: ["user-usd-banking-acc"] });
            queryClient.invalidateQueries({ queryKey: ["user-usd-banking-acc-create"] });
            onSuccess();
            message.success('Successfully added');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { addUSDBankAcc, error };
};

//*********** Update user banking account**********
export const useUpdateUserUSDBankAccService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const updateUserUSDBankAcc = async (values, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["user-usd-bank-acc-update"],
                endPoint: `/api/v1/user-banking-usd/update`,
                method: "PATCH",
                bodyContent: values,
            });
            queryClient.invalidateQueries({ queryKey: ["user-usd-banking-acc"] });
            queryClient.invalidateQueries({ queryKey: ["user-usd-bank-acc-update"] });
            onSuccess();
            message.success('Successfully updated');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { updateUserUSDBankAcc, error };
};


//*********** Delete user usd banking account **********
export const useDeleteUserUSDBankingService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const deleteUserUSDBanking = async (id, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["user-usd-bank-acc-delete", id],
                endPoint: `/api/v1/user-banking-usd/delete?bankAccId=${id}`,
                method: "DELETE",
            });
            queryClient.invalidateQueries({ queryKey: ["user-usd-banking-acc"] });
            queryClient.invalidateQueries({ queryKey: ["user-usd-bank-acc-delete", id] });
            onSuccess();
            message.success('Successfully deleted');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { deleteUserUSDBanking, error };
};

