// src/Modals/BrokerApplications/DeleteModal.jsx

import React, { useState } from 'react';
import { Modal, message } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';

const DeleteModal = ({ visible, onCancel, data, onDelete }) => {
    const [loading, setLoading] = useState(false);

    const handleDelete = async () => {
        if (!data || !data._id) {
            message.error('Invalid broker application data.');
            return;
        }

        setLoading(true);
        try {
            const response = await axios.delete(`http://localhost:3000/api/v1/brokers/delete-broker-application?id=${data._id}`);
            if (response.data.status) {
                message.success('Broker application deleted successfully.');
                onDelete(); // Refresh data or perform any additional actions
                onCancel(); // Close the modal
            } else {
                message.error('Failed to delete broker application.');
            }
        } catch (error) {
            console.error('Error deleting broker application:', error);
            message.error('Error deleting broker application.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title="Delete Broker Application"
            visible={visible}
            onCancel={onCancel}
            onOk={handleDelete}
            okText="Delete"
            okButtonProps={{ danger: true, loading }}
        >
            <p>
                Are you sure you want to delete the broker application "
                {data?.name || 'N/A'}"?
            </p>
        </Modal>
    );
};

// Optional: Add PropTypes for better type checking during development
DeleteModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    data: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string,
    }),
    onDelete: PropTypes.func.isRequired,
};

export default DeleteModal;
