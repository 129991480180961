import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { useUpdateAssetChainService } from '../../../../services/assetService';

const Edit = ({ data }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const { updateAssetChain } = useUpdateAssetChainService();

    const handleEdit = async () => {
        setLoading(true);

        const values = await form.validateFields();

        const payload = {
            _id: data?._id,
            chain: values.chain,
            fireblocksTest: values.fireblocksTest,
            fireblocksLive: values.fireblocksLive
        };

        updateAssetChain(
            payload,
            () => {
                setLoading(false);
                toggleView()
            },
            () => setLoading(false)
        );
    };

    const toggleView = () => {
        setVisible(!visible);
    };

    if (!visible) {
        return (
            <Button
                type="text"
                icon={<EditOutlined />}
                style={{
                    padding: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                onClick={toggleView}
            />
        );
    }

    return (
        <Modal
            visible={visible}
            title="Edit Chain"
            onCancel={toggleView}
            footer={[
                <Button key="cancel" onClick={toggleView}>
                    Cancel
                </Button>,
                <Button
                    key="save"
                    type="primary"
                    onClick={handleEdit}
                    loading={loading}
                >
                    Save
                </Button>,
            ]}>
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    chain: data?.chain,
                    fireblocksTest: data?.fireblocksTest,
                    fireblocksLive: data?.fireblocksLive
                }}>

                <Form.Item
                    name="chain"
                    label="Chain Name"
                    rules={[{ required: true, message: 'Please enter a chain name' }]}
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="Enter chain name"
                    />
                </Form.Item>

                <Form.Item
                    name="fireblocksTest"
                    label="Fireblock Test "
                    rules={[{ required: true, message: 'Please enter Fireblock test' }]}
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="Enter fireblock test "
                    />
                </Form.Item>

                <Form.Item
                    name="fireblocksLive"
                    label="Fireblocks Live"
                    rules={[{ required: true, message: 'Please enter fireblock live' }]}
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="Enter fireblock live"
                    />
                </Form.Item>

            </Form>
        </Modal>

    );
};

export default Edit;

