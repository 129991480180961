import React, { useState } from "react";
import { Breadcrumb, Space, Tag } from "antd";
import AdminLayout from "../../../layouts/AdminLayout";
import { useExportClientService } from "../../../services/clientService";
import { formatDate } from "../../../utils/formateData";
import PaginatedTable2 from "../../../components/PaginatedTable2";
import { useApiQuery } from "../../../hooks/useApiRequest";
import View from "./View";
import VerifyClient from "./Verify";

const VerifClientPage = () => {

    const [loading, setLoading] = useState(false);
    const { exportClient } = useExportClientService()
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [userId, setUserId] = useState(false);

    const { data, isLoading } = useApiQuery({
        key: ["verif-clients"],
        endPoint: `/api/v1/get-all-verif-clients?page=${currentPage}&limit=${pageSize}`,
        method: "GET",
    });

    const pagination = {
        totalItems: data?.totalItems || 0,
        totalPages: data?.totalPages || 1,
        currentPage,
        limit: pageSize,
        startIndex: (currentPage - 1) * pageSize + 1,
        endIndex: Math.min(currentPage * pageSize, data?.totalItems || 0),
    };

    const handlePageChange = (page, size) => {
        setCurrentPage(page);
        setPageSize(size);
    };

    const columns = [
        {
            title: "User Code",
            dataIndex: "userinfo.ref_info.user_code",
            key: "user_code",
            render: (text, record) => record.userinfo?.ref_info?.user_code || "N/A",
        },
        { title: "Email", dataIndex: "email", key: "email" },
        {
            title: "First Name",
            dataIndex: ["userinfo personal_info legal_name"],
            key: "firstname",
            render: (text, record) =>
                record.userinfo?.personal_info?.legal_name || "N/A",
        },
        {
            title: "Last Name",
            dataIndex: "userinfo.personal_info.legal_lastname",
            key: "lastname",
            render: (text, record) =>
                record.userinfo?.personal_info?.legal_lastname || "N/A",
        },
        {
            title: "Identity Number",
            dataIndex: "userinfo.personal_info.identity_number",
            key: "identity_number",
            render: (text, record) =>
                record.userinfo?.personal_info?.identity_number || "N/A",
        },
        {
            title: "Reg Status",
            dataIndex: "kycStatus",
            key: "kycStatus",
            render: (status) => {
                return (
                    <Tag color={"red"}>
                        {status.toUpperCase()}
                    </Tag>
                );
            }
        },
        {
            title: "Phone Number",
            dataIndex: "userinfo.personal_info.phoneNumber",
            key: "phone_number",
            render: (text, record) =>
                record.userinfo?.personal_info?.phoneNumber,
        },
        {
            title: "Broker Code",
            dataIndex: "userinfo.broker_info.broker_code", // Ensure dataIndex is correct
            key: "broker_code",
            render: (text, record) =>
                record.userinfo?.broker_info?.broker_code || "N/A",
        },
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (text) => formatDate(text), // Updated render function for date formatting
        },
        {
            title: "Approve",
            key: "approve",
            render: (text, record) => (
                <Space size="middle">
                    <button
                        className="px-3 py-1 text-sm font-semibold text-white bg-green-500 rounded hover:bg-[#432076]"
                        onClick={() => {
                            setIsModalVisible(true)
                            setUserId(record?._id)
                        }}
                    >
                        Approve
                    </button>
                </Space>
            ),
        },
        {
            title: "View",
            key: "view",
            render: (text, record) => (
                <Space size="middle">
                    <View userId={record._id} />
                </Space>
            ),
        }
    ];

    const handleExport = () => {
        setLoading(true);

        exportClient(
            () => setLoading(false),
            () => setLoading(false)
        )
    }

    return (
        <>
            <AdminLayout
                user={null}
                header={"Client Verif"}
                currentKey={"client-sub2"}
                openKey="4"
            >
                <div className="mx-auto p-4">
                    <Breadcrumb className="mb-4">
                        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item>Clients</Breadcrumb.Item>
                        <Breadcrumb.Item>Veriff</Breadcrumb.Item>
                    </Breadcrumb>

                    <div className="flex justify-between items-center mb-6">
                        <div className="mb-4">
                            <h3 className="text-lg font-semibold">Client Veriff</h3>
                        </div>

                        {/* <Button
                        type="primary"
                        icon={<DownloadOutlined />}
                        onClick={handleExport}
                        className="flex items-center px-4 py-2 bg-[#57249c] text-white rounded-lg hover:bg-[#432076] transition"
                    >
                        {loading ? "Loading..." : "Export (csv)"}
                    </Button> */}
                    </div>

                    <PaginatedTable2
                        data={data?.results}
                        columns={columns}
                        pagination={pagination}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageChange}
                        loading={isLoading}
                    />
                </div>
            </AdminLayout>

            {isModalVisible && (
                <VerifyClient
                    userId={userId}
                    visible={isModalVisible}
                    onCancel={() => setIsModalVisible(false)}
                    onSubmit={() => setIsModalVisible(false)}
                />
            )}

        </>
    );
};

export default VerifClientPage;
