// Modals/ClientsBankOverview/EditModal.jsx

import React, { useState } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import axios from 'axios';

const EditModal = ({ visible, onCancel, data, onSubmit }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const handleFinish = async (values) => {
        setLoading(true);
        try {
            await axios.patch(`http://localhost:3000/api/v1/clients/update/${data?.userInfo?.user_id}`, values);
            message.success('User updated successfully');
            onSubmit();
        } catch (error) {
            message.error(error.response?.data?.message || 'Failed to update user');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title="Edit User"
            visible={visible}
            onCancel={onCancel}
            footer={null}
        >
            <Form
                form={form}
                initialValues={{
                    username: data?.username || '',
                    email: data?.email || '',
                    legal_name: data?.userInfo?.personal_info?.legal_name || '',
                    legal_lastname: data?.userInfo?.personal_info?.legal_lastname || '',
                    identity_number: data?.userInfo?.personal_info?.identity_number || '',
                }}
                onFinish={handleFinish}
                layout="vertical"
            >
                <Form.Item
                    label="Username"
                    name="username"
                    rules={[{ required: true, message: 'Please input the username!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input the email!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="First Name" name="legal_name">
                    <Input />
                </Form.Item>
                <Form.Item label="Last Name" name="legal_lastname">
                    <Input />
                </Form.Item>
                <Form.Item label="Identity Number" name="identity_number">
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Update
                    </Button>
                    <Button onClick={onCancel} style={{ marginLeft: '8px' }}>
                        Cancel
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditModal;
