import { Form, Input, Modal } from "antd";
import React, { useState } from "react";
import { useUpdateContactService } from "../../../../services/contactService";

const EditContact = ({ data, visible, onCancel, onSubmit }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const { updateContact } = useUpdateContactService();

    const handleUpdate = async () => {
        setLoading(true);
        try {
            const values = await form.validateFields();

            const payload = {
                _id: data?._id,
                tel: values?.tel,
                email: values?.email,
                addressLine1: values?.addressLine1,
                addressLine2: values?.addressLine2,
                whatsappButtonNumber: values?.whatsappButtonNumber,
                map: values?.map,
                socialLinks: {
                    facebook: values?.facebook,
                    twitter: values?.twitter,
                    linkedin: values?.linkedin,
                    instagram: values?.instagram,
                    youtube: values?.youtube,
                }
            };

            updateContact(
                payload,
                () => {
                    onSubmit();
                    setLoading(false);
                },
                () => setLoading(false)
            );

        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <Modal
            visible={visible}
            title="Edit Contact Information"
            onCancel={onCancel}
            onOk={handleUpdate}
            confirmLoading={loading}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    tel: data?.tel,
                    email: data?.email,
                    addressLine1: data?.addressLine1,
                    addressLine2: data?.addressLine2,
                    whatsappButtonNumber: data?.whatsappButtonNumber,
                    map: data?.map,
                    facebook: data?.socialLinks?.facebook,
                    twitter: data?.socialLinks?.twitter,
                    linkedin: data?.socialLinks?.linkedin,
                    instagram: data?.socialLinks?.instagram,
                    youtube: data?.socialLinks?.youtube,
                }}
            >
                <Form.Item name="tel" label="Telephone" rules={[{ required: true, message: "Telephone number is required" }]}>
                    <Input placeholder="Enter telephone number" />
                </Form.Item>
                <Form.Item
                    name="email"
                    label="Email"
                    rules={[{ required: true, type: "email", message: "Valid email is required" }]}
                >
                    <Input placeholder="Enter email address" />
                </Form.Item>
                <Form.Item
                    name="addressLine1"
                    label="Address Line 1"
                    rules={[{ required: true, message: "Address Line 1 is required" }]}
                >
                    <Input placeholder="Enter Address Line 1" />
                </Form.Item>
                <Form.Item name="addressLine2" label="Address Line 2">
                    <Input placeholder="Enter Address Line 2" />
                </Form.Item>
                <Form.Item
                    name="whatsappButtonNumber"
                    label="WhatsApp Button Number"
                    rules={[{ required: true, message: "WhatsApp number is required" }]}
                >
                    <Input placeholder="Enter WhatsApp number" />
                </Form.Item>
                <Form.Item name="map" label="Google Map URL">
                    <Input placeholder="Enter Google Map URL" />
                </Form.Item>

                <Form.Item name={"facebook"} label="Facebook Link">
                    <Input placeholder="Enter Facebook URL" />
                </Form.Item>
                <Form.Item name={"twitter"} label="Twitter Link">
                    <Input placeholder="Enter Twitter URL" />
                </Form.Item>
                <Form.Item name={"linkedin"} label="LinkedIn Link">
                    <Input placeholder="Enter LinkedIn URL" />
                </Form.Item>
                <Form.Item name={"instagram"} label="Instagram Link">
                    <Input placeholder="Enter Instagram URL" />
                </Form.Item>
                <Form.Item name={"youtube"} label="YouTube Link">
                    <Input placeholder="Enter YouTube URL" />
                </Form.Item>

            </Form>
        </Modal>
    );
};

export default EditContact;
