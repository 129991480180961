import { Space, Table } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetAllChains } from '../../../../services/assetService';
import { formatDate } from '../../../../utils/formateData';
import Add from './Add';
import Delete from './Delete';
import Edit from './Edit';

const AssetChain = ({ assetId }) => {
	const { data, isLoading, error, } = useGetAllChains(assetId)
	const [isAddModalVisible, setIsAddModalVisible] = useState(false);

	const columns = [
		{
			title: 'Chain Name',
			dataIndex: 'chain',
			key: 'chain',
		},
		{
			title: 'Fireblocks Test',
			dataIndex: 'fireblocksTest',
			key: 'sellFee',
		},
		{
			title: 'Fireblocks Live',
			dataIndex: 'fireblocksLive',
			key: 'sellFee',
		},
		{
			title: "Date",
			dataIndex: "createdAt",
			key: "createdAt",
			render: (text) => formatDate(text),
		},
		{
			title: "Actions",
			key: "actions",
			render: (text, record) => {
				return (
					<Space size="middle">
						<Link>  <Edit data={record} /> </Link>
						<Link>  <Delete record={record} /> </Link>
					</Space>
				)
			},
		}
	];

	return (
		<>
			<div className="flex justify-between items-center mb-6">
				<button
					className="px-4 rounded-lg py-2 bg-[#22c6ab] text-white hover:bg-[#1da089] transition"
					onClick={() => setIsAddModalVisible(true)}
				>
					+ Add Chain
				</button>
			</div>

			<Table
				dataSource={data}
				columns={columns}
				loading={isLoading}
				// pagination={false}
				rowKey={(record) => record.id || record._id || record.key}
				rowClassName={(record, index) =>
					index % 2 === 0 ? "table-row-light" : "table-row-dark"
				}
			/>

			{/* Modals */}
			{isAddModalVisible && (
				<Add
					assetId={assetId}
					visible={isAddModalVisible}
					onCancel={() => setIsAddModalVisible(false)}
					onSubmit={() => { setIsAddModalVisible(false) }}
				/>
			)}
		</>
	)
}

export default AssetChain;