// src/Modals/Settings/UpdateSystemContacts.jsx

import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, InputNumber, Tabs, Button, message, notification } from 'antd';
import {
  MailOutlined,
  PhoneOutlined,
  HomeOutlined,
  EnvironmentOutlined,
  WhatsAppOutlined,
  FacebookOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  InstagramOutlined,
  YoutubeOutlined,
  CloseOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import PropTypes from 'prop-types';

const { TabPane } = Tabs;
const { TextArea } = Input;

const UpdateSystemContacts = ({ visible, onCancel, contact, onUpdateSuccess }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  // Populate formData when contact changes
  useEffect(() => {
    if (contact && visible) {
      form.setFieldsValue({
        tel: contact.tel || '',
        email: contact.email || '',
        addressLine1: contact.addressLine1 || '',
        addressLine2: contact.addressLine2 || '',
        city: contact.city || '',
        state: contact.state || '',
        postalCode: contact.postalCode || '',
        country: contact.country || '',
        whatsappButtonNumber: contact.whatsappButtonNumber || '',
        map: contact.map || '',
        facebookLink: contact.facebookLink || '',
        twitterLink: contact.twitterLink || '',
        linkedinLink: contact.linkedinLink || '',
        instagramLink: contact.instagramLink || '',
        youtubeLink: contact.youtubeLink || '',
      });
    }
  }, [contact, visible, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);
      const response = await axios.put(
        `http://localhost:3000/api/v1/settings/system-contacts/${contact._id}`,
        values
      );
      setLoading(false);
      onUpdateSuccess(response.data);
      onCancel();
      notification.success({
        message: 'Success',
        description: 'System Contacts updated successfully!',
        placement: 'topRight',
        duration: 3,
      });
    } catch (err) {
      console.error('Error updating System Contacts:', err);
      const errorMsg =
        err.response?.data?.message || 'Failed to update contacts. Please try again.';
      setLoading(false);
      message.error(errorMsg);
      notification.error({
        message: 'Error',
        description: errorMsg,
        placement: 'topRight',
        duration: 3,
      });
    }
  };

  return (
    <Modal
      visible={visible}
      title={<span style={{ color: '#1890ff' }}>Update System Contacts</span>}
      onCancel={onCancel}
      footer={[
        <Button
          key="cancel"
          onClick={onCancel}
          icon={<CloseOutlined />}
          disabled={loading}
          style={{ backgroundColor: '#f0f0f0', borderColor: '#d9d9d9' }}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          loading={loading}
          icon={loading ? <LoadingOutlined /> : null}
          style={{ backgroundColor: '#1890ff', borderColor: '#1890ff' }}
        >
          Save Changes
        </Button>,
      ]}
      width={800}
      bodyStyle={{ maxHeight: '70vh', overflowY: 'auto' }}
    >
      <Form form={form} layout="vertical">
        <Tabs defaultActiveKey="1">
          {/* Contact Information Tab */}
          <TabPane
            tab={
              <span>
                <PhoneOutlined style={{ color: '#1890ff', marginRight: 4 }} />
                Contact Info
              </span>
            }
            key="1"
          >
            {/* Telephone */}
            <Form.Item
              label={
                <span>
                  <PhoneOutlined style={{ color: '#1890ff', marginRight: 4 }} />
                  Telephone
                </span>
              }
              name="tel"
              rules={[{ required: true, message: 'Please enter the telephone number' }]}
            >
              <Input placeholder="Enter telephone number" />
            </Form.Item>

            {/* Email */}
            <Form.Item
              label={
                <span>
                  <MailOutlined style={{ color: '#1890ff', marginRight: 4 }} />
                  Email
                </span>
              }
              name="email"
              rules={[
                { required: true, message: 'Please enter the email address' },
                { type: 'email', message: 'Please enter a valid email address' },
              ]}
            >
              <Input placeholder="Enter email address" />
            </Form.Item>

            {/* Address Line 1 */}
            <Form.Item
              label={
                <span>
                  <HomeOutlined style={{ color: '#1890ff', marginRight: 4 }} />
                  Address Line 1
                </span>
              }
              name="addressLine1"
              rules={[{ required: true, message: 'Please enter the address line 1' }]}
            >
              <Input placeholder="Enter address line 1" />
            </Form.Item>

            {/* Address Line 2 */}
            <Form.Item
              label={
                <span>
                  <HomeOutlined style={{ color: '#1890ff', marginRight: 4 }} />
                  Address Line 2
                </span>
              }
              name="addressLine2"
            >
              <Input placeholder="Enter address line 2 (optional)" />
            </Form.Item>

            {/* City */}
            <Form.Item
              label={
                <span>
                  <EnvironmentOutlined style={{ color: '#1890ff', marginRight: 4 }} />
                  City
                </span>
              }
              name="city"
              rules={[{ required: true, message: 'Please enter the city' }]}
            >
              <Input placeholder="Enter city" />
            </Form.Item>

            {/* State */}
            <Form.Item
              label={
                <span>
                  <EnvironmentOutlined style={{ color: '#1890ff', marginRight: 4 }} />
                  State/Province
                </span>
              }
              name="state"
              rules={[{ required: true, message: 'Please enter the state or province' }]}
            >
              <Input placeholder="Enter state or province" />
            </Form.Item>

            {/* Postal Code */}
            <Form.Item
              label={
                <span>
                  <EnvironmentOutlined style={{ color: '#1890ff', marginRight: 4 }} />
                  Postal Code
                </span>
              }
              name="postalCode"
              rules={[{ required: true, message: 'Please enter the postal code' }]}
            >
              <Input placeholder="Enter postal code" />
            </Form.Item>

            {/* Country */}
            <Form.Item
              label={
                <span>
                  <EnvironmentOutlined style={{ color: '#1890ff', marginRight: 4 }} />
                  Country
                </span>
              }
              name="country"
              rules={[{ required: true, message: 'Please enter the country' }]}
            >
              <Input placeholder="Enter country" />
            </Form.Item>
          </TabPane>

          {/* Social Media Links Tab */}
          <TabPane
            tab={
              <span>
                <FacebookOutlined style={{ color: '#1890ff', marginRight: 4 }} />
                Social Media
              </span>
            }
            key="2"
          >
            {/* WhatsApp Button Number */}
            <Form.Item
              label={
                <span>
                  <WhatsAppOutlined style={{ color: '#1890ff', marginRight: 4 }} />
                  WhatsApp Button Number
                </span>
              }
              name="whatsappButtonNumber"
              rules={[{ required: true, message: 'Please enter the WhatsApp number' }]}
            >
              <Input placeholder="Enter WhatsApp number" />
            </Form.Item>

            {/* Map URL */}
            <Form.Item
              label={
                <span>
                  <EnvironmentOutlined style={{ color: '#1890ff', marginRight: 4 }} />
                  Map URL
                </span>
              }
              name="map"
              rules={[
                { required: true, message: 'Please enter the map URL' },
                { type: 'url', message: 'Please enter a valid URL' },
              ]}
            >
              <Input placeholder="Enter Google Maps URL" />
            </Form.Item>

            {/* Facebook Link */}
            <Form.Item
              label={
                <span>
                  <FacebookOutlined style={{ color: '#1890ff', marginRight: 4 }} />
                  Facebook Link
                </span>
              }
              name="facebookLink"
              rules={[{ type: 'url', message: 'Please enter a valid URL' }]}
            >
              <Input placeholder="Enter Facebook URL" />
            </Form.Item>

            {/* Twitter Link */}
            <Form.Item
              label={
                <span>
                  <TwitterOutlined style={{ color: '#1890ff', marginRight: 4 }} />
                  Twitter Link
                </span>
              }
              name="twitterLink"
              rules={[{ type: 'url', message: 'Please enter a valid URL' }]}
            >
              <Input placeholder="Enter Twitter URL" />
            </Form.Item>

            {/* LinkedIn Link */}
            <Form.Item
              label={
                <span>
                  <LinkedinOutlined style={{ color: '#1890ff', marginRight: 4 }} />
                  LinkedIn Link
                </span>
              }
              name="linkedinLink"
              rules={[{ type: 'url', message: 'Please enter a valid URL' }]}
            >
              <Input placeholder="Enter LinkedIn URL" />
            </Form.Item>

            {/* Instagram Link */}
            <Form.Item
              label={
                <span>
                  <InstagramOutlined style={{ color: '#1890ff', marginRight: 4 }} />
                  Instagram Link
                </span>
              }
              name="instagramLink"
              rules={[{ type: 'url', message: 'Please enter a valid URL' }]}
            >
              <Input placeholder="Enter Instagram URL" />
            </Form.Item>

            {/* YouTube Link */}
            <Form.Item
              label={
                <span>
                  <YoutubeOutlined style={{ color: '#1890ff', marginRight: 4 }} />
                  YouTube Link
                </span>
              }
              name="youtubeLink"
              rules={[{ type: 'url', message: 'Please enter a valid URL' }]}
            >
              <Input placeholder="Enter YouTube URL" />
            </Form.Item>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
};

UpdateSystemContacts.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  contact: PropTypes.object.isRequired,
  onUpdateSuccess: PropTypes.func.isRequired,
};

export default UpdateSystemContacts;
