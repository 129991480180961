import { EditOutlined, EyeOutlined, } from "@ant-design/icons";
import { Space, Table } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { useGetAllPolicies } from '../../services/policieService';
import Delete from './Delete';
// import getDeviceDetails from "../../utils/getDeviceDetails";

const Index = () => {

    const { data, isLoading } = useGetAllPolicies()

    const columns = [
        {
            title: 'Name',
            dataIndex: `name`,
            key: 'name'
        },
        {
            title: 'Text',
            dataIndex: 'rawText',
            key: 'rawText',
        },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Link to={`/admin/policies/view/${record?.id || record?._id}`}> <EyeOutlined /> </Link>
                        <Link to={`/admin/policies/edit/${record?.id || record?._id}`}>  <EditOutlined /> </Link>
                        <Link> <Delete record={record} /> </Link>
                    </Space>
                )
            }
        }
    ];

    return (
        <div className="mx-auto p-6 bg-white">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-xl font-semibold">Policies</h1>
                <div className="text-xs text-gray-500">
                    Dashboard &gt; Policies
                </div>
            </div>

            <Table
                dataSource={data}
                columns={columns}
                loading={isLoading}
                pagination={false}
                rowKey={(record) => record.id || record._id || record.key}
                rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                scroll={{ x: 'max-content' }}
            />
        </div>
    );
};

export default Index;

