import { useQueryClient } from "@tanstack/react-query";
import { message } from 'antd';
import { useApiMutation, useApiQuery } from "../hooks/useApiRequest";


//* ---------Get all user API keys ---------
export const useGetAllAPIKey = (userID) => {
    return useApiQuery({
        key: ["apis-key"],
        endPoint: `/api/v1/user-api-key/get-all-user-api-key?userID=${userID}`,
        method: "GET",
    });
};

//*********** add  Client API Key**********
export const useAddAPIKeyService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const addAPIKey = async (values, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["api-key-create"],
                endPoint: `/api/v1/user-api-key/create`,
                method: "POST",
                bodyContent: values,
            });
            queryClient.invalidateQueries({ queryKey: ["apis-key"] });
            queryClient.invalidateQueries({ queryKey: ["api-key-create"] });
            onSuccess();
            message.success('Successfully added');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { addAPIKey, error };
};


//*********** Delete client API KEY **********
export const useDeleteAPKeyService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const deleteAPIKey = async (id, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["user-api-key-delete", id],
                endPoint: `/api/v1/user-api-key/delete?apiKeyId=${id}`,
                method: "DELETE",
            });
            queryClient.invalidateQueries({ queryKey: ["apis-key"] });
            queryClient.invalidateQueries({ queryKey: ["user-api-key-delete", id] });
            onSuccess();
            message.success('Successfully deleted');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { deleteAPIKey, error };
};

