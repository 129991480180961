import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Space, Typography, message } from "antd";
import { useState } from "react";
import { DeleteEmailsTemplateServices } from "../../services/emailsService";
const { Text } = Typography;

const Delete = ({ record }) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const { DeleteEmailsTemplate } = DeleteEmailsTemplateServices();

  const handleDelete = async () => {
    try {
      setLoading(true);
      await DeleteEmailsTemplate(
        record?._id,
        () => {
          setLoading(false);
          toggleView();
        },
        () => {
          setLoading(false);
        }
      );
    } catch (error) {
      setLoading(false);
    }
  };

  const toggleView = () => {
    setVisible(!visible);
  };

  return (
    <>
      <Button
        type="text"
        icon={<DeleteOutlined />}
        style={{
          padding: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={toggleView}
      />
      <Modal
        open={visible}
        onCancel={toggleView}
        title={
          <Space>
            <ExclamationCircleOutlined style={{ color: "#faad14" }} />
            <Text style={{ color: "#faad14" }}>Delete Confirmation</Text>
          </Space>
        }
        footer={[
          <Button key="cancel" onClick={toggleView}>
            Cancel
          </Button>,
          <Button
            key="delete"
            type="primary"
            danger
            onClick={handleDelete}
            loading={loading}
          >
            Delete
          </Button>,
        ]}
      >
        <Space direction="vertical">
          <Text>
            Are you sure you want to delete this email template? This action
            cannot be undone.
          </Text>
        </Space>
      </Modal>
    </>
  );
};

export default Delete;
