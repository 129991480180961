import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button, message } from "antd";

const { Option } = Select;

const AdminManagerForm = ({ onSubmit }) => {
	const [form] = Form.useForm();

	const onFinish = (values) => {
		onSubmit(values);
		//form.resetFields();
	}

    return(
        <Form 
			form={form}
			layout="vertical"
			name="create_admin_manager_form"
			onFinish={onFinish}
		>
			<Form.Item
				name="email"
				label="Email"
				rules={[
					{ required: true, message: "Please input the email!" },
					{ type: "email", message: "Please enter a valid email!" },
				]}
			>
				<Input placeholder="Enter email" />
			</Form.Item>

			<Form.Item
				name="role"
				label="Role"
				rules={[{ required: true, message: "Please select a role!" }]}
			>
				<Select placeholder="Select role">
					<Option value={10}>Admin</Option>
					<Option value={20}>Manager</Option>
				</Select>
			</Form.Item>

			<Form.Item
				name="status"
				label="Status"
				initialValue="enabled"
				rules={[{ required: true, message: "Please select a status!" }]}
			>
				<Select>
					<Option value="enabled">Enabled</Option>
					<Option value="disabled">Disabled</Option>
				</Select>
			</Form.Item>
			<Form.Item
				name="legalFirstName"
				label="Legal First Name"
				rules={[{required: true, message: "Please eneter a Legarl first name"}]}
			>
				<Input placeholder="Enter legal first name" />
			</Form.Item>
			<Form.Item
				name="legalLastName"
				label="Legal Last Name"
				rules={[{required: true, message: "Please eneter a Legarl last name"}]}
			>
				<Input placeholder="Enter legal last name" />
			</Form.Item>
			<Form.Item
				name="phoneNumber"
				label="Phone Number"
				rules={[{required: true, message: "Please eneter a Legarl last name"}]}
			>
				<Input placeholder="Enter phone number" />
			</Form.Item>
			<div className="text-center">
                <Button type="primary" htmlType="submit" className="mt-4">
                    Save
                </Button>
            </div>
			{/* 
			<Form.Item
			name="createdBy"
			label="Created By (User ID)"
			rules={[
				{
				required: true,
				message: "Please input the creator's User ID!",
				},
				{
				pattern: /^[0-9a-fA-F]{24}$/,
				message: "Please enter a valid User ID!",
				},
			]}
			>
			<Input placeholder="Enter User ID" />
			</Form.Item> */}
      	</Form>
    );
}

export default AdminManagerForm;