// src/Modals/BrokerCommissions/ViewModal.jsx

import React from 'react';
import { Modal, Descriptions } from 'antd';

const ViewModal = ({ visible, onCancel, data }) => {
    if (!data) {
        return null;
    }

    const {
        name = 'N/A',
        oldId = 'N/A',
        brokerId = 'N/A',
        userId = 'N/A',
        commission = { $numberDecimal: '0.00' },
        asset = 'N/A',
        type = 'N/A',
        traceId = 'N/A',
        date = 'N/A',
        createdAt = 'N/A',
        updatedAt = 'N/A',
    } = data;

    return (
        <Modal
            title="View Broker Commission"
            visible={visible}
            onCancel={onCancel}
            footer={null}
        >
            <Descriptions bordered column={1}>
                <Descriptions.Item label="Name">{name}</Descriptions.Item>
                <Descriptions.Item label="Old ID">{oldId}</Descriptions.Item>
                <Descriptions.Item label="Broker ID">{brokerId}</Descriptions.Item>
                <Descriptions.Item label="User ID">{userId}</Descriptions.Item>
                <Descriptions.Item label="Commission">{commission.$numberDecimal}</Descriptions.Item>
                <Descriptions.Item label="Asset">{asset}</Descriptions.Item>
                <Descriptions.Item label="Type">{type}</Descriptions.Item>
                <Descriptions.Item label="Trace ID">{traceId}</Descriptions.Item>
                <Descriptions.Item label="Date">{new Date(date).toLocaleString()}</Descriptions.Item>
                <Descriptions.Item label="Created At">{new Date(createdAt).toLocaleString()}</Descriptions.Item>
                <Descriptions.Item label="Updated At">{new Date(updatedAt).toLocaleString()}</Descriptions.Item>
            </Descriptions>
        </Modal>
    );
};

export default ViewModal;
