
const DeviceDetails = async () => {
    // Example to get IP Address using a free service
    const ipResponse = await fetch('https://api.ipify.org?format=json');
    const { ip } = await ipResponse.json();

    // Detect browser and device
    const userAgent = navigator.userAgent;

    let device = "Unknown Device";
    if (/Mobi|Android/i.test(userAgent)) {
        device = "Mobile";
    } else if (/Tablet|iPad/i.test(userAgent)) {
        device = "Tablet";
    } else {
        device = "Desktop/Laptop";
    }

    const browser = (() => {
        if (userAgent.includes("Chrome")) return "Chrome";
        if (userAgent.includes("Firefox")) return "Firefox";
        if (userAgent.includes("Safari")) return "Safari";
        if (userAgent.includes("Edge")) return "Edge";
        return "Unknown Browser";
    })();

    return {
        ipAddress: ip,
        device,
        browser,
    };
};

export default DeviceDetails