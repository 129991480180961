import { useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { useApiMutation, useApiQuery } from "../hooks/useApiRequest";

//* ---------Get all users---------
export const useGetAllUsers = () => {
  return useApiQuery({
    key: ["clients"],
    endPoint: `/api/v1/clients/get-all-clients-temp`,
    method: "GET",
  });
};

//*********** add Login History **********
export const useAddLoginHistoryService = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, error } = useApiMutation();

  const addLoginHistory = async (values, onSuccess, onError) => {
    try {
      await mutateAsync({
        key: ["login-history"],
        endPoint: `/api/v1/login-history/create`,
        method: "POST",
        bodyContent: values,
      });
      queryClient.invalidateQueries({ queryKey: ["login-history"] });
      onSuccess();
    } catch (error) {
      onError();
      message.error(error.message);
    }
  };

  return { addLoginHistory, error };
};

//* ---------Get Secret Key---------
export const useGetSecret = () => {
  return useApiQuery({
    key: ["secret-key"],
    endPoint: `/api/v1/auth/get-secret`,
    method: "GET",
  });
};

//*********** Verify Code **********
export const useVerifyCodeService = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, error } = useApiMutation();

  const verifyCode = async (values, onSuccess, onError) => {
    try {
      await mutateAsync({
        key: ["verify-codes"],
        endPoint: `/api/v1/auth/verify-code`,
        method: "POST",
        bodyContent: values,
      });
      queryClient.invalidateQueries({ queryKey: ["secret-key"] });
      queryClient.invalidateQueries({ queryKey: ["verify-codes"] });
      onSuccess();
      message.success('2FA successfully enabled');
    } catch (error) {
      onError();
      message.error(error.message);
    }
  };

  return { verifyCode, error };
};

