import { Space } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import PaginatedTable from "../../components/PaginatedTable";
import AdminLayout from "../../layouts/AdminLayout";
import { UpdateInvestmentApplicationStatusService } from "../../services/investApplicationService";
import { formatDate } from "../../utils/formateData";
import Delete from "./delete";
import Edit from "./edit";

const SubscriptionApproval = () => {
  // const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  // const { data } = useGetAllInvestApplication();

  const { updateInvestmentApplication } =
    UpdateInvestmentApplicationStatusService();

  const [refresh, setRefresh] = useState(false);

  const investmentColumns = [
    {
      title: "User",
      dataIndex: "user",
      key: "userId",
      render: (text, record) =>
        `${record?.userId?.username} - ${record?.userId?.email} ` || "N/A",
    },
    {
      title: "Investment Period",
      dataIndex: "investmentPeriod",
      key: "investmentPeriod",
    },
    {
      title: "Amount",
      dataIndex: "investmentAmount",
      key: "investmentAmount",
      render: (amount) => `R ${parseFloat(amount?.$numberDecimal).toFixed(8)}`,
    },
    {
      title: "Experience",
      dataIndex: "experience",
      key: "experience",
    },
    {
      title: "Risk Tolerance",
      dataIndex: "riskTolerance",
      key: "riskTolerance",
    },
    {
      title: "Primary Goal",
      dataIndex: "primaryGoal",
      key: "primaryGoal",
    },

    {
      title: "Concerns",
      dataIndex: "concerns",
      key: "concerns",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => formatDate(date),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => {
        if (status === "Pending") {
          return (
            <button
              className="px-3 py-1 text-sm font-semibold text-white bg-yellow-500 rounded hover:bg-yellow-600"
              onClick={() => handleEditStatus(record)}
            >
              Mark Completed
            </button>
          );
        }
        return (
          <span className="text-sm font-semibold text-green-600">{status}</span>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => {
        return (
          <Space size="middle">
            <Link>
              <Delete record={record} />
              <Link>
                {" "}
                <Edit data={record} />{" "}
              </Link>
            </Link>
          </Space>
        );
      },
    },
  ];

  const handleEditStatus = async (record) => {
    const payload = {
      status: "Approved",
    };

    updateInvestmentApplication(
      payload,
      record?._id,
      () => console.log(" successfully updated"),
      () => console.log(" failed  to update the status")
    );
  };

  const filters = [
    { name: "record.userId.username", label: "User", type: "text" },
    { name: "productId", label: "Product", type: "text" },
    { name: "status", label: "Status", type: "text" },
    { name: "investmentAmount", label: "Amount", type: "number" },
    { name: "experience", label: "Experience", type: "text" },
    { name: "riskTolerance", label: "Risk Tolerance", type: "text" },
    { name: "primaryGoal", label: "Primary Goal", type: "text" },
    { name: "investmentPeriod", label: "Investment Period", type: "text" },
    { name: "concerns", label: "Concerns", type: "text" },
  ];

  return (
    <AdminLayout currentKey={"dw-sub2"} openKey="5">
      <div className="mx-auto p-6 bg-white">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-xl font-semibold">Investment Applications</h1>
          <div className="text-xs text-gray-500">
            Dashboard &gt; Investments &gt; Applications
          </div>
        </div>

        <PaginatedTable
          fetchEndpoint="/api/v1/investment-application/investindex"
          columns={investmentColumns}
          // dataSource={data?.results || []}
          isSearchable={true}
          isSelectable={true}
          filters={filters}
          refresh={refresh}
        />
      </div>
    </AdminLayout>
  );
};

export default SubscriptionApproval;
