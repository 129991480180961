import { useQueryClient } from "@tanstack/react-query";
import { message } from 'antd';
import { useApiMutation, useApiQuery } from "../hooks/useApiRequest";


//* ---------Get all user banking account---------
export const useGetAllBankingAcc = (userID) => {
    return useApiQuery({
        key: ["user-banking-acc"],
        dataIndex: "date",
        endPoint: `/api/v1/user-banking/get-banking?userID=${userID}`,
        method: "GET",
    });
};

//*********** add user bank acc**********
export const useAddBankAccService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const addBankAcc = async (values, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["user-banking-acc-create"],
                endPoint: `/api/v1/user-banking/create`,
                method: "POST",
                bodyContent: values,
            });
            queryClient.invalidateQueries({ queryKey: ["user-banking-acc"] });
            queryClient.invalidateQueries({ queryKey: ["user-banking-acc-create"] });
            onSuccess();
            message.success('Successfully added');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { addBankAcc, error };
};


//*********** Update user banking account**********
export const useUpdateUserBankAccService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const updateUserBankAcc = async (values, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["user-bank-acc-update"],
                endPoint: `/api/v1/user-banking/update`,
                method: "PATCH",
                bodyContent: values,
            });
            queryClient.invalidateQueries({ queryKey: ["user-banking-acc"] });
            queryClient.invalidateQueries({ queryKey: ["user-bank-acc-update"] });
            onSuccess();
            message.success('Successfully updated');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { updateUserBankAcc, error };
};



//*********** Delete user banking account **********
export const useDeleteUserBankingService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const deleteUserBanking = async (id, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["user-bank-acc-delete", id],
                endPoint: `/api/v1/user-banking/delete?bankAccId=${id}`,
                method: "DELETE",
            });
            queryClient.invalidateQueries({ queryKey: ["user-banking-acc"] });
            queryClient.invalidateQueries({ queryKey: ["user-bank-acc-delete", id] });
            onSuccess();
            message.success('Successfully deleted');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { deleteUserBanking, error };
};

