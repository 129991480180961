import React from 'react';
import { Spin } from 'antd';

const LoadingComponent = ({ text = 'Loading...', size = 'large' }) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <Spin size={size} className="mb-4" />
      <p className="text-lg text-gray-700">{text}</p>
    </div>
  );
};

export default LoadingComponent;
