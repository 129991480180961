import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import DetailView from "../../../../components/DetailView"; // Reusable DetailView component
import LoadingComponent from "../../../../components/LoadingComponent";
import { useGetSystemService } from "../../../../services/systemSettingService";
import { formatDate } from "../../../../utils/formateData";
import Edit from "./Edit";

const System = () => {

  const { data, isLoading } = useGetSystemService();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  if (isLoading) return <LoadingComponent />;

  const systemDetails = [
    { label: "RfQ Down Email Notifications", value: data[0]?.downEmailNotifications.join(", ") },
    { label: "RfQ Quote Time", value: formatDate(data[0]?.quoteTime) },
    { label: "RfQ Minimum Quote Trade", value: data[0]?.minQuoteTrade },
    { label: "Arbitrage Commission (%)", value: `${data[0]?.arbitrageCommission}%` },
    { label: "Basic Level Withdrawal Limit", value: data[0]?.basicWithdrawalLimit },
    { label: "Advanced Level Withdrawal Limit", value: data[0]?.advancedWithdrawalLimit },
    { label: "Pro Level Withdrawal Limit", value: data[0]?.proWithdrawalLimit },
    { label: "Inactive Timeout (in minutes)", value: `${data[0]?.inactiveTimeout} mins` },
    { label: "Created At", value: formatDate(data[0]?.createdAt) },
    { label: "Updated At", value: formatDate(data[0]?.updatedAt) },
  ];

  return (
    <div className="container mx-auto p-4">

      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">System Information</h2>
        <Button className="px-4 rounded-lg py-2 bg-[#22c6ab] text-white hover:bg-[#1da089] transition" type="primary" icon={<EditOutlined />} onClick={() => setIsEditModalVisible(true)} >
          Edit
        </Button>
      </div>

      <DetailView data={systemDetails} className="w-full" />

      {isEditModalVisible && (
        <Edit
          data={data[0]}
          visible={isEditModalVisible}
          onCancel={() => setIsEditModalVisible(false)}
          onSubmit={() => { setIsEditModalVisible(false) }}
        />
      )}

    </div>
  );
};

export default System;

