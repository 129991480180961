import React, { useState } from "react";
import { Modal, Button, Space, Steps, message, Tag } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { update, updatePatch } from "../../services/apiService";

const { Step } = Steps;

const Aprprove = ({ itemStatus, id }) => {
	const [status, setStatus] = useState(itemStatus);

	const approve = async () => {
		try {
			const response = await updatePatch('/api/v1/investment-application/update-status', { id }, { status: "approved" });
			if (response?.status === 200) {
				const data = response.data.data;
				setStatus("approved");
			}
		} catch (error) {
			message.error('Failed to update application status');
		}
	}

	if (status != "pending") {
		return (
			<Tag color={status == "approved" ? "green" : "red"}>
				{status || "Unknown"}
			</Tag>
		);
	}

	return (
		<>
			<Button
				type="primary"
				onClick={approve}
			>
				<CheckOutlined /> Approve
			</Button>
		</>
	);
};

export default Aprprove;