import { EditOutlined, SyncOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Spin, Tag } from "antd";
import React, { useState } from "react";
import DetailView from "../../../../components/DetailView";
import { useAddToMondayService, useGetClientByIdService } from '../../../../services/clientService';
import Edit from './Edit';
import { formatDate } from '../../../../utils/formateData';

const ProfileInfoTab = ({ userID, hideBtn }) => {

  const [isLoading, setLoading] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const { addUserToMonday } = useAddToMondayService()
  const { data, isLoading: loading, error } = useGetClientByIdService(userID)

  if (loading) return (
    <div className="flex flex-col items-center justify-center h-80 bg-gray-100">
      <Spin size={"large"} className="mb-4" />
      <p className="text-lg text-gray-700"> Loading Client Details</p>
    </div>
  )

  if (error) return (
    <div className="flex flex-col items-center justify-center h-80 bg-gray-100">
      <p className="text-lg text-red-700"> {error?.message || "Something went wrong to the server"} </p>
    </div>
  )

  const handleFormSubmit = async () => {
    setLoading(true)
    addUserToMonday(
      userID,
      () => setLoading(false),
      () => setLoading(false)
    )
  };

  const profileInfo = [
    { label: 'Account Type', value: data?.accountType || 'N/A' },
    { label: 'User Code', value: data?.ref_info?.user_code || 'N/A' },
    { label: 'Email', value: data?.user_id?.email || 'N/A' },
    { label: 'User Name', value: data?.user_id?.username || 'N/A' },
    { label: 'Legal First Name', value: data?.personal_info?.legal_name || 'N/A' },
    { label: 'Legal Last Name', value: data?.personal_info?.legal_lastname || 'N/A' },
    { label: 'Identity Number', value: data?.personal_info?.identity_number || 'N/A' },
    { label: 'Phone Number', value: data?.personal_info?.phoneNumber || 'N/A' },
    {
      label: 'Account Status',
      value: (
        <Tag color={data?.user_id?.status === 'enabled' ? 'green' : 'red'}>
          {data?.user_id?.status || 'N/A'}
        </Tag>
      ),
    },
    { label: 'City', value: data?.address?.city || 'N/A' },
    { label: 'Address Line 1', value: data?.address.address2 },
    { label: 'Street', value: data?.address?.street || 'N/A' },
    { label: 'Suburb', value: data?.address?.suburb || 'N/A' },
    { label: "State/Province", value: data?.address?.province || 'N/A' },
    { label: "Poastal / Zip Code", value: data?.address?.postal_code || 'N/A' },
    { label: 'Country', value: data?.address?.country || 'N/A' },
    {
      label: 'Date of Birth', value: (
        <p>
          {formatDate(data?.personal_info?.dob) || 'N/A'}
        </p>
      )
    },
    { label: 'Source of Funds', value: data?.funds_source || 'N/A' },
    { label: 'SARS TAX Number', value: data?.company_info?.sars_no },
    { label: "Referral Code", value: data?.ref_info?.ref_code || 'N/A' },
  ];

  return (
    <>
      {
        hideBtn ?
          (<div className="flex justify-end p-4">
            <Button className=" mr-4 rounded-lg py-2 bg-[#22c6ab] text-white hover:bg-[#1da089] transition" type="primary" icon={<EditOutlined />} onClick={() => setIsEditModalVisible(true)} >
              Edit Client
            </Button>

            <Button
              type="primary"
              icon={isLoading ? <SyncOutlined /> : <UploadOutlined />}
              style={{
                padding: '10px 20px',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={handleFormSubmit}
            >
              {isLoading ? "Loading..." : "Upload To monday.com"}
            </Button>
          </div>) : null
      }

      <DetailView data={profileInfo} className="w-full" />

      {isEditModalVisible && (
        <Edit
          userId={userID}
          visible={isEditModalVisible}
          onCancel={() => setIsEditModalVisible(false)}
          onSubmit={() => { setIsEditModalVisible(false) }}
        />
      )}

    </>
  );
};

export default ProfileInfoTab;
