import React, { useState } from "react";
import PaginatedTable from "../../components/PaginatedTable";
import { DownloadOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Space, Tag } from "antd";
import AdminLayout from "../../layouts/AdminLayout";
import { useExportClientService } from "../../services/clientService";
import { formatDate } from "../../utils/formateData";
import Delete from "./Delete";
// import Edit from "./Edit";
import View from "./View";


const ClientOverviewPage = () => {

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const { exportClient } = useExportClientService()

  const columns = [
    {
      title: "User Code",
      dataIndex: "userinfo.ref_info.user_code",
      key: "user_code",
      render: (text, record) => record.userinfo?.ref_info?.user_code || "N/A",
    },
    { title: "Email", dataIndex: "email", key: "email" },
    {
      title: "First Name",
      dataIndex: ["userinfo personal_info legal_name"],
      key: "firstname",
      render: (text, record) =>
        record.userinfo?.personal_info?.legal_name || "N/A",
    },
    {
      title: "Last Name",
      dataIndex: "userinfo.personal_info.legal_lastname",
      key: "lastname",
      render: (text, record) =>
        record.userinfo?.personal_info?.legal_lastname || "N/A",
    },
    {
      title: "Identity Number",
      dataIndex: "userinfo.personal_info.identity_number",
      key: "identity_number",
      render: (text, record) =>
        record.userinfo?.personal_info?.identity_number || "N/A",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color;
        switch (status) {
          case 'disabled':
            color = 'red';
            break;
          case 'enabled':
            color = 'green';
            break;
          default:
            color = 'gray';
        }

        return (
          <Tag color={color}>
            {status.toUpperCase()}
          </Tag>
        );
      }
      //render: (text, record) => record.userinfo?.personal_info?.identity_number || "N/A"
    },
    {
      title: "Phone Number",
      dataIndex: "userinfo.personal_info.phoneNumber",
      key: "phone_number",
      render: (text, record) =>
        record.userinfo?.personal_info?.phoneNumber,
    },
    {
      title: "Broker Code",
      dataIndex: "userinfo.broker_info.broker_code", // Ensure dataIndex is correct
      key: "broker_code",
      render: (text, record) =>
        record.userinfo?.broker_info?.broker_code || "N/A",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => formatDate(text), // Updated render function for date formatting
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <View userId={record._id} />
          {/* <Edit userId={record._id} onUpdateSuccess={setRefresh} /> */}
          <Delete userId={record._id} onDeleteSuccess={setRefresh} status={record?.status} />
        </Space>
      ),
    },
    // All other columns can be added here similarly without leaving placeholders
  ];

  const filters = [
    { name: "userinfo.ref_info.user_code", label: "User Code", type: "text" },
    { name: "email", label: "Email", type: "text" },
    { name: "userinfo.personal_info.legal_lastname", label: "First Name", type: "text" },
    { name: "lastname", label: "Last Name", type: "text" },
    { name: "identity_number", label: "Identity Number", type: "text" },
    {
      name: "status",
      label: "Status",
      type: "dropdown",
      options: ["enabled", "disabled"],
    },
    { name: "phone_number", label: "Phone Number", type: "text" },
    { name: "broker_code", label: "Broker Code", type: "text" },
    { name: "createdAt", label: "Created At", type: "date" },
  ];

  const handleExport = () => {
    setLoading(true);

    exportClient(
      () => setLoading(false),
      () => setLoading(false)
    )
  }

  return (
    <AdminLayout
      user={null}
      header={"Client Overview"}
      currentKey={"client-sub1"}
      openKey="4"
    >
      <div className="mx-auto p-4">
        <Breadcrumb className="mb-4">
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item>Clients</Breadcrumb.Item>
          <Breadcrumb.Item>Overview</Breadcrumb.Item>
        </Breadcrumb>

        <div className="flex justify-between items-center mb-6">
          <div className="mb-4">
            <h3 className="text-lg font-semibold">Client Overview</h3>
          </div>

          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={handleExport}
            className="flex items-center px-4 py-2 bg-[#57249c] text-white rounded-lg hover:bg-[#432076] transition"
          >
            {loading ? "Loading..." : "Export (csv)"}
          </Button>
        </div>


        <PaginatedTable
          fetchEndpoint="/api/v1/clients/index"
          columns={columns}
          //actionColumn={actionColumn}
          isSearchable={true}
          isSelectable={true}
          filters={filters}
          refresh={refresh}
        />
      </div>
    </AdminLayout>
  );
};

export default ClientOverviewPage;
