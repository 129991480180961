import React, { useState } from 'react';
import ScanQrcode from './ScanQrcode';

const TwoFactorAuth = () => {
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);

    return (
        <>
            <div className="bg-white p-8">
                <div className="mx-auto">

                    {/* 2FA Section */}
                    <div className="bg-white p-6 rounded-md shadow-md mb-4">
                        <h2 className="font-semibold mb-2">2FA</h2>
                        <p>Setup two-factor authentication as an extra layer of security for your account.</p>
                        <button
                            onClick={() => setIsAddModalVisible(true)}
                            className="mt-4 bg-[#57249c] text-white rounded-lg hover:bg-[#432076] transition font-semibold py-2 px-4">
                            ENABLE 2FA
                        </button>
                    </div>
                </div>
            </div>


            {/* Modals */}
            {isAddModalVisible && (
                <ScanQrcode
                    visible={isAddModalVisible}
                    onCancel={() => setIsAddModalVisible(false)}
                    onSubmit={() => { setIsAddModalVisible(false) }}
                />
            )}

        </>
    );
}

export default TwoFactorAuth;
