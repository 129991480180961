import axios from "axios";
import { jwtDecode } from "jwt-decode";

const AUTH_SESSION_KEY = "ubold_user";

const baseURL = process.env.REACT_APP_BASE_URL;
// Set default content type and base URL for axios
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = baseURL
//const testUser = 'auth0|66d95413e1ac983f011fb379'
//axios.defaults.baseURL = "https://80eight.zilo-aws.co.za/"

// Intercepting to capture errors
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let message;

    if (error && error.response) {
      switch (error.response.status) {
        case 401:
          message = "Invalid credentials";
          break;
        case 403:
          message = "Access Forbidden";
          window.location.href = "/unauthorized";
          break;
        case 404:
          message = "Sorry! the data you are looking for could not be found";
          break;
        default:
          message = error.response.data.message || error.message || error;
      }
      return Promise.reject(message);
    }
  }
);


axios.interceptors.request.use(
  async (config) => {
    const authUser = getUserFromSession();
    const { sub } = authUser;
    const additionalQueryParam = sub;
    config.params = {
      ...config.params, // Preserve existing params
      authId: additionalQueryParam, // Add the new parameter
    };
    return config;
  },
  (error) => {
    // Handle errors in request creation
    return Promise.reject(error);
  }
);


// Response Interceptor
axios.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response;
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Function to set the default authorization header
const setAuthorization = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

// Function to get user from session storage
const getUserFromSession = () => {
  const user = sessionStorage.getItem(AUTH_SESSION_KEY);
  return user ? JSON.parse(user) : null;
};

//function to get profile

// Fetch data with GET method
const get = (url, params) => {
  const queryString = params
    ? "?" + new URLSearchParams(params).toString()
    : "";
  return axios.get(url + queryString);
};

const getQuery = (url, params, config = {}) => {
  return axios.get(url, { params });
};

// Fetch a file
const getFile = (url, params) => {
  const queryString = params
    ? "?" + new URLSearchParams(params).toString()
    : "";
  return axios.get(url + queryString, { responseType: "blob" });
};

// Create a new resource with POST method
const create = (url, data) => {
  return axios.post(url, data);
};

// Update data with PATCH method
const updatePatch = (url, params = {}, data = {}, config = {}) => {
  return axios.patch(url, data, {
    ...config,
    params, // Add query parameters here
  });
};

// Update data with PUT method
const update = (url, params = {}, data = {}, config = {}) => {
  //return axios.put(url, data);
  return axios.put(url, data, {
    ...config,
    params, // Add query parameters here
  });
};

// Delete a resource
const deleteResource = (url, params = {}, data = {}, config = {}) => {
  //return axios.delete(url);
  return axios.delete(url, {
    ...config,
    params, // Query parameters should be included here
    data, // Body data (in case of DELETE, data might be required)
  });
};

// Create a resource with file upload
/*const createWithFile = (url, data) => {
  const formData = new FormData();
  /*for (const key in data) {
    formData.append(key, data[key]);
  }
  formData.append("data", JSON.stringify(data));

  return axios.post(url, formData, {
    headers: {
      ...axios.defaults.headers,
      "Content-Type": "multipart/form-data",
    },
  });
};*/
const createWithFile = (url, data, files = []) => {
  const formData = new FormData();
  console.log('Files: ', files);

  // Add the main data as a JSON string
  formData.append("data", JSON.stringify(data));

  // Add files to FormData
  /*files.forEach((file, index) => {
    formData.append(`files[${index}]`, file);
  });*/
  files.forEach((file) => {
    formData.append("images", file/*.originFileObj*/); // Ensure original file object is appended
  })

  console.log(formData);

  return axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const updateWithFile = (url, data, files = []) => {
  const formData = new FormData();
  console.log('Files: ', files);

  // Add the main data as a JSON string
  formData.append("data", JSON.stringify(data));

  // Add files to FormData
  /*files.forEach((file, index) => {
    formData.append(`files[${index}]`, file);
  });*/
  files.forEach((file) => {
    formData.append("images", file/*.originFileObj*/); // Ensure original file object is appended
  })

  console.log(formData);

  return axios.patch(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};


// // Update with file upload
// const updateWithFile = (url, data) => {
//   const formData = new FormData();
//   for (const key in data) {
//     formData.append(key, data[key]);
//   }

//   return axios.patch(url, formData, {
//     headers: {
//       ...axios.defaults.headers,
//       "Content-Type": "multipart/form-data",
//     },
//   });
// };

// Add images to a resource with file upload
const addImages = (url, images) => {
  if (!Array.isArray(images) || images.length === 0) {
    throw new Error("images must be a non-empty array of files.");
  }

  const formData = new FormData();
  images.forEach((image, index) => {
    formData.append('images', image); // Use dynamic keys for multiple images
  });

  return axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// Check if the user is authenticated
const isUserAuthenticated = () => {
  const user = getLoggedInUser();
  if (!user) {
    return false;
  }

  const decoded = jwtDecode(user.token);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    console.warn("access token expired");
    return false;
  }
  return true;
};

// Set logged-in user
const setLoggedInUser = (session) => {
  if (session) {
    sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
  } else {
    sessionStorage.removeItem(AUTH_SESSION_KEY);
  }
};

// Get logged-in user
const getLoggedInUser = () => {
  return getUserFromSession();
};

// Set user in session
const setUserInSession = (modifiedUser) => {
  let userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
  if (userInfo) {
    const { token, user } = JSON.parse(userInfo);
    setLoggedInUser({ token, ...user, ...modifiedUser });
  }
};

// Check if token is available in session
const user = getUserFromSession();
if (user) {
  const { token } = user;
  if (token) {
    setAuthorization(token);
  }
}

export const axiosInstance = axios;

export {
  create, createWithFile, deleteResource, get, getFile, getLoggedInUser, getQuery, isUserAuthenticated, setAuthorization, setLoggedInUser, setUserInSession, update, updatePatch, updateWithFile, addImages
};