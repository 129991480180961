import React, { useState, useEffect } from "react";
import { Modal, Descriptions, Button, Spin, message } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { GetadminManager } from "../../services/adminManagerService";
import { getQuery } from "../../services/apiService";

const View = ({ userId }) => {
  const [visible, setVisible] = useState(false);
  const [adminUser, setAdminUser] = useState({});
  console.log(userId);

  const toggleView = () => {
    setVisible(!visible);
  }

  useEffect(() => {
    const getAdminUser = async () => {
      try{
        const response = await getQuery('/admin-user/view', { id: userId });

        if(response.status = 200 && response.data.data){
          setAdminUser(response.data.data);
        }
      }catch(error){
        console.log(error);
        message.error('Could not get Admin user.');
      }
    }

    if(visible){
      getAdminUser();
    }
  }, [userId]);

  /*const { data, isLoading, isError, error } = userId
    ? GetadminManager(userId)
    : {};

  useEffect(() => {
    if (isError) {
      message.error(error?.message || "Failed to load user data.");
    }
  }, [isError, error]);

  useEffect(() => {
    if (data) {
      console.log("Fetched Data:", data);
    }
  }, [userId]);*/

  return (
    <>
      {/*<Button
        type="text"
        icon={<EyeOutlined />}
        style={{ padding: 0 }}
        onClick={() => setVisible(true)}
      />*/}
      <Modal
        open={visible}
        title="View Admin/Manager"
        onCancel={toggleView}
        footer={null}
      >
        {/*{isLoading ? (
          <Spin tip="Loading..." />
        ) : isError ? (
          <div>Error: {error?.message}</div>
        ) : (*/}
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Email">{adminUser?.email}</Descriptions.Item>
            <Descriptions.Item label="Role">{adminUser?.role}</Descriptions.Item>
            <Descriptions.Item label="Status">{adminUser?.status}</Descriptions.Item>
            {/* //{" "}
            <Descriptions.Item label="Created By">
              // {adminManager?.createdBy}
              //{" "}
            </Descriptions.Item>
            //{" "} */}
            {/* <Descriptions.Item label="Created At">
//             {new Date(adminManager?.createdAt).toLocaleString()}
//           </Descriptions.Item>
//           <Descriptions.Item label="Updated At">
//             {new Date(adminManager?.updatedAt).toLocaleString()}
//           </Descriptions.Item> */}
          </Descriptions>
        {/*})}*/}
      </Modal>
    </>
  );
};

export default View;
