import { useAuth0 } from '@auth0/auth0-react';
import { message } from 'antd';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingComponent from '../components/LoadingComponent';
import { getQuery, setAuthorization, setLoggedInUser } from '../services/apiService';
import { useAddLoginHistoryService } from '../services/userService';
import getDeviceDetails from '../utils/DeviceDetails';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

    const [/*isAuthenticated,*/ setIsAuthenticated] = useState(false);
    const [userProfile, setUserProfile] = useState(null);
    const { user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const navigate = useNavigate();
    const { addLoginHistory } = useAddLoginHistoryService();

    const login = async () => {
        try {
            //const token = await getAccessTokenSilently();
            const token = await getAccessTokenSilently({
                audience: 'https://dev-fu88hpvhk3wk7i2x.us.auth0.com/api/v2/',
                scope: 'openid profile email',
            });

            console.log(token);
            const idToken = await getIdTokenClaims();
            console.log("Token: ", idToken)
            setLoggedInUser(idToken);
            setAuthorization(token);

            const response = await getQuery('/api/v1/user/get-user', { authId: user?.sub });

            if (response.status === 200 && response.data.data) {

                setUserProfile(response.data.data);

                if (!sessionStorage.getItem('loginHistoryRecorded')) {
                    const { ipAddress, device, browser } = await getDeviceDetails();

                    const payload = {
                        ipAddress,
                        device,
                        browser,
                    };

                    addLoginHistory(
                        payload,
                        () => {
                            sessionStorage.setItem('loginHistoryRecorded', 'true');
                        },
                        () => {
                            message.error('Failed to record login history');
                        }
                    );
                }
            }
        } catch (error) {
            console.log(error);
            message.error(`Could not fetch user profile.`);
        }

    }

    useEffect(() => {
        if (isAuthenticated) {
            localStorage.setItem('authId', user?.sub);
            console.log("Auth user:", user);
            login();
        }
    }, [isAuthenticated, user]);

    // Show a loading state while the SDK processes authentication
    if (isLoading) {
        //return <div>Loading...</div>;
        return <LoadingComponent />
    }

    if (!isAuthenticated) {
        console.log(window.location.origin);
        return loginWithRedirect();
    }

    const logout = () => setIsAuthenticated(false);

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, userProfile, user }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);

